import styled from "styled-components";

export const Container = styled.div`
  .row__datatable {
    display: flex;
    align-items: center;

    padding-left: 1.6rem;
    height: 4.9rem;

    img {
      background: var(--color-primary-900);
      border-radius: 1rem;
      margin-right: 1rem;
    
      height: 4rem;
      width: 4rem;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--color-primary-700);
      border-radius: 0.8rem;
      margin-left: 1.6rem;
      cursor: pointer;
      border: none;
      height: 3rem;
      width: 3rem;

      :first-child {
        margin: 0;
      }

      :hover {
        background: var(--color-pink);
      }

      svg {
        color: white;
      }
    }
  }

  .filter {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 16rem;
    grid-gap: 1.6rem;
    margin-bottom: 3.2rem;
  }

  th {
    :nth-child(1) {
      background var(--color-primary-800);
    }

    :nth-child(2) {
      span {
        margin-right: 37rem
      }
    }
  }

  .centralized {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
  }

`;
