import React, { useEffect, useState } from 'react'
import { HiCurrencyDollar } from 'react-icons/hi'

import { CardBox, ContainerComponent } from '../../../components'
import { GetStatistics } from '../../../services/Statistics'
import { formatterPrice } from '../../../utils'

import { Container } from './styles'

const StatisticsMerchant = () => {
  const [statistics, setStatistics] = useState(null)

  async function fetchData() {
    try {
      const { data } = await GetStatistics()
      setStatistics(data.data)
    } catch (error) {
      console.log(error?.response?.data)
    }
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent title="Estatísticas">
      <Container>
        <div className="box__header">
          <CardBox>
            <div className="card__header">
              <header>
                <HiCurrencyDollar />
                Valor Itens adicionados no carrinho
              </header>

              <h3>{formatterPrice(statistics?.financial?.paid_amount)}</h3>
            </div>
          </CardBox>

          <CardBox>
            <div className="card__header">
              <header>
                <HiCurrencyDollar />
                Numero total de users
              </header>

              <h3>{statistics?.financial?.total_tickets || 0}</h3>
            </div>
          </CardBox>

          <CardBox>
            <div className="card__header">
              <header>
                <HiCurrencyDollar />
                Ticket Médio
              </header>

              <h3>{formatterPrice(statistics?.financial?.avg_ticket)}</h3>
            </div>
          </CardBox>
        </div>

        <CardBox label="Ranking de itens mais pedidos">
          <div className="box__footer">
            <ul className="card__products">
              {statistics?.products?.map((item, index) => (
                <li key={item.id}>
                  <span>
                    <strong>{index + 1}</strong>
                  </span>

                  <div className="card__products__image">
                    <img src={item.thumbnail} alt={item.name} />
                    <strong>{item.name}</strong>
                  </div>

                  <p>{`${item.total} pedidos`}</p>
                </li>
              ))}
            </ul>
          </div>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { StatisticsMerchant }
