import '../../styles/login.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import React, { useState } from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import LogoLogin from '../../assets/images/logo-escuro.png'
import VisibilityIcon from '@material-ui/icons/Visibility'
import BgLogin from '../../assets/images/bg-login.png'
import { linkRoutes } from '../../components'

const signInFormSchema = yup.object().shape({
  email: yup.string().required('Campo obrigatório').email('Informe um email válido'),
  password: yup.string().required('Campo obrigatório')
})

export const SignIn = ({ onFormSubmit, logged, ...props }) => {
  const { formState, handleSubmit, register } = useForm({ resolver: yupResolver(signInFormSchema) })
  const { errors } = formState

  const [type, setType] = useState('password')

  function handleSignIn({ password, email }) {
    const user = {
      role: 'ADMIN',
      password,
      email
    }

    onFormSubmit(user)
  }

  let { from } = props.location.state || {
    from: { pathname: '/statistics' }
  }

  const showHide = e => {
    setType(type === 'input' ? 'password' : 'input')
  }

  return (
    <>
      {logged ? (
        <Redirect to={from} />
      ) : (
        <div className="login-body">
          <div className="bg-container">
            <img src={BgLogin} alt="Background Login" />
          </div>

          <div className="form-container">
            <div className="content">
              <div className="content-logo">
                <img className="container-logo" src={LogoLogin} alt="Logo Login" />
                <h1>Bem-vindo</h1>
              </div>
              <form onSubmit={handleSubmit(handleSignIn)} className="card">
                <div className="content-form">
                  <div className="container-input">
                    <label htmlFor="inputEmail">E-mail</label>
                    <div className="content-input">
                      <input
                        type="email"
                        id="inputEmail"
                        placeholder="emaly@email.com"
                        name="email"
                        error={errors.email}
                        {...register('email')}
                      />
                    </div>
                    <p>{errors.email?.message}</p>
                  </div>

                  <div className="container-input">
                    <label className="password">Password </label>
                    <div className="content-input">
                      <input
                        type={type}
                        className="current-password"
                        placeholder="Senha"
                        toggleMask
                        name="password"
                        error={errors.password}
                        {...register('password')}
                      />

                      {type === 'input' ? (
                        <VisibilityIcon onClick={() => showHide()} />
                      ) : (
                        <VisibilityOffIcon onClick={() => showHide()} />
                      )}
                    </div>
                    <p>{errors.password?.message}</p>
                  </div>

                  <div>
                    <Link to={linkRoutes.forgotPassword} className="forgetPassword">
                      Esqueci minha senha
                    </Link>

                    <Link to={linkRoutes.firstAccess} className="forgetPassword">
                      Primeiro acesso
                    </Link>
                  </div>

                  <button style={{ marginTop: '1.6rem' }}>Entrar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
