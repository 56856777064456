import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  .container__background {
    img {
      object-fit: cover;
      height: 100vh;
      width: 50vw;
    }
  }

  .container__form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
      color: var(--color-gold);
    }

    form {
      max-width: 40rem;
      width: 100%;
    }
  }
`
