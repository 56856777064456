import React, { useEffect, useState } from "react";
import { HiBan, HiCheck, HiChevronDown, HiX } from "react-icons/hi";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import {
  ContainerComponent,
  ComponentModal,
  Button,
  Input,
  AutoComplete,
  InputSelect,
} from "../../../components";
import {
  CancelNotifications,
  GetOneNotifications,
  PostNotifications,
} from "../../../services/Notifications";
import { GetAllStores } from "../../../services/Stores";
import { ageOptions, gendersOptions } from "./utils";

import { Container } from "./styles";

const NotificationsHandle = () => {
  const history = useHistory();

  const [notification, setNotification] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [stores, setStores] = useState([]);

  async function fetchData() {
    const editNotification = history?.location?.state?.notification;
    if (editNotification) setIsEdit(true);

    try {
      const { data: response } = await GetAllStores({ page_size: 40 });
      const storesFormatted = response.data.results.map((item) => ({
        text: item.name,
        value: item.id,
        name: "stores",
      }));

      if (editNotification) {
        const { data: response } = await GetOneNotifications(
          editNotification.id
        );
        const stores = response.data.stores.map((store) => store.name);
        setNotification({ ...response.data, stores });
      }

      setStores(storesFormatted);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleCreateNotification() {
    try {
      await PostNotifications(notification);
      toast.success("Notificação criada com sucesso");
      setOpenModal(true);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleInvalidateNotification() {
    try {
      await CancelNotifications(notification.id);
      toast.success("Notificação desativada com sucesso");
      setOpenModal(true);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target;
    setNotification((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleChangeMinAge = (value) =>
    setNotification((prevState) => ({ ...prevState, min_age: value }));
  const handleChangeMaxAge = (value) =>
    setNotification((prevState) => ({ ...prevState, max_age: value }));
  const handleChangeStore = (value) => {
    if (!value) {
      setNotification((prevState) => ({ ...prevState, stores: [] }));
    } else setNotification((prevState) => ({ ...prevState, stores: [value] }));
  };

  useEffect(() => fetchData(), []);

  return (
    <>
      <ComponentModal
        onRequestClose={() => setOpenModal(!openModal)}
        isOpen={openModal}
      >
        <h3>{`Notificação ${isEdit ? "desativada" : "criada"} com sucesso`}</h3>

        <Button
          customStyles={{
            borderRadius: "1.6rem",
            height: "4rem",
            width: "20rem",
          }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <ContainerComponent
        headerLeft={["Notificações", isEdit ? "Detalhes" : "Criar notificação"]}
        title={isEdit ? "Detalhes" : "Criar notificação"}
      >
        <Container>
          <Input
            customStyles={{ color: "var(--color-gold)", gridColumn: "1/3" }}
            placeholder="Insira o título da notificação"
            onChange={handleChangeState}
            value={notification.title}
            label="Título notificação"
            disabled={isEdit}
            name="title"
          />

          <Input
            customStyles={{ color: "var(--color-gold)", gridColumn: "1/3" }}
            placeholder="Insira uma descrição para a notificação"
            onChange={handleChangeState}
            value={notification.body}
            disabled={isEdit}
            label="Descrição"
            name="body"
          />

          {isEdit ? (
            <>
              <Input
                customStyles={{ color: "var(--color-gold)" }}
                colorIconBg="var(--color-primary)"
                value={notification.genders}
                icon={HiChevronDown}
                colorIcon="white"
                disabled={isEdit}
                label="Sexo"
              />

              <Input
                customStyles={{ color: "var(--color-gold)" }}
                colorIconBg="var(--color-primary)"
                value={notification.stores}
                icon={HiChevronDown}
                label="Restaurante"
                colorIcon="white"
                disabled={isEdit}
              />
            </>
          ) : (
            <>
              <InputSelect
                customStyles={{ color: "var(--color-gold)" }}
                onChange={handleChangeState}
                options={gendersOptions}
                label="Sexo"
                name="genders"
              />

              <AutoComplete
                placeholder="Vazio = Todos os usuários"
                labelColor="var(--color-gold)"
                setState={handleChangeStore}
                suggestions={stores}
                label="Restaurante"
              />
            </>
          )}

          {isEdit ? (
            <>
              <Input
                customStyles={{ color: "var(--color-gold)" }}
                colorIconBg="var(--color-primary)"
                value={notification.min_age}
                label="Idade mínima"
                icon={HiChevronDown}
                disabled={isEdit}
                colorIcon="white"
              />

              <Input
                customStyles={{ color: "var(--color-gold)" }}
                colorIconBg="var(--color-primary)"
                value={notification.max_age}
                label="Idade máxima"
                icon={HiChevronDown}
                disabled={isEdit}
                colorIcon="white"
              />
            </>
          ) : (
            <>
              <AutoComplete
                labelColor="var(--color-gold)"
                setState={handleChangeMinAge}
                label="Idade mínima"
                suggestions={ageOptions}
                type="number"
              />

              <AutoComplete
                labelColor="var(--color-gold)"
                setState={handleChangeMaxAge}
                label="Idade máxima"
                suggestions={ageOptions}
                type="number"
              />
            </>
          )}

          {isEdit && (
            <Input
              customStyles={{ color: "var(--color-gold)" }}
              value={notification.total_sent}
              label="Enviados"
              disabled
            />
          )}

          <div className="box__options">
            <Button
              onClick={
                isEdit ? handleInvalidateNotification : () => history.goBack()
              }
              icon={isEdit ? HiBan : HiX}
              customStyles={{
                background: "var(--color-primary-500)",
                borderRadius: "1.6rem",
                marginRight: "1.6rem",
                height: "4rem",
                width: "20rem",
              }}
            >
              {isEdit ? "Desativar" : "Cancelar"}
            </Button>

            {!isEdit && (
              <Button
                customStyles={{
                  borderRadius: "1.6rem",
                  height: "4rem",
                  width: "20rem",
                }}
                onClick={handleCreateNotification}
                icon={HiCheck}
              >
                Criar
              </Button>
            )}
          </div>
        </Container>
      </ContainerComponent>
    </>
  );
};

export { NotificationsHandle };
