import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/storage'

const firebaseConfig = {
  appId: '1:677832223928:web:7dbacb9107b3af6a4e584d',
  apiKey: 'AIzaSyDWbiuQxydpmLs_WeWzt6lQbr0pQfER5W0',
  authDomain: 'feedme-cb616.firebaseapp.com',
  storageBucket: 'feedme-cb616.appspot.com',
  messagingSenderId: '677832223928',
  measurementId: 'G-HS3FQJ29X0',
  projectId: 'feedme-cb616'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export default firebase
