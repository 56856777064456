import { toast } from 'react-toastify'

export function validatePayload(payload) {
  const keys = Object.values(payload)

  for (let key in keys) {
    if (!keys[key]) {
      toast.error('Por favor preencher os campos obrigatórios.')
      return false
    }
  }

  return true
}
