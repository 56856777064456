import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-modal'

import { Container } from './style'

export const ComponentModal = ({ onRequestClose, customStyles, children, isOpen, zIndex }) => {
  return (
    <Modal
      overlayClassName="React-modal-overlay"
      onRequestClose={onRequestClose}
      className={{ display: 'flex' }}
      isOpen={isOpen}
    >
      <Container customStyles={customStyles}>
        <div className="button__close">
          <AiOutlineClose onClick={onRequestClose} />
        </div>

        {children}
      </Container>
    </Modal>
  )
}
