import { useEffect, useState } from "react";

export const parseHtmlFromString = (string = "") =>
  string
    .replace(/&amp;/g, "&")
    .replace(/&#34;/g, '"')
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(
      /\w</g,
      (ocurr) => `${ocurr.substring(0, 1)} ${ocurr.substring(1)}`
    );

export const removeHtmlTags = (string = "") =>
  string.replace(/<\/?[^>]+(>|$)/g, "").trim();

export const removeAccentuation = (string) =>
  string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

export const removeCharacters = (string) => string.replace(/[^a-zA-Z0-9]/g, "");

export const sanitize = (string = "") =>
  string.replace(/['"<>[\]{}();:\/\\]/g, "");

export const generateUUID = () => {
  let d = new Date().getTime(); // Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported

  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const formatPrice = (string) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(string);
};

export const useResize = (containerRef) => {
  const [widthContainer, setWidthContainer] = useState(1067.2);

  useEffect(() => {
    const handleResize = () => {
      setWidthContainer(containerRef.current.offsetWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);

  return { widthContainer };
};

export const cep = (value) => {
  value = value.replace(/\D/g, "");

  if (value.length > 8) {
    value = value.slice(0, -1);
  }

  value = value.replace(/(\d{5})(\d)/, "$1-$2");
  return value;
};
