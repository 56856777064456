import React from 'react'

import { Container } from './styles'

export const Button = ({ iconRight: IconRight, customStyles, icon: Icon, children, ...rest }) => {
  return (
    <Container customStyles={customStyles} {...rest} type={rest.type || 'button'}>
      <div>
        {Icon && <Icon size={16} className="svgLeft" />}

        {children}

        {IconRight && <IconRight className="svgRight" />}
      </div>
    </Container>
  )
}
