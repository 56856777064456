import { saveAs } from 'file-saver'
import QRCodeSVG from 'qrcode-svg'
import JSZip from 'jszip'

const zip = JSZip()

const download = () => {
  zip.generateAsync({ type: 'blob' }).then(function (blob) {
    saveAs(blob, 'mesas.zip')
  })
}

export const generateZipQRCode = array => {
  if (array.length) {
    for (const { table_number, store_id } of array) {
      const qrcode = new QRCodeSVG({
        content: `${process.env.REACT_APP_QRCODE_URL}?s=${store_id}&t=${table_number}`
      }).svg()

      zip.file(`mesa_${table_number}.svg`, qrcode, { binary: true })
    }

    download()
  }
}
