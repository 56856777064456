import React, { useEffect, useState } from "react";
import {
  HiCheck,
  HiChevronDown,
  HiOutlineX,
  HiPhotograph,
  HiLink,
} from "react-icons/hi";
import { useHistory } from "react-router";
import { FilePond } from "react-filepond";
import { toast } from "react-toastify";
import { InputTextarea } from "primereact/inputtextarea";
import {
  Button,
  ContainerComponent,
  CheckBox,
  Input,
  InputSelect,
  ComponentModal,
  Switch,
  linkRoutes,
} from "../../../components";
import {
  handleCreateImagesPreviewFromUrls,
  uploadArrayImagesFirebase,
  clearObject,
} from "../../../utils";
import {
  PostProduct,
  GetComplementGroup,
  PatchProduct,
  GetProduct,
} from "../../../services/Product";
import {
  optionsProductsIcons,
} from "../../../utils/optionsSelect";
import { GetCategoryProducts } from "../../../services/Category";
import { formatterPrice } from "../../../utils/formatters";
import { Container, ModalContainer } from "./styles";
import data from "../Imports/mock";
import { Autocomplete } from "@material-ui/lab"
import { TextField } from "@material-ui/core"
import { GetOpdvCatalog } from "../../../services/Stores";

const ProductHandle = () => {
  const history = useHistory();

  const [openModalIcons, setOpenModalIcons] = useState(false);
  const [product, setProduct] = useState({ available: true });
  const [aspect_ratio, setAspectRatio] = useState("1:1");
  const [category_id, setCategoryId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [imageIcon, setImageIcon] = useState(null);
  const [isReady, setIsReady] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [options, setOptions] = useState({});
  const [files, setFiles] = useState([]);

  const [opdvOptions, setOpdvOptions] = useState(false)
  const [opdvModal, setOpdvModal] = useState(false)
  const [opdvEdit, setOpdvEdit] = useState(false)
  const [selectedOpdvProduct, setSelectedOpdvProduct] = useState(null)
  let allCategories = []

  const fetchData = async () => {
    const categoryHistory = history?.location?.state?.category;
    const productHistory = history?.location?.state?.product;

    if (!!categoryHistory) setCategoryId(categoryHistory.id);

    try {
      if (!!productHistory) {
        const { data: response } = await GetProduct(productHistory.id);
        const { category_id, images } = response.data;

        const imagesUrl = handleCreateImagesPreviewFromUrls(images);

        setCategoryId(category_id);
        setProduct(response.data);
        setFiles(imagesUrl);
        setIsEdit(true);
      }

      const categoryData = await GetCategoryProducts({ include: "products" });
      const complementData = await GetComplementGroup();

      const complement = complementData.data.data.results.map(
        ({ id, name }) => ({ value: id, label: name })
      );
      const categories = categoryData.data.data.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      setOptions({ categories, complement });
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  };

  async function updateCategory() {
    const { data: categories } = await GetCategoryProducts({ include: 'products' })
    const categoryUpdated = categories.data.find(c => c.id === category_id)
    const updatedFormatted = {
      ...categoryUpdated,
      products: categoryUpdated.products.map((product) => ({
        ...product,
        price: formatterPrice(product.price),
      })),
      total_products: categoryUpdated.products.length,
    }
    history.push(linkRoutes.categoryUpdate, { category: updatedFormatted })
  }

  async function handleCreateOrUpdateProduct() {
    if (!category_id)
      return toast.error(
        "Você precisa selecionar uma categoria para o produto"
      );
    if (!product.external_code)
      return toast.error("Você precisa informar o código do produto");
    if (!product.price)
      return toast.error("Você precisa informar o preço do produto");
    if (!product.name)
      return toast.error("Você precisa informar o nome do produto");

    clearObject(product, [
      "list_priority",
      "created_at",
      "updated_at",
      "deleted_at",
      "_thumbnail",
      "category",
    ]);
    clearObject(product, ["store_id", "n_likes", "n_sold", "_image", "store"]);
    const images = files.map((image) => image?.options?.file?.name);

    Object.assign(product, {
      image_aspect_ratio: aspect_ratio === "1:1" ? 1 : 0.8,
      price: product?.price?.toString().replace(/\D/g, ""),
      thumbnail: images[0],
      image: images[0],
      category_id,
      images,
    });

    try {
      if (isEdit) await PatchProduct({ id: product.id, payload: product });
      if (!isEdit) await PostProduct(product);

      toast.success(`Produto ${isEdit ? "atualizado" : "criado"} com sucesso`);
      updateCategory()
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handlePrepareFile(fileItem, outputFile) {
    setIsReady(false);

    const imageUploaded = await uploadArrayImagesFirebase(outputFile);
    const [imagePreview] = handleCreateImagesPreviewFromUrls(imageUploaded);
    const newArray = [imagePreview, ...files];

    setFiles(newArray);
    setIsReady(true);
  }

  async function handleDeleteFile(error, outputFiles) {
    const { file } = outputFiles;
    // await deleteFileFirebase(file.name)
    const newFilesUpload = files.filter(
      (item) => file.name !== item?.options?.file?.name
    );
    setFiles(newFilesUpload);
  }

  function handleImageIcon() {
    setProduct((prevState) => ({ ...prevState, image: imageIcon.value }));
    setOpenModalIcons(false);
  }

  function handleChangeState(event) {
    if (event.label) {
      const { id, digitalName, price } = event.value
      setProduct((prevState) => ({ ...prevState, integration_code: id.toString(), integration_name: digitalName, price, origin: 'opdv' }));
    } else {
      const { value, name, type } = event.target;

      if (type === "checkbox")
        setProduct((prevState) => ({ ...prevState, [name]: !product[name] }));
      else setProduct((prevState) => ({ ...prevState, [name]: name === 'complements' ? [value] : value }));
    }
  }

  async function handleOpdv() {
    try {
      // TO DO: SALVAR EM MEMO?
      const opdvCatalog = await GetOpdvCatalog('opdv')
      if (!opdvCatalog.data.data.status) getAllOPDVCategoriesWithItems(opdvCatalog.data.data)
      // getAllOPDVCategoriesWithItems(data)

    } catch (e) {
      console.log("ERRO>>>>", e.message)
      setOpdvModal(false)
      if (e.message.includes('429')) toast.error('Limite de chamadas alcançado. Tente novamente em alguns minutos')
      if (e.message.includes('400')) toast.error('Não há integração configurada para esta loja')
    }
  }

  async function getAllOPDVCategoriesWithItems(categories) {
    let otherCategories = []
    if (!otherCategories.length) {
      categories.forEach(c => {
        if (!c.categories.length) {
          allCategories.push(c)
        } else {
          otherCategories.push(...c.categories)
        }
      })
    }
    if (otherCategories.length) {
      getAllOPDVCategoriesWithItems(otherCategories)
    } else {
      setOpdvOptions(allCategories.flatMap(cat => cat.items).map((p) => ({ label: p.name, value: p })))
    }
  }

  useEffect(() => fetchData(), []);

  return (
    <>
      <ComponentModal
        onRequestClose={() => setOpenModal(!openModal)}
        isOpen={openModal}
      >
        <h3>{`Produto ${isEdit ? "editado" : "criado"} com sucesso!`}</h3>

        <Button
          customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
          onClick={history.goBack}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <ComponentModal
        customStyles={{ background: "var(--color-primary-200)" }}
        onRequestClose={() => setOpdvModal(false)} isOpen={opdvModal}
      >
        <div>
          <Autocomplete
            // sx={{ width: 300 }}
            value={selectedOpdvProduct}
            onChange={(e, newValue) => setSelectedOpdvProduct(newValue)}
            id="opdv-product-link"
            options={opdvOptions}
            style={{ background: "white" }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label=" "
              />
            )}
            PopperProps={{
              style: {
                zIndex: 9999999, // Adjust the z-index value as needed
              },
            }}
          />

          < Button
            onClick={() => setOpdvModal(false)}
            customStyles={{
              background: "var(--color-primary)",
              borderRadius: "1.6rem",
              marginRight: "1.6rem",
              height: "4rem",
            }}
          >
            CANCELAR
          </Button >

          <Button
            customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
          // onClick={handleCreateCategory}
          >
            CONFIRMAR
          </Button>
        </div>
      </ComponentModal>

      <ComponentModal
        onRequestClose={() => setOpenModalIcons(!openModalIcons)}
        customStyles={{ background: "#4d5860" }}
        isOpen={openModalIcons}
      >
        <ModalContainer>
          <div className="box__modal change__icons">
            <h2>Selecionar Ícone</h2>
            <h3>Produto: {imageIcon?.label || "Nenhum Selecionado"}</h3>
            <h4>Associe o ícone ao produto:</h4>

            <div className="box__icons">
              {optionsProductsIcons.map((icon, index) => (
                <div key={index} className="icon">
                  <CheckBox
                    onChange={() => setImageIcon(icon)}
                    checked={icon === imageIcon}
                  />
                  <img src={icon.value} alt={icon.label} />
                </div>
              ))}
            </div>

            <div>
              <Button
                customStyles={{
                  background: "var(--color-grey-400)",
                  borderRadius: "1.6rem",
                  marginRight: "1.6rem",
                  height: "4rem",
                }}
                onClick={() => setOpenModalIcons(false)}
                icon={HiOutlineX}
              >
                Cancelar
              </Button>

              <Button
                customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
                onClick={handleImageIcon}
                icon={HiCheck}
              >
                aplicar
              </Button>
            </div>
          </div>
        </ModalContainer>
      </ComponentModal>

      <ContainerComponent
        headerLeft={[
          "Produtos",
          isEdit ? "Editar produto" : "Criar novo produto",
        ]}
        title={isEdit ? "Editar produto" : "Criar novo produto"}
      >
        <Container>
          <Input
            customStyles={{ color: "var(--color-gold)" }}
            placeholder="Insira o nome do produto..."
            onChange={handleChangeState}
            value={product.name}
            label="Nome"
            name="name"
          />

          <div className="box__description">
            <span>Descrição</span>
            <InputTextarea
              name="description"
              label="Descrição do Produto"
              rows={5}
              cols={30}
              value={product.description}
              onChange={handleChangeState}
              autoResize
            />
          </div>

          <div className="box__grid">
            <Input
              customStyles={{ color: "var(--color-gold)" }}
              value={formatterPrice(product.price)}
              onChange={handleChangeState}
              label="Preço"
              name="price"
            />

            <Input
              placeholder="Informe o código para do produto..."
              customStyles={{ color: "var(--color-gold)" }}
              value={product.external_code}
              onChange={handleChangeState}
              name="external_code"
              label="Código"
            />

            {product?.integration_code &&
              <>
                <Input
                  customStyles={{ color: "var(--color-gold)" }}
                  value={`${product.integration_code} - ${product.integration_name}`}
                  disabled={true}
                  name="integration_code"
                  label="Integração OPDV"
                />

                {!opdvEdit &&
                  <Button
                    customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
                    onClick={() => {
                      handleOpdv()
                      setOpdvEdit(true)
                    }}
                    disabled={!isReady}
                    icon={HiLink}
                  >
                    Editar Integração
                  </Button>
                }
              </>
            }
            {(opdvEdit && opdvOptions) &&
              <div
                style={{ fontWeight: '400' }}
              >
                Selecionar Novo Vínculo OPDV
                <Autocomplete
                  value={selectedOpdvProduct}
                  onChange={(e, newValue) => handleChangeState(newValue)}
                  id="opdv-product-link"
                  options={opdvOptions}
                  style={{ background: "white", borderRadius: 5, height: '3.8rem', marginTop: '0.5rem' }}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=" "
                    />
                  )}
                />

              </div>
            }

          </div>

          <div className="box__grid">
            <InputSelect
              onChange={(event) => setCategoryId(event.target.value)}
              customStyles={{ color: "var(--color-gold)" }}
              options={options.categories || []}
              bgIcon="var(--color-primary)"
              icon={HiChevronDown}
              value={category_id}
              name="category_id"
              label="Categoria"
            />

            <InputSelect
              customStyles={{ color: "var(--color-gold)" }}
              options={options.complement || []}
              bgIcon="var(--color-primary)"
              onChange={handleChangeState}
              icon={HiChevronDown}
              label="Complementos"
              value={product.complements}
              name="complements"
            />
          </div>

          <div className="box__grid">
            <div style={{ display: "flex" }}>
              <CheckBox
                checked={!!product.complement_only}
                onChange={handleChangeState}
                name="complement_only"
              >
                É um complemento
              </CheckBox>

              <CheckBox
                customStyles={{ marginLeft: "1.6rem" }}
                checked={!!product.allow_observations}
                onChange={handleChangeState}
                name="allow_observations"
              >
                Adicionar observação
              </CheckBox>

              <Switch
                onClick={() =>
                  setAspectRatio(aspect_ratio === "1:1" ? "4:5" : "1:1")
                }
                style={{ marginLeft: "1.6rem" }}
                active={aspect_ratio === "1:1"}
              >
                {aspect_ratio === "1:1"
                  ? "Dimensão da imagem 1:1"
                  : "Dimensão da imagem 4:5"}
              </Switch>
            </div>

            <Button
              onClick={() => setOpenModalIcons(true)}
              customStyles={{
                background: "var(--color-grey-400)",
                borderRadius: "1.6rem",
                fontSize: "1rem",
                fontWeight: 100,
                height: "4rem",
              }}
              icon={HiPhotograph}
            >
              Ícones FeedMe
            </Button>
          </div>

          <FilePond
            labelIdle="Arraste aqui uma imagem para anexar"
            acceptedFileTypes={["image/*"]}
            allowImagePreview={true}
            allowFilePoster={true}
            allowMultiple={true}
            files={files}
            type="file"
            // CALLBACKS
            onpreparefile={handlePrepareFile}
            onremovefile={handleDeleteFile}
            // Image CROP
            imageCropAspectRatio={aspect_ratio}
            allowImageCrop
            // Image RESIZE
            imageResizeTargetHeight={1000}
            imageResizeTargetWidth={1000}
            imageResizeMode="contain"
            allowImageResize
          />

          <div className="box__options">
            <div>
              <Button
                onClick={history.goBack}
                icon={HiOutlineX}
                customStyles={{
                  background: "var(--color-grey-400)",
                  borderRadius: "1.6rem",
                  marginRight: "1.6rem",
                  height: "4rem",
                }}
              >
                Cancelar
              </Button>

              <Button
                customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
                onClick={handleCreateOrUpdateProduct}
                disabled={!isReady}
                icon={HiCheck}
              >
                {isEdit ? "Salvar" : "Adicionar"}
              </Button>

              {!product?.integration_code &&
                <Button
                  customStyles={{ borderRadius: "1.6rem", height: "4rem", marginLeft: "1.6rem" }}
                  onClick={() => {
                    handleOpdv()
                    setOpdvEdit(!opdvEdit)}}
                  disabled={!isReady}
                  icon={HiLink}
                >
                  Integração OPDV
                </Button>
              }
            </div>
          </div>
        </Container>
      </ContainerComponent>
    </>
  );
};

export { ProductHandle };
