import React, { useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { HiClipboardList, HiCurrencyDollar } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { calcVariation } from '../../utils/calcVariation'
import { Button, CardBox, ContainerComponent, linkRoutes } from '../../components'
import { GetStatisticsUsers } from '../../services/Statistics'

import { Container } from './styles'

const Customers = () => {
  const [byPeriods, setByPeriods] = useState([])
  const [byWeek, setByWeek] = useState([])

  async function weekCounterHotFix(byWeek) {
   const current = byWeek.reduce((sum, elem) => sum + elem.current, 0);
   const past = byWeek.reduce((sum, elem) => sum + elem.past, 0);
  const variation = calcVariation(past, current)
   
   return { current, past, variation }
  }

  async function fetchData() {
    try {
      const { data: response } = await GetStatisticsUsers()

      const {current, past, variation } = await weekCounterHotFix(response.data.by_week)

      response.data.by_periods[1].current = current
      response.data.by_periods[1].past = past
      response.data.by_periods[1].variation = variation

      setByPeriods(response.data.by_periods)
      setByWeek(response.data.by_week)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      title="Usuários"
      headerRight={
        <Link to={linkRoutes.customersList}>
          <Button customStyles={{ borderRadius: '1.6rem', height: '4rem' }} icon={HiClipboardList}>
            LISTA DE USUÁRIOS
          </Button>
        </Link>
      }
    >
      <Container>
        <div className="box__header">
          <label>Usuários únicos por período:</label>

          <CardBox variation={byPeriods[0]?.variation.toFixed(2)} activeBorder>
            <div className="cards">
              <header>
                <HiCurrencyDollar />
                Hoje
              </header>

              <h2>{byPeriods[0]?.current || 0}</h2>
            </div>
          </CardBox>

          <CardBox variation={byPeriods[1]?.variation.toFixed(2)} activeBorder>
            <div className="cards">
              <header>
                <HiCurrencyDollar />
                Semanal
              </header>

              <h2>{byPeriods[1]?.current || 0}</h2>
            </div>
          </CardBox>

          <CardBox variation={byPeriods[2]?.variation.toFixed(2)} activeBorder>
            <div className="cards">
              <header>
                <HiCurrencyDollar />
                Mensal
              </header>

              <h2>{byPeriods[2]?.current || 0}</h2>
            </div>
          </CardBox>

          <CardBox variation={byPeriods[3]?.variation.toFixed(2)} activeBorder>
            <div className="cards">
              <header>
                <HiCurrencyDollar />
                Anual
              </header>

              <h2>{byPeriods[3]?.current || 0}</h2>
            </div>
          </CardBox>
        </div>

        <CardBox label="Usuários ativos semanais">
        <div className="platforms__labels">
              <p>Semana atual</p>
              <p>Semana anterior</p>
            </div>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={byWeek}>
              <XAxis dataKey="label" />
              <YAxis />
              <Bar dataKey="current" fill="var(--color-pink)" />
              <Bar dataKey="past" fill="var(--color-gold)" />
            </BarChart>
          </ResponsiveContainer>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { Customers }
