import firebase from '../../services/firebaseClient'
import { resizeImage } from '../resizeImage'

export async function uploadArrayFilesFirebase(files: Files[], type: 'images' | 'videos'): Promise<string[]> {
  const array = Array.isArray(files) ? files : [files]

  try {
    return await Promise.all(
      array.map(async file => {
        if (typeof file === 'string') return file
        if (file.url) return file.name

        const fileName = `${new Date().getTime()}-${file.name}`
        const storageRef = firebase.storage().ref(`${type || 'images'}/${fileName}`)

        switch (type) {
          case 'videos':
            await storageRef.put(file)
            break

          default:
            const imageCompressed = await resizeImage({ file })
            await storageRef.put(imageCompressed)
            break
        }

        return await storageRef.getDownloadURL()
      })
    )
  } catch (error) {
    console.log(error)
  }
}
