import styled from 'styled-components'

export const Container = styled.div`
  .top__user {
    strong {
      color: var(--color-gold);
      font-weight: 400;
      font-size: 1.4rem;
    }

    div {
      display: flex;
      align-items: center;

      border-radius: 0.8rem;
      margin-top: 0.6rem;
      background: white;
      padding: 0.1rem;

      p,
      strong {
        color: black;
      }

      img {
        border-radius: 0.8rem;
        margin-right: 0.8rem;
        object-fit: cover;
        height: 4rem;
        width: 4rem;
      }

      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  .box__options {
    display: flex;
    justify-content: space-between;
    grid-column: 1 / 3;

    .switch {
      display: flex;
      align-items: center;

      p {
        margin-left: 0.8rem;
        font-weight: 400;
      }
    }
  }
`
