import React, { useEffect, useState } from 'react'
import { HiOutlinePlus, HiQrcode, HiGift, HiX, HiTrash, HiDocumentDownload } from 'react-icons/hi'
import { ProgressBar } from 'primereact/progressbar'
import { ClipLoader } from 'react-spinners'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { motion } from 'framer-motion'
import { toast } from 'react-toastify'
import QRCode from 'react-qr-code'

import { downloadImage, formatterPrice, itsBirthdayBoy, chronometer, generateZipQRCode } from '../../utils'
import { PostOrdersWaiterDismiss, PostOrdersClose, PostOrdersSettle } from '../../services/Order'
import { Button, ComponentModal, ContainerComponent, linkRoutes } from '../../components'
import { PatchTable, GetAllTables, DeleteTable } from '../../services/Tables'
import { DismissTheCommand, GetOrdersId } from '../../services/Hall'
import { PostPaymentsPhysical } from '../../services/Payments'
import callWaiter from '../../assets/images/callWaiter.svg'
import notImage from '../../assets/images/notImage.png'

import { CardCustomer, CardCustomerInfo, Container, ContainerOptions, TableSelect, TableBox } from './styles'

const Hall = () => {
  const { data: tables, isLoading, isFetched } = useQuery('tables', fetchData, { refetchInterval: 1000 })
  const { data: tickets } = useQuery('orders_id', fetchDataOrder, { refetchInterval: 1000 })

  const [modalConfirmDeleteTable, setModalConfirmDeleteTable] = useState(false)
  const [modalFreeAllTables, setModalFreeAllTables] = useState(false)
  const [openModalQRCode, setOpenModalQRCode] = useState(false)
  const [modalFreeTable, setModalFreeTable] = useState(false)
  const [tablesOccupied, setTablesOccupied] = useState(0)
  const [openTableBox, setOpenTableBox] = useState(false)
  const [tableSelect, setTableSelect] = useState({})
  const [customer, setCustomer] = useState({})

  async function fetchData() {
    try {
      const { data: response } = await GetAllTables()

      const tablesFormatted = response.data.map(table => ({
        ...table,
        total_value: formatterPrice(table?.order?.subtotal)
      }))

      const totalTablesOccupied = response?.data?.filter(item => item?.status === 'OCCUPIED')

      tablesFormatted.sort( (a, b) => Number(a.number) - Number(b.number) )
      setTablesOccupied(totalTablesOccupied?.length)
      return tablesFormatted
    } catch (error) {}
  }   

  async function fetchDataOrder() {
    if (!tableSelect.order_id) return []

    try {
      const { data: response } = await GetOrdersId(tableSelect?.order_id)

      return response?.data?.tickets?.map(customer => ({
        waiter_requested: customer?.customer?.id === response?.data?.waiter_requester,
        total_price: formatterPrice(customer?.total || customer?.subtotal),
        ...customer
      }))
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleOpenTableBox(table, target) {
    const { offsetWidth } = document.querySelector('#table__container')
    const { offsetTop } = document.querySelector(`#${table.id}`)

    const tableReferenceWidth = `${offsetWidth * 0.087}rem`
    const tableReferenceTop = `${offsetTop * 0.1}rem`

    setTableSelect({ tableReferenceWidth, tableReferenceTop, ...table })
    setOpenTableBox(true)
  }

  async function handleDeleteTable() {
    try {
      await DeleteTable(tableSelect.id)

      setModalConfirmDeleteTable(false)
      setOpenTableBox(false)
      setTableSelect({})

      toast.success('Mesa excluída com sucesso!')
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleFreeAllTables() {
    try {
      for await (const table of tables) {
        if (table.status === 'AVAILABLE') continue
        await handleFreeTable(table)
      }

      toast.success('Mesas liberadas com sucesso')
      setModalFreeAllTables(false)
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar liberar as mesas')
    }
  }

  async function handleFreeTable(table_param) {
    const table = table_param || tableSelect

    const payload = { id: table.id, status: 'AVAILABLE' }

    try {
      if (table?.order?.status === 'OPENED') await PostOrdersClose(table?.order_id, { settle: true })
      if (table?.order?.status === 'CLOSED' && table?.order?.payment_status !== 'PAID') {
        await PostOrdersSettle(table.order_id)
      }

      const { data: response } = await PatchTable(payload)

      if (response?.data?.status === 'AVAILABLE') {
        setModalFreeTable(false)
        setOpenTableBox(false)
        setTableSelect({})

        return
      }

      toast.success('Mesa liberada.')
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleDismissCommand(ticket) {
    const payload = { customer_id: ticket?.customer_id, order_id: ticket?.order_id, amount: ticket?.total }

    try {
      // if (!ticket?.closed) await DismissTheCommand({ order_id: ticket?.order_id, ticket_id: ticket?.id })
      await PostPaymentsPhysical(payload)
      setCustomer({})
      setTableSelect({})
      setOpenTableBox(false)
      toast.success('Baixa dada na comanda!')
    } catch (error) {
      toast.error(error.response.data.error.message)
    }
  }

  async function handleWaiterDismiss() {
    try {
      await PostOrdersWaiterDismiss(tableSelect?.order_id)
      toast.success('Garçom liberado!')
    } catch (error) {
      toast.error(error.response.data.error.message)
    }
  }

  function handleDownloadQRCodes() {
    const allQRCodes = tables.map(table => ({ table_number: table?.number, store_id: table?.store_id }))
    generateZipQRCode(allQRCodes)
  }

  function handleCustomer(customer) {
    if (!customer.id) {
      setCustomer({})
      return
    }

    const items = []

    for (const item of customer.items) {
      if (item?.status !== 'SAVED') {
        const itemExist = items?.find(itemFind => itemFind?.product_id === item?.product_id)

        if (itemExist)
          items?.forEach(product => {
            if (product?.product_id === item?.product_id) {
              product.total_product += 1
            }
          })

        if (!itemExist) items.push({ ...item, total_product: 1 })
      }
    }

    setCustomer({ ...customer, items })
  }

  useEffect(() => fetchDataOrder(), [tableSelect, fetchDataOrder])

  function closeModalQrcode() {
    const table = tables.find(table => table.id === tableSelect.id)

    table.tableReferenceTop = tableSelect.tableReferenceTop
    table.tableReferenceWidth = tableSelect.tableReferenceWidth

    setTableSelect(table)
    setOpenModalQRCode(false)
  }

  return (
    <>
      <ComponentModal onRequestClose={() => setModalFreeAllTables(false)} isOpen={modalFreeAllTables}>
        <h3>Tem certeza que deseja liberar todas as mesas?</h3>

        <div style={{ display: 'flex' }}>
          <Button
            customStyles={{ borderRadius: '1.6rem', height: '4rem', marginRight: '1.6rem' }}
            onClick={() => setModalFreeAllTables(false)}
          >
            CANCELAR
          </Button>

          <Button customStyles={{ borderRadius: '1.6rem', height: '4rem' }} onClick={() => handleFreeAllTables()}>
            LIBERAR TODAS AS MESAS
          </Button>
        </div>
      </ComponentModal>

      <ComponentModal onRequestClose={() => setModalFreeTable(false)} isOpen={modalFreeTable}>
        <h3>Tem certeza que deseja liberar a mesa {tableSelect?.number}?</h3>

        <div style={{ display: 'flex' }}>
          <Button
            customStyles={{ borderRadius: '1.6rem', height: '4rem', marginRight: '1.6rem' }}
            onClick={() => setModalFreeTable(false)}
          >
            CANCELAR
          </Button>

          <Button customStyles={{ borderRadius: '1.6rem', height: '4rem' }} onClick={() => handleFreeTable()}>
            LIBERAR
          </Button>
        </div>
      </ComponentModal>

      <ComponentModal onRequestClose={() => closeModalQrcode()} 
        isOpen={openModalQRCode}>
        <QRCode
          value={`${process.env.REACT_APP_QRCODE_URL}?s=${tableSelect?.store_id}&t=${tableSelect?.number}`}
          id="QRCode"
        />

        <Button onClick={() => downloadImage('QRCode')}>Download QRCode</Button>
      </ComponentModal>

      <ComponentModal onRequestClose={() => setModalConfirmDeleteTable(false)} isOpen={modalConfirmDeleteTable}>
        <h3>Tem certeza que deseja excluir a mesa {tableSelect?.number}?</h3>

        <div style={{ display: 'flex' }}>
          <Button
            customStyles={{ borderRadius: '1.6rem', height: '4rem', marginRight: '1.6rem' }}
            onClick={() => setModalConfirmDeleteTable(false)}
          >
            CANCELAR
          </Button>

          <Button customStyles={{ borderRadius: '1.6rem', height: '4rem' }} onClick={handleDeleteTable} icon={HiTrash}>
            EXCLUIR
          </Button>
        </div>
      </ComponentModal>

      <ComponentModal customStyles={{ background: '#414a55' }} onRequestClose={handleCustomer} isOpen={!!customer?.id}>
        <CardCustomerInfo>
          <header>
            <img src={customer?.customer?.profile_picture} alt={customer?.customer?.fullname} />

            <div>
              <h3>{customer?.customer?.fullname}</h3>
              <p>@{customer?.customer?.username}</p>
              <p>{customer?.customer?.checkins} visitas</p>
            </div>
          </header>

          <main>
            {customer?.items?.map(item => (
              <div key={item.id} className="card__product">
                <img src={item?.thumbnail} alt={item?.name} />

                <div className="card__product__values">
                  <div className="flex">
                    <h4>{item?.name}</h4>

                    <strong>X {item?.total_product}</strong>
                  </div>

                  <p className="product__price">{formatterPrice(item?.total_product * item?.total_price)}</p>
                </div>
              </div>
            ))}
          </main>

          <footer>
            <p>
              Consumo total até o momento: <strong>{formatterPrice(customer?.total || customer?.subtotal)}</strong>
            </p>

            <Button
              customStyles={{ borderRadius: '1.6rem', height: '4rem', marginTop: 0 }}
              onClick={() => handleDismissCommand(customer)}
            >
              DAR BAIXA NO PEDIDO
            </Button>
          </footer>
        </CardCustomerInfo>
      </ComponentModal>

      <ContainerComponent
        title="Salão"
        headerRight={
          <ContainerOptions>
            <div style={{ marginBottom: '3.2rem' }}>
              <Link to={linkRoutes.hallCreate}>
                <Button
                  customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '16rem', marginRight: '1.6rem' }}
                  icon={HiOutlinePlus}
                >
                  CRIAR MESA
                </Button>
              </Link>

              <Button
                customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '16rem', marginRight: '1.6rem' }}
                onClick={() => setModalFreeAllTables(true)}
              >
                LIBERAR MESAS
              </Button>

              <Button
                customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '16rem' }}
                onClick={handleDownloadQRCodes}
                icon={HiDocumentDownload}
              >
                BAIXAR QRCODE
              </Button>
            </div>

            <div>
              <span className="subtitle">Legenda:</span>

              <div className="titles">
                <span>Garçon</span>
                <span>Pago</span>
                <span>Aberto</span>
                <span>Aniversariante</span>
              </div>
            </div>
          </ContainerOptions>
        }
      >
        <Container>
          <div className="progressbar">
            <header>
              <span>Ocupação do salão</span>

              <div>
                <span style={{ marginRight: '1.6rem' }}>
                  Mesas ocupadas: <strong>{tablesOccupied}</strong>
                </span>

                <span style={{ marginRight: '1.6rem' }}>
                  Mesas livres: <strong>{tables?.length - tablesOccupied}</strong>
                </span>

                <span>
                  Total de mesas: <strong>{tables?.length}</strong>
                </span>
              </div>
            </header>

            <ProgressBar style={{ height: '2.4rem' }} value={tablesOccupied} unit=" mesas ocupadas" />
          </div>

          {isLoading && (
            <div className="loading">
              <ClipLoader size={80} color="var(--color-pink)" loading={true} />
            </div>
          )}

          {isFetched && tables?.length === 0 && (
            <div className="loading">
              <h3>Não foi encontrado nenhum pedido!</h3>
            </div>
          )}

          <ul id="table__container">
            {tables?.map(table => (
              <TableBox active={openTableBox && table?.id === tableSelect.id}>
                <motion.div
                  transition={{ stiffness: 500, type: 'spring', damping: 30 }}
                  onClick={event => handleOpenTableBox(table, event)}
                  className="table__card"
                  key={table?.id}
                  id={table?.id}
                  layout
                >
                  <div className="table__header">
                    <h2>{table?.number}</h2>

                    <div className="table__status">
                      {table?.order?.payment_status === 'PAID' && <span className="paid" />}

                      {(table?.order?.payment_status === 'OPEN' ||
                        table?.order?.payment_status === 'PARTIALLY_PAID') && <span className="open" />}

                      {table?.order?.birthdays?.length > 0 && (
                        <span className="birthdays">
                          <HiGift />
                        </span>
                      )}

                      {table?.order?.waiter_requested && (
                        <span>
                          <img src={callWaiter} alt="" />
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="table_footer">
                    <p>{table?.total_value}</p>
                  </div>
                </motion.div>
              </TableBox>
            ))}

            {openTableBox && (
              <TableSelect tableSelect={tableSelect}>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout>
                  <header>
                    <div className="header__left">
                      <h3>
                        Mesa: <strong>{tableSelect?.number}</strong>
                      </h3>

                      <p>
                        Consumo total até o momento: <strong>{tableSelect?.total_value}</strong>
                      </p>

                      {tableSelect?.order?.created_at && (
                        <p>
                          Ocupada: <strong>{chronometer(tableSelect?.order?.created_at)}</strong>
                        </p>
                      )}
                    </div>

                    <div className="header__right">
                      <Button
                        onClick={() => setModalConfirmDeleteTable(true)}
                        customStyles={{
                          background: 'var(--color-primary-500)',
                          borderRadius: '1.6rem',
                          marginRight: '1.6rem',
                          height: '4rem'
                        }}
                        icon={HiTrash}
                      >
                        EXCLUIR MESA
                      </Button>

                      <Button
                        customStyles={{ borderRadius: '1.6rem', height: '4rem', marginRight: '1.6rem' }}
                        onClick={() => setOpenModalQRCode(true)}
                        icon={HiQrcode}
                      >
                        GERAR QRCODE
                      </Button>

                      {tableSelect?.order?.waiter_requested && (
                        <Button
                          customStyles={{ borderRadius: '1.6rem', height: '4rem', marginRight: '1.6rem' }}
                          onClick={() => handleWaiterDismiss(customer)}
                        >
                          LIBERAR GARÇOM
                        </Button>
                      )}

                      <Button
                        customStyles={{ borderRadius: '1.6rem', height: '4rem' }}
                        onClick={() => setModalFreeTable(true)}
                      >
                        LIBERAR MESA
                      </Button>
                    </div>

                    <div className="button__close">
                      <HiX
                        onClick={() => {
                          setOpenTableBox(false)
                          setTableSelect({})
                        }}
                      />
                    </div>
                  </header>

                  <ul>
                    {tickets?.map(ticket => (
                      <CardCustomer status={ticket?.payment_status} onClick={() => handleCustomer(ticket)}>
                        <div className="customer__header">
                          {ticket?.waiter_requested && (
                            <span className="balloon__waiter__requested">
                              <img src={callWaiter} alt="" />
                            </span>
                          )}

                          {itsBirthdayBoy(ticket?.customer?.birthdate) && (
                            <>
                              <span className="span__birthday__before" />
                              <span className="span__birthday">Aniversariante</span>
                              <span className="span__birthday__after" />
                            </>
                          )}

                          <img src={ticket?.customer?.profile_picture || notImage} alt={ticket?.customer?.fullname} />
                          <span>{ticket?.customer?.fullname}</span>
                        </div>

                        <div className={ticket?.waiter_requested ? 'waiter__requested' : 'customer__footer'}>
                          {ticket?.total_price}
                        </div>
                      </CardCustomer>
                    ))}
                  </ul>
                </motion.div>
              </TableSelect>
            )}
          </ul>
        </Container>
      </ContainerComponent>
    </>
  )
}

export { Hall }
