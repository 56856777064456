import React, { useEffect, useState } from 'react'
import { HiOutlineCheck, HiOutlinePlus } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, CardBox, ContainerComponent, Input, linkRoutes } from '../../components'
import { GetListPromoCodes, ChangePromoCodeId } from '../../services/PromoCodes'
import { formatDate } from '../../utils'

import { Container } from './styles'

export const PromoCodes = () => {
  const [promoCodes, setPromoCodes] = useState([])

  async function fetchData() {
    try {
      const { data: response } = await GetListPromoCodes()

      const promocodesFormatted = response.data.map(item => ({
        ...item,
        origin: item.origin === 'INDOOR' ? 'Presencial' : item.origin === 'DELIVERY' ? 'Delivery' : 'Todos',
        type: item.type === 'FIRST_USE' ? 'Primeiro uso' : item.type === 'NORMAL' ? 'Pedidos APP' : 'Todos',
        expires_at: formatDate(item.expires_at)
      }))

      setPromoCodes(promocodesFormatted)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleChangeStatusPromoCode(value) {
    const activated = !value.active
    const payload = { active: activated }

    try {
      await ChangePromoCodeId(value.id, payload)
      fetchData()
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  const ColumnDiscount = value => <span className="row__datatable">{value.discount + '% off'}</span>
  const ColumnDescription = value => <span className="row__datatable">{value.description}</span>
  const ColumnAvailable = value => <span className="row__datatable">{value.n_available}</span>
  const ColumnExpire = value => <span className="row__datatable">{value.expires_at}</span>
  const ColumnOrigin = value => <span className="row__datatable">{value.origin}</span>
  const ColumnUsed = value => <span className="row__datatable">{value.n_uses}</span>
  const ColumnCode = value => <span className="row__datatable">{value.code}</span>
  const ColumnType = value => <span className="row__datatable">{value.type}</span>

  const ColumnStatus = value => (
    <div className="row__datatable__status">
      <button onClick={() => handleChangeStatusPromoCode(value)}>
        {value.active ? <span className="span-active">Ativo</span> : <span className="span-inactive">Inativo</span>}
      </button>
    </div>
  )

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      title="Promocodes"
      headerRight={
        <Link to={linkRoutes.promoCodesCreate}>
          <Button icon={HiOutlinePlus} customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}>
            ADICIONAR
          </Button>
        </Link>
      }
    >
      <Container>
        <CardBox>
          <DataTable value={promoCodes} dataKey="id" currentPage paginator rows={8}>
            <Column header="Titulo" body={ColumnCode} field="code" sortable />
            <Column header="Descrição" body={ColumnDescription} field="description" sortable />
            <Column header="Desconto" body={ColumnDiscount} />
            <Column header="Utilizados" body={ColumnUsed} />
            <Column header="Disponíveis" body={ColumnAvailable} />
            <Column header="Origem" body={ColumnOrigin} />
            <Column header="Tipo de uso" body={ColumnType} />
            <Column header="Expira em" body={ColumnExpire} />
            <Column header="Status" body={ColumnStatus} field="active" sortable />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}
