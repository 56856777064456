import styled from 'styled-components'

export const Container = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem 3.2rem;

  .box__options {
    display: flex;
    justify-content: flex-end;

    grid-column: 1/3;
  }

  .box__password {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 1/3;
    gap: 1.6rem;
  }
`
