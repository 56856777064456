import React, { useEffect, useState } from "react";
import { HiBadgeCheck, HiOutlinePlus, HiTrash } from "react-icons/hi";
import { BiCommentDetail } from "react-icons/bi";
import { AiOutlineArrowDown, AiOutlineCheck, AiOutlineCopy } from "react-icons/ai";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ContainerComponent,
  ComponentModal,
  InputCalendar,
  InputSelect,
  linkRoutes,
  CardBox,
} from "../../components";
import {
  PatchReservations,
  GetReservations,
} from "../../services/Reservations";
import { optionsReservesTypes } from "../../utils/optionsSelect";
import { GetOneCustomer } from "../../services/Customers";
import { formatDate, formatterPhone, formatDateHour } from "../../utils";
import { Button, Input } from "../../components";

import { CardReserve, Container } from "./styles";

export const Reserves = () => {
  const [params, setParams] = useState({ offset: 0, limit: 50, sort: 1 });
  const [openModal, setOpenModal] = useState(false);
  const [customer, setCustomer] = useState({});
  const [reserves, setReserves] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [obs, setObs] = useState(null)
  const [obsModal, setObsModal] = useState(false)

  async function fetchData() {
    try {
      const { data: response } = await GetReservations({ params });

      const reservesFormatted = [];

      for (let item of response.data) {
        if (item.status !== "CONFIRMED" && item.status !== "CANCELLED")
          item.status_translated = "Aguardando";
        if (item.status === "CONFIRMED") item.status_translated = "Confirmado";
        if (item.status === "CANCELLED") item.status_translated = "Cancelado";
        item.created_at = formatDateHour(item.created_at);
        reservesFormatted.push({
          ...item,
          phone: formatterPhone(item.phone),
          date: formatDateHour(item.date),
        });
      }

      setReserves(reservesFormatted);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleShowUser(id) {
    try {
      const { data: response } = await GetOneCustomer({
        id,
        params: { include: "stats" },
      });
      const { data: currentCustomer } = response;

      const items = [];

      if (currentCustomer.stats.lastTicket) {
        for (const item of currentCustomer?.stats?.lastTicket?.items) {
          const itemExist = items?.find(
            (itemFind) => itemFind?.product_id === item?.product_id
          );

          if (itemExist)
            items?.forEach((product) => {
              if (product?.product_id === item?.product_id) {
                product.total_product += 1;
              }
            });

          if (!itemExist) items.push({ ...item, total_product: 1 });
        }
      }

      Object.assign(currentCustomer, { items });
      setCustomer(currentCustomer);
      setOpenModal(true);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  function handleShowObs(obs) {
    setObs(obs)
    setObsModal(true)
  }

  async function handleConfirmReserve(reserve) {
    if (reserve.status === "CANCELLED")
      return toast.error("Não é possível confirmar uma reserva cancelada!");
    if (reserve.status === "CONFIRMED")
      return toast.success("Essa reserva já está confirmada!");

    try {
      await PatchReservations(reserve.id, { status: "CONFIRMED" });
      toast.success("Reserva confirmada");
      await fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleCanceledReserve(reserve) {
    if (reserve.status === "CONFIRMED")
      return toast.error("Não é possível cancelar uma reserva confirmada!");
    if (reserve.status === "CANCELLED")
      return toast.success("Essa reserva já está cancelada!");

    try {
      await PatchReservations(reserve.id, { status: "CANCELLED" });
      toast.success("Reserva Cancelada");
      await fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target;
    setParams((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleCopy(reserve) {
    let { fullname, date: fullDate, phone, n_persons, observations } = reserve
    if (!observations) observations = ''
    const [date, time] = fullDate.split(" ")
    navigator.clipboard.writeText(
      `Nome: ${fullname}
Dia: ${date}
Telefone: ${phone}
N de Pessoas: ${n_persons}
Horário: ${time}
Observação: ${observations}
[Reservou pela FeedMe]
`
    )
    toast.success('Dados copiados!')
  }

  const ColumnStatus = (reserve) => (
    <div className="row__reserves">
      <span className={reserve.status}>{reserve.status_translated}</span>
    </div>
  );

  const ColumnFullName = (reserve) => (
    <div
      className="row__reserves"
      onClick={() => handleShowUser(reserve.customer_id)}
    >
      <p>{reserve.fullname}</p>
    </div>
  );

  const ColumnObs = (reserve) => (
    <div
      className="row__reserves"
      onClick={() => handleShowObs(reserve.observations)}
    >
      {reserve.observations && <button
        // style={{ background: 'transparent' }}
        style={{ background: 'var(--color-green)', borderRadius: 50}}
        title="Observações"
      >
        <BiCommentDetail />
      </button>}
    </div>
  );

  const ColumnActions = (reserve) => {
    if (reserve.status === "CANCELLED" || reserve.status === "CONFIRMED") {
      return <div className="row__reserves">
        <button
          onClick={() => handleCopy(reserve)}
          title="Copiar para área de transferência"
        >
          <AiOutlineCopy />
        </button>
      </div>

    }
    return (
      <div className="row__reserves">
        <button
          onClick={() => handleConfirmReserve(reserve)}
          title="Confirmar reserva"
        >
          <HiBadgeCheck />
        </button>

        <button
          onClick={() => handleCanceledReserve(reserve)}
          title="Cancelar reserva"
        >
          <HiTrash />
        </button>

        <button
          onClick={() => handleCopy(reserve)}
          title="Copiar para área de transferência"
        >
          <AiOutlineCopy />
        </button>
      </div>
    );
  };

  const rowExpansionObservations = (data) => {
    return <p style={{ marginLeft: "2rem" }}>{data.observations ? data.observations : 'Sem observações'}</p>
  };

  const rearrangeDate = (date, reverse = true) => {
    if (reverse) {
      const [day, month, rest] = date.split("/");
      const [year, time] = rest.split(" ");
      if (time) {
        return `${year}/${month}/${day} ${time}`;
      }
      return `${year}/${month}/${day}`;
    }
    const [year, month, rest] = date.split("/");
    const [day, time] = rest.split(" ");
    if (time) {
      return `${day}/${month}/${year} ${time}`;
    }
    return `${year}/${month}/${day}`;
  };

  function onSortFunction(e) {
    const data = reserves;
    for (let d of data) {
      d[e.field] = rearrangeDate(d[e.field], true);
    }
    data.sort((a, b) => {
      return e.order * a[e.field].localeCompare(b[e.field]);
    });
    for (let d of data) {
      d[e.field] = rearrangeDate(d[e.field]);
    }
    return data;
  }

  useEffect(() => fetchData(), []);

  return (
    <>
      <ComponentModal
        customStyles={{ background: "#414a55" }}
        isOpen={openModal}
        onRequestClose={() => {
          setOpenModal(false);
          setCustomer({});
        }}
      >
        <CardReserve>
          <main>
            <div className="card__customer">
              <img src={customer?.profile_picture} alt="" />

              <div className="card__customer__infos">
                <h4>{customer?.fullname}</h4>
                <h5>{customer?.email}</h5>
                <h5>@{customer?.username}</h5>
              </div>
            </div>

            <div className="card__customer__items">
              <ul>
                {customer?.items?.map((item) => (
                  <li key={item.id}>
                    <img src={item.thumbnail} alt="" />

                    <div>
                      <h3>{item.name}</h3>
                      <span>Pedidos {item.total_product}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </main>

          <footer>
            <p>
              Ultima visita:{" "}
              <strong>
                {formatDate(customer?.stats?.lastTicket?.created_at)}
              </strong>
            </p>

            <p>
              Total de visitas: <strong>{customer?.stats?.visits || 0}</strong>
            </p>
          </footer>
        </CardReserve>
      </ComponentModal>

      <ComponentModal
        isOpen={obsModal}
        onRequestClose={() => {
          setObsModal(false);
          setObs(null);
        }}
      >
        <p>
          {obs}
        </p>
      </ComponentModal>

      <ContainerComponent
        title="Reservas"
        headerRight={
          <div>
            <Link to={linkRoutes.reservesOpeningHours}>
              <Button
                customStyles={{
                  borderRadius: "1.6rem",
                  height: "4rem",
                  marginRight: "1.6rem",
                }}
              >
                HORÁRIOS DE RESERVA
              </Button>
            </Link>

            <Link to={linkRoutes.reservesCreate}>
              <Button
                icon={HiOutlinePlus}
                customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
              >
                CRIAR RESERVA
              </Button>
            </Link>
          </div>
        }
      >
        <Container>
          <div className="filter">
            <Input
              placeholder="Busque aqui pelo nome do usuário..."
              iconLight={AiOutlineArrowDown}
              onChange={handleChangeState}
              label="Busca"
              name="search"
            />

            <InputCalendar
              onChange={handleChangeState}
              label="Data de inicio"
              name="start_at"
            />

            <InputCalendar
              onChange={handleChangeState}
              label="Data final"
              name="end_at"
            />

            <InputSelect
              options={optionsReservesTypes}
              onChange={handleChangeState}
              icon={AiOutlineArrowDown}
              label="Status"
              name="status"
            />

            <Button iconRight={AiOutlineCheck} onClick={fetchData}>
              Aplicar
            </Button>
          </div>

          <CardBox>
            <DataTable
              onRowToggle={(e) => setExpandedRows(e.data)}
              expandedRows={expandedRows}
              rowExpansionTemplate={rowExpansionObservations}
              value={reserves}
              dataKey="id"
              autoLayout
            >
              <Column
                style={{ textAlign: "center" }}
                header="Nome do usuário"
                body={ColumnFullName}
                field="fullname"
                sortable
              />
              <Column
                style={{ textAlign: "center" }}
                header="Código"
                field="code"
                sortable
              />
              <Column
                style={{
                  textAlign: "center",
                }}
                header="Data de criação"
                field="created_at"
                sortable
                sortFunction={onSortFunction}
              />
              <Column
                style={{
                  textAlign: "center",
                }}
                header="Email"
                field="email"
                sortable
              />
              <Column
                style={{
                  textAlign: "center",
                }}
                header="Telefone de contato"
                field="phone"
                sortable
              />
              <Column
                style={{
                  textAlign: "center",
                }}
                header="Número de pessoas"
                field="n_persons"
                sortable
              />
              <Column
                style={{ textAlign: "center" }}
                header="Horário"
                field="date"
                sortable
                sortFunction={onSortFunction}
              />
              <Column
                header="Obs."
                // expander
                style={{
                  textAlign: "center",
                  width: '5rem',
                }}
                body={ColumnObs}
              />
              <Column
                style={{ textAlign: "center" }}
                header="Status"
                body={ColumnStatus}
                field="status"
                sortable
              />
              <Column
                style={{ textAlign: "center", width: "13%" }}
                header="Ações"
                body={ColumnActions}
              />
            </DataTable>
          </CardBox>
        </Container>
      </ContainerComponent>
    </>
  );
};
