import FormControl from '@material-ui/core/FormControl'
import { Select, MenuItem } from '@material-ui/core'
import { HiChevronDown } from 'react-icons/hi'

import { Container } from './styles'

export const InputSelect = ({
  customStyles,
  placeholder,
  handleBlur,
  className,
  onChange,
  disabled,
  options,
  bgIcon,
  value,
  label,
  type,
  name,

  icon: Icon
}) => {
  return (
    <Container customStyles={customStyles} disabled={disabled} bgIcon={bgIcon}>
      <span>{label}</span>

      <FormControl>
        <Select
          placeholder={placeholder}
          selectedValue={value}
          className={className}
          onChange={onChange}
          variant="outlined"
          value={value}
          name={name}
        >
          {options.map(item => (
            <MenuItem value={item.value}>
              <span style={{ fontSize: '1.4rem', fontWeight: 'normal', margin: '0' }}>{`${item.label}`}</span>
            </MenuItem>
          ))}
        </Select>

        {!!Icon ? <Icon /> : <HiChevronDown />}
      </FormControl>
    </Container>
  )
}
