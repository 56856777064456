import styled from 'styled-components'

export const Container = styled.div`
  .filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.2rem;
    label {
      margin-right: 1.6rem;
    }
  }
  .row__datatable {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0.6rem 1rem;
    margin-left: 1.6rem;

    img {
      background: var(--color-primary-900);
      border-radius: 1rem;
      margin-right: 1rem;
      object-fit: cover;
      height: 4rem;
      width: 6rem;
    }

    span {
      padding: 0.4rem 0.8rem;
      border-radius: 1rem;
      font-size: 1.6rem;
    }

    .span-active {
      background-color: var(--color-green);
    }
    .span-inactive {
      background-color: var(--color-orange);
    }
  }

  .centralized {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
  }
`
