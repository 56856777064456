import React, { useEffect, useState } from 'react'
import { HiOutlineClipboardList, HiOutlineCheck, HiSearch } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, CardBox, ContainerComponent, Input, linkRoutes } from '../../../components'
import { GetLoyaltyProgramsCustomers } from '../../../services/LoyaltyPrograms'
import { formatDateHour, formatterPrice } from '../../../utils'
import notImage from '../../../assets/images/notImage.png'

import { Container } from './styles'

const LoyaltyProgramsMerchants = () => {
  const [searchName, setSearchName] = useState('')
  const [customers, setCustomers] = useState([])

  async function fetchData() {
    try {
      const { data: response } = await GetLoyaltyProgramsCustomers(searchName)

      const loyaltyProgramsFormatted = response.data.map(item => ({
        ...item,
        order_type: item.coupon?.order_type === 'INDOOR' ? 'Presencial' : 'Delivery',
        current_consumption: formatterPrice(item.current_consumption),
        total_consumption: formatterPrice(item.total_consumption),
        created_at: formatDateHour(item.created_at)
      }))

      setCustomers(loyaltyProgramsFormatted)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  const ColumnUser = rowData => (
    <div className="row__datatable">
      <img src={rowData.customer.profile_picture || notImage} alt={'foto do usuário ' + rowData.customer.fullname} />

      <p>
        {rowData.customer.fullname} {rowData.customer?.username && <strong>{`(@${rowData.customer.username})`}</strong>}
      </p>
    </div>
  )

  const ColumnDate = rowData => (
    <div className="row__datatable">
      <span>{rowData.created_at}</span>
    </div>
  )

  const ColumnOrigin = rowData => (
    <div className="row__datatable">
      <span>{rowData.order_type}</span>
    </div>
  )

  const ColumnQuantity = rowData => (
    <div className="row__datatable">
      <span>{rowData.n_uses}</span>
    </div>
  )

  const ColumnTotalConsumption = rowData => (
    <div className="row__datatable">
      <p>{rowData.total_consumption}</p>
    </div>
  )

  const ColumnCurrentConsumption = rowData => (
    <div className="row__datatable">
      <p>{rowData.current_consumption}</p>
    </div>
  )

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      headerRight={
        <Link to={linkRoutes.loyaltyProgramsDetails}>
          <Button
            customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
            icon={HiOutlineClipboardList}
            bgColor="var(--color-pink)"
          >
            VER DETALHES
          </Button>
        </Link>
      }
      title="Programa de fidelidade"
    >
      <Container>
        <div className="filter">
          <Input
            onChange={event => setSearchName(event.target.value)}
            customStyles={{ maxWidth: '80rem', width: '100%' }}
            placeholder="Busque aqui pelo nome do usuário"
            icon={HiSearch}
            label="Busca"
          />

          <Button customStyles={{ width: '16rem' }} iconRight={HiOutlineCheck} onClick={fetchData}>
            Buscar
          </Button>
        </div>

        <CardBox>
          <DataTable value={customers} rows={8} paginator>
            <Column body={ColumnUser} header="Nome do usuário" field="customer.fullname" sortable />
            <Column body={ColumnOrigin} header="Origem" field="coupon.order_type" sortable />

            <Column
              body={ColumnCurrentConsumption}
              header="valor consumido desde o último resgate"
              field="current_consumption"
              sortable
            />

            <Column body={ColumnTotalConsumption} header="Valor total consumido" field="total_consumption" sortable />
            <Column body={ColumnQuantity} header="Quantidade já resgatada" field="n_uses" sortable />
            <Column body={ColumnDate} header="Data de resgate" field="created_at" sortable />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { LoyaltyProgramsMerchants }
