import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { HiOutlinePlus, HiCheck } from "react-icons/hi";
import { Column } from "primereact/column";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import {
  Button,
  CardBox,
  ContainerComponent,
  linkRoutes,
} from "../../components";
import notImage from "../../assets/images/notImage.png";
import { GetAllPosts, PatchPostsReorder } from "../../services/Posts";
import { formatDate } from "../../utils";
import { Container } from "./styles";
import { toast } from "react-toastify";

const Posts = () => {
  const history = useHistory();

  const [posts, setPosts] = useState([]);
  const [reorder, setReorder] = useState(false);

  async function fetchData() {
    try {
      const { data } = await GetAllPosts();
      setPosts(data.data);
    } catch (error) {
      console.log(error?.response?.data?.error?.message);
    }
  }

  async function handleReorderPosts() {
    const payload = { ids: posts.map((item) => item.id) };

    try {
      await PatchPostsReorder({ payload });
      toast.success("Ordem dos postss atualizada com sucesso!");
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  function handleShowPost(event) {
    const post = event.data;
    history.push(linkRoutes.postDetails, { post });
  }

  const ColumnDate = (value) => (
    <div className="row__datatable centralized">
      {formatDate(value.created_at)}
    </div>
  );
  const ColumnViews = (value) => (
    <div className="row__datatable centralized">{value.n_views}</div>
  );

  const ColumnPublication = (value) => (
    <div className="row__datatable">
      <img src={value.image || notImage} alt={value.title} />
      {value.title}
    </div>
  );

  const ColumnStatus = (value) => (
    <div className="row__datatable centralized">
      <button>
        {value.active ? (
          <span className="span-active">Ativo</span>
        ) : (
          <span className="span-inactive">Inativo</span>
        )}
      </button>
    </div>
  );

  useEffect(() => fetchData(), []);

  return (
    <ContainerComponent
      title="Conteúdo do mural"
      headerRight={
        <div>
          {reorder && (
            <Button
                customStyles={{
                  borderRadius: "1.6rem",
                  height: "4rem",
                  marginRight: "1.6rem",
                }}
                onClick={handleReorderPosts}
                icon={HiCheck}
              >
                SALVAR ORDEM
              </Button>
            )}
          <Link to={linkRoutes.postNew}>
            <Button
              customStyles={{
                borderRadius: "1.6rem",
                height: "4rem",
                width: "20rem",
              }}
              icon={HiOutlinePlus}
            >
              CRIAR PUBLICAÇÃO
            </Button>
          </Link>
        </div>
      }
    >
      <Container>
        <CardBox>
          <DataTable
            onRowReorder={(event) => {
              setPosts(event.value);
              setReorder(true);
            }}
            onRowClick={handleShowPost}
            value={posts}
            dataKey="id"
          >
            <Column
              rowReorder
              style={{ width: "3.2em", paddingLeft: "1.6rem" }}
            />
            <Column
              header="Publicação"
              body={ColumnPublication}
              field="title"
              sortable
            />
            <Column header="Descrição" field="body" sortable />
            <Column
              header="Visualizações"
              body={ColumnViews}
              field="n_views"
              sortable
            />
            <Column
              header="Data"
              body={ColumnDate}
              field="created_at"
              sortable
            />
            <Column
              header="Status"
              body={ColumnStatus}
              field="active"
              sortable
            />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  );
};

export { Posts };
