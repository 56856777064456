import styled from 'styled-components'

export const InputValidate = styled.div`
  display: flex;
  flex-direction: column;

  color: var(--color-pink);
  font-weight: 400;
  font-size: 1.6rem;
  height: 7rem;
  width: 100%;

  ${({ customStyles }) => customStyles}

  .input__box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.8rem;
    padding-left: 1rem;
    margin-top: 0.8rem;
    background: white;
    input {
      color: ${({ disabled }) => (disabled ? '#444' : '#000')};
      background: transparent;
      font-size: 1.6rem;
      outline: none;
      border: none;
      width: 100%;
    }
    .icon__box {
      display: flex;
      justify-content: center;
      align-items: center;

      background: ${({ colorIconBg }) => colorIconBg || 'transparent'};
      border-radius: 0 0.8rem 0.8rem 0;
      height: 3.5rem;
      width: 3.5rem;
      svg {
        color: ${({ colorIcon }) => colorIcon || 'var(--color-gold)'};
      }
    }
  }
  span {
    color: var(--color-orange);
    margin-top: 0.4rem;
    font-size: 1.4rem;
    font-weight: 600;
    display: block;
  }
`

export const InputNotValidate = styled.div`
  display: flex;
  flex-direction: column;

  color: var(--color-pink);
  font-weight: 400;
  font-size: 1.6rem;
  width: 100%;

  ${({ customStyles }) => customStyles}

  .input__box {
    display: flex;

    margin: 0.8rem 0 0rem 0;
    border-radius: 0.4rem;
    padding-left: 1rem;
    background: #fff;
    input {
      color: ${({ disabled }) => (disabled ? '#444' : '#000')};
      vertical-align: text-top;
      background: transparent;
      font-size: 1.4rem;
      outline: none;
      border: none;
      height: 100%;
      width: 100%;
    }
    .icon__box {
      display: flex;
      justify-content: center;
      align-items: center;

      background: ${({ colorIconBg }) => colorIconBg || 'transparent'};
      border-radius: 0 0.4rem 0.4rem 0;
      min-height: 3.2rem;
      width: 3.2rem;
      height: 100%;
      svg {
        color: ${({ colorIcon }) => colorIcon || 'var(--color-pink)'};
      }
    }
  }
`
