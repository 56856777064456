import React, { useEffect, useState } from "react";
import { HiOutlineCheck, HiSearch } from "react-icons/hi";
import { AiOutlineArrowDown } from "react-icons/ai";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils";
import {
  Button,
  CardBox,
  ContainerComponent,
  Input,
  InputSelect,
  linkRoutes,
} from "../../../components";
import { GetAllCustomers } from "../../../services/Customers";
import notImage from "../../../assets/images/notImage.png";
import { feedhunter, gender } from "../optionsCustomers";
import { formatterPhone } from "../../../utils";
import { useHistory } from "react-router";

import { Container } from "./styles";

const CustomersLit = () => {
  const history = useHistory();

  const [customers, setCustomers] = useState([]);
  const [filters, setFilters] = useState({});
  const [currPage, setCurrPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState();
  const [loading, setLoading] = useState(true);
  const [firstPage, setFirstPage] = useState(0);
  const [pageSize, setPageSize] = useState(200);

  function formatCustomers(raw) {
    const result = raw.data.results.map((item) => ({
      ...item,
      gender: item.gender ? (item.gender === "F" ? "Fem" : "Masc") : "-",
      style: item.status === "ACTIVE" ? "span-active" : "span-inactive",
      status: item.status === "ACTIVE" ? "Ativo" : "Inativo",
      phone: item.phone ? formatterPhone(item.phone) : "-",
      age: item.age ? `${item.age} anos` : "-",
      created_at: formatDate(item.created_at),
    }));
    return result;
  }

  async function fetchData() {
    try {
      setFilters({
        ...filters,
        page_size: pageSize,
      })
      const { data: response } = await GetAllCustomers({ params: filters });

      setTotalRecords(response.data.total);
      const customersFormatted = formatCustomers(response);
      setCustomers(customersFormatted);
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  function onPage(event) {
    setLoading(true)
    setFirstPage(event.first);
    setCurrPage(event.page)
    setFilters((prev) => ({...prev, page: event.page}))
  };

  function handleShowCustomer(event) {
    const customer = event.data;
    history.push(linkRoutes.customersDetails, { customer });
  }

  function handleChangeState(event) {
    const { value, name } = event.target;
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  }

  function onSort(e) {
    const { sortField, sortOrder } = e
    setFilters((prevState => ({...prevState, sort_field: sortField, sort_order: sortOrder})))
  }

  const ColumnPhone = (value) => (
    <div className="row__datatable centralized">{value.phone}</div>
  );
  const ColumnAge = (value) => (
    <div className="row__datatable centralized">{value.age}</div>
  );
  const ColumnGender = (value) => (
    <div className="row__datatable">{value.gender}</div>
  );
  const ColumnEmail = (value) => (
    <div className="row__datatable">{value.email}</div>
  );

  const ColumnUser = (value) => (
    <div className="row__datatable">
      <img src={value.profile_picture || notImage} alt="" />
      <strong>{value.fullname}</strong>
    </div>
  );

  const ColumnStatus = (value) => (
    <div className="row__datatable centralized">
      <span className={value.style}>{value.status}</span>
    </div>
  );

  useEffect(() => fetchData(), [filters.page, filters.sort_field, filters.sort_order]);

  return (
    <ContainerComponent
      headerLeft={["Usuários", "Lista de usuários"]}
      title="Lista de usuários"
    >
      <Container>
        <div className="filter">
          <Input
            placeholder="Busque aqui pelo nome do usuário"
            customStyles={{ marginRight: "1.6rem" }}
            onChange={handleChangeState}
            icon={HiSearch}
            label="Busca"
            name="search"
          />

          <InputSelect
            customStyles={{ marginRight: "1.6rem", maxWidth: "20rem" }}
            onChange={handleChangeState}
            icon={AiOutlineArrowDown}
            options={gender}
            name="gender"
            label="Sexo"
          />

          <InputSelect
            customStyles={{ marginRight: "1.6rem", maxWidth: "20rem" }}
            onChange={handleChangeState}
            icon={AiOutlineArrowDown}
            options={feedhunter}
            label="Feedhunter"
            name="feedhunter"
          />

          <Button iconRight={HiOutlineCheck} onClick={fetchData}>
            Buscar
          </Button>
        </div>

        <CardBox>
          <DataTable
            onRowClick={handleShowCustomer}
            paginator
            value={customers}
            rows={pageSize}
            totalRecords={totalRecords}
            lazy
            onPage={onPage}
            loading={loading}
            first={firstPage}
            dataKey="id"
            onSort={onSort}
            sortField={filters.sort_field}
            sortOrder={filters.sort_order}
          >
            <Column
              style={{ width: "25%" }}
              body={ColumnUser}
              header="Usuário"
              field="fullname"
              sortable
            />
            <Column
              style={{ width: "25%" }}
              body={ColumnEmail}
              header="Email"
              field="email"
              sortable
            />
            <Column
              style={{ width: "5%" }}
              body={ColumnGender}
              header="Sexo"
              field="gender"
              sortable
            />
            <Column
              body={ColumnPhone}
              header="Telefone"
              field="phone"
              sortable
            />
            <Column
              style={{
                textAlign: "center",
              }}
              header="Usuário desde"
              field="created_at"
              sortable
            />
            <Column body={ColumnAge} header="Idade" field="age" sortable />
            <Column
              body={ColumnStatus}
              header="Status"
              field="status"
              sortable
            />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  );
};

export { CustomersLit };
