// const dataRaw = [
//     {
//         "id": 19571,
//         "name": "ENTRADAS ",
//         "position": 0,
//         "image": null,
//         "parentCategoryId": null,
//         "siteColor": null,
//         "virtualMerchantId": null,
//         "categories": [
//             {
//                 "id": 19572,
//                 "name": "VEGETARIANOS",
//                 "position": 1,
//                 "image": null,
//                 "parentCategoryId": 19571,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 313329,
//                         "name": "MIILHO DOCE ",
//                         "digitalName": "MIILHO DOCE ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "469375870",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315755,
//                         "name": "MILHO DOCE GRELHADO",
//                         "digitalName": "MILHO DOCE GRELHADO",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1351227367",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 313330,
//                         "name": "MILHO DOCE TEMPURÁ ",
//                         "digitalName": "MILHO DOCE TEMPURÁ ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1019411399",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315713,
//                         "name": "MIX DE COGUMELOS ",
//                         "digitalName": "MIX DE COGUMELOS ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-341417351",
//                         "unitType": 1,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 313173,
//                         "name": "SUNOMONO",
//                         "digitalName": "SUNOMONO",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": 3773,
//                         "sku": "1223514928",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             },
//             {
//                 "id": 19573,
//                 "name": "SUSHI ",
//                 "position": 2,
//                 "image": null,
//                 "parentCategoryId": 19571,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 302213,
//                         "name": "TARTAR ATUM AVOCADO ",
//                         "digitalName": "TARTAR ATUM AVOCADO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": "p_302213-1675872587563.jpeg",
//                         "taxCategoryId": null,
//                         "sku": "-2037099008",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 313338,
//                         "name": "TARTAR SALMÃO MIX TRUFADO ",
//                         "digitalName": "TARTAR SALMÃO MIX TRUFADO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-114622823",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 302225,
//                         "name": "TARTAR SALMÃO TRUFADO ",
//                         "digitalName": "TARTAR SALMÃO TRUFADO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": "p_302225-1675876221178.jpeg",
//                         "taxCategoryId": null,
//                         "sku": "4502842780",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315714,
//                         "name": "TEMAKI ATUM ",
//                         "digitalName": "TEMAKI ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "875227602",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [
//                             {
//                                 "id": 62655,
//                                 "name": "CRISPY ADICIONAL ",
//                                 "min": 1,
//                                 "max": 1,
//                                 "type": 1,
//                                 "priceType": 0,
//                                 "position": 1,
//                                 "items": [
//                                     {
//                                         "id": 315715,
//                                         "name": "CRISPY ADICIONAL ",
//                                         "digitalName": "CRISPY ADICIONAL ",
//                                         "description": " ",
//                                         "details": null,
//                                         "image": null,
//                                         "price": 0,
//                                         "priceDelivery": 0,
//                                         "sku": "2212167466",
//                                         "barcode": null,
//                                         "taxCategoryId": null,
//                                         "position": 0
//                                     }
//                                 ]
//                             }
//                         ],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 313343,
//                         "name": "TEMAKI DO PADRIN ",
//                         "digitalName": "TEMAKI DO PADRIN ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1698801251",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 313341,
//                         "name": "MINI TEMAKI ",
//                         "digitalName": "MINI TEMAKI ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "907444861",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             },
//             {
//                 "id": 19574,
//                 "name": "COZINHA",
//                 "position": 3,
//                 "image": null,
//                 "parentCategoryId": 19571,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 302995,
//                         "name": "BAO DE ATUM ",
//                         "digitalName": "BAO DE ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": "p_302995-1677698752164.jpeg",
//                         "taxCategoryId": null,
//                         "sku": "-1229303563",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315769,
//                         "name": "CAMARÃO CROCANTE ",
//                         "digitalName": "CAMARÃO CROCANTE ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "624597306",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 300142,
//                         "name": "CEVICHE MAR E TERRA ",
//                         "digitalName": "CEVICHE MAR E TERRA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "3384331610",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315768,
//                         "name": "GUIOZA BURGUER ",
//                         "digitalName": "GUIOZA BURGUER ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "776453568",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 313353,
//                         "name": "GUIOZA TRADICIONAL ",
//                         "digitalName": "GUIOZA TRADICIONAL ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1845643220",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 313351,
//                         "name": "TOASTE DE ATUM ",
//                         "digitalName": "TOASTE DE ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-50474059",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315763,
//                         "name": "LULAS FURAI ",
//                         "digitalName": "LULAS FURAI ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1700301234",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 300132,
//                         "name": "ROCK SHRIMP ",
//                         "digitalName": "ROCK SHRIMP ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": "p_300132-1675089775141.jpeg",
//                         "taxCategoryId": null,
//                         "sku": "5190498403",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 300138,
//                         "name": "TACO",
//                         "digitalName": "TACO",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "7541467097",
//                         "unitType": 1,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 313350,
//                         "name": "TOASTE DE ATUM ",
//                         "digitalName": "TOASTE DE ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1359500338",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315764,
//                         "name": "TORTILHA",
//                         "digitalName": "TORTILHA",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "383695049",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 300140,
//                         "name": "VIEIRA EXPERIENCE ",
//                         "digitalName": "VIEIRA EXPERIENCE ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": "p_300140-1675089877103.jpeg",
//                         "taxCategoryId": null,
//                         "sku": "4760910921",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             }
//         ],
//         "items": [],
//         "publishedPDV": true
//     },
//     {
//         "id": 19702,
//         "name": "SUSHI VEGANOS",
//         "position": 4,
//         "image": null,
//         "parentCategoryId": null,
//         "siteColor": null,
//         "virtualMerchantId": null,
//         "categories": [],
//         "items": [
//             {
//                 "id": 315716,
//                 "name": "GUNKAN KAPPACOUVE",
//                 "digitalName": "GUNKAN KAPPACOUVE",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "1073265881",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315718,
//                 "name": "HOSSOMAKI PEPINO",
//                 "digitalName": "HOSSOMAKI PEPINO",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "-2031100066",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315717,
//                 "name": "HOT COGUMELOS ",
//                 "digitalName": "HOT COGUMELOS ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "1468511094",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315719,
//                 "name": "URAMAKI BROCÓLIS VEGANO ",
//                 "digitalName": "URAMAKI BROCÓLIS VEGANO ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "778007615",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315720,
//                 "name": "URAMAKI VEGANO ",
//                 "digitalName": "URAMAKI VEGANO ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "1079233550",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             }
//         ],
//         "publishedPDV": true
//     },
//     {
//         "id": 19704,
//         "name": "SUSHI ",
//         "position": 5,
//         "image": null,
//         "parentCategoryId": null,
//         "siteColor": null,
//         "virtualMerchantId": null,
//         "categories": [
//             {
//                 "id": 19705,
//                 "name": "FUTOMAKIS",
//                 "position": 6,
//                 "image": null,
//                 "parentCategoryId": 19704,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 315722,
//                         "name": "FUTOMAKI ATUM ",
//                         "digitalName": "FUTOMAKI ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "493220054",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315721,
//                         "name": "FUTOMAKI CAMARÃO SPICY ",
//                         "digitalName": "FUTOMAKI CAMARÃO SPICY ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-483772202",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             },
//             {
//                 "id": 19706,
//                 "name": "HOSSOMAKI",
//                 "position": 7,
//                 "image": null,
//                 "parentCategoryId": 19704,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 315724,
//                         "name": "HOSSOMAKI ATUM ",
//                         "digitalName": "HOSSOMAKI ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "681354836",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315725,
//                         "name": "HOSSOMAKI ATUM ",
//                         "digitalName": "HOSSOMAKI ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "9714325524",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315723,
//                         "name": "HOSSOMAKI SALMÃO ",
//                         "digitalName": "HOSSOMAKI SALMÃO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1840801164",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             },
//             {
//                 "id": 19707,
//                 "name": "URAMAKIS ",
//                 "position": 8,
//                 "image": null,
//                 "parentCategoryId": 19704,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 315726,
//                         "name": "URAMAKI FILADÉLFIA ",
//                         "digitalName": "URAMAKI FILADÉLFIA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-989613893",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315730,
//                         "name": "URAMAKI EBI CRISPY ",
//                         "digitalName": "URAMAKI EBI CRISPY ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1646783285",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315727,
//                         "name": "URAMAKI ALASKA ",
//                         "digitalName": "URAMAKI ALASKA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "8520703038",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315729,
//                         "name": "URAMAKI FILADÉLFIA ",
//                         "digitalName": "URAMAKI FILADÉLFIA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "3600230901",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315731,
//                         "name": "URAMAKI TARTAR TRUFADO ",
//                         "digitalName": "URAMAKI TARTAR TRUFADO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "3194808425",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315732,
//                         "name": "URAMAKI TUNA ",
//                         "digitalName": "URAMAKI TUNA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "311518979",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             },
//             {
//                 "id": 19708,
//                 "name": "NIGIRI",
//                 "position": 9,
//                 "image": null,
//                 "parentCategoryId": 19704,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 315734,
//                         "name": "NIGIRI ATUM ",
//                         "digitalName": "NIGIRI ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-642691595",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315735,
//                         "name": "NIGIRI ATUM ",
//                         "digitalName": "NIGIRI ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-55172726",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315736,
//                         "name": "NIGIRI PEIXE BRANCO ",
//                         "digitalName": "NIGIRI PEIXE BRANCO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1139806181",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315733,
//                         "name": "NIGIRI SALMÃO ",
//                         "digitalName": "NIGIRI SALMÃO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "2088957773",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315737,
//                         "name": "NIGIRI SALMÃO TORO ",
//                         "digitalName": "NIGIRI SALMÃO TORO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1125021059",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315738,
//                         "name": "NIGIRI TAMAGÔ",
//                         "digitalName": "NIGIRI TAMAGÔ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1854564103",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             },
//             {
//                 "id": 19709,
//                 "name": "JOE/GUNKAN",
//                 "position": 10,
//                 "image": null,
//                 "parentCategoryId": 19704,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 315741,
//                         "name": "JOE ATUM ",
//                         "digitalName": "JOE ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "24805245",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315744,
//                         "name": "JOE GORGONZOLA ",
//                         "digitalName": "JOE GORGONZOLA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1468674940",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315745,
//                         "name": "JOE IMPERA ",
//                         "digitalName": "JOE IMPERA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-534378135",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315746,
//                         "name": "JOE PALHA DOCE ",
//                         "digitalName": "JOE PALHA DOCE ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "929801616",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315740,
//                         "name": "JOE SALMÃO ",
//                         "digitalName": "JOE SALMÃO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1262986877",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315742,
//                         "name": "JOE SALMÃO TAMAGÔ",
//                         "digitalName": "JOE SALMÃO TAMAGÔ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "91045013",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315743,
//                         "name": "JOE SALMÃO TRUFADO ",
//                         "digitalName": "JOE SALMÃO TRUFADO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "335892279",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             },
//             {
//                 "id": 19711,
//                 "name": "SASHIMI ",
//                 "position": 11,
//                 "image": null,
//                 "parentCategoryId": 19704,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 315750,
//                         "name": "SASHIMI ATUM NA CROSTA ",
//                         "digitalName": "SASHIMI ATUM NA CROSTA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1990332172",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315748,
//                         "name": "SASHIMI DE ATUM ",
//                         "digitalName": "SASHIMI DE ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1698096905",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315747,
//                         "name": "SASHIMI DE SALMÃO ",
//                         "digitalName": "SASHIMI DE SALMÃO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "685980384",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315751,
//                         "name": "SASHIMI DE SALMÃO TRUFADO ",
//                         "digitalName": "SASHIMI DE SALMÃO TRUFADO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1449004023",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315749,
//                         "name": "SASHIMI PEIXE BRANCO ",
//                         "digitalName": "SASHIMI PEIXE BRANCO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-928402959",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             },
//             {
//                 "id": 19712,
//                 "name": "HOT",
//                 "position": 12,
//                 "image": null,
//                 "parentCategoryId": 19704,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 315752,
//                         "name": "HOT FILADÉLFIA ",
//                         "digitalName": "HOT FILADÉLFIA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1266638966",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315754,
//                         "name": "HOT LEMON ",
//                         "digitalName": "HOT LEMON ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1123588320",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315753,
//                         "name": "HOT PORÓ ",
//                         "digitalName": "HOT PORÓ ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1129796948",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             },
//             {
//                 "id": 19713,
//                 "name": "CARPACCIO",
//                 "position": 13,
//                 "image": null,
//                 "parentCategoryId": 19704,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 315759,
//                         "name": "CARPACCIO ATUM ",
//                         "digitalName": "CARPACCIO ATUM ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "609945176",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315758,
//                         "name": "CARPACCIO POLVO ",
//                         "digitalName": "CARPACCIO POLVO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-466786658",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315756,
//                         "name": "USUZUKURI SALMÃO TRUFADO ",
//                         "digitalName": "USUZUKURI SALMÃO TRUFADO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1922284795",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             },
//             {
//                 "id": 19714,
//                 "name": "ESPECIAIS SEM ARROZ ",
//                 "position": 14,
//                 "image": null,
//                 "parentCategoryId": 19704,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 302807,
//                         "name": "DRAGON REI ",
//                         "digitalName": "DRAGON REI ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "5046565784",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315762,
//                         "name": "EBI ROLL ",
//                         "digitalName": "EBI ROLL ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1286592512",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315760,
//                         "name": "EBI SHAKE ",
//                         "digitalName": "EBI SHAKE ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-2043563480",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 302948,
//                         "name": "SHAKE TORO ",
//                         "digitalName": "SHAKE TORO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "4654253411",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315761,
//                         "name": "TAMAGÔ",
//                         "digitalName": "TAMAGÔ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1867524063",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 302950,
//                         "name": "TUNA AVOCADO ",
//                         "digitalName": "TUNA AVOCADO ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": "p_302949-1676397835295.jpeg",
//                         "taxCategoryId": null,
//                         "sku": "7251057020",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             }
//         ],
//         "items": [],
//         "publishedPDV": true
//     },
//     {
//         "id": 19715,
//         "name": "COMBINADOS ",
//         "position": 15,
//         "image": null,
//         "parentCategoryId": null,
//         "siteColor": null,
//         "virtualMerchantId": null,
//         "categories": [],
//         "items": [
//             {
//                 "id": 315773,
//                 "name": "COMBO CRISPY ",
//                 "digitalName": "COMBO CRISPY ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "1378437975",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315775,
//                 "name": "COMBO IMPERA ",
//                 "digitalName": "COMBO IMPERA ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "-245449766",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315770,
//                 "name": "COMBO JOUS ",
//                 "digitalName": "COMBO JOUS ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "1690141529",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315772,
//                 "name": "COMBO TUNA ",
//                 "digitalName": "COMBO TUNA ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "-1052934436",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315771,
//                 "name": "SAKE ",
//                 "digitalName": "SAKE ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "-1076244933",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315776,
//                 "name": "SASHIMI EXPERIENCE ",
//                 "digitalName": "SASHIMI EXPERIENCE ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "115835993",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             }
//         ],
//         "publishedPDV": true
//     },
//     {
//         "id": 19717,
//         "name": "SOBREMESAS ",
//         "position": 17,
//         "image": null,
//         "parentCategoryId": null,
//         "siteColor": null,
//         "virtualMerchantId": null,
//         "categories": [],
//         "items": [
//             {
//                 "id": 315779,
//                 "name": "BANOFFE EXPERIENCE ",
//                 "digitalName": "BANOFFE EXPERIENCE ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": "",
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "105191035",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315780,
//                 "name": "CREPE DE OVOMALTINE ",
//                 "digitalName": "CREPE DE OVOMALTINE ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "1846677909",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             },
//             {
//                 "id": 315781,
//                 "name": "GRAN GATEAU CROCANTE ",
//                 "digitalName": "GRAN GATEAU CROCANTE ",
//                 "virtualMerchantId": null,
//                 "position": 0,
//                 "description": null,
//                 "details": null,
//                 "image": null,
//                 "taxCategoryId": null,
//                 "sku": "713034072",
//                 "unitType": 3,
//                 "price": 0,
//                 "items": [],
//                 "priceDelivery": 0,
//                 "barcode": null
//             }
//         ],
//         "publishedPDV": true
//     },
//     {
//         "id": 18764,
//         "name": "BEBIDAS ",
//         "position": 30,
//         "image": null,
//         "parentCategoryId": null,
//         "siteColor": null,
//         "virtualMerchantId": null,
//         "categories": [
//             {
//                 "id": 18772,
//                 "name": "SOFT DRINKS ",
//                 "position": 38,
//                 "image": null,
//                 "parentCategoryId": 18764,
//                 "siteColor": null,
//                 "virtualMerchantId": null,
//                 "categories": [],
//                 "items": [
//                     {
//                         "id": 300275,
//                         "name": "ÁGUA TÔNICA SCHWEPPES LATA",
//                         "digitalName": "ÁGUA TÔNICA SCHWEPPES LATA",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-1695241862",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 315841,
//                         "name": "ÁGUA TÔNICA SCHWEPPES",
//                         "digitalName": "ÁGUA TÔNICA SCHWEPPES",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": null,
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "-2090557554",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 300279,
//                         "name": "GUARANÁ  ZERO LATA ",
//                         "digitalName": "GUARANÁ  ZERO LATA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "395058499",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     },
//                     {
//                         "id": 300280,
//                         "name": "GUARANÁ LATA ",
//                         "digitalName": "GUARANÁ LATA ",
//                         "virtualMerchantId": null,
//                         "position": 0,
//                         "description": "",
//                         "details": null,
//                         "image": null,
//                         "taxCategoryId": null,
//                         "sku": "1203380939",
//                         "unitType": 3,
//                         "price": 0,
//                         "items": [],
//                         "priceDelivery": 0,
//                         "barcode": null
//                     }
//                 ],
//                 "publishedPDV": true
//             }
//         ],
//         "items": [],
//         "publishedPDV": true
//     }
// ]
const dataRaw = [
    {
        "id": 19616,
        "name": "Cozinha",
        "position": 1,
        "image": "c_19616-1685452931926.png",
        "parentCategoryId": null,
        "siteColor": null,
        "virtualMerchantId": null,
        "categories": [],
        "items": [
            {
                "id": 313819,
                "name": "Arroz E-Commerce",
                "digitalName": "Arroz E-Commerce",
                "virtualMerchantId": null,
                "position": 1,
                "description": "Arroz e-commerce (descrição)",
                "details": null,
                "image": "c_19616-1685452931926.png",
                "taxCategoryId": 3778,
                "sku": "307576594",
                "unitType": 3,
                "price": 2000,
                "items": [],
                "priceDelivery": 2000,
                "barcode": null
            }
        ],
        "publishedPDV": true
    },
    {
        "id": 19723,
        "name": "Pizzas",
        "position": 1,
        "image": "c_19723-1684764707805.jpeg",
        "parentCategoryId": null,
        "siteColor": null,
        "virtualMerchantId": null,
        "categories": [],
        "items": [
            {
                "id": 315827,
                "name": "Pizza Margherita e-commerce",
                "digitalName": "Pizza Margherita e-commerce",
                "virtualMerchantId": null,
                "position": 1,
                "description": "Pizza margherita descrição e-commerce",
                "details": null,
                "image": "p_315827-1684764881397.jpeg",
                "taxCategoryId": 3778,
                "sku": "-531677973",
                "unitType": 3,
                "price": 1500,
                "items": [],
                "priceDelivery": 1500,
                "barcode": null
            }
        ],
        "publishedPDV": true
    },
    {
        "id": 19808,
        "name": "Promoções",
        "position": 1,
        "image": "c_19808-1685453114302.png",
        "parentCategoryId": null,
        "siteColor": null,
        "virtualMerchantId": null,
        "categories": [
            {
                "id": 19809,
                "name": "Hamburgers",
                "position": 2,
                "image": "c_19809-1685453147048.png",
                "parentCategoryId": 19808,
                "siteColor": null,
                "virtualMerchantId": null,
                "categories": [],
                "items": [
                    {
                        "id": 317445,
                        "name": "Classic Cheeseburger",
                        "digitalName": "Classic Cheeseburger",
                        "virtualMerchantId": null,
                        "position": 1,
                        "description": "Classic taste",
                        "details": null,
                        "image": "p_317445-1685453267076.png",
                        "taxCategoryId": 3778,
                        "sku": "308409021",
                        "unitType": 3,
                        "price": 2500,
                        "items": [],
                        "priceDelivery": 2500,
                        "barcode": null
                    }
                ],
                "publishedPDV": true
            }
        ],
        "items": [],
        "publishedPDV": true
    }

]
const data = dataRaw.map(jsonObj => Object.assign({}, jsonObj));

console.log(data);
export default data