import React, { useEffect, useState } from 'react'
import { HiCheck, HiOutlineCheck } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { Button, CardBox, ContainerComponent, Input, linkRoutes } from '../../../components'
import { GetAllStores, PostStoresReorder } from '../../../services/Stores'

import { Container } from './styles'
import { STORE_STATUS, STYLED_STATUS } from './utils'

const RestaurantsList = () => {
  const history = useHistory()

  const [restaurants, setRestaurants] = useState([])
  const [reorder, setReorder] = useState(false)

  async function fetchData() {
    try {
      const { data: response } = await GetAllStores({})
      setRestaurants(response.data.results)
      setReorder(false)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleSaveReorder() {
    const payload = { ids: restaurants.map(item => item.id) }

    try {
      await PostStoresReorder(payload)
      await fetchData()
      toast.success('Ordem dos restaurantes atualizado com sucesso!')
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleShowRestaurant(event) {
    const restaurant = event.data
    history.push(linkRoutes.restaurantsDetails, { restaurant })
  }

  async function handleReorder({ value }) {
    setRestaurants(value)
    setReorder(true)
  }

  const ColumnUsers = value => <span className="row__datatable centralized">{value.n_ratings} usuários</span>
  const ColumnRating = value => <span className="row__datatable centralized">{value.rating}</span>
  const ColumnName = value => <span className="row__datatable">{value.name}</span>

  const ColumnStatus = value => (
    <div className="row__datatable centralized">
      <span className={STYLED_STATUS[value.status]}>{STORE_STATUS[value.status]}</span>
    </div>
  )

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      headerLeft={['Restaurantes', 'Lista de restaurantes']}
      headerRight={
        reorder && (
          <Button
            customStyles={{ borderRadius: '1.6rem', height: '4rem', marginRight: '1.6rem' }}
            onClick={handleSaveReorder}
            icon={HiCheck}
          >
            SALVAR ORDEM
          </Button>
        )
      }
      title="Restaurantes"
    >
      <Container>
        <div className="filter">
          <Input placeholder="Busque aqui pelo nome do restaurante" label="Busca" />

          <Button iconRight={HiOutlineCheck} customStyles={{ marginLeft: '1.6rem', width: '12rem' }}>
            Aplicar
          </Button>
        </div>

        <CardBox>
          <DataTable
            onRowClick={handleShowRestaurant}
            onRowReorder={handleReorder}
            reorderableColumns
            value={restaurants}
            dataKey="id"
          >
            <Column rowReorder style={{ width: '3.2em', paddingLeft: '1.6rem' }} />
            <Column header="Nome do restaurante" body={ColumnName} field="name" sortable />
            <Column header="Usuários iniciados" body={ColumnUsers} field="n_ratings" sortable />
            <Column header="Avaliações" body={ColumnRating} field="rating" sortable />
            <Column header="Status" body={ColumnStatus} field="active" sortable />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { RestaurantsList }
