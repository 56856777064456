import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-rows: 12rem auto;
  grid-gap: 6.4rem;

  margin-top: 6.4rem;

  .box__header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.6rem;

    .card__header {
      display: flex;
      align-items: center;
      flex-direction: column;

      header {
        display: flex;
        align-items: center;

        width: 100%;

        svg {
          background: var(--color-pink);
          border-radius: 0.4rem;
          margin-right: 0.8rem;
          padding: 0.4rem;
          height: 1.6rem;
          width: 1.6rem;
        }
      }

      h3 {
        color: var(--color-gold);
        font-weight: 500;
        font-size: 3.2rem;
        margin: 0.8rem;
      }
    }
  }

  .box__footer {
    ul {
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;

        padding: 0.8rem;

        span {
          display: flex;
          justify-content: center;
          align-items: center;

          background: var(--color-pink);
          border-radius: 0.4rem;
          font-size: 1rem;
          padding: 0.2rem;
          height: 1.6rem;
          width: 1.6rem;
        }

        .card__products__image {
          display: flex;
          align-items: center;

          width: 100%;

          img {
            border-radius: 0.8rem;
            margin: 0 1.6rem;
            height: 4.2rem;
            width: 4.2rem;
          }
        }

        p {
          max-width: 10rem;
          text-align: end;
          width: 100%;
        }

        :nth-child(even) {
          background: var(--color-primary-800);
        }

        :nth-child(-n + 3) {
          span {
            background: var(--color-gold);
          }
        }
      }
    }
  }
`
