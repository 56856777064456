import styled from 'styled-components'

export const Container = styled.div`
  .row__datatable {
    display: flex;
    align-items: center;

    text-overflow: ellipsis;
    padding-left: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    height: 6.2rem;

    img {
      border-radius: 0.8rem;
      margin-right: 0.8rem;
      height: 4.9rem;
      width: 4.9rem;
    }

    button {
      background: none;
      cursor: pointer;
      border: none;
      color: white;

      span {
        padding: 0.4rem 0.8rem;
        border-radius: 1rem;
        font-size: 1.6rem;
      }

      .span-active {
        background-color: var(--color-green);
      }

      .span-inactive {
        background-color: var(--color-orange);
      }
    }
  }

  th {
    :nth-child(1) {
      background: var(--color-primary-800);
    }

    :nth-child(2) {
        .p-column-title {
        margin-right: 5rem
        }
    }
  }

  .centralized {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
  }
`
