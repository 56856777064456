import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 20rem 1fr;
  grid-template-areas: 'aside content';
  grid-gap: 2rem;

  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;

  input {
    font-family: 'Manrope'
  }

  textarea {
    font-family: 'Manrope'
  }

  aside {
    grid-area: 'aside';
  }

  main {
    grid-area: 'content';
    margin: 0 10rem;
    .header__main {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 3.2rem;
      height: 15rem;
      .headerLeft {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        color: #fff;
        h1 {
          font-weight: 500;
          font-size: 5rem;
          padding: 0;
          margin: 0;
        }
        .header__navigate {
          display: flex;
          align-items: center;

          margin-top: 4rem;
          p {
            margin: 0;
          }
          button {
            background: transparent;
            transition: 0.5s color;
            font-size: 1.4rem;
            cursor: pointer;
            border: none;
            color: #fff;
            padding: 0;
            font-weight: 100;
            :hover {
              color: var(--color-pink);
              transition: 0.5s color;
            }
          }
        }
      }
    }
  }
`
