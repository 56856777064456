import React, { useEffect, useState } from "react";
import {
  HiCheck,
  HiEye,
  HiEyeOff,
  HiOutlinePlus,
  HiPencil,
  HiTrash,
  HiX,
} from "react-icons/hi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHistory } from "react-router";
import { FilePond } from "react-filepond";
import { toast } from "react-toastify";
import { formatterPrice } from "../../../utils/formatters";
import {
  Button,
  CardBox,
  ComponentModal,
  ContainerComponent,
  Input,
  CheckBox,
  linkRoutes,
} from "../../../components";
import {
  PatchProductReorder,
  ProductsDeleted,
  ProductsUpdateId,
} from "../../../services/Product";
import {
  handleCreateImagesPreviewFromUrls,
  uploadArrayImagesFirebase,
} from "../../../utils";
import {
  GetCategoryId,
  PatchCategory,
  PostCategory,
  GetCategoryProducts,
} from "../../../services/Category";
import notImage from "../../../assets/images/notImage.png";

import { Container } from "./styles";
import { Link } from "react-router-dom";
import { NavigateBefore } from "@material-ui/icons";

const CategoryHandle = () => {
  const history = useHistory();

  const handleShowProduct = (product) =>
    history.push(linkRoutes.productsUpdate, { product, products });

  const [viewDisplay, setViewDisplay] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [reorder, setReorder] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [category, setCategory] = useState();
  const [files, setFiles] = useState([]);

  async function fetchData() {
    const categoryHistory = history?.location?.state?.category;
    if (!categoryHistory) return;

    try {
      const { data: response } = await GetCategoryId(categoryHistory.id);

      if (response.data.display === "ALL")
        setViewDisplay({ grid: true, feed: true });
      if (response.data.display === "GRID") setViewDisplay({ grid: true });
      if (response.data.display === "LIST") setViewDisplay({ feed: true });

      const imagesUrl = handleCreateImagesPreviewFromUrls(
        response?.data?.image
      );

      setProducts(categoryHistory.products);
      setCategory(response.data);
      setFiles(imagesUrl);
      setIsEdit(true);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleCreateOrUpdateCategory() {
    try {
      const [image] = files.map((image) => image?.options?.file?.name);

      const payload = {
        display:
          viewDisplay.grid && viewDisplay.feed
            ? "ALL"
            : viewDisplay.grid
            ? "GRID"
            : "LIST",
        name: category?.name,
        available: true,
        image,
      };

      if (!!category?.id) {
        if (reorder)
          await PatchProductReorder({
            id: category?.id,
            payload: { ids: products.map((item) => item.id) },
          });
        await PatchCategory({ id: category?.id, payload });
      } else {
        await PostCategory({ payload });
      }

      toast.success(`Categoria ${isEdit ? "editada" : "criada"} com sucesso!`);
      if (!isEdit) history.push(linkRoutes.products);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handlePrepareFile(fileItem, outputFile) {
    const imageUploaded = await uploadArrayImagesFirebase(outputFile);
    const [imagePreview] = handleCreateImagesPreviewFromUrls(imageUploaded);
    const newArray = [imagePreview, ...files];
    setFiles(newArray);
  }

  async function handleDeleteFile(error, outputFiles) {
    const { file } = outputFiles;
    // await deleteFileFirebase(file.name)
    const newFilesUpload = files.filter(
      (item) => file.name !== item?.options?.file?.name
    );
    setFiles(newFilesUpload);
  }

  async function handleChangeAvailableProduct({ available, id }) {
    const payload = { id, available: !available };

    try {
      await ProductsUpdateId({ id, payload });

      const newProducts = products.map((item) =>
        item.id === id ? { ...item, available: !available } : item
      );

      toast.success("Visibilidade do produto alterada com sucesso!");
      setProducts(newProducts);
    } catch (error) {
      toast.error("Error alterar visibilidade do produto");
    }
  }
  
  async function handleDeleteProduct() {
    try {
      await ProductsDeleted(product.id);
      const productsUpdated = products.filter(item => item.id !== product.id)
      setProducts(productsUpdated)
      history.push(linkRoutes.categoryUpdate, { category: {...category, products: productsUpdated} })
      toast.success("Produto excluído com sucesso");
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }

    handleCloseModal();
  }

  function handleReorder({ value }) {
    setProducts(value);
    setReorder(true);
  }

  function handleOpenModalProduct(product) {
    setProduct(product);
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
    setProduct(null);
  }

  function handleChangeState(event) {
    const { value, name } = event.target;
    setCategory((prevState) => ({ ...prevState, [name]: value }));
  }

  const ColumnProductCode = (product) => (
    <div className="row__datatable centralized">#{product.external_code}</div>
  );
  const ColumnProductPrice = (product) => (
    <div className="row__datatable centralized">{product.price}</div>
  );

  const ColumnProductOptions = (product) => (
    <div className="row__datatable centralized">
      <button onClick={() => handleShowProduct(product)} title="Editar">
        <HiPencil />
      </button>

      <button
        style={{ background: product.available ? "" : "var(--color-pink)" }}
        onClick={() => handleChangeAvailableProduct(product)}
        title={product.available ? "Visível" : "Oculto"}
      >
        {product.available ? <HiEye /> : <HiEyeOff />}
      </button>

      <button onClick={() => handleOpenModalProduct(product)} title="Excluir">
        <HiTrash />
      </button>
    </div>
  );

  const ColumnProduct = (product) => (
    <div className="row__datatable">
      <img src={product.thumbnail || notImage} alt="" />
      <p>{product.name}</p>
    </div>
  );

  useEffect(() => fetchData(), []);

  return (
    <>
      <ComponentModal onRequestClose={handleCloseModal} isOpen={openModal}>
        <p>
          Tem certeza que deseja excluir o produto <b>{product?.name}</b>?
        </p>

        <div>
          <Button
            onClick={handleCloseModal}
            customStyles={{
              background: "var(--color-primary)",
              borderRadius: "1.6rem",
              marginRight: "1.6rem",
              height: "4rem",
            }}
          >
            CANCELAR
          </Button>

          <Button
            customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
            onClick={handleDeleteProduct}
          >
            EXCLUIR
          </Button>
        </div>
      </ComponentModal>

      <ContainerComponent
        headerLeft={[
          "Produtos",
          "Categorias",
          isEdit ? "Editar" : "Nova categoria",
        ]}
        headerRight={
          <Link
            to={{ pathname: linkRoutes.productsCreate, state: { category } }}
          >
            <Button
              customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
              icon={HiOutlinePlus}
            >
              NOVO PRODUTO
            </Button>
          </Link>
        }
        title={isEdit ? "Editar" : "Nova categoria"}
      >
        <CardBox>
          <Container>
            <div className="box__category">
              <FilePond
                labelIdle="Arraste aqui uma imagem para anexar"
                acceptedFileTypes={["image/*"]}
                allowImagePreview={true}
                allowFilePoster={true}
                allowMultiple={false}
                files={files}
                type="file"
                // CALLBACKS
                onpreparefile={handlePrepareFile}
                onremovefile={handleDeleteFile}
                // Image CROP
                // imageCropAspectRatio="1:1"
                // allowImageCrop
                // Image RESIZE
                imageResizeTargetHeight={1000}
                imageResizeTargetWidth={1000}
                imageResizeMode="contain"
                allowImageResize
              />

              <div>
                <Input
                  customStyles={{ color: "var(--color-gold)" }}
                  onChange={handleChangeState}
                  label="Nome da categoria"
                  value={category?.name}
                  name="name"
                />

                <div style={{ display: "flex", marginTop: "1.6rem" }}>
                  <CheckBox
                    onChange={() =>
                      setViewDisplay({
                        ...viewDisplay,
                        grid: !viewDisplay.grid,
                      })
                    }
                    customStyles={{ marginRight: "1.6rem" }}
                    checked={!!viewDisplay.grid}
                  >
                    Exibir no GRID
                  </CheckBox>

                  <CheckBox
                    onChange={() =>
                      setViewDisplay({
                        ...viewDisplay,
                        feed: !viewDisplay.feed,
                      })
                    }
                    customStyles={{ marginRight: "1.6rem" }}
                    checked={!!viewDisplay.feed}
                  >
                    Exibir no FEED
                  </CheckBox>
                </div>
              </div>
            </div>

            {isEdit && products && (
              <DataTable
                value={products}
                reorderableColumns
                onRowReorder={handleReorder}
              >
                <Column
                  rowReorder
                  style={{ width: "3.2em", paddingLeft: "1.6rem" }}
                />
                <Column header="Produto" body={ColumnProduct} />
                <Column header="Código" body={ColumnProductCode} />
                <Column header="Valor" body={ColumnProductPrice} />
                <Column header="Ações" body={ColumnProductOptions} />
              </DataTable>
            )}

            <div className="box__options">
              <Button
                customStyles={{
                  borderRadius: "1.6rem",
                  height: "4rem",
                  background: "var(--color-primary-500)",
                }}
                onClick={history.goBack}
                icon={HiX}
              >
                Cancelar
              </Button>

              <Button
                customStyles={{
                  borderRadius: "1.6rem",
                  height: "4rem",
                  marginLeft: "1.6rem",
                }}
                onClick={handleCreateOrUpdateCategory}
                icon={HiCheck}
              >
                {isEdit ? "Salvar" : "Criar"}
              </Button>
            </div>
          </Container>
        </CardBox>
      </ContainerComponent>
    </>
  );
};

export { CategoryHandle };
