import styled from 'styled-components'

export const Container = styled.div`
  color: white;
  .button__options {
    display: flex;
    button {
      background: var(--color-primary-900);
      border-radius: 2rem;
      margin-left: 1.6rem;
      font-weight: 100;
      height: 4rem;
      :first-child {
        margin-left: 0;
      }
      :focus,
      :active {
        border: 0.2rem solid var(--color-primary-100);
        background: var(--color-primary-100);
        font-weight: 400;
      }
    }
  }
  .loading {
    display: flex;
    justify-content: center;
    margin-top: 20%;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    grid-gap: 3.2rem;

    margin-top: 3.2rem;
    padding: 0;
    li {
      display: grid;
      grid-template-rows: 5rem auto 6rem;

      background: var(--color-primary-700);
      border-radius: 2rem;
      position: relative;
      list-style: none;
      width: 20rem;
      p {
        margin: 0;
      }
      img {
        border-radius: 1rem;
        object-fit: cover;
        background: white;
        height: 4rem;
        width: 4rem;
      }
      .order__header {
        display: flex;
        align-items: center;

        border-left: 2rem solid var(--color-pink);
        background: var(--color-primary-600);
        border-radius: 2rem 2rem 0 0;
        padding-left: 1rem;
      }
      .order__main {
        padding: 1.6rem 2rem;
        strong {
          font-weight: 400;
        }
        .order__main__header {
          display: flex;
          align-items: center;
          strong {
            margin: 0 0 0 0.8rem;
          }
        }
        .order__main__observation {
          margin-top: 2rem;
          strong {
            background: var(--color-primary-600);
            padding: 0.1rem 0.8rem;
            border-radius: 2rem;
          }
          p {
            margin-bottom: 1.6rem;
          }
          .complements {
            padding: 0.8rem 0;
            p {
              font-weight: 400;
              margin: 0;
            }
          }
        }
      }
      .order__footer {
        display: flex;
        align-items: center;

        background: var(--color-primary-600);
        border-radius: 0 0 2rem 2rem;
        padding: 0 1.6rem;
        div {
          margin-left: 1.6rem;
        }
      }
      .order__options {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        animation: fadeOut 0.5s forwards;
        background: #07152299;
        border-radius: 2rem;
        position: absolute;
        height: 100%;
        width: 100%;
        button {
          border-radius: 2rem;
          margin-top: 1rem;
          font-size: 1rem;
          height: 4rem;
          width: 60%;
          :first-child {
            margin-top: 0;
          }
        }
      }
      :hover {
        .order__options {
          animation: fadeIn 0.5s forwards;
        }
        .order__header,
        .order__main,
        .order__footer {
          filter: blur(0.1rem);
        }
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`

export const TopRight = styled.label`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 2rem;
  width: 100%;
  color: white;
  button {
    margin-left: 1rem;
    width: 10rem;
  }
`
