export const STORE_STATUS = {
  INACTIVE: 'Inativo',
  CLOSED: 'Fechado',
  OK: 'Ativo'
}

export const STYLED_STATUS = {
  INACTIVE: 'span-inactive',
  CLOSED: 'span-inactive',
  OK: 'span-active'
}
