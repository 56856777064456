import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css'
import 'react-toastify/dist/ReactToastify.css'
import 'primereact/resources/primereact.css'
import 'filepond/dist/filepond.min.css'
import 'primeicons/primeicons.css'

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from '@material-ui/core/styles'
import { QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import { registerPlugin } from 'react-filepond'
import ReactDOM from 'react-dom'
import React from 'react'

import { queryClient } from './services/queryClient'
import { AuthProvider } from './hook/AuthProvider'
import { GlobalStyle } from './styles/global'
import { theme } from './utils/theme'
import { App } from './App'

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview,
  FilePondPluginImageResize,
  FilePondPluginFilePoster,
  FilePondPluginImageCrop
)

ReactDOM.render(
  <AuthProvider>
    <GlobalStyle />

    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App />
        {process.env.REACT_APP_NODE_ENV === 'development' && <ReactQueryDevtools />}
      </QueryClientProvider>
    </ThemeProvider>

    <ToastContainer style={{ fontSize: '1.2rem', zIndex: '20000' }} hideProgressBar={true} position="bottom-left" />
  </AuthProvider>,

  document.getElementById('root')
)
