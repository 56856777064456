import React, { useEffect, useState } from 'react'
import { HiOutlineCheck, HiPlus, HiSearch } from 'react-icons/hi'
import { Link, useHistory } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { toast } from 'react-toastify'

import { Button, CardBox, ContainerComponent, Input, InputSelect, linkRoutes } from '../../components'
import { GetNotifications } from '../../services/Notifications'
import { GetAllStores } from '../../services/Stores'
import { genders } from './optionsNotifications'
import { formatDate } from '../../utils'

import { Container } from './styles'

const Notifications = () => {
  const history = useHistory()

  const [notifications, setNotifications] = useState([])
  const [filters, setFilters] = useState({})
  const [stores, setStores] = useState([])

  async function fetchData() {
    try {
      const { data: responseNotifications } = await GetNotifications({ params: filters })
      const { data: responseStores } = await GetAllStores({ page_size: 40 })

      const storesFormatted = responseStores.data.results.map(item => ({ label: item.name, value: item.id }))

      const notificationsFormatted = responseNotifications.data.map(item => ({
        ...item,
        style: item.status !== 'CANCELLED' ? 'span-active' : 'span-inactive',
        status: item.status !== 'CANCELLED' ? 'Ativo' : 'Inativo',
        age: item.min_age && item.max_age ? `${item.min_age} à ${item.max_age}` : 'Todas',
        updated_at: formatDate(item.updated_at)
      }))

      setNotifications(notificationsFormatted)
      setStores(storesFormatted)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleShowNotification(event) {
    const notification = event.data
    history.push(linkRoutes.notificationsDetails, { notification })
  }

  const ColumnTotalSend = value => <div className="row__datatable centralized">{value.total_sent}</div>
  const ColumnDate = value => <div className="row__datatable centralized">{value.updated_at}</div>
  const ColumnAge = value => <div className="row__datatable centralized">{value.age}</div>
  const ColumnDescription = value => <div className="row__datatable">{value.body}</div>
  const ColumnGenders = value => <div className="row__datatable" style={{ justifyContent: 'center' }} >{value.genders}</div>

  const ColumnStatus = admin => (
    <div className="row__datatable centralized">
      <span className={admin.style}>{admin.status}</span>
    </div>
  )

  const ColumnTitle = value => (
    <div className="row__datatable">
      <strong>{value.title}</strong>
    </div>
  )

  function handleChangeState(event) {
    const { value, name } = event.target
    setFilters(prevState => ({ ...prevState, [name]: value }))
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      title="Notificações"
      headerRight={
        <Link to={linkRoutes.notificationsNew}>
          <Button customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }} icon={HiPlus}>
            CRIAR NOTIFICAÇÃO
          </Button>
        </Link>
      }
    >
      <Container>
        <div className="filter">
          <Input
            placeholder="Busque aqui por palavras-chave"
            customStyles={{ marginRight: '1.6rem' }}
            onChange={handleChangeState}
            icon={HiSearch}
            label="Busca"
            name="search"
          />

          <InputSelect onChange={handleChangeState} options={stores} label="Restaurante" name="store_id" />

          <InputSelect onChange={handleChangeState} options={genders} label="Sexo" name="gender" />

          <Button onClick={fetchData} iconRight={HiOutlineCheck} customStyles={{ width: '20rem', height: '3.2rem' }}>
            Aplicar
          </Button>
        </div>

        <CardBox>
          <DataTable
            onRowClick={handleShowNotification}
            value={notifications}
            dataKey="id"
          >
            <Column header="Título notificação" body={ColumnTitle} sortable field="title" />
            <Column header="Descrição" body={ColumnDescription} sortable field="body" />
            <Column header="Sexo" body={ColumnGenders} />
            <Column header="Idade" body={ColumnAge} sortable field="age" />
            <Column header="Enviados" body={ColumnTotalSend} sortable field="total_sent" />
            <Column header="Enviado em" body={ColumnDate} sortable field="updated_at" />
            <Column header="Status" body={ColumnStatus} sortable field="status" />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { Notifications }
