
import React, { useEffect } from 'react';
import { ComponentModal, Button } from '../../../../components';

const ImportsModal = ({ isOpen, setModal, text, okFunction }) => {
  return (
    <ComponentModal onRequestClose={() => setModal(false)} isOpen={isOpen}>
      <p>
        {text}
      </p>

      <div>
        <Button
          onClick={() => setModal(false)}
          customStyles={{
            background: "var(--color-primary)",
            borderRadius: "1.6rem",
            marginRight: "1.6rem",
            height: "4rem",
          }}
        >
          CANCELAR
        </Button>

        <Button
          customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
          onClick={okFunction}
        >
          CONFIRMAR
        </Button>
      </div>
    </ComponentModal>
  );
};

export default ImportsModal;
