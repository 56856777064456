export const app_skin = [
  { label: 'Azul escuro', value: 'DARK_BLUE' },
  { label: 'Preto', value: 'BLACK' },
  { label: 'Branco', value: 'WHITE' }
]

export const daysOfTheWeek = [
  { label: 'Segunda-feira', value: 1 },
  { label: 'Terça-feira', value: 2 },
  { label: 'Quarta-feira', value: 3 },
  { label: 'Quinta-feira', value: 4 },
  { label: 'Sexta-feira', value: 5 },
  { label: 'Sabado', value: 6 },
  { label: 'Domingo', value: 0 }
]
