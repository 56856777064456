import * as yup from 'yup'

const phoneRegex = /^(\d{1,2})?[- ]?(\d{1,5})?(\d{1,4})?(.*)?$/

export const schemaSettings = yup.object().shape({
  full_description: yup.string().required('Campo obrigatorio'),
  description: yup.string().required('Campo obrigatorio'),
  cousine_id: yup.string().required('Campo obrigatorio'),
  name: yup.string().required('Campo obrigatorio'),

  // INFO
  service_charge: yup.string().required('Campo obrigatorio'),
  instagram: yup.string().required('Campo obrigatorio'),
  site: yup.string().required('Campo obrigatorio'),

  whatsapp: yup
    .string()
    .matches(phoneRegex, 'Informe um número de telefone válido (XX) XXXXX-XXXX')
    .required('Campo obrigatorio'),

  phone: yup
    .string()
    .matches(phoneRegex, 'Informe um número de telefone válido (XX) XXXXX-XXXX')
    .required('Campo obrigatorio'),

  // ADDRESS
  uf: yup.string().required('Campo obrigatorio').length(2, 'Informe uma UF válida'),
  street_number: yup.string().required('Campo obrigatorio'),
  neighborhood: yup.string().required('Campo obrigatorio'),
  maps_url: yup.string().url('Informe uma URLs válida'),
  zip_code: yup.string().required('Campo obrigatorio'),
  street: yup.string().required('Campo obrigatorio'),
  city: yup.string().required('Campo obrigatorio')
})
