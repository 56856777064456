import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem;

  border: 0.1rem solid var(--color-primary-500);
  background: var(--color-primary-600);
  border-radius: 1.6rem;
  padding: 3.2rem;

  .box__description {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 3;
    span {
      color: var(--color-gold);
      margin-bottom: 0.8rem;
      font-weight: 400;
    }

    textarea {
      border-radius: 0.4rem;
      min-height: 20rem;
      padding: 0.8rem;
    }
  }

  .box__options {
    display: flex;
    justify-content: flex-end;
    grid-column: 1 / 3;
  }
`
