import React, { useContext, useState, createContext, useEffect } from 'react'
import { toast } from 'react-toastify'

import { linkRoutes } from '../components/routes'
import { signIn } from '../services/Auth'
import Api from '../services/client'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [fetched, setFetched] = useState(false)
  const [Loading, setLoading] = useState(true)
  const [logged, setLogged] = useState(false)
  const [user, setUser] = useState()

  const fetchDataAuth = async () => {
    const userLocalStorage = localStorage.getItem('@userData')
    const userParseJSON = userLocalStorage ? JSON.parse(userLocalStorage) : false

    if (userParseJSON === false) {
      setLoading(false)
      return
    }

    setUser(userParseJSON)

    try {
      await Api.setTokenInHeader(userParseJSON.token)

      setFetched(true)
      setLogged(true)
    } catch (error) {
      toast.error(error.message)
    }

    setLoading(false)
  }

  const onUserLogout = () => {
    localStorage.clear()
    setUser([])

    window.location.href = linkRoutes.login
  }

  async function onUserLogin(user) {
    setLoading(true)

    try {
      const { data } = await signIn(user)
      const { role } = JSON.parse(atob(data.data.access_token.split('.')[1]))

      localStorage.setItem('@userData', JSON.stringify({ ...user, role, token: data.data.access_token }))
      setUser({ ...user, role })

      await Api.setTokenInHeader(data.data.access_token)
      setLogged(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }

    setLoading(false)
  }

  async function onTokenLogin(access_token) {
    setLoading(true)

    const { role } = JSON.parse(atob(access_token.split('.')[1]))
    localStorage.setItem('@userData', JSON.stringify({ role, token: access_token }))

    try {
      await Api.setTokenInHeader(access_token)
      setUser({ ...user, role })
      setLogged(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (!fetched) fetchDataAuth()
  }, [fetched])

  return (
    <AuthContext.Provider value={{ onTokenLogin, onUserLogout, onUserLogin, Loading, logged, user }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) throw new Error('useAuth must be used within a AuthContext')

  return context
}
