import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem 3.2rem;

  border: 0.1rem solid var(--color-primary-500);
  background: var(--color-primary-600);
  border-radius: 1.6rem;
  padding: 3.2rem;

  .box_mini {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.6rem;
  }

  .box__options {
    display: flex;
    justify-content: flex-end;
    grid-column: 1/3;
  }
`
