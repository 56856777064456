import Client from '../client'

export const PatchCategoriesReorder = ({ payload }) => Client.apiYounner.patch('/api/categories/reorder', payload)
export const DeletedCategory = ({ category_id }) => Client.apiYounner.delete(`/api/categories/id/${category_id}`)
export const PatchCategory = ({ id, payload }) => Client.apiYounner.patch(`/api/categories/id/${id}`, payload)
export const GetCategoryId = category_id => Client.apiYounner.get(`/api/categories/id/${category_id}`)
export const PostCategory = ({ payload }) => Client.apiYounner.post(`/api/categories`, payload)

export const GetCategoryProducts = ({ id, include }) =>
  Client.apiYounner.get(`/api/categories`, { params: { include } })

export const GetCategories = () => Client.apiYounner.get('/api/categories')