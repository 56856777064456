import React from 'react'
import { HiTrendingDown, HiTrendingUp } from 'react-icons/hi'

import { Container } from './styles'

const CardBox = ({ activeBorder, customStyles, children, variation, label }) => {
  return (
    <Container customStyles={customStyles} variation={variation} activeBorder={activeBorder}>
      {label && <div className="label">{label}</div>}

      {!!variation && (
        <span className="card__box__span">
          {variation >= 0 ? <HiTrendingUp /> : <HiTrendingDown />}
          {variation}%
        </span>
      )}

      {children}
    </Container>
  )
}

export { CardBox }
