import React from 'react'
import { ContainerComponent } from '../../components'

import { Container } from './styles'

const UsagePolicies = () => {
  return (
    <ContainerComponent title="Políticas de uso">
      <Container>
        <section>
          <h2>TERMO DE USO – ESTABELECIMENTO</h2>

          <p>
            Pelo presente instrumento particular, as partes, de um lado,
            <strong> FEEDME SERVICOS DE INTERMEDIACAO E AGENCIAMENTO DE NEGÓCIOS LTDA.</strong>, com sede à Rua Santa
            Catarina, nº 848, Bairro Santa Maria Goretti, Porto Alegre/RS, CEP 91.030- 330, neste ato representada por
            seu Contrato Social (a "<u>FeedMe</u>") e, do outro lado, aquela indicada e qualificada no
            <strong> FORMULÁRIO DE CREDENCIAMENTO </strong>(<u>Anexo I</u>) (o "<u>Estabelecimento</u>" e, em conjunto
            com a FeedMe, as “<u>Partes</u>”), têm entre si, justo e acordado o presente Termo de Uso mediante as
            cláusulas e condições estipuladas (o "<u>Termo</u>").
          </p>
          <p>
            <strong>CONSIDERANDO QUE</strong> o serviço prestado pela FeedMe consiste na intermediação entre o
            Estabelecimento e o Usuário, através da disponibilização, em seu Aplicativo, do cardápio do Estabelecimento,
            permitindo que o Usuário realize a compra dos produtos e serviços através do Aplicativo, tendo acesso às
            avaliações de outros Usuários referentes ao Estabelecimento e sugestões de refeições.
          </p>
          <p>
            <strong>CONSIDERANDO QUE</strong> a responsabilidade pelo cumprimento dos produtos e serviços veiculados no
            Cardápio é inteiramente do Estabelecimento anunciante.
          </p>
          <p>
            <strong>CONSIDERANDO QUE</strong> a FeedMe não utilizará de forma indevida qualquer informação coletada,
            prezando sempre pela intimidade e segurança do Usuário.
          </p>
          <p>
            Resolvem as Partes, de comum acordo e na melhor forma de direito, firmar o presente Termo, de acordo com as
            condições e cláusulas que seguem.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA PRIMEIRA – DEFINIÇÕES</h2>

          <p>
            1.1. Na forma como utilizados neste Contrato, os seguintes termos, os quais não estão definidos ao longo do
            Contrato, terão os significados abaixo definidos, aplicando-se às suas formas singular e plural.
          </p>
          <p>
            “<strong>APLICATIVO</strong>” – É a plataforma online disponibilizada para download gratuito através do
            “Google Play” ou da “Apple Store” que será utilizada como o meio para que os Usuários da FeedMe tenham
            acesso ao Cardápio do Estabelecimento.
          </p>
          <p>
            “<strong>CARDÁPIO</strong>” – É o catálogo onde se encontrarão todos os produtos e serviços comercializados
            e/ou oferecidos pelo Estabelecimento ao Usuário.
          </p>
          <p>
            “<strong>ESTABELECIMENTO</strong>” – É todo e qualquer empreendimento que seja cadastrado no Aplicativo da
            FeedMe e atue como um parceiro, ofertando seu Cardápio aos Usuários da FeedMe.
          </p>
          <p>
            “<strong>FEED HUNTERS</strong>” – Parceiros da FeedMe que irão consumir os produtos e/ou serviços do
            Estabelecimento e indicar o Estabelecimento para terceiros através do marketing de influência.
          </p>
          <p>
            “<strong>USUÁRIO</strong>” – É o cliente que irá acessar o Aplicativo da FeedMe para efetuar a compra do
            produto ofertado pelo Estabelecimento.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA SEGUNDA – OBJETO DO TERMO</h2>

          <p>
            2.1. O Termo tem por objeto o regramento geral da prestação de serviços de intermediação de venda do
            Cardápio do Estabelecimento através do Aplicativo da FeedMe.
          </p>
          <p>
            2.2. O objeto da prestação de serviços da FeedMe é divulgar e intermediar, em seu Aplicativo, o Cardápio do
            Estabelecimento. Todas as ofertas disponibilizadas no Aplicativo passam por critérios de qualificação com
            base nas certificações obrigatórias de cada item, enviadas pelos Estabelecimentos, que são responsáveis pela
            autenticidade dos mesmos.
          </p>
          <p>
            2.3. O Cardápio será estruturado pelo Estabelecimento que irá repassar à FeedMe as condições exatas, tais
            como preço, especificação do produto e/ou serviço, bem como qualquer outra especificidade existente na
            oferta.
          </p>
          <p>
            2.4. A FeedMe não vende produtos e nem presta os serviços divulgados no Aplicativo. O Cardápio divulgado
            através do Aplicativo é realizado e entregue pelo próprio Estabelecimento, de modo que a FeedMe somente
            realiza a intermediação da venda.
          </p>
          <p>
            2.5. A FeedMe, por ser apenas intermediadora, não é a proprietária do Cardápio ofertado pelo
            Estabelecimento, não realiza ofertas em seu nome, entretanto, fica à disposição, através de seus canais de
            atendimento ao Estabelecimento, para intermediar qualquer comunicação necessária com o Usuário e tomar as
            providências necessárias para a solução de eventual transtorno.
          </p>
          <p>
            2.6. Para utilização do Aplicativo é necessário conexão com a internet, sendo seus custos de
            responsabilidade do Estabelecimento. As falhas de comunicação ou do dispositivo utilizado para o acesso não
            são de responsabilidade da FeedMe.
          </p>
          <p>
            2.7. Eventualmente, o sistema poderá não estar disponível por motivos técnicos ou falhas da internet, ou por
            qualquer outro evento fortuito ou de força maior alheio ao controle da FeedMe.
          </p>
          <p>
            2.8. A FeedMe não é responsável pelos comentários escritos pelos Usuários no Aplicativo referente ao
            Cardápio do Estabelecimento ou pelas resenhas elaboradas pelos Feed Hunters.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA TERCEIRA – OBRIGAÇÕES E RESPONSABILIDADES DA FEEDME</h2>

          <p>
            3.1. A FeedMe, por força deste Termo, deverá credenciar o Estabelecimento no Aplicativo, onde será ofertado
            o seu Cardápio aos Usuário.
          </p>
          <p>3.2. A FeedMe, por força deste Termo, poderá, mas não necessariamente irá, a seu exclusivo critério:</p>
          <p>
            a. Divulgar o Estabelecimento e seu Cardápio no Aplicativo FeedMe e nas mídias sociais da FeedMe, da forma e
            na periodicidade escolhida por critério único e exclusivo da FeedMe;
          </p>
          <p>
            b. Divulgar o Cardápio do Estabelecimento através de resenhas elaboradas pelos Feed Hunters contratados por
            critério único e exclusivo da FeedMe;
          </p>
          <p>
            d. Fornecer o material visual e virtual de divulgação do credenciamento do Estabelecimento para que sejam
            divulgados nas mídias sociais do Estabelecimento;
          </p>
          <p>
            e. Revalidar e atualizar o cadastro do Estabelecimento no Aplicativo quando a FeedMe entender que for
            necessário;
          </p>
          <p>
            f. Formatar os textos, imagens e layout referentes à oferta do Estabelecimento a ser publicada no
            Aplicativo, a seu exclusivo critério;
          </p>
          <p>
            g. Disponibilizar as fotos dos produtos e serviços do Cardápio do Estabelecimento para que o Estabelecimento
            possa utilizá-las em suas mídias sociais, a saber, Facebook e Instagram, sendo totalmente vedado o seu uso
            em cardápios físicos ou digitais e em qualquer outro aplicativo, plataforma ou site idêntico, semelhante ou
            de qualquer forma concorrente ao Aplicativo da FeedMe.
          </p>
          <p>
            h. Arcar com todos os custos necessários para a implementação do Cardápio do Estabelecimento no Aplicativo.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA QUARTA – OBRIGAÇÕES E RESPONSABILIDADES DO ESTABELECIMENTO</h2>

          <p>4.1. O Estabelecimento deverá, além de toda as outras obrigações dispostas neste instrumento:</p>
          <p>
            a. Relacionar-se sempre adequadamente com os Usuários, em estrita conformidade com as disposições previstas
            no Código de Defesa do Consumidor;
          </p>
          <p>b. Cumprir todas as condições definidas em seu Cardápio;</p>
          <p>
            c. Assumir toda a responsabilidade referente ao Cardápio anunciado, bem como pela entrega do(s) produto(s)
            e/ou serviço(s) aos Usuários;
          </p>
          <p>
            d. Responsabilizar-se por qualquer prejuízo que o Usuário e/ou a FeedMe, conforme o caso, venha a ter
            decorrente da inobservância das presentes cláusulas contratuais, por quaisquer de seus prepostos ou
            empregados, devendo reembolsar a FeedMe por eventuais despesas causadas decorrentes de ações judiciais e
            extrajudiciais, além de eventuais custas judiciais, honorários advocatícios, bem como de qualquer condenação
            que a FeedMe venha a sofrer, em decorrência da má prestação de serviço do Estabelecimento;
          </p>
          <p>
            e. Responsabilizar-se pela qualidade, adequação e legalidade/regularidade dos produtos e/ou serviços
            ofertados, respondendo exclusivamente por eventuais penalidades/multas aplicadas por autoridades
            competentes, bem como por quaisquer danos causados aos Usuários e terceiros em decorrência da oferta, desde
            que apurada sua culpa e declara, para todos os efeitos, que o seu produto ou serviço está sendo
            comercializado em condição regular e de acordo com as normas gerais e regulamentações específicas inerentes
            àquela atividade ou produto;
          </p>
          <p>
            f. Informar a FeedMe, via e-mail, todas as alterações cadastrais posteriores àquela inicialmente declarada
            no momento do seu credenciamento, tais como e-mail, telefone, mudança de atividade e/ou em sua capacidade de
            instalação/atendimento, alterações de endereço de suas lojas, e em relação ao próprio Cardápio, etc;
          </p>
          <p>
            h. Zelar pela qualidade das experiências ofertadas, sendo o único responsável por eventual negligência,
            imperícia ou imprudência que possam resultar em qualquer tipo de problema ao Usuário;
          </p>
          <p>
            i. Permitir que a FeedMe, sem qualquer ônus ou encargo, utilize o nome e logotipo do Estabelecimento nas
            redes sociais bem como em ações de marketing que a FeedMe venha a desenvolver;
          </p>
          <p>
            k. Disponibilizar canais de atendimento suficientes para realizar os agendamentos e esclarecer as eventuais
            dúvidas dos Usuários;
          </p>
          <p>
            . Emitir, aos Usuários, as notas fiscais devidas sobre o valor promocional integral, referente à sua
            prestação de serviços/produtos, de acordo com a legislação aplicável;
          </p>
          <p>
            m. Garantir a autenticidade do valor original do produto ou serviço, exatamente como estiver disposto no
            Cardápio;
          </p>
          <p>
            p. Garantir que não possui qualquer impedimento, inclusive de órgãos de classe, conselhos regionais ou
            autarquias, para veicular seus serviços respondendo exclusivamente por eventuais penalidades/multas
            aplicadas pelo descumprimento de quaisquer determinações destas entidades;
          </p>
          <p>q. Fornecer rede de internet aos Usuários com velocidade mínima de 100MB (cem megabytes) via wi-fi;</p>
          <p>
            r. Autorizar que a FeedMe realize ações mensais de ativação dos Usuários no Estabelecimento, em horário e
            data previamente ajustado entre as Partes;
          </p>
          <p>
            s. Manter os displays da FeedMe nas mesas do Estabelecimento por, no mínimo, 3 (três) meses para divulgação
            do Aplicativo;
          </p>
        </section>

        <section>
          <h2>CLÁUSULA QUINTA – PAGAMENTO</h2>

          <p>
            5.1. O pagamento de todos os produtos e/ou serviços do Cardápio do Estabelecimento que forem solicitados
            através do Aplicativo, ocorrerá no próprio Aplicativo ou diretamente ao Estabelecimento pelo o Usuário.
          </p>
          <p>
            5.2. A FeedMe cobrará do Estabelecimento uma Taxa de Transação (a “<u>Taxa de Transação</u>”) por cada
            pagamento realizado, conforme valores previstos na Proposta Comercial (“<u>Anexo II – Proposta Comercial</u>
            ”).
          </p>
          <p>
            5.3. O sistema de processamento do pagamento dentro do Aplicativo, referido na Cláusula 5.1, será efetuado
            pelo Sistema Safe2pay (o “<u>Sistema Safe2pay</u>”), o qual será responsável por realizar a divisão dos
            valores devidos entre as Partes, depositando o montante devido ao Estabelecimento diretamente na conta
            bancária de titularidade do Estabelecimento, indicada no <u>Anexo II</u> deste instrumento.
          </p>
          <p>
            5.3.1. Os valores devidos referentes às compras em cartão de crédito e débito, serão liberados em até 31
            (trinta e um) dias e transferidos para a conta indicada neste Termo.
          </p>
          <p>
            5.4. Não será cobrada qualquer tarifa ou taxa pela FeedMe para que o Estabelecimento seja cadastrado no
            Aplicativo, ou para manutenção do mesmo.
          </p>
          <p>
            5.5. Na hipótese de o Usuário realizar o pedido dos produtos e/ou serviços do Cardápio do Estabelecimento
            através do Aplicativo e efetuar o pagamento em espécie diretamente ao Estabelecimento, a Taxa de Transação
            será paga mediante a retenção do respectivo valor dos valores existentes a serem liberados através do
            Sistema Safe2pay e transferidos diretamente à FeedMe.
          </p>
          <p>
            5.6. O Estabelecimento declara ciência do Contrato de Prestação de Serviços (o “<u>Contrato Safe2pay</u>”)
            firmado entre a FeedMe e a SAFE2PAY Intermediação De Negócios LTDA (a “<u>SAFE2PAY</u>”), responsável pelo
            Sistema Safe2pay e que será utilizado para fins de processamento do pagamento dos pedidos realizados no
            Aplicativo, conforme descrito nas Cláusulas 5.2 e 5.3, declarando sua anuência em tornar-se beneficiária do
            Contrato Safe2pay.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA SEXTA – PRAZO, VIGÊNCIA E ENCERRAMENTO DO TERMO</h2>

          <p>
            6.1. O Termo tem prazo de vigência indeterminado, sendo que o início da sua vigência se dá a partir da data
            de assinatura.
          </p>
          <p>
            6.2. Este instrumento poderá ser rescindido por qualquer das Partes, a qualquer momento, sem qualquer tipo
            de ônus, cabendo à Parte interessada na rescisão comunicar a outra com 60 (sessenta) dias de antecedência,
            devendo as Partes seguirem com suas obrigações até o final do aviso prévio.
          </p>
          <p>
            6.3. Ainda, este Termo poderá ser rescindido unilateralmente, de forma imediata, mediante simples
            comunicação ao Estabelecimento, por parte da FeedMe, sempre que o Estabelecimento incorrer no descumprimento
            das obrigações dispostas neste Termo.
          </p>
          <p>
            6.4. Ocorrendo a rescisão acima mencionada, o Estabelecimento será descredenciado do Aplicativo da FeedMe e
            se obriga a interromper imediatamente sua utilização, bem como se compromete a não mais utilizar o material
            publicitário que aponta o Estabelecimento como credenciado, além de eventuais perdas e danos.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA SÉTIMA – AUSÊNCIA DE VÍNCULO TRABALHISTA</h2>

          <p>
            7.1. Não se estabelece, por força deste Termo, qualquer vínculo empregatício entre o a FeedMe e os sócios,
            empregados, prepostos ou funcionários do Estabelecimento, correndo por conta exclusiva do Estabelecimento os
            encargos decorrentes da legislação vigente, sejam sociais, fiscais, parafiscais, trabalhistas,
            previdenciárias, ambientais e sanitárias, ou quaisquer outros que vierem a ser criados pela lei.
          </p>
          <p>
            7.2. A FeedMe está autorizada a celebrar acordos e efetuar pagamentos decorrentes de eventuais condenações
            em processos judiciais ou administrativos oriundos do presente Termo, sem necessidade de prévia aprovação do
            Estabelecimento, devendo o Estabelecimento ressarcir a FeedMe valores pagos, acrescidos de honorários,
            custas e/ou qualquer outra taxa que possa ser imputada, em até 10 (dez) dias contados do recebimento da
            respectiva notificação.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA OITAVA – SIGILO E CONFIDENCIALIDADE</h2>

          <p>
            8.1. O Estabelecimento se compromete a manter sigilo de todas as informações que tiver acesso diante do
            cumprimento do presente Termo, como também informações atinentes a política comercial, estratégia de
            marketing, dados de Usuários, entre outros.
          </p>
          <p>
            8.2. O Estabelecimento compromete-se a não utilizar as informações para propósitos que não se coadunem com o
            presente Termo, nem as divulgar, em nenhuma hipótese, a quaisquer terceiros ou a qualquer de seus
            empregados, sócios, subcontratados, prepostos e/ou colaboradores, ressalvando-se, quanto aos empregados,
            sócios, subcontratados, prepostos e/ou colaboradores, as informações absolutamente necessárias para executar
            as obrigações assumidas neste Termo.
          </p>
          <p>
            8.3. O Estabelecimento responsabiliza-se pelos atos de seus empregados, sócios, subcontratados, prepostos
            e/ou colaboradores que resultem em prejuízo à FeedMe, sobretudo em relação à quebra do dever de sigilo e
            confidencialidade ora assumidos, seja em relação a informações e/ou segredos de comércio previstos nas
            autorizações mencionadas nas cláusulas anteriores, seja em relação a quaisquer outras informações e/ou
            segredos de que tenham conhecimento por qualquer outro meio.
          </p>
          <p>
            8.4. No caso de violação das obrigações de confidencialidade previstas no presente Termo, o Estabelecimento
            ficará obrigado a indenizar todos os prejuízos sofridos pela FeedMe, incluindo lucros cessantes, sem
            prejuízo das demais cominações legais, desde que apurados judicialmente.
          </p>
          <p>
            8.5. Durante o prazo de vigência deste Termo e por um período de 2 (dois) anos após seu término, qualquer
            que seja o motivo do término, o Estabelecimento compromete-se a não revelar, direta ou indiretamente, a
            qualquer pessoa física, jurídica ou entidade de qualquer natureza, as informações confidenciais às quais
            tenha tido acesso ou venha a ter acesso, por qualquer meio.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA NONA – PROPRIEDADE INTELECTUAL E ADEQUAÇÃO À LEI GERAL DE PROTEÇÃO DE DADOS</h2>

          <p>
            9.1. Todos os direitos de propriedade intelectual no tocante ao Aplicativo serão de propriedade exclusiva e
            definitiva da FeedMe.
          </p>
          <p>
            9.2. Todas as imagens elaboradas pela FeedMe referente ao Cardápio e ao Estabelecimento (as “<u>Imagens</u>
            ”), ainda que com o auxílio do Estabelecimento, serão de propriedade exclusiva e definitiva da FeedMe,
            motivo pelo qual o Estabelecimento cede, em caráter irrevogável e irretratável, a título gratuito, todos os
            eventuais direitos que seja o titular no tocante às Imagens
          </p>
          <p>
            9.3. As Partes concordam que não poderá o Estabelecimento, durante ou mesmo após o término do presente
            Termo, copiá-los, modificá-los, repassá-los a quaisquer terceiros ou utilizá-los para fins alheios a este
            instrumento.
          </p>
          <p>
            9.4. O presente Termo não autoriza o Estabelecimento a utilizar quaisquer componentes de propriedade
            industrial e/ou intelectual da FeedMe, notadamente seus nomes e marcas, a estes não se limitando, para fins
            de propaganda, ou quaisquer outros, fora do escopo da contratação sem o prévio e expresso consentimento por
            escrito da FeedMe.
          </p>
          <p>
            9.5. As Partes se comprometem para o bom e fiel cumprimento deste Contrato, em caso da realização de
            qualquer tipo de tratamento de dados pessoais (o “<u>Tratamento de Dados</u>”), isto é, que faça uso de
            informações relacionadas à pessoa natural identificada ou identificável (os “<u>Dados Pessoais</u>”) e
            obrigam-se a cumprirem as normas de proteção de dados aplicáveis à espécie, notadamente a Lei Federal 13.709
            de 14 de agosto de 2018 (a “<u>LGPD</u>”).
          </p>
          <p>
            9.6. As Partes estão cientes que no caso de exposição/vazamento de dados ou outra violação à LGPD,
            decorrente do tratamento de Dados Pessoais de cada, estas obrigam-se a comunicar o fato imediatamente à
            Parte prejudicada (a “<u>Parte Prejudicada</u>”), para que esta tome as providencias cabíveis e necessárias
            no prazo máximo de 24 (vinte e quatro) horas, a contar da ciência do incidente por qualquer uma das Partes.
          </p>
          <p>
            9.7. Uma vez terminado este Termo, as Partes obrigam-se, expressamente, a excluir todo e qualquer Dado
            Pessoal tratado para a finalidade de execução deste Contrato, inclusive backups e arquivos externos,
            isentando cada Parte de responsabilidade por qualquer dano e prejuízo, direto ou indireto, advindos de
            tratamento de Dados Pessoais perpetrados após o término deste Termo.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA DEZ – PRÁTICAS ANTICORRUPÇÃO</h2>

          <p>
            10.1. As Partes obrigam-se a observar, cumprir e/ou fazer cumprir, por si, e prepostos (diretores,
            funcionários, quaisquer terceiros, incluindo assessores ou prestadores de serviços) toda e qualquer Lei
            Anticorrupção, em especial a Lei 12.846/13, bem como abster-se de praticar quaisquer condutas indevidas.
          </p>
          <p>
            10.2. O Estabelecimento assumirá todos os ônus e consequências de suas práticas ilegais, inclusive o
            ressarcimento de perdas e danos que atingirem a FeedMe e/ou seus Usuários.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA ONZE – DISPOSIÇÕES GERAIS</h2>

          <p>
            11.1. O Estabelecimento mantém o vínculo com a FeedMe na vigência do presente instrumento em caráter não
            exclusivo.
          </p>
          <p>
            11.2. Qualquer alteração a este Termo só será considerada válida se realizada por escrito e assinada pelas
            pessoas identificadas e qualificadas no preâmbulo deste Termo, e/ou por aquelas que documentalmente
            possuírem poderes para representar a pessoa jurídica que firma este instrumento.
          </p>
          <p>
            11.3. Qualquer tolerância em relação às obrigações aqui assumidas será considerada mera liberalidade, não
            gerando qualquer direito para ambas as Partes, tampouco podendo ser interpretada como repactuação ou
            aditamento a este Termo.
          </p>
          <p>
            11.4. As Partes resolverão eventuais divergências, lacunas ou ambiguidades na interpretação ou no
            cumprimento deste Termo com base nos princípios da boa-fé, da probidade, da equidade, da razoabilidade e da
            economicidade, preenchendo as lacunas com estipulações que, presumivelmente, teriam correspondido à vontade
            das Partes na ocasião, de acordo com a lei brasileira.
          </p>
        </section>

        <section>
          <h2>CLÁUSULA DOZE – FORO DE ELEIÇÃO</h2>

          <p>
            12.1. Fica eleito o foro da comarca de Porto Alegre/RS para dirimir quaisquer dúvidas emergentes deste
            instrumento, com expressa renúncia a qualquer outro.
          </p>
          <p>
            E por assim se acharem justas e contratadas, as Partes firmam o presente em 02 (duas) vias de igual teor e
            para um só efeito, juntamente com as testemunhas adiantes assinadas.
          </p>
        </section>
      </Container>
    </ContainerComponent>
  )
}

export { UsagePolicies }
