import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: var(--color-primary);
  border-radius: 1.6rem;
  position: relative;
  background: white;
  padding: 3.2rem;

  ${({ customStyles }) => customStyles}

  .button__close {
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--color-pink);
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    color: white;
    height: 2rem;
    width: 2rem;
    right: 1rem;
    top: 1rem;
  }
`
