import styled from 'styled-components'

export const Container = styled.div`
  .row__datatable {
    display: flex;
    align-items: center;

    padding-left: 1.6rem;
    height: 4.9rem;

    img {
      background: var(--color-primary-900);
      border-radius: 1rem;
      margin-right: 1rem;
      object-fit: cover;
      height: 4rem;
      width: 4rem;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--color-primary-700);
      border-radius: 0.8rem;
      margin-left: 1.6rem;
      cursor: pointer;
      border: none;
      height: 3rem;
      width: 3rem;

      :first-child {
        margin: 0;
      }

      :hover {
        background: var(--color-pink);
      }

      svg {
        color: white;
      }
    }
  }

  th {
    :nth-child(2) {
      background: var(--color-primary-500);
    }

    :nth-child(3) {
      span {
        margin-right: 9rem
      }
    }
  }

  .centralized {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
  }
`
