import styled from 'styled-components'

export const Container = styled.div`
  .card__container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1.6rem;

    margin-bottom: 3.2rem;

    .card__box {
      border: 0.1rem solid var(--color-primary-500);
      border-left: 0.8rem solid var(--color-pink);
      background: var(--color-primary-700);
      padding: 1.6rem 3.2rem;
      border-radius: 1.6rem;
      height: 8rem;

      h2 {
        color: var(--color-gold);
        text-align: center;
        font-size: 3.2rem;
      }
      div {
        display: flex;
        align-items: center;
        span {
          display: flex;
          justify-content: center;
          align-items: center;

          background: var(--color-pink);
          border-radius: 0.4rem;
          margin-right: 0.8rem;
          height: 2.2rem;
          width: 2.2rem;
        }
      }
    }
  }

  .row__datatable {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0.6rem 1rem;
    margin-left: 1.6rem;

    span {
      padding: 0.4rem 0.8rem;
      border-radius: 1rem;
      font-size: 1.6rem;
    }
  }

  .centralized {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
  }
`
