import React from 'react'
import * as ReactInputMask from 'react-input-mask'
import Input from '../Input'

import { Container } from './styles'

const InputMask = ({ customStyles, className, placeholder, register, errors, label, mask, name }) => {
  return (
    <Container className={className} errors={errors}>
      <ReactInputMask
        placeholder={placeholder}
        error={errors.name}
        {...register(name)}
        label={label}
        mask={mask}
        name={name}
      >
        {inputProps => <Input customStyles={customStyles} {...inputProps} />}
      </ReactInputMask>

      {errors && errors[name] && <span className="message__error">{errors[name]?.message}</span>}
    </Container>
  )
}

export { InputMask }
