import styled from 'styled-components'

export const Container = styled.div`
  border: 0.1rem solid var(--color-primary-800);
  background: var(--color-primary-700);
  border-radius: 1.6rem;
  position: relative;
  padding: 1.6rem;

  ${({ activeBorder, variation }) =>
    activeBorder
      ? variation >= 0
        ? 'border-left: 0.8rem solid var(--color-green);'
        : 'border-left: 0.8rem solid var(--color-orange);'
      : ''}

  ${({ customStyles }) => customStyles}

  .label {
    position: absolute;
    font-weight: 400;
    font-size: 2.2rem;
    top: -3.2rem;
    left: 0;
  }

  .card__box__span {
    display: flex;
    align-items: center;

    color: ${({ variation }) => (variation >= 0 ? 'var(--color-green)' : 'var(--color-orange)')};
    border: 0.1rem solid var(--color-primary-800);
    border-radius: 1.6rem 1.6rem 0rem 0rem;
    background: var(--color-primary-700);
    padding: 0.8rem 0.8rem 0 0.8rem;
    border-bottom: none;
    font-weight: 500;
    position: absolute;
    font-size: 1.2rem;
    right: 1.6rem;
    top: -2.6rem;

    svg {
      background: ${({ variation }) => (variation >= 0 ? 'var(--color-green)' : 'var(--color-orange)')};
      border-radius: 0.4rem;
      margin-right: 0.8rem;
      padding: 0.4rem;
      height: 0.8rem;
      width: 0.8rem;
      color: white;
    }
  }
`
