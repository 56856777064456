import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;

  p {
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    margin-left: 0.8rem;
  }

  div {
    display: flex;
    justify-content: flex-start;

    background-color: ${({ active }) => (active ? 'var(--color-pink)' : 'var(--color-primary-400)')};
    justify-content: ${({ active }) => (active ? 'flex-end' : 'flex-start')};
    border-radius: 10rem;
    padding: 0.2rem;
    cursor: pointer;
    width: 4rem;

    .switch__span {
      border-radius: 100%;
      background: white;
      height: 2rem;
      width: 2rem;
    }
  }
`
