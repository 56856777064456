import styled from 'styled-components'

export const Container = styled.label`
  display: flex;
  flex-direction: column;

  color: var(--color-pink);
  font-weight: 400;
  font-size: 1.6rem;
  width: 100%;

  ${({ customStyles }) => customStyles}

  textarea {
    border-radius: 0.4rem;
    margin-top: 0.8rem;
    padding: 0.8rem;
    height: 100%;
    color: #000;
  }

  span {
    color: var(--color-orange);
    margin-top: 0.4rem;
    font-size: 1.4rem;
    font-weight: 600;
    display: block;
  }
`
