import React, { useEffect, useState } from 'react'
import { HiOutlineClipboardList, HiCurrencyDollar, HiOutlinePlus } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import { Button, CardBox, ContainerComponent, linkRoutes } from '../../components'
import { GetStatisticsStores } from '../../services/Statistics'
import notImage from '../../assets/images/notImage.png'
import { formatterPrice } from '../../utils'

import { Container } from './styles'

const Restaurants = () => {
  const [trending, setTrending] = useState([])
  const [revenues, setRevenues] = useState([])

  async function fetchData() {
    try {
      const { data: response } = await GetStatisticsStores()

      setTrending(response.data.trending)
      setRevenues(response.data.revenues)
    } catch (error) {
      console.log(error?.response?.data)
    }
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      title="Restaurantes"
      headerRight={
        <div>
          <Link to={linkRoutes.restaurantsCreate}>
            <Button
              icon={HiOutlinePlus}
              customStyles={{
                borderRadius: '1.6rem',
                marginRight: '1.6rem',
                height: '4rem'
              }}
            >
              CRIAR RESTAURANTE
            </Button>
          </Link>

          <Link to={linkRoutes.restaurantsList}>
            <Button
              icon={HiOutlineClipboardList}
              customStyles={{
                borderRadius: '1.6rem',
                height: '4rem'
              }}
            >
              LISTA DE RESTAURANTES
            </Button>
          </Link>
        </div>
      }
    >
      <Container>
        <div className="box__header">
          <label>Faturamento médio por restaurante ativo na plataforma</label>

          <div className="box__header__cards">
            {revenues?.map(item => (
              <CardBox variation={item?.variation} activeBorder>
                <div className="cards">
                  <header>
                    <HiCurrencyDollar />
                    {item.label}
                  </header>

                  <h2>{formatterPrice(item.amount)}</h2>
                </div>
              </CardBox>
            ))}
          </div>
        </div>

        <CardBox label="Restaurantes em alta">
          <ul className="cards__restaurants">
            {trending?.map((item, index) => (
              <li key={item.id}>
                <div className="restaurant__position">
                  <strong>{index + 1}</strong>
                </div>

                <div className="restaurant__image">
                  <img src={item.logo_black || notImage} alt={item.name} />
                  <strong>{item.name}</strong>
                </div>

                <p>{`${item.total} visitas`}</p>
              </li>
            ))}
          </ul>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { Restaurants }
