import firebase from '../../services/firebaseClient'

export async function uploadArrayImagesFirebase(files: Files | Files[]): Promise<string[]> {
  const dirname = process.env.REACT_APP_NODE_ENV === 'development' ? 'development' : 'images'
  const array = Array.isArray(files) ? files : [files]

  try {
    return await Promise.all(
      array.map(async item => {
        if (!!item.options) return item.options.file.name

        const fileName = `${new Date().getTime()}-${item.name}`
        const storageRef = firebase.storage().ref(`${dirname}/${fileName}`)

        await storageRef.put(item)

        return await storageRef.getDownloadURL()
      })
    )
  } catch (error) {
    console.log('uploadArrayImagesFirebase: ', error)
  }
}
