import React, { useEffect, useState } from 'react'
import { HiOutlineCheck, HiOutlineChevronDown } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { toast } from 'react-toastify'

import { Button, ContainerComponent, Input, InputSelect } from '../../../components'
import { options } from '../../../utils/optionsSelect'

import { Container } from './styles'

const RestaurantsApprovals = () => {
  const [approval, setApproval] = useState([])

  async function fetchData() {
    try {
      const { data: response } = []

      const approvalFormatted = response.data.map(item => ({
        ...item,
        status: item.XXX === 'XXX' ? 'Aberto' : 'Fechado'
      }))

      setApproval(approvalFormatted)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  const ColumnCategory = value => <span className="row__datatable">{value.xxx}</span>
  const ColumnAddress = value => <span className="row__datatable">{value.xxx}</span>
  const ColumnActions = value => <span className="row__datatable">{value.xxx}</span>
  const ColumnCNPJ = value => <span className="row__datatable">{value.xxx}</span>
  const ColumnName = value => <span className="row__datatable">{value.xxx}</span>

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent headerLeft={['Restaurantes', 'Lista de aprovações']} title="Lista de aprovações">
      <Container>
        <div className="filter">
          <Input placeholder="Busque aqui pelo nome do restaurante" label="Busca" />
          <InputSelect icon={HiOutlineChevronDown} options={options} label="Categoria" />
          <InputSelect icon={HiOutlineChevronDown} options={options} label="Localização" />
          <InputSelect icon={HiOutlineChevronDown} options={options} label="Status" />

          <Button iconRight={HiOutlineCheck} customStyles={{ width: '66rem' }}>
            Aplicar
          </Button>
        </div>

        <DataTable value={approval} dataKey="id" currentPage paginator rows={8}>
          <Column header="Nome do restaurante" body={ColumnName} field="code" sortable />
          <Column header="Categoria" body={ColumnCategory} field="description" sortable />
          <Column header="CNPJ" body={ColumnCNPJ} />
          <Column header="Localização" body={ColumnAddress} />
          <Column header="Ações" body={ColumnActions} />
        </DataTable>
      </Container>
    </ContainerComponent>
  )
}

export { RestaurantsApprovals }
