import { useState, useEffect } from "react"
import { InputSelect, Button } from "../../../../components"
import { HiArrowNarrowRight } from "react-icons/hi"
import { PatchProduct } from "../../../../services/Product"
import { Autocomplete } from "@material-ui/lab"
import { TextField } from "@material-ui/core"
import { toast } from "react-toastify";

const ProductLink = ({ fmProducts, categories }) => {
    const [selectedOpdvProduct, setSelectedOpdvProduct] = useState(null)

    const [opdvOptions, setOpdvOptions] = useState(null)
    const [selectedFMProduct, setSelectedFMProduct] = useState(null)


    useEffect(() => {
        const options = categories.flatMap(cat => cat.items).map((p) => ({ label: p.name, value: p }))
        setOpdvOptions(options)

    }, [categories])

    async function syncWithOpdv() {
        const linkedProduct = {
            ...selectedFMProduct.value,
            price: selectedOpdvProduct.value.price.toString().replace('.', ''),
            origin: "opdv",
            integration_code: selectedOpdvProduct.value.id.toString(),
            integration_name:  selectedOpdvProduct.value.digitalName
        }
        delete linkedProduct.n_likes
        delete linkedProduct.liked
        try {
            await PatchProduct({ id: linkedProduct.id, payload: linkedProduct })
            toast.success("Produto vinculado com sucesso")
        } catch (e) {
            toast.error("Algo deu errado")
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                <div
                    style={{ width: '50rem' }}
                    >
                    <p>Produto OPDV</p>
                    <Autocomplete
                        // sx={{ width: 300 }}
                        value={selectedOpdvProduct}
                        onChange={(e, newValue) => setSelectedOpdvProduct(newValue)}
                        id="opdv-product-link"
                        options={opdvOptions}
                        style={{ background: "white" }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=" "
                            />
                        )}
                    />
                </div>

                <  HiArrowNarrowRight
                    size={30} style={{ marginTop: 60 }} />

                <div 
                style={{ width: '50rem' }}
                >
                    <p>Produto FeedMe</p>
                    <Autocomplete
                        sx={{ width: 300 }}
                        value={selectedFMProduct}
                        onChange={(e, newValue) => setSelectedFMProduct(newValue)}
                        id="fm-product-link"
                        options={fmProducts.map((p) => ({ label: p.name, value: p }))}
                        style={{ background: "white" }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=" "
                            />
                        )}
                    />

                </div>

            </div>
            <Button
                onClick={syncWithOpdv}
                customStyles={{
                    borderRadius: "1.6rem",
                    height: "4rem",
                    marginRight: "1.6rem",
                }}
            >
                VINCULAR PRODUTOS
            </Button>
        </div>
    )
}

export default ProductLink