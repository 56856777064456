import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem 3.2rem;

  border: 0.1rem solid var(--color-primary-500);
  background: var(--color-primary-600);
  border-radius: 1.6rem;
  padding: 3.2rem;

  .inbox__change__image {
    grid-column: 1 / 2;
    grid-row: 1 / 7;

    img {
      background: var(--color-primary-900);
      border-radius: 1.6rem;
      cursor: pointer;
      height: 100%;
      width: 100%;
    }

    input {
      display: none;
    }
  }

  .box__description {
    display: flex;
    flex-direction: column;
    grid-row: 2 / 4;

    span {
      color: var(--color-gold);
      margin-bottom: 0.8rem;
      font-weight: 400;
    }

    textarea {
      border-radius: 0.4rem;
      padding: 0.8rem;
      height: 100%;
    }
  }

  .box__options {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div {
      display: flex;
    }
  }
`
