import React from 'react'
import { Calendar } from 'primereact/calendar'
import { addLocale } from 'primereact/api'

import { Container } from './styles'

const InputCalendar = ({ customStyles, onChange, label, icon: Icon, ...props }) => {
  addLocale('ptBr', {
    firstDayOfWeek: 1,
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ]
  })

  return (
    <Container customStyles={customStyles}>
      {label}

      <div className="input__box">
        <Calendar onChange={onChange} locale="ptBr" dateFormat="dd/mm/yy" {...props} />

        <div className="icon__box">{Icon && <Icon />}</div>
      </div>
    </Container>
  )
}

export { InputCalendar }
