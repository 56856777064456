import React, { useEffect, useState } from 'react'
import { HiCheck, HiTrash, HiX } from 'react-icons/hi'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { DeleteOneCousines, GetOneCousine, PatchCousines, PostCousines } from '../../../services/Cousines'
import { ContainerComponent, ComponentModal, Switch, Button, Input, CardBox } from '../../../components'

import { Container } from './styles'

const CousinesHandle = () => {
  const history = useHistory()

  const [cousine, setCousine] = useState({ available: false })
  const [openModal, setOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  async function fetchData() {
    const cousineHistory = history?.location?.state?.cousine
    if (!cousineHistory) return
    setIsEdit(true)

    try {
      const { data: response } = await GetOneCousine(cousineHistory.id)
      setCousine(response.data)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleCreateCousine() {
    if (!cousine.name) {
      toast.error('Você precisa informar o nome da cozinha!')
      return
    }

    try {
      await PostCousines(cousine)
      toast.success('Cozinha criada com sucesso')
      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleEditCousine() {
    if (!cousine.name) {
      toast.error('Você precisa informar o nome da cozinha!')
      return
    }

    const { available, name, id } = cousine

    try {
      await PatchCousines(id, { available, name })
      toast.success('Cozinha editada com sucesso')
      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleDeleteCousine() {
    try {
      await DeleteOneCousines(cousine.id)
      toast.success('Cozinha removida com sucesso')
      history.goBack()
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setCousine(prevState => ({ ...prevState, [name]: value }))
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      headerLeft={['Cozinhas', isEdit ? 'Detalhes' : 'Nova cozinha']}
      title={isEdit ? 'Detalhes' : 'Nova cozinha'}
    >
      <ComponentModal onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <h3>{`Cozinha ${isEdit ? 'editada' : 'criada'} com sucesso`}</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <Container>
        <CardBox customStyles={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '6.4rem' }}>
          <Input
            customStyles={{ color: 'var(--color-gold)', gridColumn: '1 / 3' }}
            placeholder="Insira o nome da cozinha"
            onChange={handleChangeState}
            value={cousine.name}
            label="Cozinha"
            name="name"
          />

          <div className="box__options">
            <Switch
              onClick={() => setCousine({ ...cousine, available: !cousine.available })}
              active={cousine.available}
            >
              {cousine.available ? 'Ativo' : 'Inativo'}
            </Switch>

            <div>
              <Button
                onClick={isEdit ? handleDeleteCousine : () => history.goBack()}
                icon={isEdit ? HiTrash : HiX}
                customStyles={{
                  background: 'var(--color-primary-500)',
                  borderRadius: '1.6rem',
                  marginRight: '1.6rem',
                  height: '4rem',
                  width: '20rem'
                }}
              >
                {isEdit ? 'Remover' : 'Cancelar'}
              </Button>

              <Button
                customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
                onClick={isEdit ? handleEditCousine : handleCreateCousine}
                icon={HiCheck}
              >
                SALVAR
              </Button>
            </div>
          </div>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { CousinesHandle }
