import React, { useState } from 'react'
import { HiCheck, HiTrash } from 'react-icons/hi'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { ContainerComponent, ComponentModal, Button, Switch, Input } from '../../../components'

import { Container } from './styles'

const FacPrivacy = () => {
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)
  const [privacy, setPrivacy] = useState({})

  async function handleUpdatePrivacy() {
    try {
      toast.success('Texto alterado com sucesso!')
      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setPrivacy(prevState => ({ ...prevState, [name]: value }))
  }

  return (
    <ContainerComponent title="Privacidade" headerLeft={['Dúvidas', 'Privacidade']}>
      <ComponentModal onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <h3>Texto alterado com sucesso!</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <Container>
        <Input
          customStyles={{ color: 'var(--color-gold)' }}
          onChange={handleChangeState}
          value={privacy.xxx}
          label="Título"
          name=""
        />

        <div className="box__description">
          <span>Texto</span>

          <textarea onChange={handleChangeState} name="description" />
        </div>

        <div className="box__options">
          <Switch
            onClick={() => setPrivacy(prevState => ({ ...prevState, active: !privacy.active }))}
            active={privacy.active}
          >
            {privacy.active ? 'Ativo' : 'Desativado'}
          </Switch>

          <Button
            customStyles={{ borderRadius: '1.6rem', width: '14rem', height: '4rem' }}
            onClick={handleUpdatePrivacy}
            icon={HiCheck}
          >
            SALVAR
          </Button>
        </div>
      </Container>
    </ContainerComponent>
  )
}

export { FacPrivacy }
