import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-gap: 6.4rem;

  margin-bottom: 6.4rem;

  .box__statistics {
    display: flex;
    align-items: center;

    img {
      height: 13rem;
      width: 19rem;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin: 0 auto;
      width: 100%;

      :nth-child(odd) {
        border-right: 0.1rem solid var(--color-primary-500);
        border-left: 0.1rem solid var(--color-primary-500);
      }

      h3 {
        color: var(--color-gold);
        font-weight: 500;
        font-size: 3.2rem;
        margin: 0.8rem;
      }

      p {
        margin: 0;
      }
    }
  }

  .box__options {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    grid-column: 1 / 3;
  }
`
