import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { HiOutlineCheck } from 'react-icons/hi'
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { Button, CardBox, ComponentModal, ContainerComponent, Input, InputMask } from '../../../components'
import { removeCharacters } from '../../../utils/string'
import { PostAdmin } from '../../../services/Admins'

import { Container } from './styles'

const addCollaborator = yup.object().shape({
  fullname: yup.string().required('Por favor preencher o nome.').max(100, 'Limite máximo de 100 caracteres.'),
  repeatPassword: yup.string().oneOf([null, yup.ref('password')], 'As senhas precisam ser iguais'),
  password: yup.string().required('Senha obrigatória').min(5, 'No mínimo 5 caracteres'),
  email: yup.string().required('E-mail obrigatório.').email('Ops!, E-mail incorreto.'),
  phone: yup.string().required('Telefone obrigatório')
})

const CreateTeam = () => {
  const { formState, handleSubmit, register } = useForm({ resolver: yupResolver(addCollaborator) })
  const { errors } = formState
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)

  async function handleCreationCollaboration({ password, fullname, email, phone }) {
    try {
      await PostAdmin({ password, fullname, email, phone: removeCharacters(phone) })
      toast.success('Usuário criado com sucesso')
      setOpenModal(true)
    } catch (error) {
      toast.error(error.response?.data.error.message)
    }
  }

  return (
    <>
      <ComponentModal onRequestClose={() => setOpenModal(false)} isOpen={openModal}>
        <h2>Usuário criado com sucesso!</h2>
        <Button onClick={() => history.goBack()}>OK</Button>
      </ComponentModal>

      <ContainerComponent
        title="Adicionar colaborador"
        headerLeft={['Configurações', 'Equipe', 'Adicionar colaborador']}
      >
        <CardBox>
          <Container onSubmit={handleSubmit(handleCreationCollaboration)}>
            <Input
              placeholder="Insira o nome completo do usuário"
              customStyles={{ color: 'var(--color-gold)' }}
              label="Nome completo"
              register={register}
              errors={errors}
              name="fullname"
            />

            <InputMask
              customStyles={{ color: 'var(--color-gold)' }}
              placeholder="(99) 99999-9999"
              mask="(99) 99999-9999"
              register={register}
              label="Telefone"
              errors={errors}
              name="phone"
            />

            <Input
              customStyles={{ color: 'var(--color-gold)' }}
              placeholder="Insira o e-email"
              register={register}
              errors={errors}
              label="E-mail"
              name="email"
            />

            <div className="box__password">
              <Input
                placeholder="Insira uma senha de acesso para o colaborador"
                customStyles={{ color: 'var(--color-gold)' }}
                register={register}
                errors={errors}
                name="password"
                label="Senha"
                type="password"
              />

              <Input
                placeholder="Repita a senha de acesso do colaborador"
                customStyles={{ color: 'var(--color-gold)' }}
                label="Repetir senha"
                name="repeatPassword"
                register={register}
                errors={errors}
                type="password"
              />
            </div>

            <div className="box__options">
              <Button customStyles={{ borderRadius: '1.6rem', height: '4rem' }} icon={HiOutlineCheck} type="submit">
                ADICIONAR
              </Button>
            </div>
          </Container>
        </CardBox>
      </ContainerComponent>
    </>
  )
}

export { CreateTeam }
