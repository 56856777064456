import styled from 'styled-components'

export const Container = styled.div`
  .filter {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 16rem;
    grid-gap: 1.6rem;

    margin-bottom: 3.2rem;
  }

  .row__datatable {
    display: flex;
    align-items: center;

    padding-left: 1.6rem;
    cursor: pointer;
    height: 4.9rem;

    img {
      background: var(--color-primary-900);
      border-radius: 1rem;
      margin-right: 1rem;
      object-fit: cover;
      height: 4rem;
      width: 4rem;
    }
  }

  .centralized {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
  }
`

export const CardOrder = styled.div`
  color: white;

  strong {
    font-weight: 500;
  }

  header {
    display: flex;

    border-bottom: 0.1rem solid var(--color-primary-500);
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;

    h3 {
      font-size: 3.2rem;

      strong {
        color: var(--color-gold);
      }
    }
  }

  main {
    overflow: auto;
    height: 40rem;

    .container__customer {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-gap: 1.6rem;

      border-radius: 0.8rem;
      margin: 0.8rem 0;
      padding: 1.6rem;
      width: 50rem;

      :hover {
        background: var(--color-primary-700);
      }

      .customer__tickets {
        .p-panelmenu {
          width: auto !important;
        }

        .p-component {
          background: var(--color-primary-700);
          border-radius: 0.8rem;
          padding: 0.8rem;
          width: auto;
        }

        .p-panelmenu-header-link {
          color: white !important;

          span {
            margin-right: 0.8rem;
          }
        }

        ul {
          li {
            a {
              color: white !important;
              margin: 0.8rem;
            }
          }
        }
      }

      .card__customer {
        display: flex;
        align-items: center;

        img {
          border-radius: 1.6rem;
          margin-right: 1.6rem;
          height: 12rem;
          width: 12rem;
        }

        h4,
        h5,
        p {
          font-size: 2.4rem;
          margin: 0;
        }

        .card__customer__infos {
          display: flex;
          justify-content: space-between;

          height: 5rem;
          width: 100%;

          h5 {
            font-size: 1.6rem;
          }
        }

        .customer__customer {
          color: var(--color-gold);
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-top: 0.1rem solid var(--color-primary-500);
    padding-top: 0.8rem;
    margin-top: 0.8rem;

    strong {
      color: var(--color-gold);
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
