import React from 'react'

import { ContainerComponent } from '../../../components'

import { Container } from './styles'

const BankAccounts = () => {
  return (
    <ContainerComponent
      headerLeft={['Financeiro', 'Histórico financeiro', 'Contas bancárias']}
      title="Contas bancárias"
    >
      <Container>
        <h1>Contas bancárias</h1>
      </Container>
    </ContainerComponent>
  )
}

export { BankAccounts }
