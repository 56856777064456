import React, { useEffect, useState } from "react";
import { HiCheck, HiChevronDown, HiX } from "react-icons/hi";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { HiOutlinePlus } from "react-icons/hi";
import {
  ContainerComponent,
  Button,
  Input,
  CardBox,
  TextArea,
  AutoComplete,
  InputMask,
  ComponentModal,
  InputSelect,
} from "../../../components";
import { uploadImageFirebase, deleteFileFirebase } from "../../../utils";
import { schemaRestaurantCreate } from "./schemaRestaurantCreate";
import { GetAllOperations } from "../../../services/Operations";
import { GetAllCousines } from "../../../services/Cousines";
import notImage from "../../../assets/images/notImage.png";
import { removeCharacters } from "../../../utils/string";
import { PostStores, GetCities } from "../../../services/Stores";
import { PostOperation } from "../../../services/Operations";
import { Container } from "./styles";

const RestaurantCreate = () => {
  const { formState, handleSubmit, register } = useForm({
    resolver: yupResolver(schemaRestaurantCreate),
  });
  const { errors } = formState;
  const history = useHistory();

  const [imageBackground, setImageBackground] = useState(null);
  const [logoWhite, setLogoWhite] = useState(null);
  const [logoBlack, setLogoBlack] = useState(null);
  const [modalOperation, setModalOperation] = useState(false);
  const [operations, setOperations] = useState([]);
  const [newOperation, setNewOperation] = useState("");
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [cousines, setCousines] = useState([]);
  const [selectedCousine, setSelectedCousine] = useState(null);

  const [cities, setCities] = useState([]);

  async function fetchData() {
    try {
      const { data: responseOperations } = await GetAllOperations();
      const { data: responseCousines } = await GetAllCousines();
      const value = await GetCities();

      const operationsFormatted = responseOperations.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      const cousinesFormatted = responseCousines.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setOperations(operationsFormatted);
      setCousines(cousinesFormatted);
      const parse = JSON.parse(value.request.response);
      const cityOptions = parse.map((c) => ({ text: c.nome, value: c.nome }));
      setCities(cityOptions);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleCreateRestaurant(value) {
    const {
      full_description,
      company_name,
      instagram,
      whatsapp,
      site,
      phone,
      cnpj,
    } = value; // Deconstructs from info
    const { phone_responsible, name_responsible, email, email_admin, cpf } =
      value; // Deconstructs from responsible
    const { zip_code, street_number, street, neighborhood, city, uf } = value; // Deconstructs from address
    const { feedme_physical_tax, feedme_digital_tax } = value; // Deconstructs from financial
    const { description, name } = value; // Deconstructs from store

    if (!logoWhite?.image_url || !logoBlack?.image_url) {
      toast.error("Você precisa inserir imagens");
      return;
    }

    if (!selectedOperation || !selectedCousine) {
      toast.error(
        'Você precisa selecionar os campos "Cidade de Operação" e "Cozinha"'
      );
      return;
    }

    const payload = {
      bg_image: imageBackground?.image_url,
      logo_white: logoWhite?.image_url,
      logo_black: logoBlack?.image_url,
      operation_id: selectedOperation,
      cousine_id: selectedCousine,
      status: "OK",
      description,
      name,

      financial: {
        feedme_physical_tax,
        feedme_digital_tax,
      },

      admin: {
        phone: removeCharacters(String(phone_responsible)),
        fullname: name_responsible,
        email: email_admin,
      },

      info: {
        opening_hours: [
          {
            day_of_week: 0,
            time_periods: [{ start_time: "11:00", end_time: "15:00" }],
          },
        ],
        address: {
          street_number: String(street_number),
          neighborhood,
          zip_code,
          street,
          city,
          uf,
        },
        whatsapp: removeCharacters(String(whatsapp)),
        phone: removeCharacters(String(phone)),
        cnpj: removeCharacters(String(cnpj)),
        app_skin: "WHITE",
        full_description,
        company_name,
        instagram,
        site,

        responsible: {
          phone: removeCharacters(String(phone_responsible)),
          cpf: removeCharacters(String(cpf)),
          name: name_responsible,
          email,
        },
      },
    };

    try {
      await PostStores(payload);
      toast.success("Restaurante criado com sucesso!");
      history.goBack();
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleChangeImage(event) {
    const { files, name } = event.target;
    if (!files) return;

    const file = files[0];

    if (name === "bg_image")
      await uploadImageFirebase(setImageBackground, imageBackground, file);
    if (name === "logo_black")
      await uploadImageFirebase(setLogoBlack, logoBlack, file);
    if (name === "logo_white")
      await uploadImageFirebase(setLogoWhite, logoWhite, file);
  }

  async function handleDeleteImage(name) {
    if (name === "bg_image")
      await deleteFileFirebase(imageBackground.image_url);
    if (name === "logo_white") await deleteFileFirebase(logoWhite.image_url);
    if (name === "logo_black") await deleteFileFirebase(logoBlack.image_url);
  }

  async function checkExistingOperation(value) {
    const existing = operations.some((o) => o.label === value);
    return existing;
  }

  async function handleNewOperation() {
    const existing = await checkExistingOperation(newOperation);
    if (existing) {
      toast.error("Já existe operação nessa cidade");
    } else {
      try {
        await PostOperation({ name: newOperation });
        toast.success("Nova operação criada com sucesso!");
        setModalOperation(false);
        fetchData();
      } catch (error) {
        toast.error(error?.response?.data?.error?.message);
      }
    }
  }

  const handleSetNewOperation = (value) => {
    setNewOperation(value);
  };

  useEffect(() => fetchData(), []);

  return (
    <>
      <ComponentModal
        customStyles={{ background: "rgba(0,0,0,0.25)" }}
        onRequestClose={() => setModalOperation(!modalOperation)}
        isOpen={modalOperation}
      >
        <AutoComplete
          setState={handleSetNewOperation}
          labelColor="var(--color-gold)"
          label="Nova cidade de operação:"
          suggestions={cities}
        />
        <div style={{ marginLeft: "2rem" }}>
          <Button
            customStyles={{
              marginTop: "1rem",
              borderRadius: "1.6rem",
              marginRight: "1.6rem",
              height: "4rem",
            }}
            onClick={() => handleNewOperation()}
          >
            Adicionar
          </Button>

          <Button
            customStyles={{
              marginTop: "0px",
              borderRadius: "1.6rem",
              marginRight: "1.6rem",
              height: "4rem",
            }}
            onClick={() => setModalOperation(false)}
          >
            Cancelar
          </Button>
        </div>
      </ComponentModal>

      <ContainerComponent
        headerLeft={[
          "Restaurantes",
          "Lista de restaurantes",
          "Criar Restaurante",
        ]}
        title="Criar Restaurante"
      >
        <Container onSubmit={handleSubmit(handleCreateRestaurant)}>
          <CardBox customStyles={{ display: "flex" }}>
            <div className="view__image">
              <span>Imagem capa parceiros</span>

              <label htmlFor="select-logo-black">
                <img src={logoBlack?.image_url || notImage} alt="logo escura" />
              </label>

              <input
                accept="image/png"
                onChange={handleChangeImage}
                id="select-logo-black"
                name="logo_black"
                type="file"
              />

              {!!logoBlack && (
                <button onClick={() => handleDeleteImage("logo_black")}>
                  <HiX />
                </button>
              )}
            </div>

            <div className="view__image">
              <span>Logo branca</span>

              <label htmlFor="select-logo-white">
                <img src={logoWhite?.image_url || notImage} alt="logo branca" />
              </label>

              <input
                accept="image/png"
                onChange={handleChangeImage}
                id="select-logo-white"
                name="logo_white"
                type="file"
              />

              {!!logoWhite && (
                <button onClick={() => handleDeleteImage("logo_white")}>
                  <HiX />
                </button>
              )}
            </div>

            <div className="view__image">
              <span>Foto home</span>

              <label htmlFor="select-background-image">
                <img
                  src={imageBackground?.image_url || notImage}
                  alt="logo do restaurante"
                />
              </label>

              <input
                onChange={handleChangeImage}
                id="select-background-image"
                accept=".jpeg, .jpg"
                name="bg_image"
                type="file"
              />

              {!!imageBackground && (
                <button onClick={() => handleDeleteImage("bg_image")}>
                  <HiX />
                </button>
              )}
            </div>
          </CardBox>

          <CardBox
            customStyles={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "3.2rem",
            }}
          >
            <Input
              customStyles={{ color: "var(--color-gold)" }}
              label="Nome fantasia"
              register={register}
              errors={errors}
              name="name"
            />

            <Input
              customStyles={{ color: "var(--color-gold)" }}
              label="Razão social"
              register={register}
              name="company_name"
              errors={errors}
            />

            <InputMask
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              label="Telefone"
              errors={errors}
              name="phone"
              mask="(99) 99999-9999"
            />

            <InputMask
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              label="Whatsapp"
              errors={errors}
              name="whatsapp"
              mask="(99) 99999-9999"
            />

            <InputMask
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              errors={errors}
              label="CNPJ"
              name="cnpj"
              mask="99.999.999/9999-99"
            />

            <Input
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              errors={errors}
              label="Site"
              name="site"
            />

            <Input
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              label="Instagram"
              name="instagram"
              errors={errors}
            />

            <div style={{ gridColumns: "1/3" }} />
            <div>
              <InputSelect
                customStyles={{
                  color: "var(--color-gold)",
                  marginBottom: "1rem",
                }}
                bgIcon="var(--color-primary)"
                icon={HiChevronDown}
                options={operations}
                name="operation"
                label="Cidade de Operação"
                onChange={(e) => setSelectedOperation(e.target.value)}
                register={register}
                errors={errors}
                value={selectedOperation}
              />

              <Button
                icon={HiOutlinePlus}
                customStyles={{
                  marginTop: "0px",
                  borderRadius: "1.6rem",
                  marginRight: "1.6rem",
                  height: "4rem",
                }}
                onClick={() => setModalOperation(true)}
              >
                Adicionar cidade de operação
              </Button>
            </div>

            <InputSelect
              customStyles={{ color: "var(--color-gold)" }}
              bgIcon="var(--color-primary)"
              icon={HiChevronDown}
              options={cousines}
              name="cousine_id"
              label="Cozinha"
              onChange={(e) => setSelectedCousine(e.target.value)}
              errors={errors}
              value={selectedCousine}
            />

            <TextArea
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              name="description"
              label="Descrição"
              errors={errors}
            />

            <TextArea
              customStyles={{ color: "var(--color-gold)" }}
              label="Descrição completa"
              name="full_description"
              register={register}
              errors={errors}
            />
          </CardBox>

          <CardBox
            label="Endereço"
            customStyles={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "3.2rem",
            }}
          >
            <Input
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              errors={errors}
              label="Cidade"
              name="city"
            />

            <Input
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              label="Endereço"
              errors={errors}
              name="street"
            />

            <Input
              customStyles={{ color: "var(--color-gold)" }}
              name="street_number"
              register={register}
              errors={errors}
              label="Número"
            />

            <Input
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              name="neighborhood"
              errors={errors}
              label="Bairro"
            />

            <Input
              customStyles={{ color: "var(--color-gold)" }}
              label="Unidade federativa"
              register={register}
              errors={errors}
              name="uf"
            />

            <InputMask
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              errors={errors}
              name="zip_code"
              label="CEP"
              mask="99999-999"
            />
          </CardBox>

          <CardBox
            customStyles={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "3.2rem",
            }}
            label="Responsável legal"
          >
            <Input
              customStyles={{ color: "var(--color-gold)" }}
              name="name_responsible"
              label="Nome completo"
              register={register}
              errors={errors}
            />

            <InputMask
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              errors={errors}
              label="CPF"
              name="cpf"
              mask="999.999.999-99"
            />

            <Input
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              errors={errors}
              label="E-mail ADMIN (email usado no primeiro acesso)"
              name="email_admin"
            />

            <Input
              customStyles={{ color: "var(--color-gold)" }}
              register={register}
              errors={errors}
              label="E-mail RESPONSÁVEL LEGAL"
              name="email"
            />

            <InputMask
              customStyles={{ color: "var(--color-gold)" }}
              name="phone_responsible"
              register={register}
              label="Telefone"
              errors={errors}
              mask="(99) 99999-9999"
            />
          </CardBox>

          <CardBox
            customStyles={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "3.2rem",
            }}
            label="Taxas"
          >
            <Input
              label="Taxas por pagamento pelo app com cartão de credito ou pix"
              customStyles={{ color: "var(--color-gold)" }}
              name="feedme_digital_tax"
              register={register}
              errors={errors}
            />

            <Input
              customStyles={{ color: "var(--color-gold)" }}
              label="Taxas por pagamento direto no caixa"
              name="feedme_physical_tax"
              register={register}
              errors={errors}
            />
          </CardBox>

          <div className="box__options">
            <Button
              customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
              icon={HiCheck}
              type="submit"
            >
              CRIAR
            </Button>
          </div>
        </Container>
      </ContainerComponent>
    </>
  );
};

export { RestaurantCreate };
