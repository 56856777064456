import { HiOutlineClipboardList, HiOutlineCheck, HiSearch } from 'react-icons/hi'
import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { Button, ContainerComponent, Input, CardBox, linkRoutes } from '../../components'
import { GetAllAdmins } from '../../services/Admins'
import { formatDate, formatterPhone } from '../../utils'
import { Container } from './styles'

const Teams = () => {
  const [team, setTeam] = useState([])
  const history = useHistory()
  const fetchData = async () => {
    try {
      const { data } = await GetAllAdmins({})

      const newArray = data.data.results.map(item => ({
        ...item,
        style: item.status === 'ACTIVE' ? 'span-active' : 'span-inactive',
        status: item.status === 'ACTIVE' ? 'Ativo' : 'Inativo',
        created_at: formatDate(item.created_at),
        phone: formatterPhone(item.phone)
      }))

      setTeam(newArray)
    } catch (error) {
      toast.error(error.response?.data.error.message)
    }
  }

  const ColumnName = rowData => <div className="row__datatable fullname">{rowData.fullname}</div>
  const ColumnEmail = rowData => <div className="row__datatable ">{rowData.email}</div>
  const ColumnStatus = rowData => (
    <div className="row__datatable centralized">
      <span className={rowData.style}>{rowData.status}</span>
    </div>
  )

  function handleShowAdmin(event) {
    const admin = event.data
    history.push(linkRoutes.adminsDetails, { admin })
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      headerLeft={['Configurações', 'Equipe']}
      title="Equipe"
      headerRight={
        <Link to={linkRoutes.createTeams}>
          <Button
            icon={HiOutlineClipboardList}
            customStyles={{
              borderRadius: '1.6rem',
              fontSize: '1.2rem',
              height: '4rem'
            }}
          >
            INCLUIR COLABORADOR
          </Button>
        </Link>
      }
    >
      <Container>
        <div className="filter">
          <div className="content__inputs">
            <Input
              placeholder="Busque aqui pelo nome do usuário, status e e-mail"
              customStyles={{ maxWidth: '40rem', width: '100%' }}
              icon={HiSearch}
              label="Busca"
            />
          </div>

          <Button iconRight={HiOutlineCheck} onClick={fetchData}>
            Aplicar
          </Button>
        </div>

        <CardBox>
          <DataTable rows={8} value={team} onRowClick={handleShowAdmin}>
            <Column body={ColumnName} header="Nome completo" field="Nome completo" sortField="fullname" sortable />
            <Column body={ColumnEmail} header="E-mail" field="E-mail" sortField="email" sortable />
            <Column body={ColumnStatus} header="Status" field="Status" sortField="status" sortable />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { Teams }
