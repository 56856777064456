export const downloadImage = QRCode => {
  const svg = document.getElementById(`${QRCode}`)
  const svgData = new XMLSerializer().serializeToString(svg)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const img = new Image()

  img.onload = () => {
    canvas.height = img.height
    canvas.width = img.width

    ctx.drawImage(img, 0, 0)

    const downloadLink = document.createElement('a')
    const pngFile = canvas.toDataURL('image/png')

    downloadLink.download = 'QRCode'
    downloadLink.href = `${pngFile}`
    downloadLink.click()
  }

  return (img.src = `data:image/svg+xml;base64,${btoa(svgData)}`)
}
