import React, { useEffect, useState } from 'react'
import { HiOutlineCheck, HiPlus, HiSearch } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, CardBox, ContainerComponent, Input, linkRoutes } from '../../components'
import { formatDate, formatterPhone } from '../../utils'
import { GetAllAdmins } from '../../services/Admins'

import { Container } from './styles'

const Admins = () => {
  const history = useHistory()

  const [params, setParams] = useState({})
  const [admins, setAdmins] = useState([])

  async function fetchData() {
    try {
      const { data: response } = await GetAllAdmins(params)

      const adminsFormatted = response.data.results.map(item => ({
        ...item,
        style: item.status === 'ACTIVE' ? 'span-active' : 'span-inactive',
        status: item.status === 'ACTIVE' ? 'Ativo' : 'Inativo',
        created_at: formatDate(item.created_at),
        phone: formatterPhone(item.phone)
      }))

      setAdmins(adminsFormatted)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setParams(prevState => ({ ...prevState, [name]: value }))
  }

  function handleShowAdmin(event) {
    const admin = event.data
    history.push(linkRoutes.adminsDetails, { admin })
  }

  const ColumnCreated = admin => <div className="row__datatable centralized">{admin.created_at}</div>
  const ColumnPhone = admin => <div className="row__datatable centralized">{admin.phone}</div>
  const ColumnUserName = admin => <div className="row__datatable">{admin.fullname}</div>
  const ColumnEmail = admin => <div className="row__datatable">{admin.email}</div>
  const ColumnStatus = admin => (
    <div className="row__datatable centralized">
      <span className={admin.style}>{admin.status}</span>
    </div>
  )

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      title="Permissões"
      headerRight={
        <div>
          <Link to={linkRoutes.adminsAdd}>
            <Button customStyles={{ borderRadius: '1.6rem', marginRight: '1.6rem', height: '4rem' }} icon={HiPlus}>
              ADICIONAR ADMINISTRADOR
            </Button>
          </Link>
        </div>
      }
    >
      <Container>
        <div className="filter">
          <Input
            placeholder="Busque aqui pelo nome ou email do administrador"
            onChange={handleChangeState}
            icon={HiSearch}
            label="Busca"
            name="search"
          />

          <Button customStyles={{ width: '16rem', height: '3.1rem' }} iconRight={HiOutlineCheck} onClick={fetchData}>
            Buscar
          </Button>
        </div>

        <CardBox>
          <DataTable rows={50} onRowClick={handleShowAdmin} value={admins}>
            <Column body={ColumnUserName} header="Usuário" field="fullname" sortable />
            <Column body={ColumnEmail} header="E-mail" field="email" sortable />
            <Column body={ColumnPhone} header="Telefone" field="phone" sortable />
            <Column body={ColumnCreated} header="Data de criação" field="created_at" sortable />
            <Column body={ColumnStatus} header="Status" field="status" sortable />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { Admins }
