import React from 'react'

import { InputNotValidate, InputValidate } from './styles'

const Input = ({
  customStyles,
  colorIconBg,
  handleBlur,
  icon: Icon,
  colorIcon,
  disabled,
  onChange,
  register,
  errors,
  value,
  label,
  name,
  ...props
}) => {
  return (
    <>
      {register ? (
        <InputValidate customStyles={customStyles} colorIconBg={colorIconBg} colorIcon={colorIcon} disabled={disabled}>
          {label}

          <div className="input__box">
            {register ? (
              <input
                onChange={onChange}
                onBlur={handleBlur}
                {...register(name)}
                error={errors}
                value={value}
                name={name}
                {...props}
              />
            ) : (
              <input onChange={onChange} onBlur={handleBlur} disabled={disabled} value={value} name={name} {...props} />
            )}

            <div className="icon__box">{Icon && <Icon />}</div>
          </div>

          {errors && errors[name] && <span>{errors[name]?.message}</span>}
        </InputValidate>
      ) : (
        <InputNotValidate
          customStyles={customStyles}
          colorIconBg={colorIconBg}
          colorIcon={colorIcon}
          disabled={disabled}
        >
          {label}

          <div className="input__box">
            <input onChange={onChange} onBlur={handleBlur} disabled={disabled} value={value} name={name} {...props} />
            <div className="icon__box">{Icon && <Icon />}</div>
          </div>
        </InputNotValidate>
      )}
    </>
  )
}

export default Input
