import styled from 'styled-components'

export const Container = styled.button`
  background: var(--color-pink);
  border-radius: 0.4rem;
  min-height: 3.2rem;
  margin-top: 2.7rem;
  font-weight: 400;
  padding: 0 1.6rem;
  transition: 0.2s;
  cursor: pointer;
  border: none;
  color: white;

  ${({ customStyles }) => customStyles}

  :hover {
    opacity: 0.8;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    .svgLeft {
      margin-right: 0.5rem;
    }

    .svgRight {
      margin-left: 1rem;
    }
  }
`
