import styled from 'styled-components'

export const Container = styled.div`
  .filter {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 16rem;
    grid-gap: 1.6rem;

    margin-bottom: 3.2rem;
  }

  .row__reserves {
    font-weight: 500;
    text-align: center;

    p {
      margin-left: 0.8rem;
      text-align: left;
      cursor: pointer;
    }

    span {
      padding: 0.4rem 2rem;
      border-radius: 0.4rem;
    }

    button {
      background: var(--color-green);
      border-radius: 0.4rem;
      cursor: pointer;
      margin: 0.4rem;
      border: none;
   

      :nth-child(2) {
        background: var(--color-pink);
      }

      :last-child {
        background: var(--color-gold);
      }

      svg {
        margin: 0.4rem 0.4rem 0.2rem 0.4rem;
        height: 1.6rem;
        width: 1.4rem;
        color: white;
      }
    }

    .CONFIRMED {
      background: var(--color-green);
    }

    .CANCELLED {
      background: var(--color-orange);
    }

    .UNAVAILABLE,
    .CREATED {
      background: var(--color-gold);
    }
  }
`

export const CardReserve = styled.div`
  color: white;

  strong {
    font-weight: 500;
  }

  main {
    .card__customer {
      display: flex;
      align-items: center;

      padding: 0.8rem 1.6rem;
      margin: 0.8rem 0;
      width: 50rem;

      :hover {
        background: var(--color-primary-700);
      }

      img {
        border-radius: 1.6rem;
        margin-right: 1.6rem;
        height: 12rem;
        width: 12rem;
      }

      h4,
      h5,
      p {
        font-size: 2.4rem;
        margin: 0;
      }

      .card__customer__infos {
        height: 5rem;
        width: 100%;

        h5 {
          font-size: 1.6rem;
        }
      }
    }

    .card__customer__items {
      ul {
        overflow: auto;
        height: 40rem;

        li {
          display: flex;
          align-items: center;

          list-style: none;
          margin: 1.6rem 0;

          img {
            border-radius: 0.8rem;
            margin-right: 0.8rem;
            height: 6.4rem;
            width: 6.4rem;
          }

          h3 {
            margin: 0 0 0.4rem 0;
          }
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-top: 0.1rem solid var(--color-primary-500);
    padding-top: 0.8rem;
    margin-top: 0.8rem;

    strong {
      color: var(--color-gold);
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
