import React, { useEffect, useState } from 'react'
import { HiOutlineClipboardList, HiOutlineCheck, HiSearch } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, CardBox, ContainerComponent, Input, linkRoutes } from '../../../components'
import { GetLoyaltyPrograms } from '../../../services/LoyaltyPrograms'
import notImage from '../../../assets/images/notImage.png'
import { formatDate } from '../../../utils'

import { Container } from './styles'

const LoyaltyProgramsAdmin = () => {
  const [searchName, setSearchName] = useState('')
  const [customers, setCustomers] = useState([])

  async function fetchData() {
    try {
      const { data: response } = await GetLoyaltyPrograms(searchName)

      const loyaltyProgramsFormatted = response.data.map(item => ({
        ...item,
        style: item.status ? 'span-active' : 'span-inactive',
        created_at: formatDate(item.created_at),
        status: item.status ? 'Ativo' : 'Inativo'
      }))

      setCustomers(loyaltyProgramsFormatted)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  const ColumnConsumption = value => <div className="row__datatable centralized">{value.consumption}</div>
  const ColumnCustomers = value => <div className="row__datatable centralized">{value.n_customers}</div>
  const ColumnDiscount = value => <div className="row__datatable centralized">{value.discount}</div>
  const ColumnQuantity = value => <div className="row__datatable centralized">{value.n_uses}</div>
  const ColumnDate = value => <div className="row__datatable centralized">{value.created_at}</div>

  const ColumnStore = value => (
    <div className="row__datatable">
      <img src={value.store?.logo_white || notImage} alt="" />
      <strong>{value.store?.name}</strong>
    </div>
  )

  const ColumnStatus = value => (
    <div className="row__datatable centralized">
      <span className={value.style}>{value.status}</span>
    </div>
  )

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      headerRight={
        <Link to={linkRoutes.loyaltyProgramsDetails}>
          <Button
            customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
            icon={HiOutlineClipboardList}
            bgColor="var(--color-pink)"
          >
            VER DETALHES
          </Button>
        </Link>
      }
      title="Programa de fidelidade"
    >
      <Container>
        <div className="filter">
          <Input
            onChange={event => setSearchName(event.target.value)}
            customStyles={{ maxWidth: '80rem', width: '100%' }}
            placeholder="Busque aqui pelo nome do usuário"
            icon={HiSearch}
            label="Busca"
          />

          <Button customStyles={{ width: '16rem' }} iconRight={HiOutlineCheck} onClick={fetchData}>
            Buscar
          </Button>
        </div>

        <CardBox>
          <DataTable value={customers} rows={8} paginator>
            <Column body={ColumnStore} header="Nome do usuário" field="customer.fullname" sortable />
            <Column body={ColumnQuantity} header="Quantidade já resgatada" field="n_uses" sortable />
            <Column body={ColumnConsumption} header="Valor de consumo para cupom" field="consumption" sortable />
            <Column body={ColumnDiscount} header="Valor do desconto" field="discount" sortable />
            <Column body={ColumnCustomers} header="Participantes" field="n_customers" sortable />
            <Column body={ColumnDate} header="Data de criação" field="created_at" sortable />
            <Column body={ColumnStatus} header="Status" field="status" sortable />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { LoyaltyProgramsAdmin }
