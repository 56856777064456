import axios from 'axios'

const linkApiStaging = 'https://feedme-api-staging.herokuapp.com/'
const linkApiDev = 'https://feedme-api-dev.herokuapp.com'
const linkApiProd = 'https://api.usefeedme.com.br'

const urlStag = 'feedme-dashboard-staging.herokuapp.com'
const urlDev = 'feedme-dashboard-dev.herokuapp.com'
const urlProd = 'parceiros.usefeedme.com.br'

class Client {
  constructor() {
    const hostname = window && window.location && window.location.hostname

    let baseUrl = linkApiProd

    if (hostname === 'localhost' || hostname === urlStag) {
      baseUrl = linkApiStaging
    }

    if (hostname === urlDev) {
      baseUrl = linkApiDev
    }

    this.apiYounner = axios.create({
      baseURL: baseUrl,
      timeout: 60000
    })

    this.apiYounner.interceptors.response.use(
      response => {
        if (process.env.REACT_APP_NODE_ENV === 'development') {
          console.group('Response OK')
          console.log(response.data.data)
          console.groupEnd('Response OK')
        }

        if (response.status) {
          if (response.data !== undefined) {
            return Promise.resolve(response)
          }
          return Promise.resolve(response)
        }
        return Promise.reject(response)
      },

      error => {
        console.group('Response ERROR')
        console.log(`error.message >>> ${error.message} <<<`)
        console.log(error?.response?.data?.error?.message)
        console.groupEnd('Response ERROR')

        return Promise.reject(error)
      }
    )
  }

  setTokenInHeader(token) {
    this.apiYounner.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8'
    this.apiYounner.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

export default new Client()
