import Client from '../client'

const route = '/api/stories/'

const PatchStories = (id, payload) => Client.apiYounner.patch(route + 'id/' + id, payload)
const GetStories = ({ params }) => Client.apiYounner.get(route, { params })
const DeleteStories = id => Client.apiYounner.delete(route + 'id/' + id)
const PostStories = payload => Client.apiYounner.post(route, payload)
const ShowStories = id => Client.apiYounner.get(route + 'id/' + id)

export { DeleteStories, PatchStories, ShowStories, PostStories, GetStories }
