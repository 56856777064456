import { toast } from 'react-toastify'

import firebase from '../../services/firebaseClient'

export function deleteFileFirebase(url) {
  const pictureRef = firebase.storage().refFromURL(url)

  pictureRef
    .delete()
    .then(() => console.log('Picture is deleted successfully!'))
    .catch(error => {
      console.log('ERROR deleteFileFirebase:', error)
      toast.error('Ops! Ocorreu um erro, tente novamente.')
    })
}
