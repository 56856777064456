import React, { useEffect, useState } from 'react'
import { HiCheck, HiTrash } from 'react-icons/hi'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { ContainerComponent, ComponentModal, Button, Input } from '../../../components'
import { DeleteAdmin, PatchAdmin } from '../../../services/Admins'

import { Container } from './styles'

const AdminsDetails = () => {
  const history = useHistory()

  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [admin, setAdmin] = useState({})

  function fetchData() {
    const editAdmin = history?.location?.state?.admin
    setAdmin(editAdmin)
  }

  function formatPhoneNumber(number) {
    const regex = /[^0-9]/g;
    const result = number.replace(regex, '');
    
    return result;
  }

  async function handleChangeAdmin() {
    const { fullname, phone, email } = admin

    try {
      await PatchAdmin(admin.id, { fullname, phone: formatPhoneNumber(phone), email, permissions: { a: 1 } })
      toast.success('Administrador atualizado com sucesso')
      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleDeleteAdmin() {
    try {
      await DeleteAdmin(admin.id)
      toast.success('Administrador removido com sucesso')
      history.goBack()
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setAdmin(prevState => ({ ...prevState, [name]: value }))
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent title="Detalhes" headerLeft={['Permissões', 'Detalhes']}>
      <ComponentModal onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <h3>Administrador atualizado com sucesso!</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <ComponentModal onRequestClose={() => setOpenConfirmModal(!openConfirmModal)} isOpen={openConfirmModal}>
        <h3>Tem certeza que deseja remover esse administrador?</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={handleDeleteAdmin}
          icon={HiCheck}
        >
          SIM
        </Button>
      </ComponentModal>

      <Container>
        <Input
          customStyles={{ color: 'var(--color-gold)', gridColumn: '1/3' }}
          placeholder="insira o nome completo do colaborador"
          label="Nome completo do colaborador"
          onChange={handleChangeState}
          value={admin.fullname}
          name="fullname"
        />

        <Input
          customStyles={{ color: 'var(--color-gold)' }}
          onChange={handleChangeState}
          value={admin.phone}
          label="Telefone"
          name="phone"
        />

        <Input
          customStyles={{ color: 'var(--color-gold)' }}
          onChange={handleChangeState}
          value={admin.email}
          label="E-mail"
          name="email"
        />

        <div className="box__options">
          <div>
            <Button
              onClick={() => setOpenConfirmModal(true)}
              icon={HiTrash}
              customStyles={{
                background: 'var(--color-grey-400)',
                borderRadius: '1.6rem',
                marginRight: '1.6rem',
                height: '4rem',
                width: '14rem'
              }}
            >
              REMOVER
            </Button>

            <Button
              customStyles={{ borderRadius: '1.6rem', width: '14rem', height: '4rem' }}
              onClick={handleChangeAdmin}
              icon={HiCheck}
            >
              SALVAR
            </Button>
          </div>
        </div>
      </Container>
    </ContainerComponent>
  )
}

export { AdminsDetails }
