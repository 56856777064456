import React, { useEffect, useState } from 'react'
import { HiCurrencyDollar } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { toast } from 'react-toastify'

import { GetFinancialBalance, GetFinancialExtracts } from '../../../services/Financial'
import { ContainerComponent } from '../../../components'
import { formatterPrice } from '../../../utils'

import { Container } from './styles'

const FinancialExtracts = () => {
  const [extracts, setExtract] = useState([])
  const [balance, setBalance] = useState({})

  async function fetchData() {
    try {
      const { data: responseExtracts } = await GetFinancialExtracts()
      const { data: balanceResponse } = await GetFinancialBalance()

      setExtract(responseExtracts.data)
      setBalance(balanceResponse.data)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  const ColumnExample = value => <div className="row__datatable">{value.amount}</div>

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent headerLeft={['Financeiro', 'Extrato financeiro']} title="Extrato financeiro">
      <Container>
        <div className="card__container">
          <div className="card__box">
            <div>
              <span>
                <HiCurrencyDollar />
              </span>
              Recebido
            </div>

            <h2>{formatterPrice(balance.amount_received || 0)}</h2>
          </div>

          <div className="card__box">
            <div>
              <span>
                <HiCurrencyDollar />
              </span>
              Disponível
            </div>

            <h2>{formatterPrice(balance.amount_available || 0)}</h2>
          </div>

          <div className="card__box">
            <div>
              <span>
                <HiCurrencyDollar />
              </span>
              A receber
            </div>

            <h2>{formatterPrice(balance.amount_preview || 0)}</h2>
          </div>

          <div className="card__box">
            <div>
              <span>
                <HiCurrencyDollar />
              </span>
              Cancelado
            </div>

            <h2>{formatterPrice(balance.amount_canceled || 0)}</h2>
          </div>

          <div className="card__box">
            <div>
              <span>
                <HiCurrencyDollar />
              </span>
              Taxas
            </div>

            <h2>{formatterPrice(balance.amount_taxes || 0)}</h2>
          </div>
        </div>

        <DataTable value={extracts} dataKey="id" currentPage paginator rows={8}>
          <Column header="XXX" body={ColumnExample} field="xxx" sortable />
        </DataTable>
      </Container>
    </ContainerComponent>
  )
}

export { FinancialExtracts }
