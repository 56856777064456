import styled from 'styled-components'

export const ContainerChangeImage = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.6rem;

  height: 20rem;

  .view__image {
    display: flex;
    justify-content: center;

    margin-top: 3.2rem;
    position: relative;
    height: 12rem;
    width: 12rem;

    span {
      color: var(--color-gold);
      text-overflow: ellipsis;
      white-space: nowrap;
      position: absolute;
      font-weight: 400;
      overflow: hidden;
      top: -3.2rem;
    }

    img {
      border-radius: 1.6rem;
      cursor: pointer;
      height: 12rem;
      width: 12rem;
    }

    input {
      display: none;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      bottom: -1.2rem;
      right: 4.5rem;

      background: var(--color-orange);
      border-radius: 100%;
      cursor: pointer;
      border: none;
      color: white;
      height: 2rem;
      width: 2rem;
    }
  }
`

export const ContainerOpeningHours = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;

    border-bottom: 0.1rem solid var(--color-primary-500);
    padding-bottom: 3.2rem;
    margin-bottom: 3.2rem;
  }

  ul {
    li {
      display: grid;
      grid-template-columns: 20rem 60rem;
      grid-gap: 1.6rem;

      margin-top: 1.6rem;

      .content__inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.6rem;

        input[type='time']::-webkit-calendar-picker-indicator {
          background: none;
        }
      }
    }
  }
`

export const BoxOptions = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-bottom: 6.4rem;
`
