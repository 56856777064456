import React, { useEffect, useState } from "react";

import {
  GetComplementGroup,
  DeleteComplementsGroup,
  DeleteProdFromComplementGroup,
} from "../../../services/Product";

import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { Container } from "./styles";

import {
  ComponentModal,
  ContainerComponent,
  CardBox,
  Input,
  Button,
  linkRoutes,
} from "../../../components";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import {
  HiPencil,
  HiTrash,
  HiOutlinePlus,
} from "react-icons/hi";
import { AiOutlineCheck, AiOutlineArrowDown } from "react-icons/ai";

const Complements = () => {
  const [complementGroup, setComplementGroup] = useState([]);
  const [complement, setComplement] = useState(null);
  const [product, setProduct] = useState(null);
  const [search, setSearch] = useState("");
  const [expandedRows, setExpandedRows] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();

  const fetchData = async () => {
    try {
      const { data } = await GetComplementGroup();

      const newComplement = [];
      for (let { id, name } of data.data.results) {
        newComplement.push({ value: id, label: name });
      }


      setComplementGroup(data.data.results);
    } catch (error) {
      toast.error("Tente novamente", error);
    }
  };

  async function handleSearch() {
    try {
      const { data } = await GetComplementGroup();
      const filtered = data.data.results.filter(g => g.name.toLowerCase().includes(search.toLowerCase()))

      setComplementGroup(filtered);
    } catch (error) {
      console.log("ERROR: =>>", error);
    }
  };

  const handleProductDelete = async () => {
    const { data } = await GetComplementGroup();
    
    let group = {}
    for (const g of data.data.results) {
      const found = g.products.find(p => p.id === product.id)
      if (found) group = g
    } try {
      await DeleteProdFromComplementGroup({ group_id: group.id, prod_id: product.id});
      toast.success("Produto excluído com sucesso");
      fetchData();

      handleCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleComplementGroupDelete = async () => {
    try {
      await DeleteComplementsGroup(complement.id);
      toast.success("Grupo de complementos excluído com sucesso");
      fetchData();
    } catch (error) {
      console.log(error);
    }
    handleCloseModal();
  };

  const ColumnProduct = (value) => (
    <div className="row__datatable">
      <img src={value.thumbnail || "" } alt="" />
      <p>{value.name}</p>
    </div>
  );

  const ColumnCode = (value) => {
    const products = [value];
    return (
      <div className="row__datatable centralized">
        <span># {products[0].external_code}</span>
      </div>
    );
  };

  const ColumnComplementOptions = (value) => {
    return (
      <div className="row__datatable centralized">
        <button onClick={() => handleEditComplement(value)}>
          <HiPencil />
        </button>
        <button onClick={() => handleOpenModalComplement(value)}>
          <HiTrash />
        </button>
      </div>
    );
  };

  const ColumnProductOptions = (product) => (
    <div className="row__datatable centralized">
      <button onClick={() => handleEditProduct(product)}>
        <HiPencil />
      </button>

      <button onClick={(e) => handleOpenModalProduct(product)}>
        <HiTrash />
      </button>
    </div>
  );

  const handleEditProduct = (product) =>
    history.push(linkRoutes.productsUpdate, { product });

  const handleEditComplement = (complement) =>
    history.push(linkRoutes.createNewGroup, { complement });

  const handleOpenModalComplement = (value) => {
    setOpenModal(true);
    setComplement(value);
  };

  const handleOpenModalProduct = (value) => {
    setOpenModal(true);
    setProduct(value);
  };

  function handleCloseModal() {
    setOpenModal(false);
    setComplement(null);
    setProduct(null);
  }

  const rowExpansionComplement = (data) => (
    <DataTable value={data.products}>
      <Column header="Produto" body={ColumnProduct} />
      <Column header="Código" body={ColumnCode} sortField="code" />

      <Column
        header="Min - Max"
        body={() => (
          <div className="row__datatable centralized">
            <span
              style={{ justifySelf: "center" }}
            >{`${data.min_items} - ${data.max_items}`}</span>
          </div>
        )}
      />

      <Column header="Opções" body={ColumnProductOptions} />
    </DataTable>
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ComponentModal onRequestClose={handleCloseModal} isOpen={openModal}>
        <p>
          Tem certeza que deseja excluir
          <strong>
            {!!complement
              ? ` complementos ${complement?.name}`
              : ` produto ${product?.name}`}
          </strong>
          ?
        </p>

        <div>
          <Button
            customStyles={{
              background: "var(--color-primary)",
              borderRadius: "1.6rem",
              marginRight: "1.6rem",
              height: "4rem",
            }}
            onClick={handleCloseModal}
          >
            NÃO
          </Button>

          <Button
            customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
            onClick={
              !!complement ? handleComplementGroupDelete : handleProductDelete
            }
          >
            SIM
          </Button>
        </div>
      </ComponentModal>

      <ContainerComponent
        headerLeft={["Produtos", "Complementos"]}
        title="Complementos"
        headerRight={
          <Link to={linkRoutes.createNewGroup}>
            <Button
              icon={HiOutlinePlus}
              customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
            >
              CRIAR NOVO GRUPO
            </Button>
          </Link>
        }
      >
        <Container>
          <div className="filter">
            <Input
              onChange={(e) => setSearch(e.target.value)}
              label="Busca"
              placeholder="Buscar por nome..."
              labelColor="#d60747"
              iconLight={AiOutlineArrowDown}
              width="130rem"
              height="5.6rem"
            />

            <Button
              type="button"
              color="#ffffff"
              bgColor="#d60747"
              iconRight={AiOutlineCheck}
              height="3.6rem"
              width="66rem"
              marginTop="1.5rem"
              onClick={() => handleSearch()}
            >
              Aplicar
            </Button>
          </div>
          <CardBox>
            <DataTable
              onRowToggle={(e) => setExpandedRows(e.data)}
              expandedRows={expandedRows}
              value={complementGroup}
              rowExpansionTemplate={rowExpansionComplement}
              dataKey="id"
            >
              <Column expander />
              <Column header="Grupo" field="name" />
              <Column
                header="Ações"
                body={ColumnComplementOptions}
                style={{ width: "30rem" }}
              />
            </DataTable>
          </CardBox>
        </Container>
      </ContainerComponent>
    </>
  );
};

export { Complements };
