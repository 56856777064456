import Client from '../client'

export const PatchOrdersItem = (id, payload) => Client.apiYounner.patch(`/api/orders/items/id/${id}/status`, payload)
export const PostOrdersClose = (id, payload) => Client.apiYounner.post(`/api/orders/id/${id}/close`, payload)
export const PostOrdersWaiterDismiss = id => Client.apiYounner.post(`/api/orders/id/${id}/waiter/dismiss`)
export const GetOrdersSearch = params => Client.apiYounner.get(`/api/orders/search`, { params })
export const DeleteOrdersItem = id => Client.apiYounner.delete(`/api/orders/items/id/${id}`)
export const PostOrdersSettle = id => Client.apiYounner.post(`/api/orders/id/${id}/settle`)
export const GetOrdersOneItem = id => Client.apiYounner.get(`/api/orders/items/id/${id}`)
export const GetOrdersItems = () => Client.apiYounner.get(`api/orders/items`)
