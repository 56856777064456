import React, { useState } from 'react'
import { HiOutlineReply, HiTrash } from 'react-icons/hi'
import { ClipLoader } from 'react-spinners'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { DeleteOrdersItem, PatchOrdersItem, GetOrdersItems } from '../../services/Order'
import { Button, ContainerComponent } from '../../components'
import notImage from '../../assets/images/notImage.png'
import { formatDateHour } from '../../utils'

import { Container, TopRight } from './styles'

export const Orders = () => {
  const { data, isLoading, isFetched } = useQuery('orders', fetchData, { refetchInterval: 5000 })

  const [status, setStatus] = useState({})
  const [filter, setFilter] = useState('')

  async function fetchData() {
    try {
      const { data } = await GetOrdersItems()

      const PREPARING = data.data.filter(data => data.status === 'PREPARING').length
      const CANCELLED = data.data.filter(data => data.status === 'CANCELLED').length
      const PLACED = data.data.filter(data => data.status === 'PLACED').length
      const READY = data.data.filter(data => data.status === 'READY').length
      const TOTAL = data.data.length

      setStatus({ PREPARING, CANCELLED, PLACED, READY, TOTAL })
      return data.data
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleDeleteItem(item) {
    try {
      await DeleteOrdersItem(item.id)
      toast.success(`O item ${item.name} removido com sucesso!`)
      await fetchData()
    } catch (error) {
      toast.error(`Ocorreu um problema ao tentar remover o item ${item.name}.`)
    }
  }

  async function handleStatusItem(item) {
    if (item.status === 'READY') {
      toast.error(`O item ${item.name} não pode ser atualizado pois já está pronto!`)
      return
    }

    const status = item.status === 'PLACED' ? 'PREPARING' : 'READY'

    try {
      await PatchOrdersItem(item.id, { status })
      toast.success(`O status do item ${item.name} atualizado com sucesso!`)
    } catch (error) {
      toast.error(`Ocorreu um problema ao tentar atualizar o status do item ${item.name}.`)
    }
  }

  const CardOrder = item => (
    <li key={item.id}>
      <div className="order__header">
        <time>{formatDateHour(item.created_at)}</time>
      </div>

      <div className="order__main">
        <div className="order__main__header">
          <img src={item.thumbnail || notImage} alt={item.name} />
          <strong>{item.name}</strong>
        </div>

        <div className="order__main__observation">
          <strong>Observação:</strong>
          <p>{item.observations || 'sem observação'}</p>

          <strong>Complementos:</strong>

          <div className="complements">
            {item.complements.map(complement => (
              <p>{complement.name};</p>
            ))}
          </div>
        </div>
      </div>

      <div className="order__footer">
        <img src={item.customer.profile_picture || notImage} alt={item.customer.profile_picture} />

        <div>
          <strong>Mesa {item.table.number}</strong>
          <p>User: @{item.customer.username}</p>
        </div>
      </div>

      <div className="order__options">
        {item.status !== 'CANCELLED' ? (
          <>
            <Button onClick={() => handleStatusItem(item)} bgColor="var(--color-pink)" icon={HiOutlineReply}>
              PULAR ETAPA
            </Button>

            <Button onClick={() => handleDeleteItem(item)} bgColor="var(--color-grey)" icon={HiTrash}>
              CANCELAR PEDIDO
            </Button>
          </>
        ) : (
          <strong>Esse item foi cancelado</strong>
        )}
      </div>
    </li>
  )

  return (
    <ContainerComponent
      title="Pedidos"
      headerRight={
        <TopRight>
          <p>Legenda dos pedidos</p>

          <Button customStyles={{ minHeight: '2rem', width: '9rem', margin: 0, padding: '0.4rem' }}>Presencial</Button>
        </TopRight>
      }
    >
      <Container>
        <div className="button__options">
          <Button onClick={() => setFilter('PLACED')}>Aguardando ({status.PLACED || 0})</Button>
          <Button onClick={() => setFilter('PREPARING')}>Preparando ({status.PREPARING || 0})</Button>
          <Button onClick={() => setFilter('READY')}>Prontos ({status.READY || 0})</Button>
          <Button onClick={() => setFilter('CANCELLED')}>Cancelados ({status.CANCELLED || 0})</Button>
          <Button onClick={() => setFilter()}>Todos ({status.TOTAL || 0})</Button>
        </div>

        {isLoading && (
          <div className="loading">
            <ClipLoader size={80} color="var(--color-pink)" loading={true} />
          </div>
        )}

        {isFetched && data.length === 0 && (
          <div className="loading">
            <h3>Não foi encontrado nenhum pedido!</h3>
          </div>
        )}

        <ul>
          {data && data.map(item => (!filter ? CardOrder(item) : item.status === filter ? CardOrder(item) : null))}
        </ul>
      </Container>
    </ContainerComponent>
  )
}
