import React, { useEffect, useState } from 'react'
import { HiCheck, HiOutlinePlus } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, CardBox, ContainerComponent, InputCalendar, linkRoutes } from '../../components'
import notImage from '../../assets/images/notImage.png'
import { GetStories } from '../../services/Stories'
import { formatDateHour } from '../../utils'

import { Container } from './styles'

const FeedHunters = () => {
  const history = useHistory()

  const [params, setParams] = useState({ offset: 0, limit: 1000 })
  const [stories, setStories] = useState([])

  async function fetchData() {
    try {
      const { data: response } = await GetStories(params)
      const { data } = response

      setStories(data.map(item => ({ ...item, created_at: formatDateHour(item.created_at) })))
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleShowFeedHunter(event) {
    const feedHunter = event.data
    history.push(linkRoutes.feedHuntersDetails, { feedHunter })
  }

  function handleChangeState(event) {
    const { name, value } = event.target
    setParams({ ...params, [name]: value })
  }

  const ColumnDate = value => <div className="row__datatable centralized">{value.created_at}</div>

  const ColumnImage = value => (
    <div className="row__datatable">
      <img src={value?.image_url || notImage} alt="" />
      {value.title}
    </div>
  )

  const ColumnUserName = value => (
    <div className="row__datatable">
      {value.feedhunter.fullname} {value.feedhunter.nickname && <strong>@{value.feedhunter.nickname}</strong>}
    </div>
  )

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      title="Destaques"
      headerRight={
        <Link to={linkRoutes.feedHuntersNew}>
          <Button customStyles={{ borderRadius: '1.6rem', height: '4rem' }} icon={HiOutlinePlus}>
            CRIAR POST DESTAQUE
          </Button>
        </Link>
      }
    >
      <Container>
        <div className="filter">
          <InputCalendar onChange={handleChangeState} label="Data de inicio" name="start_at" />
          <InputCalendar onChange={handleChangeState} label="Data final" name="end_at" />

          <Button iconRight={HiCheck} onClick={fetchData}>
            Aplicar
          </Button>
        </div>

        <CardBox>
          <DataTable onRowClick={handleShowFeedHunter} value={stories} dataKey="id">
            <Column header="Imagem" body={ColumnImage} sortable/>
            <Column header="Usuário" body={ColumnUserName} field="feedhunter.fullname" sortable />
            <Column header="Data" body={ColumnDate} field="created_at" sortable />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { FeedHunters }
