import styled from 'styled-components'

export const Container = styled.div`
  .filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.2rem;
    label {
      margin-right: 1.6rem;
    }
  }
  .row__datatable {
    padding-left: 1.6rem;
  }
  .row__datatable__status {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      background: none;
      cursor: pointer;
      border: none;
      color: white;
      span {
        padding: 0.4rem 0.8rem;
        border-radius: 1rem;
        font-size: 1.6rem;
      }
      .span-active {
        background-color: var(--color-green);
      }
      .span-inactive {
        background-color: var(--color-orange);
      }
    }
  }
`
