import React, { useEffect, useState } from "react";
import { HiCheck, HiTrash } from "react-icons/hi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import {
  formatDate,
  formatterPrice,
  formatterCEP,
  formatterCNPJ,
  formatterCPF,
  formatterPhone,
} from "../../../utils";
import {
  DeleteStores,
  GetOneStore,
  PatchStore,
} from "../../../services/Stores";
import {
  ContainerComponent,
  Button,
  Input,
  CardBox,
  InputSelect,
} from "../../../components";
import { ShowStoreLoyaltyProgram } from "../../../services/LoyaltyPrograms";
import { GetStatisticsStoreOverview } from "../../../services/Statistics";
import { GetAllCousines } from "../../../services/Cousines";
import notImage from "../../../assets/images/notImage.png";

import { Container } from "./styles";
import { OPTIONS_STATUS } from "./utils";

const RestaurantDetails = () => {
  const history = useHistory();

  const [restaurantStatistics, setRestaurantStatistics] = useState({});
  const [loyaltyPrograms, setLoyaltyPrograms] = useState({});
  const [cousines, setCousines] = useState([])
  const [responsible, setResponsible] = useState({});
  const [restaurant, setRestaurant] = useState({});
  const [status, setStatus] = useState(null);
  const [selectedCousine, setSelectedCousine] = useState(null)
  const [address, setAddress] = useState({});
  const [info, setInfo] = useState({});

  async function fetchData() {
    const restaurantHistory = history?.location?.state?.restaurant;

    try {
      const { data: responseLoyalty } = await ShowStoreLoyaltyProgram(
        restaurantHistory.id
      );
      const { data: statistics } = await GetStatisticsStoreOverview(
        restaurantHistory.id
      );
      const { data: response } = await GetOneStore(restaurantHistory.id);
      const cousines = await GetAllCousines();
      const cousinesFormatted = cousines.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCousines(cousinesFormatted)
      setSelectedCousine(response.data.cousine_id);
      setResponsible(response.data.info.responsible);
      setAddress(response.data.info.address);
      setRestaurant(response.data);
      setStatus(response.data.status);
      setInfo(response.data.info);

      setRestaurantStatistics(statistics.data);
      setLoyaltyPrograms(responseLoyalty.data);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleUpdateRestaurant() {
    const {
      phone: phoneResponsible,
      name: nameResponsible,
      email,
      cpf,
    } = responsible;
    const { company_name, phone, site, cnpj } = info;
    const { name } = restaurant;

    const payload = {
      info: {
        responsible: {
          phone: phoneResponsible?.replace(/\D/g, ""),
          name: nameResponsible,
          email,
          cpf,
        },
        phone: phone?.replace(/\D/g, ""),
        cnpj: cnpj?.replace(/\D/g, ""),
        company_name,
        address,
        site,
      },
      status,
      name,
      cousine_id: selectedCousine,
    };
    console.log({ payload });
    try {
      await PatchStore({ id: restaurant.id, payload });
      toast.success("Restaurante atualizado com sucesso!");
      history.goBack();
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleDeleteRestaurant() {
    try {
      await DeleteStores(restaurant.id);
      toast.success("Restaurante removido com sucesso!");
      history.goBack();
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target;
    setRestaurant((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleChangeAddress(event) {
    const { value, name } = event.target;
    setAddress((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleChangeInfo(event) {
    const { value, name } = event.target;
    setInfo((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleChangeResponsible(event) {
    const { value, name } = event.target;
    setResponsible((prevState) => ({ ...prevState, [name]: value }));
  }

  const ColumnConsumption = (value) => (
    <span className="row__datatable">{formatterPrice(value?.consumption)}</span>
  );
  const ColumnCustomers = (value) => (
    <span className="row__datatable">{value?.n_customers}</span>
  );
  const ColumnBenefit = (value) => (
    <span className="row__datatable">{value?.discount}</span>
  );
  const ColumnName = (value) => (
    <span className="row__datatable">{value?.store?.name}</span>
  );
  const ColumnUsers = (value) => (
    <span className="row__datatable">{value?.n_uses}</span>
  );

  useEffect(() => fetchData(), []);

  return (
    <ContainerComponent
      headerLeft={["Restaurantes", "Lista de restaurantes", "Detalhes"]}
      title="Detalhes"
    >
      <Container>
        <CardBox>
          <div className="box__statistics">
            <img src={restaurant.logo_black || notImage} alt="" />

            <div>
              <h3>{restaurantStatistics?.visitors || 0}</h3>
              <p>Usuários visitantes</p>
            </div>

            <div>
              <h3>{restaurantStatistics?.revenues || 0}</h3>
              <p>Faturamento</p>
            </div>

            <div>
              <h3>{restaurantStatistics?.paid_tickets || 0}</h3>
              <p>Número de comandas pagas</p>
            </div>
          </div>
        </CardBox>

        <CardBox
          customStyles={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "3.2rem",
          }}
        >
          <Input
            customStyles={{ color: "var(--color-gold)" }}
            onChange={handleChangeState}
            value={restaurant?.name}
            label="Nome fantasia"
            name="name"
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            value={formatterCNPJ(info?.cnpj)}
            onChange={handleChangeInfo}
            label="CNPJ"
            name="cnpj"
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            onChange={handleChangeInfo}
            value={info?.company_name}
            label="Razão social"
            name="company_name"
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            value={formatterPhone(info?.phone)}
            onChange={handleChangeInfo}
            label="Telefone"
            name="phone"
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            onChange={handleChangeInfo}
            value={info?.site}
            label="Site"
            name="site"
          />

          <InputSelect
            customStyles={{ color: "var(--color-gold)" }}
            bgIcon="var(--color-primary)"
            options={cousines}
            name="cousine_id"
            label="Cozinha"
            onChange={(e) => setSelectedCousine(e.target.value)}
            value={selectedCousine}
          />

          <Input
            value={
              restaurant.created_at ? formatDate(restaurant.created_at) : ""
            }
            customStyles={{ color: "var(--color-gold)" }}
            label="Parceiro desde"
            disabled
          />

          <InputSelect
            customStyles={{ color: "var(--color-gold)" }}
            bgIcon="var(--color-primary)"
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            options={OPTIONS_STATUS}
            label="Status"
            name="status"
          />

          <div className="box__options">
            <Button
              onClick={handleDeleteRestaurant}
              icon={HiTrash}
              customStyles={{
                background: "var(--color-primary-500)",
                borderRadius: "1.6rem",
                marginRight: "1.6rem",
                height: "4rem",
              }}
            >
              REMOVER
            </Button>

            <Button
              customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
              onClick={handleUpdateRestaurant}
              icon={HiCheck}
            >
              SALVAR
            </Button>
          </div>
        </CardBox>

        <CardBox
          label="Endereço"
          customStyles={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "3.2rem",
          }}
        >
          <Input
            customStyles={{ color: "var(--color-gold)" }}
            onChange={handleChangeAddress}
            value={address?.city}
            label="Cidade"
            name="city"
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            onChange={handleChangeAddress}
            value={address?.street}
            label="Endereço"
            name="street"
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            onChange={handleChangeAddress}
            value={address?.neighborhood}
            name="neighborhood"
            label="Bairro"
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            value={formatterCEP(address?.zip_code)}
            onChange={handleChangeAddress}
            name="zip_code"
            label="CEP"
          />
        </CardBox>

        <CardBox
          customStyles={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "3.2rem",
          }}
          label="Responsável legal"
        >
          <Input
            customStyles={{ color: "var(--color-gold)" }}
            onChange={handleChangeResponsible}
            value={responsible?.name}
            label="Nome completo"
            name="name"
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            value={formatterCPF(responsible?.cpf)}
            onChange={handleChangeResponsible}
            label="CPF"
            name="cpf"
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            value={formatterPhone(responsible?.phone)}
            onChange={handleChangeResponsible}
            label="Telefone"
            name="phone"
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            onChange={handleChangeResponsible}
            value={responsible?.email}
            label="E-mail"
            name="email"
          />
        </CardBox>

        <CardBox
          customStyles={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "3.2rem",
          }}
          label="Dados bancários"
        >
          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Banco"
            value={restaurant.xxx}
            disabled
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Tipo de conta"
            value={restaurant.xxx}
            disabled
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Agência"
            value={restaurant.xxx}
            disabled
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Conta (com dígito)"
            value={restaurant.xxx}
            disabled
          />
        </CardBox>

        <CardBox label="Programa de fidelidade">
          <DataTable value={[loyaltyPrograms]}>
            <Column header="Nome do restaurante" body={ColumnName} />
            <Column header="Usuários ativos" body={ColumnCustomers} />
            <Column
              header="Valor de consumo para resgate"
              body={ColumnConsumption}
            />
            <Column header="Benefício" body={ColumnBenefit} />
            <Column header="Quantidade resgatada" body={ColumnUsers} />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  );
};

export { RestaurantDetails };
