import React, { useEffect, useState } from 'react'
import { HiCheck } from 'react-icons/hi'
import { toast } from 'react-toastify'

import { GetLoyaltyPrograms, PatchLoyaltyPrograms } from '../../../services/LoyaltyPrograms'
import { Button, CardBox, ContainerComponent, Input, Switch } from '../../../components'
import notImage from '../../../assets/images/notImage.png'

import { Container } from './styles'

const LoyaltyProgramsDetails = () => {
  const [loyaltyProgram, setLoyaltyProgram] = useState({})

  async function fetchData() {
    try {
      const { data } = await GetLoyaltyPrograms()
      setLoyaltyProgram(data.data)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleUpdateLoyaltyProgram() {
    const { consumption, discount, active } = loyaltyProgram

    try {
      await PatchLoyaltyPrograms({ consumption, discount, active })
      toast.success(`Programa de fidelidade atualizado com sucesso`)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleFormatPrice(value) {
    if (value === undefined) return 'R$ 0,00'

    value = value + ''
    value = value.replace(/([0-9]{2})$/g, ',$1')

    if (value.length >= 6) value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2')

    return `R$ ${value}`
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent title="Detalhes do programa" headerLeft={['Programa de fidelidade', 'Detalhes']}>
      <Container>
        <CardBox customStyles={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '3.2rem' }}>
          <Input
            label="Núm. total de usuários ativos no programa de fidelidade"
            value={`${loyaltyProgram.n_customers || 0} usuário(s)`}
            customStyles={{ color: 'var(--color-gold)' }}
            disabled
          />

          <Input
            onChange={event =>
              setLoyaltyProgram(prevState => ({ ...prevState, consumption: event.target.value.replace(/\D/g, '') }))
            }
            label="Valor de consumo para resgate de benefícios"
            value={handleFormatPrice(loyaltyProgram.consumption)}
            customStyles={{ color: 'var(--color-gold)' }}
          />

          <Input
            onChange={event =>
              setLoyaltyProgram(prevState => ({ ...prevState, discount: event.target.value.replace(/\D/g, '') }))
            }
            customStyles={{ color: 'var(--color-gold)' }}
            value={`${loyaltyProgram.discount || 0} % off`}
            label="Benefício"
          />

          <Input
            value={`${loyaltyProgram.n_uses || 0} resgates`}
            customStyles={{ color: 'var(--color-gold)' }}
            label="Quantidade resgatada"
            disabled
          />

          <div className="top__user">
            <strong>Usuário com mais resgates</strong>

            <div>
              <img
                alt={`foto do usuário ${loyaltyProgram?.top_customer?.fullname}`}
                src={loyaltyProgram?.top_customer?.profile_picture || notImage}
              />

              <p>
                {loyaltyProgram?.top_customer?.fullname || 'nenhum'} (
                {loyaltyProgram?.top_customer?.username || 'nenhum'}) -{' '}
                <strong>{loyaltyProgram?.top_customer?.n_uses || 0} resgates</strong>
              </p>
            </div>
          </div>

          <div className="box__options">
            <div className="switch">
              <Switch
                onClick={() => setLoyaltyProgram(prevState => ({ ...prevState, active: !loyaltyProgram.active }))}
                active={loyaltyProgram.active}
              />

              <p>{loyaltyProgram.active ? 'Ativo' : 'Desativado'}</p>
            </div>

            <Button
              customStyles={{ width: '14rem', borderRadius: '1.6rem', height: '4rem' }}
              onClick={handleUpdateLoyaltyProgram}
              icon={HiCheck}
            >
              Salvar
            </Button>
          </div>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { LoyaltyProgramsDetails }
