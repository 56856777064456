import React, { memo, useState } from 'react'
import { HiOutlineLogout, HiChevronRight } from 'react-icons/hi'
import { AnimateSharedLayout, motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import LogoMenu from '../../assets/images/logo-escuro.png'
import { useAuth } from '../../hook/AuthProvider'
import { linksMerchant } from './linksMerchant'
import { linksAdmin } from './linksAdmin'

import { Button, ComponentModal } from '..'

import { Container } from './style'

const NavMenuBar = () => {
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const { onUserLogout, user } = useAuth()

  const links = user.role === 'ADMIN' ? linksAdmin : linksMerchant

  return (
    <Container>
      <div className="logo">
        <img src={LogoMenu} alt="Logo saara menu" />
      </div>

      <div className="container__menu">
        <ul>
          {links.map((item, index) => {
            const isActive = window.location.pathname.startsWith(String(item.path)) ? true : false

            return !item.subMenus ? (
              <li key={index}>
                <Link id={isActive && 'active'} to={item.path}>
                  <div layout className="menu">
                    {item.icon}
                    <div className="title">{item.title}</div>
                    <HiChevronRight />
                  </div>
                </Link>
              </li>
            ) : (
              <li key={index} onClick={() => setOpenSubMenu(!openSubMenu)}>
                <span>
                  <motion.div layout className="menu">
                    {item.icon}
                    <div className="title">{item.title}</div>
                    <HiChevronRight />
                  </motion.div>
                </span>

                <AnimateSharedLayout>
                  {item.subMenus && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="submenu"
                      layout
                    >
                      {openSubMenu &&
                        item.subMenus.map((itemSubMenu, indexSubMenu) => (
                          <Link key={indexSubMenu} to={itemSubMenu.path}>
                            <div className="subtitle">
                              {itemSubMenu.icon}
                              <p> {itemSubMenu.title}</p>
                            </div>
                          </Link>
                        ))}
                    </motion.div>
                  )}
                </AnimateSharedLayout>
              </li>
            )
          })}

          <li onClick={() => setOpenModal(!openModal)}>
            <span>
              <div layout className="menu">
                <HiOutlineLogout />
                <div className="title">Sair</div>
                <HiChevronRight />
              </div>
            </span>
          </li>
        </ul>
      </div>

      <ComponentModal className="container_logout" onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <span>Tem certeza que deseja sair?</span>

        <div>
          <Button
            customStyles={{ borderRadius: '1.6rem', marginRight: '1.6rem', height: '4rem', width: '10rem' }}
            onClick={onUserLogout}
          >
            Sim
          </Button>

          <Button
            customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '10rem' }}
            onClick={() => setOpenModal(!openModal)}
          >
            Cancelar
          </Button>
        </div>
      </ComponentModal>
    </Container>
  )
}

export const NavMenu = memo(NavMenuBar)
