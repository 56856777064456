import { useEffect, useState } from "react";
import {
  HiCheck,
  HiChevronDown,
  HiOutlineClipboardList,
  HiPlus,
  HiTrash,
  HiX,
} from "react-icons/hi";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  CardBox,
  ContainerComponent,
  Input,
  InputSelect,
  linkRoutes,
  Switch,
  TextArea,
} from "../../components";
import { deleteFileFirebase, uploadImageFirebase } from "../../utils";
import { GetOneStore, PatchStore } from "../../services/Stores";
import { app_skin, daysOfTheWeek } from "./optionsSettings";
import { GetAllCousines } from "../../services/Cousines";
import notImage from "../../assets/images/notImage.png";
import { removeCharacters } from "../../utils/string";
import { Account } from "../../services/Account";

import {
  BoxOptions,
  ContainerChangeImage,
  ContainerOpeningHours,
} from "./styles";
import { schemaSettings } from "./schemaSettings";

export const Settings = () => {
  const { formState, handleSubmit, setValue, register } = useForm({
    resolver: yupResolver(schemaSettings),
  });
  const { errors } = formState;

  const [imagePreviewBackground, setImagePreviewBackground] = useState(null);
  const [logoPreviewWhite, setLogoPreviewWhite] = useState(null);
  const [logoPreviewBlack, setLogoPreviewBlack] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null)
  const [selectedCousine, setSelectedCousine] = useState(null);
  const [opening_hours, setOpeningHours] = useState([]);
  const [cousines, setCousines] = useState([]);
  const [id, setId] = useState(null);

  async function fetchData() {
    try {
      const { data: responseAccount } = await Account();
      const store_id = responseAccount?.data?.roles[0]?.store_id;

      const cousines = await GetAllCousines();
      const cousinesFormatted = cousines.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      const { data: response } = await GetOneStore(store_id);

      for (const item in response.data) {
        setValue(item, response.data[item]);
      }

      for (const item in response.data.info.address) {
        setValue(item, response.data.info.address[item]);
      }

      for (const item in response.data.info) {
        setValue(item, response.data.info[item]);
      }

      setImagePreviewBackground({ image_url: response.data.bg_image });
      setLogoPreviewWhite({ image_url: response.data.logo_white });
      setLogoPreviewBlack({ image_url: response.data.logo_black });
      setOpeningHours(response.data.info.opening_hours);
      setCousines(cousinesFormatted);
      setSelectedCousine(response.data.cousine_id);
      setSelectedTheme(response.data.info.app_skin);
      setId(store_id);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleUpdateStore(value) {
    const {
      maps_url,
      zip_code,
      street,
      street_number,
      neighborhood,
      city,
      uf,
    } = value; // Deconstructs from address
    const {
      full_description,
      service_charge,
      instagram,
      whatsapp,
      site,
      phone,
    } = value; // Deconstructs from info
    const { description, name } = value; // Deconstructs from store

    const payload = {
      bg_image: imagePreviewBackground.image_url,
      logo_white: logoPreviewWhite.image_url,
      logo_black: logoPreviewBlack.image_url,
      description,
      cousine_id: selectedCousine,
      name,
      info: {
        address: {
          maps_url: maps_url || undefined,
          zip_code,
          street,
          street_number,
          neighborhood,
          city,
          uf,
        },
        app_skin: selectedTheme,
        service_charge: removeCharacters(String(service_charge)),
        whatsapp: removeCharacters(String(whatsapp)),
        company_name: name,
        full_description,
        opening_hours,
        instagram,
        phone,
        site,
      },
    };

    try {
      await PatchStore({ id, payload });
      toast.success("As configurações foram atualizadas com sucesso!");
    } catch (error) {
      toast.error("Ocorreu um erro na atualização");
    }
  }

  async function handleChangeImage(event) {
    const { files, name } = event.target;
    if (!files) return;

    const file = files[0];

    if (name === "bg_image")
      await uploadImageFirebase(
        setImagePreviewBackground,
        imagePreviewBackground,
        file
      );
    if (name === "logo_white")
      await uploadImageFirebase(setLogoPreviewWhite, logoPreviewWhite, file);
    if (name === "logo_black")
      await uploadImageFirebase(setLogoPreviewBlack, logoPreviewBlack, file);
  }

  async function handleDeleteImage(name) {
    if (name === "bg_image")
      await deleteFileFirebase(imagePreviewBackground.image_url);
    if (name === "logo_white")
      await deleteFileFirebase(logoPreviewWhite.image_url);
    if (name === "logo_black")
      await deleteFileFirebase(logoPreviewBlack.image_url);
  }

  const handleAddDayOfWeek = () => {
    const newOpeningHour = {
      time_periods: [{ start_time: undefined, end_time: undefined }],
      day_of_week: undefined,
    };
    setOpeningHours([...opening_hours, newOpeningHour]);
  };

  const handleChangeDayOfWeek = (indexDayOfWeek, event) => {
    const { value } = event.target;
    opening_hours[indexDayOfWeek].day_of_week = value;
    setOpeningHours([...opening_hours]);
  };

  const handleRemoveDayOfWeek = (itemSelect) => {
    const openingHourFiltered = opening_hours.filter(
      (item) => item !== itemSelect
    );
    setOpeningHours(openingHourFiltered);
  };

  const handleAddTimePeriod = (indexDayOfWeek) => {
    opening_hours[indexDayOfWeek]?.time_periods?.push({
      start_time: undefined,
      end_time: undefined,
    });
    setOpeningHours([...opening_hours]);
  };

  const handleChangeTimePeriod = (indexDayOfWeek, indexTimePeriod, event) => {
    const { value, name } = event.target;
    opening_hours[indexDayOfWeek]["time_periods"][indexTimePeriod][name] =
      value;
    setOpeningHours([...opening_hours]);
  };

  const handleRemoveTimePeriod = (timePeriod, indexDayOfWeek) => {
    const time_periods = opening_hours[indexDayOfWeek]?.time_periods?.filter(
      (item) => item !== timePeriod
    );
    opening_hours[indexDayOfWeek].time_periods = time_periods;
    setOpeningHours([...opening_hours]);
  };

  useEffect(() => fetchData(), []);

  return (
    <ContainerComponent
      title="Configurações"
      headerRight={
        <Link to={linkRoutes.settingsTeams}>
          <Button
            customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
            icon={HiOutlineClipboardList}
          >
            VER EQUIPE
          </Button>
        </Link>
      }
    >
      <form onSubmit={handleSubmit(handleUpdateStore)}>
        <CardBox
          label="Sobre"
          customStyles={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "1.6rem 3.2rem",
            marginTop: "6.4rem",
            padding: "3.2rem",
          }}
        >
          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Nome"
            name="name"
            register={register}
            errors={errors}
          />

          <InputSelect
            customStyles={{ color: "var(--color-gold)" }}
            bgIcon="var(--color-primary)"
            icon={HiChevronDown}
            options={cousines}
            name="cousine_id"
            label="Cozinha"
            onChange={(e) => setSelectedCousine(e.target.value)}
            register={register}
            errors={errors}
            value={selectedCousine}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Taxa de servico"
            name="service_charge"
            register={register}
            errors={errors}
          />

          <InputSelect
            customStyles={{ color: "var(--color-gold)" }}
            label="Cor do perfil no aplicativo"
            bgIcon="var(--color-primary)"
            icon={HiChevronDown}
            options={app_skin}
            name="app_skin"
            register={register}
            errors={errors}
            onChange={(e) => setSelectedTheme(e.target.value)}
            value={selectedTheme}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Site"
            name="site"
            register={register}
            errors={errors}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Telefone"
            name="phone"
            register={register}
            errors={errors}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Instagram"
            name="instagram"
            register={register}
            errors={errors}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Whatsapp"
            name="whatsapp"
            register={register}
            errors={errors}
          />

          <TextArea
            customStyles={{ color: "var(--color-gold)" }}
            label="Frase promocional"
            name="description"
            register={register}
            errors={errors}
          />

          <TextArea
            customStyles={{ color: "var(--color-gold)" }}
            label="Sobre o restaurante"
            name="full_description"
            register={register}
            errors={errors}
          />

          <ContainerChangeImage>
            <div className="view__image">
              <span>Imagem capa parceiros</span>

              <label htmlFor="select-logo-black">
                <img
                  src={logoPreviewBlack?.image_url || notImage}
                  alt="logo escura"
                />
              </label>

              <input
                accept="image/png"
                onChange={handleChangeImage}
                id="select-logo-black"
                name="logo_black"
                type="file"
              />

              {!!logoPreviewBlack && (
                <button onClick={() => handleDeleteImage("logo_black")}>
                  <HiX />
                </button>
              )}
            </div>

            <div className="view__image">
              <span>Logo clara</span>

              <label htmlFor="select-logo-white">
                <img
                  src={logoPreviewWhite?.image_url || notImage}
                  alt="logo branca"
                />
              </label>

              <input
                accept="image/png"
                onChange={handleChangeImage}
                id="select-logo-white"
                name="logo_white"
                type="file"
              />

              {!!logoPreviewWhite && (
                <button onClick={() => handleDeleteImage("logo_white")}>
                  <HiX />
                </button>
              )}
            </div>

            <div className="view__image">
              <span>Foto home</span>

              <label htmlFor="select-background-image">
                <img
                  src={imagePreviewBackground?.image_url || notImage}
                  alt="logo do restaurante"
                />
              </label>

              <input
                onChange={handleChangeImage}
                id="select-background-image"
                accept=".jpeg, .jpg"
                name="bg_image"
                type="file"
              />

              {!!imagePreviewBackground && (
                <button onClick={() => handleDeleteImage("bg_image")}>
                  <HiX />
                </button>
              )}
            </div>
          </ContainerChangeImage>
        </CardBox>

        <CardBox
          label="Endereço"
          customStyles={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "1.6rem 3.2rem",
            marginTop: "6.4rem",
            padding: "3.2rem",
          }}
        >
          <Input
            customStyles={{ color: "var(--color-gold)", gridColumn: "1/3" }}
            label="URL Google Maps"
            name="maps_url"
            register={register}
            errors={errors}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            name="zip_code"
            label="Cep"
            register={register}
            errors={errors}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            name="street"
            label="Rua"
            register={register}
            errors={errors}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            name="street_number"
            label="Número"
            register={register}
            errors={errors}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            name="neighborhood"
            label="Bairro"
            register={register}
            errors={errors}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Cidade"
            name="city"
            register={register}
            errors={errors}
          />

          <Input
            customStyles={{ color: "var(--color-gold)" }}
            label="Uf"
            name="uf"
            register={register}
            errors={errors}
          />
        </CardBox>

        <CardBox
          label="Funcionamento"
          customStyles={{ marginTop: "6.4rem", padding: "3.2rem" }}
        >
          <ContainerOpeningHours>
            <header>
              <Button
                customStyles={{
                  background: "var(--color-primary-500)",
                  borderRadius: "1.6rem",
                  height: "4rem",
                  margin: 0,
                }}
                onClick={handleAddDayOfWeek}
                icon={HiPlus}
              >
                ADICIONAR DIA
              </Button>
            </header>

            <ul>
              {opening_hours?.map((item, indexDayOfWeek) => (
                <li
                  key={indexDayOfWeek}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    background: "var(--color-primary-800)",
                    borderRadius: "1.6rem",
                    padding: "1.6rem",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <InputSelect
                      onChange={(event) =>
                        handleChangeDayOfWeek(indexDayOfWeek, event)
                      }
                      customStyles={{
                        color: "var(--color-gold)",
                        width: "16rem",
                      }}
                      bgIcon="var(--color-primary)"
                      value={item.day_of_week}
                      name="day_of_week_start"
                      options={daysOfTheWeek}
                      label="Dia da semana"
                    />

                    <Button
                      icon={HiTrash}
                      onClick={() => handleRemoveDayOfWeek(item)}
                    >
                      Remover dia
                    </Button>
                  </div>

                  <div>
                    {item?.time_periods?.map((timePeriod, indexTimePeriod) => (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 18rem",
                          gap: "1.6rem",
                        }}
                      >
                        <Input
                          onChange={(event) =>
                            handleChangeTimePeriod(
                              indexDayOfWeek,
                              indexTimePeriod,
                              event
                            )
                          }
                          customStyles={{ color: "var(--color-gold)" }}
                          value={timePeriod?.start_time}
                          name="start_time"
                          type="time"
                          label="Das:"
                        />

                        <Input
                          onChange={(event) =>
                            handleChangeTimePeriod(
                              indexDayOfWeek,
                              indexTimePeriod,
                              event
                            )
                          }
                          customStyles={{ color: "var(--color-gold)" }}
                          value={timePeriod?.end_time}
                          name="end_time"
                          label="Até:"
                          type="time"
                        />

                        <Button
                          icon={HiTrash}
                          onClick={() =>
                            handleRemoveTimePeriod(timePeriod, indexDayOfWeek)
                          }
                        >
                          Remover horário
                        </Button>
                      </div>
                    ))}
                  </div>

                  <Button
                    onClick={() => handleAddTimePeriod(indexDayOfWeek)}
                    style={{ margin: "0 auto", marginTop: "3.2rem" }}
                    icon={HiPlus}
                  >
                    Adicionar hora
                  </Button>
                </li>
              ))}
            </ul>
          </ContainerOpeningHours>
        </CardBox>

        <BoxOptions>
          <Button
            customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
            icon={HiCheck}
            type="submit"
          >
            SALVAR
          </Button>
        </BoxOptions>
      </form>
    </ContainerComponent>
  );
};
