import { HiCheck, HiTrash, HiX } from 'react-icons/hi'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { FilePond } from 'react-filepond'
import { toast } from 'react-toastify'

import { ContainerComponent, ComponentModal, AutoComplete, Switch, Button, Input } from '../../../components'
import { PostStories, PatchStories, DeleteStories } from '../../../services/Stories'
import { handleCreateImagesPreviewFromUrls, clearObject } from '../../../utils'
import { GetSimpleListProducts } from '../../../services/Product'
import { GetFeedHunters } from '../../../services/FeedHunters'
import { uploadArrayFilesFirebase } from '../../../utils'
import { Account } from '../../../services/Account'

import { Container } from './styles'

const HandleFeedHunter = () => {
  const history = useHistory()

  const [feedHuntersArray, setFeedHuntersArray] = useState([])
  const [productsArray, setProductsArray] = useState([])
  const [imagesPreview, setImagesPreview] = useState([])
  const [videoPreview, setVideoPreview] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [feedHunter, setFeedHunter] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [modalDeletePost, setModalDeletePost] = useState(false)

  async function fetchData() {
    const editFeedHunter = history?.location?.state?.feedHunter

    if (!!editFeedHunter) {
      const isVideo = editFeedHunter.media_type === 'VIDEO' ? true : false

      const image = handleCreateImagesPreviewFromUrls(editFeedHunter.image_url)
      const video = editFeedHunter.video_url

      setFeedHunter({ ...editFeedHunter, isVideo })
      setImagesPreview(image)
      setVideoPreview(video)
      setIsEdit(true)
    }

    try {
      const { data: responseAccount } = await Account()
      const store_id = responseAccount?.data?.roles[0]?.store_id

      const { data: productsResponse } = await GetSimpleListProducts(store_id)
      const { data: feedHunterResponse } = await GetFeedHunters()

      const feedHuntersArrayFormatted = feedHunterResponse.data.map(item => ({ value: item.id, text: item.username }))
      const productsArrayFormatted = productsResponse.data.map(item => ({ value: item.id, text: item.name }))

      setFeedHuntersArray(feedHuntersArrayFormatted)
      setProductsArray(productsArrayFormatted)
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar carregar as informações dessa página')
    }
  }

  async function handleCreateOrUpdateFeedHunter() {
    const media_type = feedHunter.isVideo ? 'VIDEO' : 'PHOTO'
    const { id } = feedHunter

    clearObject(feedHunter, ['created_at', 'feedhunter', 'store_id', 'product', 'isVideo', 'id'])

    if (imagesPreview.length > 0) {
      const [image_url] = await uploadArrayFilesFirebase(imagesPreview, 'images')
      Object.assign(feedHunter, { image_url, media_type })
    }

    if (!!videoPreview) {
      const [video_url] = await uploadArrayFilesFirebase(videoPreview, 'videos')
      Object.assign(feedHunter, { video_url, media_type })
    }

    try {
      if (isEdit) await PatchStories(id, feedHunter)
      if (!isEdit) await PostStories(feedHunter)

      toast.success(`FeedHunter ${isEdit ? 'atualizado' : 'criado'} com sucesso`)
      setOpenModal(true)
    } catch ({ response }) {
      toast.error('Ocorreu um erro na atualização do FeedHunter')
    }
  }

  async function handleDeleteFeedHunter() {
    try {
      await DeleteStories(feedHunter.id)
      setModalDeletePost(false)
      toast.success('Feed Hunter deletado com sucesso!')
      setOpenModal(true)
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar excluir o FeedHunter')
    }
  }

  function handleSelectImage(event) {
    if (!!event[0] && event[0]?.file !== 'undefined') {
      const files = event.map(file => file.file)
      setImagesPreview(files)
    }
  }

  function handleSelectVideo(event) {
    if (!!event && typeof event[0]?.file !== 'undefined') {
      const files = event.map(file => file.file)
      setVideoPreview(files)
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setFeedHunter(prevState => ({ ...prevState, [name]: value }))
  }

  const handleChangeProduct = product => setFeedHunter(prevState => ({ ...prevState, product_id: product }))
  const handleChangeUser = user => setFeedHunter(prevState => ({ ...prevState, customer_id: user }))

  useEffect(() => fetchData(), [])

  return (
    <>
      <ComponentModal onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <h3>{`FeedHunter ${isEdit ? 'editada' : 'criada'} com sucesso`}</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <ComponentModal onRequestClose={() => setModalDeletePost(!modalDeletePost)} isOpen={modalDeletePost}>
        <p>
          Tem certeza que deseja excluir o post {' '}
          <strong>{feedHunter?.title}</strong>?
        </p>

        <div>
          <Button
            customStyles={{
              background: 'var(--color-primary)',
              borderRadius: '1.6rem',
              marginRight: '1.6rem',
              height: '4rem'
            }}
            onClick={() => setModalDeletePost(!modalDeletePost)}
          >
            NÃO
          </Button>

          <Button
            customStyles={{ borderRadius: '1.6rem', height: '4rem' }}
            onClick={() => handleDeleteFeedHunter()}
          >
            SIM
          </Button>
        </div>
      </ComponentModal>

      <ContainerComponent
        headerLeft={['Destaques', isEdit ? 'Editar' : 'Criar post']}
        title={isEdit ? 'Editar' : 'Criar post'}
      >
        <Container>
          <div className="box__grid">
            <Input
              customStyles={{ color: 'var(--color-gold)' }}
              placeholder="Insira o título do post"
              onChange={handleChangeState}
              label="Título do post"
              value={feedHunter?.title}
              name="title"
              type="text"
            />

            <AutoComplete
              placeholder="Selecione o usuário"
              labelColor="var(--color-gold)"
              label="Selecione o usuário"
              defaultValue={feedHunter?.feedHunter?.name}
              suggestions={feedHuntersArray}
              setState={handleChangeUser}
            />

            <AutoComplete
              placeholder="Selecione o produto"
              labelColor="var(--color-gold)"
              label="Selecione o produto"
              defaultValue={feedHunter?.product?.name}
              setState={handleChangeProduct}
              suggestions={productsArray}
            />
          </div>

          <div className="inbox__change__media">
            <FilePond
              labelIdle="Arraste aqui uma imagem para anexar"
              onupdatefiles={handleSelectImage}
              acceptedFileTypes={['image/*']}
              allowImagePreview={true}
              allowFilePoster={true}
              allowMultiple={false}
              files={imagesPreview}
            />

            <FilePond
              labelIdle="Arraste aqui uma vídeo para anexar"
              onupdatefiles={handleSelectVideo}
              acceptedFileTypes={['video/mp4']}
              allowVideoPreview={true}
              allowMultiple={false}
            />
          </div>

          <div className="box__grid__options">
            <Switch
              onClick={() => setFeedHunter({ ...feedHunter, isVideo: !feedHunter.isVideo })}
              active={feedHunter.isVideo}
            >
              {feedHunter.isVideo ? 'Vídeo' : 'Imagem'}
            </Switch>

            <div>
              <Button
                customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
                onClick={history.goBack}
                icon={HiX}
              >
                Cancelar
              </Button>

              <Button
                customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem', marginLeft: '1.6rem' }}
                onClick={handleCreateOrUpdateFeedHunter}
                icon={HiCheck}
              >
                {isEdit ? 'Salvar' : 'Criar'}
              </Button>

              {isEdit && (
                <Button
                  customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem', marginLeft: '1.6rem' }}
                  onClick={() => setModalDeletePost(true)}
                  icon={HiTrash}
                >
                  Excluir
                </Button>
              )}
            </div>
          </div>
        </Container>
      </ContainerComponent>
    </>
  )
}

export { HandleFeedHunter }
