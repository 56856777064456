import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { PostAuthToken, PostRequestToken } from '../../services/Auth'
import { emailSchema, schemeChangePassword, tokenSchema } from './schemesForgotPassword'
import { Button, Input, linkRoutes } from '../../components'
import { UpdateAccount } from '../../services/Account'
import bgLogin from '../../assets/images/bg-login.png'
import { useAuth } from '../../hook/AuthProvider'

import { Container } from './styles'

const ForgotPassword = () => {
  const [accessToken, setAccessToken] = useState(null)
  const [schema, setSchema] = useState(emailSchema)
  const [email, setEmail] = useState(null)
  const [steps, setSteps] = useState('email')
  const [key, setKey] = useState(null)

  const { formState, handleSubmit, register } = useForm({ resolver: yupResolver(schema) })
  const { onTokenLogin } = useAuth()
  const { errors } = formState

  async function handleSendMail({ email }) {
    const payload = { role: 'ADMIN', email }

    try {
      const { data: response } = await PostRequestToken(payload)

      setKey(response.data.key)
      setSchema(tokenSchema)
      setEmail(email)
      setSteps('token')

      toast.success(`Um token foi enviado para o email ${email}`)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleAuthToken({ token }) {
    const payload = { token, role: 'ADMIN', key }

    try {
      const { data: response } = await PostAuthToken(payload)

      setAccessToken(response.data.access_token)
      setSchema(schemeChangePassword)
      setSteps('changePassword')
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleChangePassword({ password }) {
    const payload = { password }

    try {
      onTokenLogin(accessToken)

      await UpdateAccount(payload)

      window.location.href = linkRoutes.statistics
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  return (
    <Container>
      <div className="container__background">
        <img src={bgLogin} alt="" srcset="" />
      </div>

      <div className="container__form">
        <div>
          <h1>Recuperação de senha</h1>

          {steps === 'email' && (
            <form onSubmit={handleSubmit(handleSendMail)}>
              <p>Preencha o formulário abaixo para que possamos enviar um email com um código de acesso.</p>

              <Input
                customStyles={{ color: 'var(--color-gold)' }}
                register={register}
                errors={errors}
                label="E-mail"
                name="email"
              />

              <Button
                customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem', marginBottom: '3.2rem' }}
                type="submit"
              >
                Enviar
              </Button>
            </form>
          )}

          {steps === 'token' && (
            <form onSubmit={handleSubmit(handleAuthToken)}>
              <p>Informe o token que foi enviado para o email {email}</p>

              <Input
                customStyles={{ color: 'var(--color-gold)' }}
                register={register}
                errors={errors}
                label="Informe o token"
                name="token"
              />

              <Button
                customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem', marginBottom: '3.2rem' }}
                type="submit"
              >
                Trocar senha
              </Button>
            </form>
          )}

          {steps === 'changePassword' && (
            <form onSubmit={handleSubmit(handleChangePassword)}>
              <p>Informe uma nova senha para o email {email}</p>

              <Input
                customStyles={{ color: 'var(--color-gold)' }}
                register={register}
                errors={errors}
                label="Nova senha"
                name="password"
                type="password"
              />

              <Input
                customStyles={{ color: 'var(--color-gold)', marginTop: '3.2rem' }}
                register={register}
                errors={errors}
                label="Senha de confirmação"
                name="confirm_password"
                type="password"
              />

              <Button
                customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem', marginBottom: '3.2rem' }}
                type="submit"
              >
                Salvar nova senha
              </Button>
            </form>
          )}
        </div>
      </div>
    </Container>
  )
}

export { ForgotPassword }
