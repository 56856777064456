import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-gap: 3.2rem;

  border: 0.1rem solid var(--color-primary-500);
  background: var(--color-primary-600);
  border-radius: 1.6rem;
  padding: 3.2rem;

  .inbox__change__media {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3.2rem;
  }

  .box__grid {
    display: grid;
    grid-gap: 1.6rem;
  }

  .box__grid__options {
    display: flex;
    justify-content: space-between;
  }

  .filepond--item {
    height: 25.6rem;
    width: 25.6rem;
  }
`
