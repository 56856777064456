import React, { createElement } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import HttpsRedirect from 'react-https-redirect'
import Modal from 'react-modal'

import { LoadingTwo } from './components/Loading/index'
import { linkRoutes } from './components/routes'
import { useAuth } from './hook/AuthProvider'

import { ComplementsGroupHandle } from './pages/Products/Complements/ComplementsGroupHandle'
import { LoyaltyProgramsMerchants } from './pages/LoyaltyPrograms/LoyaltyProgramsMerchants'
import { LoyaltyProgramsDetails } from './pages/LoyaltyPrograms/LoyaltyProgramsDetails'
import { AdminsIncludeCollaborator } from './pages/Admins/AdminsIncludeCollaborator'
import { LoyaltyProgramsAdmin } from './pages/LoyaltyPrograms/LoyaltyProgramsAdmin'
import { RestaurantsApprovals } from './pages/Restaurants/RestaurantsApprovals'
import { NotificationsHandle } from './pages/Notifications/NotificationsHandle'
import { StatisticsMerchant } from './pages/Statistics/StatisticsMerchant'
import { RestaurantDetails } from './pages/Restaurants/RestaurantDetails'
import { RestaurantCreate } from './pages/Restaurants/RestaurantCreate'
import { FinancialExtracts } from './pages/Financial/FinancialExtracts'
import { HandleFeedHunter } from './pages/FeedHunters/HandleFeedHunter'
import { PromoCodesCreate } from './pages/PromoCodes/PromoCodesCreate'
import { FinancialHistory } from './pages/Financial/FinancialHistory'
import { CustomersDetails } from './pages/Customers/CustomersDetails'
import { RestaurantsList } from './pages/Restaurants/RestaurantsList'
import { StatisticsAdmin } from './pages/Statistics/StatisticsAdmin'
import { CategoryHandle } from './pages/Products/CategoryHandle'
import { CousinesHandle } from './pages/Cousines/CousinesHandle'
import { ReserveCreate } from './pages/Reserves/ReserveCreate'
import { ProductHandle } from './pages/Products/ProductHandle'
import { CustomersLit } from './pages/Customers/CustomersLit'
import { BankAccounts } from './pages/Financial/BankAccounts'
import { OpeningHours } from './pages/Reserves/OpeningHours'
import { AdminsDetails } from './pages/Admins/AdminsDetails'
import { Complements } from './pages/Products/Complements'
import { ForgotPassword } from './pages/ForgotPassword'
import { PostsHandle } from './pages/Posts/PostsHandle'
import { CreateTable } from './pages/Hall/CreateTable'
import { UsagePolicies } from './pages/UsagePolicies'
import { Notifications } from './pages/Notifications'
import { CreateTeam } from './pages/Teams/CreateTeam'
import { FacPrivacy } from './pages/FACs/FacPrivacy'
import { AdminsAdd } from './pages/Admins/AdminsAdd'
import { Integrations } from './pages/Integrations'
import { FacHandle } from './pages/FACs/FacHandle'
import { Restaurants } from './pages/Restaurants'
import { FirstAccess } from './pages/FirstAccess'
import { FeedHunters } from './pages/FeedHunters'
import { PromoCodes } from './pages/PromoCodes'
import { Customers } from './pages/Customers'
import { Financial } from './pages/Financial'
import { Cousines } from './pages/Cousines'
import { Commands } from './pages/Commands'
import { Reserves } from './pages/Reserves'
import { Products } from './pages/Products'
import { Settings } from './pages/Settings'
import { Reviews } from './pages/Reviews'
import { Orders } from './pages/Orders'
import { Admins } from './pages/Admins'
import { SignIn } from './pages/Login'
import { Teams } from './pages/Teams'
import { Posts } from './pages/Posts'
import { Hall } from './pages/Hall'
import { FACs } from './pages/FACs'
import { Imports } from './pages/Products/Imports/Imports'
Modal.setAppElement('#root')

export const App = () => {
  const { onUserLogin, Loading, logged, user } = useAuth()

  function renderMergedProps(component, ...props) {
    const finalProps = Object.assign({}, ...props)

    return createElement(component, finalProps)
  }

  const PropsRoute = ({ component, ...rest }) => {
    return <Route {...rest} render={routeProps => renderMergedProps(component, routeProps, rest)} />
  }

  const PrivateRoute = ({ component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props => {
          return rest.logged ? (
            renderMergedProps(component, props, rest)
          ) : (
            <Redirect to={{ pathname: linkRoutes.login, state: { from: props.location } }} />
          )
        }}
      />
    )
  }

  return (
    <HttpsRedirect>
      {Loading ? (
        <LoadingTwo isLoading={Loading} />
      ) : (
        <BrowserRouter>
          <Switch>
            <PropsRoute
              onFormSubmit={onUserLogin}
              path={linkRoutes.login}
              LoadingTwo={LoadingTwo}
              component={SignIn}
              logged={logged}
              exact
            />

            <PropsRoute path={linkRoutes.forgotPassword} component={ForgotPassword} exact />
            <PropsRoute path={linkRoutes.firstAccess} component={FirstAccess} exact />

            {user?.role === 'ADMIN' ? (
              <>
                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.restaurantsApprovals}
                  component={RestaurantsApprovals}
                />

                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.restaurantsDetails}
                  component={RestaurantDetails}
                />

                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.loyaltyProgramsDetails}
                  component={LoyaltyProgramsDetails}
                />

                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.notificationsDetails}
                  component={NotificationsHandle}
                />

                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.notificationsNew}
                  component={NotificationsHandle}
                />

                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.adminsIncludeCollaborator}
                  component={AdminsIncludeCollaborator}
                />

                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.loyaltyPrograms}
                  component={LoyaltyProgramsAdmin}
                />

                <PrivateRoute exact logged={logged} path={linkRoutes.financialExtracts} component={FinancialExtracts} />
                <PrivateRoute exact logged={logged} path={linkRoutes.restaurantsCreate} component={RestaurantCreate} />
                <PrivateRoute exact logged={logged} path={linkRoutes.financialHistory} component={FinancialHistory} />
                <PrivateRoute exact logged={logged} path={linkRoutes.promoCodesCreate} component={PromoCodesCreate} />
                <PrivateRoute exact logged={logged} path={linkRoutes.customersDetails} component={CustomersDetails} />
                <PrivateRoute exact logged={logged} path={linkRoutes.restaurantsList} component={RestaurantsList} />
                <PrivateRoute exact logged={logged} path={linkRoutes.cousinesDetails} component={CousinesHandle} />
                <PrivateRoute exact logged={logged} path={linkRoutes.notifications} component={Notifications} />
                <PrivateRoute exact logged={logged} path={linkRoutes.adminsDetails} component={AdminsDetails} />
                <PrivateRoute exact logged={logged} path={linkRoutes.customersList} component={CustomersLit} />
                <PrivateRoute exact logged={logged} path={linkRoutes.cousinesNew} component={CousinesHandle} />
                <PrivateRoute exact logged={logged} path={linkRoutes.statistics} component={StatisticsAdmin} />
                <PrivateRoute exact logged={logged} path={linkRoutes.restaurants} component={Restaurants} />
                <PrivateRoute exact logged={logged} path={linkRoutes.promoCodes} component={PromoCodes} />
                <PrivateRoute exact logged={logged} path={linkRoutes.facPrivacy} component={FacPrivacy} />
                <PrivateRoute exact logged={logged} path={linkRoutes.facDetails} component={FacHandle} />
                <PrivateRoute exact logged={logged} path={linkRoutes.financial} component={Financial} />
                <PrivateRoute exact logged={logged} path={linkRoutes.adminsAdd} component={AdminsAdd} />
                <PrivateRoute exact logged={logged} path={linkRoutes.customers} component={Customers} />
                <PrivateRoute exact logged={logged} path={linkRoutes.cousines} component={Cousines} />
                <PrivateRoute exact logged={logged} path={linkRoutes.facAdd} component={FacHandle} />
                <PrivateRoute exact logged={logged} path={linkRoutes.admins} component={Admins} />
                <PrivateRoute exact logged={logged} path={linkRoutes.fac} component={FACs} />
              </>
            ) : (
              <>
                <PrivateRoute exact logged={logged} path={linkRoutes.reservesOpeningHours} component={OpeningHours} />

                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.loyaltyProgramsDetails}
                  component={LoyaltyProgramsDetails}
                />

                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.loyaltyPrograms}
                  component={LoyaltyProgramsMerchants}
                />

                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.createNewGroup}
                  component={ComplementsGroupHandle}
                />

                <PrivateRoute
                  exact
                  logged={logged}
                  path={linkRoutes.imports}
                  component={Imports}
                />

                <PrivateRoute exact logged={logged} path={linkRoutes.feedHuntersDetails} component={HandleFeedHunter} />
                <PrivateRoute exact logged={logged} path={linkRoutes.financialHistory} component={FinancialHistory} />
                <PrivateRoute exact logged={logged} path={linkRoutes.promoCodesCreate} component={PromoCodesCreate} />
                <PrivateRoute exact logged={logged} path={linkRoutes.customersDetails} component={CustomersDetails} />
                <PrivateRoute exact logged={logged} path={linkRoutes.feedHuntersNew} component={HandleFeedHunter} />
                <PrivateRoute exact logged={logged} path={linkRoutes.productComplements} component={Complements} />
                <PrivateRoute exact logged={logged} path={linkRoutes.categoryCreate} component={CategoryHandle} />
                <PrivateRoute exact logged={logged} path={linkRoutes.categoryUpdate} component={CategoryHandle} />
                <PrivateRoute exact logged={logged} path={linkRoutes.statistics} component={StatisticsMerchant} />
                <PrivateRoute exact logged={logged} path={linkRoutes.productsUpdate} component={ProductHandle} />
                <PrivateRoute exact logged={logged} path={linkRoutes.productsCreate} component={ProductHandle} />
                <PrivateRoute exact logged={logged} path={linkRoutes.reservesCreate} component={ReserveCreate} />
                <PrivateRoute exact logged={logged} path={linkRoutes.usagePolicies} component={UsagePolicies} />
                <PrivateRoute exact logged={logged} path={linkRoutes.bankAccounts} component={BankAccounts} />
                <PrivateRoute exact logged={logged} path={linkRoutes.postDetails} component={PostsHandle} />
                <PrivateRoute exact logged={logged} path={linkRoutes.feedHunters} component={FeedHunters} />
                <PrivateRoute exact logged={logged} path={linkRoutes.hallCreate} component={CreateTable} />
                <PrivateRoute exact logged={logged} path={linkRoutes.createTeams} component={CreateTeam} />
                <PrivateRoute exact logged={logged} path={linkRoutes.promoCodes} component={PromoCodes} />
                <PrivateRoute exact logged={logged} path={linkRoutes.settingsTeams} component={Teams} />
                <PrivateRoute exact logged={logged} path={linkRoutes.adminsDetails} component={AdminsDetails} />

                <PrivateRoute exact logged={logged} path={linkRoutes.financial} component={Financial} />
                <PrivateRoute exact logged={logged} path={linkRoutes.postNew} component={PostsHandle} />
                <PrivateRoute exact logged={logged} path={linkRoutes.settings} component={Settings} />
                <PrivateRoute exact logged={logged} path={linkRoutes.products} component={Products} />
                <PrivateRoute exact logged={logged} path={linkRoutes.reserves} component={Reserves} />
                <PrivateRoute exact logged={logged} path={linkRoutes.commands} component={Commands} />
                <PrivateRoute exact logged={logged} path={linkRoutes.reviews} component={Reviews} />
                <PrivateRoute exact logged={logged} path={linkRoutes.orders} component={Orders} />
                <PrivateRoute exact logged={logged} path={linkRoutes.post} component={Posts} />
                <PrivateRoute exact logged={logged} path={linkRoutes.hall} component={Hall} />
                <PrivateRoute exact logged={logged} path={linkRoutes.integrations} component={Integrations} />
              </>
            )}
          </Switch>
        </BrowserRouter>
      )}
    </HttpsRedirect>
  )
}
