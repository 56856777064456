import React, { useEffect, useState } from 'react'
import { HiCheck, HiPlus, HiTrash } from 'react-icons/hi'
import { toast } from 'react-toastify'

import { Button, CardBox, ContainerComponent, Input, InputSelect, Switch } from '../../../components'
import { GetOneStore, PatchStore } from '../../../services/Stores'
import { daysOfTheWeek } from '../../Settings/optionsSettings'
import { ContainerOpeningHours } from '../../Settings/styles'
import { Account } from '../../../services/Account'

const OpeningHours = () => {
  const [activeReservations, setActiveReservations] = useState(false)
  const [reservation_hours, setReservationsHours] = useState([])
  const [storeId, setStoreId] = useState(null)

  async function fetchData() {
    try {
      const { data: responseAccount } = await Account()
      const store_id = responseAccount?.data?.roles[0]?.store_id

      const { data: response } = await GetOneStore(store_id)
      const { reservation_hours, reservations } = response.data.info

      setReservationsHours(reservation_hours)
      setActiveReservations(reservations)
      setStoreId(store_id)
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar carregar as informações dessa página')
    }
  }

  async function handleUpdateStore() {
    const payload = { info: { reservations: activeReservations, reservation_hours } }

    try {
      await PatchStore({ id: storeId, payload })
      toast.success('As configurações foram atualizadas com sucesso!')
    } catch (error) {
      toast.error('Ocorreu um erro na atualização')
    }
  }

  function handleAddDayOfWeek() {
    const newOpeningHour = { time_periods: [{ start_time: undefined, end_time: undefined }], day_of_week: undefined }
    setReservationsHours([...reservation_hours, newOpeningHour])
  }

  function handleChangeDayOfWeek(indexDayOfWeek, event) {
    const { value } = event.target
    reservation_hours[indexDayOfWeek].day_of_week = value
    setReservationsHours([...reservation_hours])
  }

  function handleRemoveDayOfWeek(itemSelect) {
    const openingHourFiltered = reservation_hours.filter(item => item !== itemSelect)
    setReservationsHours(openingHourFiltered)
  }

  function handleAddTimePeriod(indexDayOfWeek) {
    reservation_hours[indexDayOfWeek]?.time_periods?.push({ start_time: undefined, end_time: undefined })
    setReservationsHours([...reservation_hours])
  }

  function handleChangeTimePeriod(indexDayOfWeek, indexTimePeriod, event) {
    const { value, name } = event.target
    reservation_hours[indexDayOfWeek]['time_periods'][indexTimePeriod][name] = value
    setReservationsHours([...reservation_hours])
  }

  function handleRemoveTimePeriod(timePeriod, indexDayOfWeek) {
    const time_periods = reservation_hours[indexDayOfWeek]?.time_periods?.filter(item => item !== timePeriod)
    reservation_hours[indexDayOfWeek].time_periods = time_periods
    setReservationsHours([...reservation_hours])
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent title="Horários de reserva" headerLeft={['Reservas', 'Horários de reserva']}>
      <CardBox customStyles={{ marginTop: '6.4rem', padding: '3.2rem' }}>
        <ContainerOpeningHours>
          <header>
            <Switch onClick={() => setActiveReservations(!activeReservations)} active={activeReservations}>
              {activeReservations ? 'Reservas habilitas' : 'Reservas desabilitas'}
            </Switch>

            <div>
              <Button
                customStyles={{ borderRadius: '1.6rem', margin: '0 1.6rem', height: '4rem' }}
                onClick={handleUpdateStore}
                icon={HiCheck}
              >
                SALVAR
              </Button>

              <Button
                customStyles={{
                  background: 'var(--color-primary-500)',
                  borderRadius: '1.6rem',
                  height: '4rem',
                  margin: 0
                }}
                onClick={handleAddDayOfWeek}
                icon={HiPlus}
              >
                ADICIONAR DIA
              </Button>
            </div>
          </header>

          <ul>
            {reservation_hours?.map((item, indexDayOfWeek) => (
              <li
                key={indexDayOfWeek}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  background: 'var(--color-primary-800)',
                  borderRadius: '1.6rem',
                  padding: '1.6rem'
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <InputSelect
                    onChange={event => handleChangeDayOfWeek(indexDayOfWeek, event)}
                    customStyles={{ color: 'var(--color-gold)', width: '16rem' }}
                    bgIcon="var(--color-primary)"
                    value={item.day_of_week}
                    name="day_of_week_start"
                    options={daysOfTheWeek}
                    label="Dia da semana"
                  />

                  <Button icon={HiTrash} onClick={() => handleRemoveDayOfWeek(item)}>
                    Remover dia
                  </Button>
                </div>

                <div>
                  {item?.time_periods?.map((timePeriod, indexTimePeriod) => (
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 18rem', gap: '1.6rem' }}>
                      <Input
                        onChange={event => handleChangeTimePeriod(indexDayOfWeek, indexTimePeriod, event)}
                        customStyles={{ color: 'var(--color-gold)' }}
                        value={timePeriod?.start_time}
                        name="start_time"
                        type="time"
                        label="Das:"
                      />

                      <Input
                        onChange={event => handleChangeTimePeriod(indexDayOfWeek, indexTimePeriod, event)}
                        customStyles={{ color: 'var(--color-gold)' }}
                        value={timePeriod?.end_time}
                        name="end_time"
                        label="Até:"
                        type="time"
                      />

                      <Button icon={HiTrash} onClick={() => handleRemoveTimePeriod(timePeriod, indexDayOfWeek)}>
                        Remover horário
                      </Button>
                    </div>
                  ))}
                </div>

                <Button
                  onClick={() => handleAddTimePeriod(indexDayOfWeek)}
                  style={{ margin: '0 auto', marginTop: '3.2rem' }}
                  icon={HiPlus}
                >
                  Adicionar hora
                </Button>
              </li>
            ))}
          </ul>
        </ContainerOpeningHours>
      </CardBox>
    </ContainerComponent>
  )
}

export { OpeningHours }
