import styled from 'styled-components'

export const Container = styled.div`
  .progressbar {
    margin-bottom: 3.2rem;

    header {
      display: flex;
      justify-content: space-between;

      margin-bottom: 1.6rem;

      strong {
        color: var(--color-gold);
      }
    }

    .p-progressbar-label {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    grid-gap: 1.6rem;

    padding: 0;
    margin: 0;
  }

  .loading {
    display: flex;
    justify-content: center;
    margin-top: 20%;
  }
`

export const TableBox = styled.li`
  list-style: none;

  .table__card {
    display: grid;
    grid-template-rows: 2fr 1fr;

    border: 0.2rem solid ${({ active }) => (active ? 'var(--color-primary-100)' : 'transparent')};
    margin-bottom: ${({ active }) => (active ? '50rem' : '0')};
    border-radius: 1.6rem;
    cursor: pointer;
    height: 10rem;

    .table__header {
      background: var(--color-primary-700);
      border-radius: 1.6rem 1.6rem 0 0;
      position: relative;

      h2 {
        margin: 1.6rem 0 0 0;
        text-align: center;
        font-size: 3.2rem;
      }

      .table__status {
        display: flex;
        justify-content: flex-end;

        position: absolute;
        top: -0.8rem;
        width: 100%;

        span {
          display: flex;
          justify-content: center;
          align-items: center;

          background: var(--color-pink);
          margin-right: 0.4rem;
          border-radius: 100%;
          height: 1.8rem;
          width: 1.8rem;

          :last-child {
            margin-right: 0;
          }

          img,
          svg {
            height: 1rem;
            width: 1rem;
          }
        }

        .paid {
          background: var(--color-green);
        }

        .open {
          background: var(--color-gold);
        }

        .birthdays {
          background: #00bcd4;
        }
      }
    }

    .table_footer {
      background: var(--color-primary-900);
      border-radius: 0 0 1.6rem 1.6rem;

      p {
        margin: 0.8rem 0 0 0;
        text-align: center;
        font-weight: 400;
      }
    }

    .table__card__span {
      height: 20rem;
      width: 100%;
    }
  }
`

export const TableSelect = styled.div`
  width: ${({ tableSelect }) => tableSelect.tableReferenceWidth};
  top: ${({ tableSelect }) => tableSelect.tableReferenceTop};
  background: var(--color-primary-700);
  border-radius: 2rem;
  position: absolute;
  margin-top: 12rem;
  padding: 3.2rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;

    .header__left {
      h3,
      p {
        margin: 0;
      }

      strong {
        color: var(--color-gold);
      }
    }

    .header__right {
      display: flex;
    }

    .button__close {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--color-pink);
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      color: white;
      height: 2rem;
      right: -2rem;
      width: 2rem;
      top: -2rem;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    grid-gap: 1.6rem;

    padding-top: 3.2rem;
  }
`

export const CardCustomer = styled.li`
  background: var(--color-primary-600);
  border-radius: 1.6rem;
  font-weight: 500;
  text-align: center;
  list-style: none;
  cursor: pointer;

  .customer__header {
    display: flex;
    flex-direction: column;

    position: relative;

    img {
      border-radius: 0.8rem;
      margin: 0.8rem;
      height: 18rem;
    }

    .balloon__waiter__requested {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--color-pink);
      margin-right: 0.4rem;
      border-radius: 100%;
      position: absolute;
      height: 3.2rem;
      width: 3.2rem;
      right: 0;
      top: -1rem;

      :last-child {
        margin-right: 0;
      }

      img {
        height: 1.6rem;
        width: 1.6rem;
      }
    }

    .span__birthday__before,
    .span__birthday__after {
      border: 2.4rem solid transparent;
      border-left-color: #00bcd4;
      transform: rotate(-45deg);
      height: 0;
      width: 0;
    }

    .span__birthday__before {
      position: absolute;
      right: 3.7rem;
      top: -2.4rem;
    }

    .span__birthday__after {
      position: absolute;
      right: -2.4rem;
      top: 3.7rem;
    }

    .span__birthday {
      display: flex;
      align-items: center;

      transform: rotate(45deg);
      background: #00bcd4;
      position: absolute;
      height: 2.4rem;
      right: -0.5rem;
      top: 2.7rem;
      z-index: 1;
    }
  }

  .customer__footer {
    padding: 0.8rem 0 0.4rem 0;

    border-top: 0.8rem solid
      ${({ status }) =>
        status === 'PAID'
          ? 'var(--color-green)'
          : status === 'PARTIALLY_PAID'
          ? 'var(--color-green)'
          : 'var(--color-gold)'};
  }

  .waiter__requested {
    border-radius: 0 0 1.6rem 1.6rem;
    background: var(--color-pink);
    padding: 1rem 0.4rem;
  }
`

export const CardCustomerInfo = styled.div`
  font-weight: 500;
  width: 50rem;
  color: white;

  strong {
    font-weight: 500;
  }

  header {
    display: flex;

    border-bottom: 0.1rem solid var(--color-primary-500);
    padding-bottom: 1.6rem;
    margin-bottom: 1.6rem;

    img {
      border-radius: 1.6rem;
      height: 10rem;
      width: 10rem;
    }

    div {
      margin-left: 1.6rem;

      h3 {
        color: var(--color-gold);
        margin: 0 0 0.8rem 0;
        font-weight: 400;
      }

      p {
        margin: 0.4rem 0 0 0;
      }
    }
  }

  main {
    overflow: auto;
    height: 40rem;

    .card__product {
      display: flex;
      align-items: center;

      padding: 0.8rem 1.6rem;
      margin: 0.8rem 0;

      :hover {
        background: var(--color-primary-700);
      }

      img {
        border-radius: 1.6rem;
        margin-right: 1.6rem;
        height: 7rem;
        width: 7rem;
      }

      h4,
      p {
        font-size: 1.8rem;
        margin: 0;
      }

      .card__product__values {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        height: 5rem;
        width: 100%;
      }

      .product__price {
        color: var(--color-gold);
        font-weight: 500;
        margin: 0;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-top: 0.1rem solid var(--color-primary-500);
    padding-top: 0.8rem;
    margin-top: 0.8rem;

    strong {
      color: var(--color-gold);
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const ContainerOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  div {
    display: flex;
    align-items: center;

    .subtitle {
      margin-right: 1.5rem;
      font-weight: 400;
      font-size: 2rem;
      color: white;
    }

    .titles {
      span {
        background-color: var(--color-pink);
        padding: 0.4rem 0.8rem;
        border-radius: 0.8rem;
        margin-right: 1.6rem;
        font-weight: 400;
      }

      span:nth-child(2) {
        background-color: var(--color-green);
      }

      span:nth-child(3) {
        background-color: var(--color-gold);
      }

      span:nth-child(4) {
        background: #00bcd4;
      }
    }
  }
`
