import styled from 'styled-components'

export const Container = styled.form`
  display: grid;
  grid-template-rows: repeat(auto, 4);
  grid-gap: 6.4rem;

  margin-bottom: 6.4rem;

  .view__image {
    display: flex;
    justify-content: center;

    margin: 3.2rem 3.2rem 0 3.2rem;
    margin-top: 3.2rem;
    position: relative;
    height: 12rem;
    width: 12rem;

    span {
      color: var(--color-gold);
      text-overflow: ellipsis;
      white-space: nowrap;
      position: absolute;
      font-weight: 400;
      overflow: hidden;
      top: -3.2rem;
    }

    img {
      border-radius: 1.6rem;
      cursor: pointer;
      height: 12rem;
      width: 12rem;
    }

    input {
      display: none;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      bottom: -1.2rem;
      right: 4.5rem;

      background: var(--color-orange);
      border-radius: 100%;
      cursor: pointer;
      border: none;
      color: white;
      height: 2rem;
      width: 2rem;
    }
  }

  .box__options {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`
