import Client from '../client'

export const PatchStore = ({ id, payload }) => Client.apiYounner.patch(`/api/stores/id/${id}`, payload)
export const PostStoresReorder = payload => Client.apiYounner.patch('/api/stores/reorder', payload)
export const GetAllStores = ({ params }) => Client.apiYounner.get('/api/stores', { params })
export const PostStores = payload => Client.apiYounner.post('/api/stores', payload)
export const DeleteStores = id => Client.apiYounner.delete(`/api/stores/id/${id}`)
export const GetOneStore = id => Client.apiYounner.get(`/api/stores/id/${id}`)
export const GetCities = () => Client.apiYounner.get('https://servicodados.ibge.gov.br/api/v1/localidades/municipios')
export const CreateIntegration = (payload) => Client.apiYounner.post('/api/stores/integration/create', {payload})
export const GetOpdvCatalog = (integration_type) => Client.apiYounner.get(`/api/stores/integration/${integration_type}/catalog`)
export const GetStoreIntegrations = () => Client.apiYounner.get('/api/stores/integration')
