import { Container } from './styles'

const TextArea = ({ customStyles, placeholder, onChange, value, label, name, register, errors }) => (
  <Container customStyles={customStyles}>
    {label}

    {!!register ? (
      <>
        <textarea
          {...register(name)}
          label={placeholder}
          onChange={onChange}
          error={errors}
          value={value}
          name={name}
        />

        {errors && errors[name] && <span>{errors[name]?.message}</span>}
      </>
    ) : (
      <>
        <textarea label={placeholder} onChange={onChange} value={value} name={name} />
      </>
    )}
  </Container>
)

export { TextArea }
