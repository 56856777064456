import React, { useEffect, useState } from 'react'
import { HiCheck, HiChevronDown, HiTrash, HiX } from 'react-icons/hi'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { ContainerComponent, ComponentModal, Button, Input, InputSelect } from '../../../components'
import { DeleteFACs, PatchFACs, PostFACs } from '../../../services/Facs'
import { clearObject } from '../../../utils'
import { categoryArray } from '../optionsFac'

import { Container } from './styles'

const FacHandle = () => {
  const history = useHistory()

  const [category, setCategory] = useState(null)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [fac, setFAC] = useState({})

  function fetchData() {
    const facHistory = history?.location?.state?.fac
    if (!facHistory) return

    setCategory(facHistory.category)
    setFAC(facHistory)
    setIsEdit(true)
  }

  async function handleCreateFAC() {
    Object.assign(fac, { category })

    try {
      await PostFACs(fac)
      toast.success('Pergunta criada com sucesso!')
      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleUpdateFAC() {
    const { id } = fac

    clearObject(fac, ['created_at', 'category_format', 'created_at_format', 'id'])
    Object.assign(fac, { category })

    try {
      await PatchFACs(id, fac)
      toast.success('Pergunta atualizada com sucesso!')
      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleDeleteFAC() {
    try {
      await DeleteFACs(fac.id)
      toast.success('Pergunta excluída com sucesso!')
      setOpenModalDelete(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setFAC(prevState => ({ ...prevState, [name]: value }))
  }

  useEffect(() => fetchData(), [])

  return (
    <>
      <ComponentModal onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <h3>Pergunta criada com sucesso!</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <ComponentModal onRequestClose={() => setOpenModalDelete(!openModalDelete)} isOpen={openModalDelete}>
        <h3>Pergunta foi removida com sucesso!</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <ContainerComponent
        headerLeft={['Dúvidas', isEdit ? 'Detalhes' : 'Criar nova pergunta']}
        title={isEdit ? 'Detalhes' : 'Criar nova pergunta'}
      >
        <Container>
          <Input
            customStyles={{ color: 'var(--color-gold)' }}
            placeholder="Insira aqui a pergunta"
            onChange={handleChangeState}
            label="Pergunta"
            value={fac?.title}
            name="title"
          />

          <InputSelect
            onChange={event => setCategory(event.target.value)}
            customStyles={{ color: 'var(--color-gold)' }}
            bgIcon="var(--color-primary)"
            options={categoryArray}
            icon={HiChevronDown}
            label="Categoria"
            value={category}
          />

          <div className="box__description">
            <span>Descrição</span>

            <textarea placeholder="Insira aqui a resposta" onChange={handleChangeState} name="text" value={fac.text} />
          </div>

          <div className="box__options">
            <div>
              <Button
                onClick={isEdit ? handleDeleteFAC : () => history.goBack()}
                icon={isEdit ? HiTrash : HiX}
                customStyles={{
                  background: 'var(--color-primary-500)',
                  borderRadius: '1.6rem',
                  marginRight: '1.6rem',
                  height: '4rem',
                  width: '20rem'
                }}
              >
                {isEdit ? 'REMOVER' : 'CANCELAR'}
              </Button>

              <Button
                customStyles={{ borderRadius: '1.6rem', width: '14rem', height: '4rem' }}
                onClick={isEdit ? handleUpdateFAC : handleCreateFAC}
                icon={HiCheck}
              >
                {isEdit ? 'SALVAR' : 'CRIAR'}
              </Button>
            </div>
          </div>
        </Container>
      </ContainerComponent>
    </>
  )
}

export { FacHandle }
