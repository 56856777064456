import styled from 'styled-components'

export const Container = styled.div`
  border: 0.1rem solid var(--color-primary-500);
  background: var(--color-primary-600);
  border-radius: 1.6rem;
  padding: 3.2rem;

  section {
    &:not(:first-child) {
      margin-top: 6.4rem;
    }

    h2 {
      margin: 0 auto;
      width: 100%;
    }

    p {
    }
  }
`
