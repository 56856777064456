import React, { useEffect, useState } from 'react'
import { HiPlus, HiQuestionMarkCircle } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, CardBox, ContainerComponent, linkRoutes } from '../../components'
import { GetAllFACs } from '../../services/Facs'
import { formatDateHour } from '../../utils'

import { Container } from './styles'

const FACs = () => {
  const history = useHistory()

  const [facs, setFACs] = useState([])

  async function fetchData() {
    try {
      const { data: response } = await GetAllFACs({})

      const facsFormatted = response.data.map(item => ({
        ...item,
        category_format: item.category === 'MERCHANT' ? 'Restaurante' : 'Usuário',
        created_at_format: formatDateHour(item.created_at)
      }))

      setFACs(facsFormatted)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleShowFAC(event) {
    history.push(linkRoutes.facDetails, { fac: event.data })
  }

  const ColumnCategory = value => <div className="row__datatable centralized">{value.category_format}</div>
  const ColumnDate = value => <div className="row__datatable centralized">{value.created_at_format}</div>
  const ColumnText = value => <div className="row__datatable">{value.text}</div>

  const ColumnTitle = value => (
    <div className="row__datatable">
      <HiQuestionMarkCircle />
      {value.title}
    </div>
  )

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      title="Dúvidas"
      headerRight={
        <Link to={linkRoutes.facAdd}>
          <Button customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '25rem' }} icon={HiPlus}>
            CRIAR NOVA PERGUNTA
          </Button>
        </Link>
      }
    >
      <Container>
        <CardBox label="Ajuda">
          <DataTable onRowClick={handleShowFAC} value={facs}>
            <Column body={ColumnTitle} header="Título" />
            <Column body={ColumnText} header="Texto" sortable field="text" />
            <Column body={ColumnCategory} header="Categoria" sortable field="category_format" />
            <Column body={ColumnDate} header="Data de criação" sortable field="created_at_format" />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { FACs }
