import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 1.4rem;
  font-weight: 300;
  height: 8.5rem;

  ${({ customStyles }) => customStyles}

  .box__input {
    display: flex;
    align-items: center;

    box-shadow: inset 0 0.5rem 1rem #00000020;
    border: 0.1rem solid #e6ebef;
    padding: 0.8rem 1.6rem;
    border-radius: 0.4rem;
    margin: auto 0 0 0;
    background: white;

    position: relative;

    input {
      color: var(--color-grey);
      background: transparent;
      outline: none;
      height: 2rem;
      border: none;
      color: black;
      width: 100%;
    }

    .box__suggestions {
      border: 0.1rem solid #e6ebef;
      border-radius: 0.4rem;
      max-height: 20rem;
      background: white;
      overflow: auto;
      color: black;
      width: 100%;
      padding: 0;

      position: absolute;
      z-index: 1000;
      top: 4.2rem;
      left: 0;

      li {
        padding: 0.8rem 1.6rem;
        list-style-type: none;

        &:hover {
          background: var(--color-pink);
          font-weight: 400;
          color: white;

          cursor: pointer;
        }
      }
    }

    .box__no__suggestions {
      border: 0.1rem solid #e6ebef;
      border-radius: 0.4rem;
      font-weight: 500;
      position: absolute;
      background: white;
      padding: 0.8rem 0;
      color: black;
      width: 100%;
      top: 4.2rem;
      left: 0;
    }
  }

  span {
    color: var(--color-pink);
    margin-top: 0.4rem;
    font-size: 1.2rem;
    height: 1.2rem;
  }
`
