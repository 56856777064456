import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-primary: #071522;
    --color-primary-100: #ffffff90;
    --color-primary-200: #ffffff80;
    --color-primary-300: #ffffff70;
    --color-primary-400: #ffffff60;
    --color-primary-500: #ffffff50;
    --color-primary-600: #ffffff40;
    --color-primary-700: #ffffff30;
    --color-primary-800: #ffffff20;
    --color-primary-900: #ffffff10;

    --color-grey: #c4c4c4;
    --color-grey-100: #c4c4c490;
    --color-grey-200: #c4c4c480;
    --color-grey-300: #c4c4c470;
    --color-grey-400: #c4c4c460;
    --color-grey-500: #c4c4c450;
    --color-grey-600: #c4c4c440;
    --color-grey-700: #c4c4c430;
    --color-grey-800: #c4c4c420;
    --color-grey-900: #c4c4c410;

    --color-orange: #e04949;
    --color-orange-100: #e0494990;
    --color-orange-200: #e0494980;
    --color-orange-300: #e0494970;
    --color-orange-400: #e0494960;
    --color-orange-500: #e0494950;
    --color-orange-600: #e0494940;
    --color-orange-700: #e0494930;
    --color-orange-800: #e0494920;
    --color-orange-900: #e0494910;

    --color-green: #56a65e;
    --color-green-500: #56a65e50;

    --color-pink: #d60747;
    --color-pink-100: #d6074790;
    --color-pink-200: #d6074780;
    --color-pink-300: #d6074770;
    --color-pink-400: #d6074760;
    --color-pink-500: #d6074750;
    --color-pink-600: #d6074740;
    --color-pink-700: #d6074730;
    --color-pink-800: #d6074720;
    --color-pink-900: #d6074710;

    --color-gold: #bfa767;
    --color-gold-500: #bfa76750;

    --color-cinza-100: #4d5760;
    --color-cinza-200: #39444e;
    --color-cinza-300: #717980;
    --color-white: #fff;
  }

  html {
    font-size: 68.5%;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  body {
    background: #071522;
    font-size: 1.6rem;
    font-weight: 200;
    color: #fff;
    margin: 0;

    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
      object-fit: cover;
    }
  }

  #root {
    display: flex;
    justify-content: center;

    height: 100%;
    width: 100%;
  }

  .container-layout {
    margin-left: 20rem;
    height: 100vh;
    width: 100vw;
  }

  .filepond--credits {
      display: none;
  }

  .React-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9999;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  table {
    border-radius: 1.6rem;
    padding: 1.6rem;
    width: 100%;

    thead {
      tr {
        height: 4rem;

        th {
          border-bottom: 0.1rem solid var(--color-primary-400);
          padding: 0 0.5rem;
          margin: 0;

          .p-column-header-content{
            display: flex;
            justify-content: center;
          }

          :nth-child(even) {
            background: var(--color-primary-800);
          }

          :nth-child(odd) {
            background: var(--color-primary-500);
          }

          :first-child {
            border-radius: 1rem 0 0 0;
          }

          :last-child {
            border-radius: 0 1rem 0 0;
          }
        }
      }
    }

    tbody {
      tr {
        height: 5rem;

        :nth-child(even) {
          background: var(--color-primary-800);
        }

        :nth-child(odd) {
          background: var(--color-primary-500);
        }

        :hover {
          border-left: 0.4rem solid var(--color-gold);
          background: var(--color-gold-500);
        }

        td {
          .p-row-toggler-icon {
            color: white;
          }
          line-break: auto;
        }
      }
    }

    .p-row-expanded {
      thead {
        tr {
          th {
            border-radius: 0;
          }
        }
      }
    }
  }

  .p-paginator {
    font-weight: 500;
    position: fixed;
    bottom: 1rem;
    right: 40%;

    span,
    .p-paginator-page {
      margin: 0.2rem;
      color: white;
    }
  }

  .p-datepicker-group-container {
    color: var(--color-primary);
    border-radius: 0.2rem;
    background: #ffffff;
    font-size: 1.6rem;
    padding: 0.8rem;

    .p-datepicker-header {
      border-bottom: 0.1rem solid var(--color-grey-100);
      padding-bottom: 1.6rem;
      margin-bottom: 1.6rem;

      .p-link {
        color: var(--color-pink);
        border-radius: 50%;
        padding: 0.4rem;

        :hover {
          background: var(--color-pink);
          font-weight: 500;
          color: white;
        }
      }

      .p-datepicker-title {
        font-weight: 500;

        .p-datepicker-month {
          margin-right: 0.8rem;
        }
      }
    }

    .p-datepicker-calendar {
      thead {
        background: var(--color-primary-900);
      }

      tbody {
        tr {
          td {
            padding: 0.4rem;

            span {
              display: flex;
              justify-content: center;
              align-items: center;

              border-radius: 100%;
              height: 3.2rem;
              width: 3.2rem;

              :hover {
                background: var(--color-pink);
                font-weight: 500;
                color: white;
              }
            }

            .p-disabled {
              color: var(--color-grey-100);
            }
          }
        }
      }
    }
  }

  .p-progressbar {
    display: flex;

    background: var(--color-primary-500);
    border-radius: 0.8rem;
    height: 1.6rem;
    width: 100%;

    .p-progressbar-value {
      border-radius: 0.8rem 0 0 0.8rem;
      background: var(--color-pink);
    }

    .p-progressbar-label {
      border-radius: 0 0.8rem 0.8rem 0;
      font-weight: normal;
      font-weight: normal;
      font-size: 0.8rem;
      color: #fff;
    }
  }

  .p-datatable .p-sortable-column .p-column-title, .p-datatable .p-sortable-column .p-sortable-column-icon, .p-datatable .p-sortable-column .p-sortable-column-badge {
  vertical-align: middle;
  margin-left: auto;
  }

  @media (max-width: 1664px) {
    html {
      font-size: 60%;
    }
  }

  @media (max-width: 1370px) {
    html {
      font-size: 54%;
    }
  }

  @media (max-width: 1260px) {
    html {
      font-size: 50%;
    }
  }

  @media (max-width: 1200px) {
    html {
      font-size: 50%;
    }
  }

  *::-webkit-scrollbar {
    height: 0.2rem;
    width: 0.2rem;
  }

  *::-webkit-scrollbar-track {
    background: #071522;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #d60747;
    border-radius: 2rem;
  }
`
