import styled from 'styled-components'

export const Container = styled.div`
  border-right: 0.1rem solid var(--color-primary-800);
  position: fixed;
  height: 100vh;
  width: 20rem;
  left: 0;
  .logo {
    margin: 3.2rem 0 1.6rem 1.6rem;
    img {
      width: 7.5rem;
    }
  }
  .container__menu {
    #active {
      color: var(--color-gold);
    }
    ul {
      font-weight: 400;
      font-size: 1.6rem;
      padding: 0;
      margin: 0;
      li {
        min-height: 4rem;
        a,
        span {
          text-decoration: none;
          cursor: pointer;
          color: white;
          .menu {
            display: flex;
            align-items: center;
            padding: 0 0.8rem;
            list-style: none;
            height: 4rem;
            .title {
              margin-left: 0.8rem;
              width: 100%;
            }
            :hover {
              background: var(--color-gold-500);
            }
          }
        }
        .submenu {
          .subtitle {
            display: flex;
            align-items: center;
            padding: 0 0.8rem 0 3.2rem;
            min-height: 4rem;
            p {
              margin: 0;
              margin-left: 0.8rem;
              width: 100%;
            }
            :hover {
              background: var(--color-gold-500);
            }
          }
        }
      }
    }
  }
`
