export const linkRoutes = {
  createNewGroup: '/products/complements/create/group',
  productComplements: '/products/complements',
  categoryCreate: '/products/category/create',
  categoryUpdate: '/products/category/update',
  productsCreate: '/products/create',
  productsUpdate: '/products/update',
  category: '/products/category',
  imports: '/products/imports',
  products: '/products',
  
  postDetails: '/mural/details',
  postNew: '/mural/new',
  post: '/mural',

  createTeams: '/settings/teams-create',
  settingsTeams: '/settings/teams',
  settings: '/settings',

  loyaltyProgramsDetails: '/loyalty-programs/details',
  loyaltyPrograms: '/loyalty-programs',

  feedHuntersDetails: '/feedhunters/details',
  feedHuntersNew: '/feedhunters/new',
  feedHunters: '/feedhunters',

  bankAccountsNew: '/financial/bank-accounts/new',
  bankAccounts: '/financial/bank-accounts',

  financialExtracts: '/financial/extracts',
  financialHistory: '/financial/history',
  financial: '/financial',

  customersDetails: '/customers/list/details',
  customersList: '/customers/list',
  customers: '/customers',

  promoCodesCreate: '/promoCodes/create',
  promoCodes: '/promoCodes',

  reservesOpeningHours: '/reserves/opening-hours',
  reservesCreate: '/reserves/create',
  reserves: '/reserves',

  hallCreate: '/hall/create',
  hall: '/hall',

  integrations: '/integrations',

  restaurantsDetails: '/restaurants/list/details',
  restaurantsApprovals: '/restaurants/approvals',
  restaurantsCreate: '/restaurants/create',
  restaurantsList: '/restaurants/list',
  restaurants: '/restaurants',

  cousinesDetails: '/cousines/details',
  cousinesNew: '/cousines/new',
  cousines: '/cousines',

  notificationsDetails: '/notifications/details',
  notificationsNew: '/notifications/new',
  notifications: '/notifications',

  adminsIncludeCollaborator: '/admins/include-collaborator',
  adminsDetails: '/admins/details',
  adminsAdd: '/admins/add',
  admins: '/admins',

  facPrivacy: '/fac/privacy',
  facDetails: '/fac/details',
  facAdd: '/fac/add',
  fac: '/fac',

  forgotPassword: '/forgot-password',
  usagePolicies: '/usage-policies',
  firstAccess: '/first-access',
  statistics: '/statistics',
  commands: '/commands',
  reviews: '/reviews',
  orders: '/orders',
  login: '/'
}
