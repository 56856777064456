import React, { useEffect, useState } from 'react'
import { HiCheck, HiChevronDown } from 'react-icons/hi'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { ContainerComponent, ComponentModal, Button, AutoComplete, CheckBox } from '../../../components'
import notImage from '../../../assets/images/notImage.png'

import { Container } from './styles'

const AdminsIncludeCollaborator = () => {
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)
  const [collaborators, setCollaborators] = useState([])
  const [users, setUsers] = useState([])

  async function fetchData() {
    try {
      // const { data: response } = await getUser()
      // setUsers(response.data)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleSavePermissions() {
    try {
      toast.success('Colaboradores incluídos com sucesso!')
      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent title="Incluir colaborador" headerLeft={['Permissões', 'Adicionar usuário']}>
      <ComponentModal onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <h3>Colaboradores incluídos com sucesso!</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <Container>
        <header>
          <AutoComplete
            customStyles={{ color: 'var(--color-gold)' }}
            bgIcon="var(--color-primary)"
            label="Incluir colaborador"
            icon={HiChevronDown}
            options={users}
          />
        </header>

        <ul>
          <li>
            <header>
              <CheckBox customStyles={{ marginLeft: '1.6rem' }}>Selecionar colaborador</CheckBox>
            </header>

            <main>
              <img src={notImage} alt="" />
            </main>

            <footer>
              <h2>John Doe</h2>
              john@feedme.com
            </footer>
          </li>
        </ul>

        <footer>
          <Button
            customStyles={{ borderRadius: '1.6rem', width: '14rem', height: '4rem' }}
            onClick={handleSavePermissions}
            icon={HiCheck}
          >
            INCLUIR
          </Button>
        </footer>
      </Container>
    </ContainerComponent>
  )
}

export { AdminsIncludeCollaborator }
