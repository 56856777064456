import { HiChevronRight } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { NavMenu } from '../MenuSideBar';

import { Container } from './styles';

const ContainerComponent = ({ children, title, headerLeft, headerRight }) => {
  const history = useHistory();

  function formatNavigation(texts) {
    if (!texts) return;
    const totalArray = texts.length - 1;

    return texts.map((item, index) => (
      <>
        {index === totalArray - 1 ? (
          <button onClick={() => history.goBack()}>{item}</button>
        ) : index === totalArray ? (
          <strong>{item}</strong>
        ) : (
          <p>{item}</p>
        )}
        {index < totalArray && <HiChevronRight />}
      </>
    ));
  }

  return (
    <Container>
      <aside>
        <NavMenu />
      </aside>

      <main>
        <div className="header__main">
          <div className="headerLeft">
            <div className="header__navigate">{formatNavigation(headerLeft)}</div>
            <h1 className="tittle">{title}</h1>
          </div>
          {headerRight}
        </div>

        {children}
      </main>
    </Container>
  );
};

export default ContainerComponent;
