export const options = [
  {
    label: 'Teste 1',
    value: 'teste 1'
  },
  {
    label: 'Teste 2',
    value: 'teste 2'
  },
  {
    label: 'Teste 3',
    value: 'teste 3'
  }
]

export const typesUse = [
  { label: 'Apenas primeiro pedido', value: 'FIRST_USE' },
  { label: 'Apenas primeiro uso', value: 'FIRST_USE' },
  { label: 'Pedidos APP', value: 'NORMAL' }
]

export const typesOrigins = [
  { label: 'Qualquer um', value: 'EITHER' },
  { label: 'Presencial', value: 'INDOOR' },
  { label: 'Delivery', value: 'DELIVERY' }
]

export const optionsProductsTypes = [
  { label: 'Drink', value: 'DRINK' },
  { label: 'Food', value: 'FOOD' }
]

export const optionsReservesTypes = [
  { label: 'Todas', value: undefined },
  { label: 'Canceladas', value: 'CANCELLED' },
  { label: 'Confirmadas', value: 'CONFIRMED' },
  { label: 'A responder', value: 'CREATED' },
  // { label: 'Não avaliados', value: 'UNAVAILABLE' }
]

export const optionsProductsIcons = [
  {
    label: 'Água Mineral',
    value:
      'https://firebasestorage.googleapis.com/v0/b/feedme-cb616.appspot.com/o/agua.png?alt=media&token=131e9347-df05-4cad-849d-2fe888bfcb01'
  },
  {
    label: 'Chopp',
    value:
      'https://firebasestorage.googleapis.com/v0/b/feedme-cb616.appspot.com/o/chopp.png?alt=media&token=d1a42565-1031-453f-993c-a125fbd599e4'
  },
  {
    label: 'Refrigerante',
    value:
      'https://firebasestorage.googleapis.com/v0/b/feedme-cb616.appspot.com/o/refri.png?alt=media&token=7b9836a2-1bd6-4bfa-a173-c2be517510e4'
  },
  {
    label: 'Suco',
    value:
      'https://firebasestorage.googleapis.com/v0/b/feedme-cb616.appspot.com/o/suco.png?alt=media&token=979c6ec2-3364-4516-b8fa-f6c281a57c65'
  },
  {
    label: 'Vinho',
    value:
      'https://firebasestorage.googleapis.com/v0/b/feedme-cb616.appspot.com/o/vinho.png?alt=media&token=be31761a-d5a0-4fd0-96c5-f4a990b1e954'
  }
]
