import React, { useEffect, useState } from 'react'
import { HiCheck, HiStar } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { useHistory } from 'react-router-dom'
import { Column } from 'primereact/column'

import { Button, CardBox, ContainerComponent, InputSelect, linkRoutes } from '../../components'
import { GetReviews } from '../../services/Reviews'
import { formatDateHour } from '../../utils'

import { Container } from './styles'
import { GetAllStores } from '../../services/Stores'

const Reviews = () => {
  const history = useHistory()

  const [params, setParams] = useState({ offset: 0, limit: 50 })
  const [listReviews, setListReviews] = useState([])
  const [isFetched, setIsFetched] = useState(false)
  const [listStores, setListStores] = useState([])

  async function fetchData() {
    try {
      if (!isFetched) {
        const { data: getStores } = await GetAllStores({})

        const storesFormatted = getStores?.data?.results?.map(store => ({ label: store.name, value: store.id }))

        setListStores(storesFormatted)
        setIsFetched(true)
      }

      const { data: getReviews } = await GetReviews(params)
      const reviewsFormatted = getReviews?.data?.map(item => ({ ...item, created_at: formatDateHour(item.created_at) }))

      setListReviews(reviewsFormatted)
    } catch (error) {
      console.log(error?.response?.data)
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setParams(prevState => ({ ...prevState, [name]: value }))
  }

  function handleShowCustomer(event) {
    const customer = event.data.customer
    Object.assign(customer, { isNotAdmin: true })
    history.push(linkRoutes.customersDetails, { customer })
  }

  const ColumnRating = value => (
    <div className="row__datatable">
      <HiStar />
      <p>{value.rating}</p>
    </div>
  )

  const ColumnUser = value => (
    <div className="row__datatable">
      <p>{value?.customer?.name}</p>
    </div>
  )

  const ColumnDescription = value => (
    <div className="row__datatable">
      <p>{value.text}</p>
    </div>
  )

  const ColumnDate = value => (
    <div className="row__datatable">
      <p>{value.created_at}</p>
    </div>
  )

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent title="Avaliações">
      <Container>
        <div className="filter">
          <InputSelect
            label="Buscar por restaurante"
            onChange={handleChangeState}
            options={listStores}
            name="store_id"
          />

          <Button
            customStyles={{ marginTop: '2.8rem', fontSize: '1.2rem', height: '3.2rem', width: '16rem' }}
            onClick={fetchData}
            iconRight={HiCheck}
          >
            Aplicar
          </Button>
        </div>

        <CardBox>
          <DataTable onRowClick={handleShowCustomer} value={listReviews} dataKey="id">
            <Column header="Nota" body={ColumnRating} field="rating" sortable />
            <Column header="Usuário" body={ColumnUser} field="customer.name" sortable />
            <Column header="Avaliação escrita" body={ColumnDescription} field="text" sortable />
            <Column header="Data" body={ColumnDate} field="created_at" sortable />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { Reviews }
