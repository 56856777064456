import styled from 'styled-components'

export const Container = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem;

  .box__options {
    display: flex;
    justify-content: flex-end;
    grid-column: 1/3;

    width: 100%;
  }
`
