import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 6.4rem;

  .row__datatable {
    display: flex;
    align-items: center;

    padding-left: 1.6rem;
    cursor: pointer;
    height: 4.9rem;

    svg {
      background: var(--color-pink);
      border-radius: 0.4rem;
      margin-right: 0.8rem;
      padding: 0.4rem;
      height: 1.6rem;
      width: 1.6rem;
    }
  }

  .centralized {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
