import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'
import { HiCheck } from 'react-icons/hi'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { ContainerComponent, ComponentModal, Button, Input } from '../../../components'
import { PostAdmin } from '../../../services/Admins'

import { Container } from './styles'

const phoneRegex = /^(\d{1,2})?[- ]?(\d{1,5})?(\d{1,4})?(.*)?$/

const schema = yup.object().shape({
  email: yup.string().required('Você precisa informar o email.').email('Você precisa inserir um email válido.'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
  fullname: yup.string().required('Você precisa informar nome.'),
  phone: yup
    .string()
    .matches(phoneRegex, 'Informe um número de telefone válido (XX) XXXXX-XXXX')
    .required('Informe o número de telefone'),
  password: yup
    .string()
    .min(6, 'Você precisa informar uma senha de no mínimo 6 dígitos.')
    .when('oldPassword', (oldPassword, field) => (oldPassword ? field.required() : field))
})

const AdminsAdd = () => {
  const { formState, handleSubmit, register } = useForm({ resolver: yupResolver(schema) })
  const { errors } = formState
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)

  async function handleAddAdmin({ fullname, password, phone, email }) {
    try {
      await PostAdmin({ fullname, password, phone: phone.replace(/\D/g, ''), email, permissions: { a: 1 } })
      toast.success('Administrador adicionado com sucesso!')
      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  return (
    <ContainerComponent title="Adicionar administrador" headerLeft={['Permissões', 'Adicionar administrador']}>
      <ComponentModal onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <h3>Administrador adicionado com sucesso!</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <Container onSubmit={handleSubmit(handleAddAdmin)}>
        <Input
          customStyles={{ color: 'var(--color-gold)', gridColumn: '1/3' }}
          placeholder="insira o nome completo do colaborador"
          label="Nome completo do colaborador"
          register={register}
          errors={errors}
          name="fullname"
        />

        <Input
          customStyles={{ color: 'var(--color-gold)' }}
          placeholder="Insira o telefone"
          register={register}
          errors={errors}
          label="Telefone"
          name="phone"
        />

        <Input
          customStyles={{ color: 'var(--color-gold)' }}
          placeholder="Insira o e-mail"
          register={register}
          errors={errors}
          label="E-mail"
          name="email"
        />

        <Input
          placeholder="Insira uma senha de acesso para o colaborador"
          customStyles={{ color: 'var(--color-gold)' }}
          register={register}
          errors={errors}
          name="password"
          type="password"
          label="Senha"
        />

        <Input
          placeholder="Insira uma senha de acesso para o colaborador"
          customStyles={{ color: 'var(--color-gold)' }}
          name="confirmPassword"
          register={register}
          errors={errors}
          type="password"
          label="Senha"
        />
        <div className="box__options">
          <Button
            customStyles={{ borderRadius: '1.6rem', width: '14rem', height: '4rem' }}
            icon={HiCheck}
            type="submit"
          >
            ADICIONAR
          </Button>
        </div>
      </Container>
    </ContainerComponent>
  )
}

export { AdminsAdd }
