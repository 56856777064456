import { toast } from 'react-toastify'

import firebase from '../../services/firebaseClient'
import { resizeImage } from '../resizeImage'

export async function uploadImageFirebase(setStateCallback, state, file, simpleState = false) {
  const fileName = `${new Date().getTime()}-${file.name}`
  const imageCompressed = await resizeImage({ file })

  const uploadTask = firebase.storage().ref(`images/${fileName}`).put(imageCompressed)

  uploadTask.on(
    'state_changed',
    snapshot => {},
    error => {
      toast.error('Não foi possível salvar a imagem!')
    },
    () => {
      firebase
        .storage()
        .ref('images')
        .child(fileName)
        .getDownloadURL()
        .then(downloadURL => {
          if (!simpleState) {
            setStateCallback(prevState => ({ ...state, image_url: downloadURL }))
          } else {
            setStateCallback(downloadURL)
          }
        })
    }
  )
}
