import styled from 'styled-components'

export const Container = styled.div`
  .card__container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1.6rem;

    margin-bottom: 3.2rem;

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;

      header {
        display: flex;
        align-items: center;

        width: 100%;

        svg {
          background: var(--color-pink);
          border-radius: 0.4rem;
          margin-right: 0.8rem;
          padding: 0.4rem;
          height: 1.6rem;
          width: 1.6rem;
        }
      }

      h2 {
        color: var(--color-gold);
        font-size: 3.2rem;
        margin: 1.6rem 0;
      }
    }
  }

  .row__datatable {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0.6rem 1rem;
    margin-left: 1.6rem;

    span {
      padding: 0.4rem 0.8rem;
      border-radius: 1rem;
      font-size: 1.6rem;
    }
  }

  .centralized {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
  }
`
