export default function formatterCPF (raw) {
  const numberCleaned = ('' + raw).replace(/\D/g, '')
  const regex = /^(\d{1,3})?[- ]??[\s]?(\d{1,3})?[\s]?(\d{1,3})?(.*)?$/
  const match = numberCleaned.match(regex)

  for (let i = 1; i <= 4; i++) {
    if (!match[i]) match[i] = ''
  }

  if (!match[1] || numberCleaned.length < 3) return raw
  if (match[1] && !match[2]) return `${match[1]}.`
  if (match[2] && !match[3]) return `${match[1]}.${match[2]}${numberCleaned.length < 6 ? '' : '.'}`
  if (match[3] && !match[4]) return `${match[1]}.${match[2]}.${match[3]}${numberCleaned.length < 9 ? '' : '-'}`

  return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`
}
