import * as yup from 'yup'

const emailSchema = yup.object().shape({ email: yup.string().required('Campo obrigatório').email('Email inválido') })

const tokenSchema = yup
  .object()
  .shape({ token: yup.string().required('Campo obrigatório').length(5, 'Informe os 5 dígitos enviados para o email') })

const schemeChangePassword = yup.object().shape({
  confirm_password: yup.string().oneOf([yup.ref('password'), null], 'As senhas não conferem'),
  password: yup
    .string()
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula')
    .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula')
    .matches(/[0-9]/, 'A senha deve conter pelo menos um número')
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .required('Campo obrigatório')
})

export { schemeChangePassword, tokenSchema, emailSchema }
