import React, { useState, useEffect } from 'react'
import { ContainerComponent, InputSelect, Input, Button, CardBox } from '../../components'
import { HiChevronDown, HiCheck, HiPlus } from "react-icons/hi";
import styled from 'styled-components'
import { CreateIntegration } from '../../services/Stores';
import { toast } from "react-toastify";
import { GetStoreIntegrations } from '../../services/Stores';

export const Container = styled.div`
  border: 0.1rem solid var(--color-primary-500);
  background: var(--color-primary-600);
  border-radius: 1.6rem;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;


  section {
    &:not(:first-child) {
      margin-top: 6.4rem;
    }

    h2 {
      margin: 0 auto;
      width: 100%;
    }

    p {
    }
  }
`


const Integrations = () => {
    const [showNewForm, setShowNewForm] = useState(false)
    const [integrations, setIntegrations] = useState(null)
    const [selectedType, setSelectedType] = useState('')
    const [tokenInput, setTokenInput] = useState('')

    useEffect(() => {
        fetchData()
    }, [])

    async function fetchData() {
        const { data } = await GetStoreIntegrations()
        setIntegrations(data.data)
    }

    async function handleSubmit() {
        try {
            const create = await CreateIntegration({ integration_type: selectedType, token: tokenInput })
            toast.success("Integração adicionada com sucesso")
            setShowNewForm(false)
            setSelectedType('')
            setTokenInput('')
            fetchData()
        } catch (e) {
            toast.error("Aconteceu um erro ao adicionar a integração")
            console.log(e)
        }
    }

    return (
        <ContainerComponent title="Integrações">
            {integrations?.length > 0 &&
                <CardBox
                    style={{ marginBottom: 20 }}
                >
                    <p
                        style={{ fontSize: 25, marginTop: 5 }}
                    >Integrações ativas:</p>
                    <img
                        style={{ width: '10rem', borderRadius: 15 }}
                        src={"https://cnd.opdv.com.br/wp-content/uploads/2023/02/OPDV-Logotipo-02-300x300.png"} alt="OPDV" />
                </CardBox>

            }

            {showNewForm &&
                <Container style={{ marginTop: 20 }}>
                    <InputSelect
                        customStyles={{ color: "var(--color-gold)" }}
                        bgIcon="var(--color-primary)"
                        icon={HiChevronDown}
                        options={[{ value: "opdv", label: "OPDV" }]}
                        name="integration_type"
                        label="Tipo"
                        onChange={(e) => setSelectedType(e.target.value)}
                        value={selectedType}
                    />

                    {(selectedType === 'opdv' && integrations?.length > 0)
                        ? <p>Já existe uma integração OPDV ativa para este restaurante.</p>
                        : <>
                            <Input
                                customStyles={{ color: "var(--color-gold)", marginTop: 10 }}
                                name="neighborhood"
                                label="Token OPDV"
                                value={tokenInput}
                                onChange={(e) => setTokenInput(e.target.value)} />

                            <Button
                                customStyles={{ borderRadius: "1.6rem", height: "4rem", width: '30rem', alignSelf: 'flex-end' }}
                                icon={HiCheck}
                                onClick={handleSubmit}
                            >
                                SALVAR
                            </Button>
                        </>
                    }
                </Container>
            }
            {!showNewForm && <Button
                customStyles={{
                    background: "var(--color-primary-500)",
                    borderRadius: "1.6rem",
                    height: "4rem",
                    marginTop: 20,
                    width: '100%',
                    justfiSelf: 'center'
                }}
                onClick={() => setShowNewForm(true)}
                icon={HiPlus}
            >
                NOVA INTEGRAÇÃO
            </Button>}
        </ContainerComponent>
    )
}

export { Integrations }
