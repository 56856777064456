import styled from 'styled-components'

export const Container = styled.div`
  .box__options {
    display: flex;
    justify-content: flex-end;
    grid-column: 1 / 3;
  }

  .flex {
    display: flex;
  }
`
