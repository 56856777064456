import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-rows: 20rem 30rem 35rem;
  grid-gap: 6.4rem;

  margin-top: 6.4rem;
  .card__box {
    border: 0.1rem solid var(--color-primary-500);
    border-radius: 1.6rem;
    background: #43505b;
    position: relative;
    min-height: 1rem;
    padding: 1.6rem;

    label {
      position: absolute;
      font-weight: 400;
      font-size: 2.2rem;
      top: -3.2rem;
      left: 0;
    }

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        svg {
          background: var(--color-pink);
          border-radius: 0.4rem;
          margin-right: 0.8rem;
          padding: 0.4rem;
          height: 1.6rem;
          width: 1.6rem;
        }
      }
    }

    main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin: 0 auto;
      height: 100%;
      width: 100%;
      h3 {
        color: var(--color-gold);
        font-weight: 500;
        font-size: 3.2rem;
        margin: 0.8rem;
      }
      p {
        border-top: 0.1rem solid var(--color-primary-500);
        padding: 1.6rem 0 0 0;
        margin: 1.6rem 0 0 0;
        font-weight: 500;
      }
    }

    .total__users {
      font-size: 6rem;
      margin-bottom: 0;
    }
  }

  .amount__up {
    border-left: 0.8rem solid var(--color-green);
    header {
      span {
        :nth-child(2n) {
          svg {
            background: var(--color-green);
          }
        }
      }
    }
  }

  .amount__down {
    border-left: 0.8rem solid var(--color-pink);
  }

  .box__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.6rem;

    .cards__statistics__right {
      grid-column: 1 / 5;
    }
    
    .cards__statistics__full {
      grid-column: 1 / 5;
      margin-bottom: 5rem;
      .platforms__labels {
        display: flex;
        p {
          display: flex;
          align-items: center;

          margin: 0 3.2rem 0 0;
          font-weight: 400;
          ::before {
            background: var(--color-pink);
            margin-right: 1rem;
            display: block;
            height: 0.8rem;
            content: '';
            width: 3rem;
          }
          :nth-child(2n) {
            ::before {
              background: var(--color-gold);
            }
          }
        }
      }
    }
  }
`
