import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Wrapper } from './styles';

import { ClipLoader } from 'react-spinners';

export const Loading = () => {
  return (
    <div className="card">
      <div className="custom-skeleton p-p-4">
        <div className="p-d-flex p-mb-3">
          <Skeleton shape="circle" size="4rem" className="p-mr-2"></Skeleton>
          <div>
            <Skeleton width="10rem" className="p-mb-2"></Skeleton>
            <Skeleton width="5rem" className="p-mb-2"></Skeleton>
            <Skeleton height=".5rem"></Skeleton>
          </div>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
        <div className="p-d-flex p-jc-between p-mt-3">
          <Skeleton width="4rem" height="2rem"></Skeleton>
          <Skeleton width="4rem" height="2rem"></Skeleton>
        </div>
      </div>
    </div>
  );
};

export const LoadingTwo = ({ isLoading }) => {
  return (
    <Wrapper>
      <ClipLoader
        size={80}
        sizeUnit={'px'}
        color={'#d60747'}
        loading={isLoading}
      />
    </Wrapper>
  );
};
