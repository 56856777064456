import React, { useEffect, useState } from 'react'
import { HiCurrencyDollar, HiPlus } from 'react-icons/hi'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { GetFinancialBalance, GetFinancialDeposits } from '../../../services/Financial'
import { Button, CardBox, ContainerComponent, linkRoutes } from '../../../components'
import { formatDate, formatterPrice } from '../../../utils'

import { Container } from './styles'

const FinancialHistory = () => {
  const [deposits, setDeposits] = useState([])
  const [balance, setBalance] = useState({})

  async function fetchData() {
    try {
      const { data: depositResponse } = await GetFinancialDeposits()
      const { data: balanceResponse } = await GetFinancialBalance()

      const depositsFormatted = depositResponse.data.map(item => ({
        ...item,
        depositDate: formatDate(item.DepositDate || 0),
        amount: formatterPrice(item.Amount)
      }))

      setBalance(balanceResponse.data)
      setDeposits(depositsFormatted)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  const ColumnDepositDate = value => <div className="row__datatable centralized">{value.depositDate}</div>
  const ColumnMessage = value => <div className="row__datatable">{value.Message}</div>
  const ColumnAmount = value => <div className="row__datatable">{value.amount}</div>

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      headerLeft={['Financeiro', 'Histórico financeiro']}
      title="Histórico financeiro"
      headerRight={
        <Link to={linkRoutes.bankAccountsNew}>
          <Button
            icon={HiPlus}
            customStyles={{
              borderRadius: '1.6rem',
              marginRight: '1.6rem',
              height: '4rem'
            }}
          >
            Adicionar conta
          </Button>
        </Link>
      }
    >
      <Container>
        <div className="card__container">
          <CardBox customStyles={{ borderLeft: '0.8rem solid var(--color-pink)' }}>
            <div className="card">
              <header>
                <HiCurrencyDollar />
                Recebido
              </header>

              <h2>{formatterPrice(balance.amount_received || 0)}</h2>
            </div>
          </CardBox>

          <CardBox customStyles={{ borderLeft: '0.8rem solid var(--color-pink)' }}>
            <div className="card">
              <header>
                <HiCurrencyDollar />
                Disponível
              </header>

              <h2>{formatterPrice(balance.amount_available || 0)}</h2>
            </div>
          </CardBox>

          <CardBox customStyles={{ borderLeft: '0.8rem solid var(--color-pink)' }}>
            <div className="card">
              <header>
                <HiCurrencyDollar />A receber
              </header>

              <h2>{formatterPrice(balance.amount_preview || 0)}</h2>
            </div>
          </CardBox>

          <CardBox customStyles={{ borderLeft: '0.8rem solid var(--color-pink)' }}>
            <div className="card">
              <header>
                <HiCurrencyDollar />
                Cancelado
              </header>

              <h2>{formatterPrice(balance.amount_canceled || 0)}</h2>
            </div>
          </CardBox>

          <CardBox customStyles={{ borderLeft: '0.8rem solid var(--color-pink)' }}>
            <div className="card">
              <header>
                <HiCurrencyDollar />
                Taxas
              </header>

              <h2>{formatterPrice(balance.amount_taxes || 0)}</h2>
            </div>
          </CardBox>
        </div>

        <CardBox>
          <DataTable value={deposits} dataKey="id" currentPage paginator rows={8}>
            <Column header="Valor liquido" body={ColumnAmount} field="amount" sortable />
            <Column header="Status" body={ColumnMessage} field="Message" sortable />
            <Column header="Data de deposito" body={ColumnDepositDate} field="depositDate" sortable />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { FinancialHistory }
