import Pica from 'pica'

let pica

const defaultOptions = {
  unsharpThreshold: 255,
  unsharpAmount: 100,
  unsharpRadius: 2,
  quality: 3,
  alpha: true
}

const loadFileIntoImage = file => {
  return new Promise(async (resolve, reject) => {
    const img = new Image()
    img.addEventListener('load', () => resolve(img))
    img.addEventListener('error', reject)
    img.src = URL.createObjectURL(file)
  })
}

export const resizeImage = ({ options = defaultOptions, quality = 3, maxWidth = 1000, maxHeight = 1000, file }) => {
  return new Promise(async (resolve, reject) => {
    const resizedCanvas = document.createElement('canvas')
    resizedCanvas.height = 1
    resizedCanvas.width = 1

    const image = await loadFileIntoImage(file)

    // const ratio = Math.min(maxWidth / image.width, maxHeight / image.height)
    // resizedCanvas.height = image.height * ratio
    // resizedCanvas.width = image.width * ratio
    resizedCanvas.height = image.height
    resizedCanvas.width = image.width

    if (!pica) pica = new Pica()

    await pica.resize(image, resizedCanvas, options)

    resizedCanvas.toBlob(resolve, 'image/webp', quality)
  })
}
