import React, { useEffect, useState } from 'react';
import { FormControl, Select, Checkbox, MenuItem, ListItemText } from '@material-ui/core';
import {
  HiArrowNarrowDown,
} from "react-icons/hi";
import { InputSelect, Button, ComponentModal, Input } from '../../../../components';
import { GetCategoryProducts } from '../../../../services/Category';
import { PostProduct } from '../../../../services/Product';
import { toast } from "react-toastify";
import ImportsModal from './ImportsModal';
import { PostCategory } from '../../../../services/Category';


const ImportProducts = ({ categories, allOPDVCategories, convertOPDVProduct }) => {
  const [selectedProducts, setSelectedProducts] = useState([])
  const [OPDVproductOptions, setOPDVProductOptions] = useState([])
  const [selectedFMCategory, setSelectedFMCategory] = useState(null)
  const [feedmeCategories, setFeedmeCategories] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [newCategoryModal, setNewCategoryModal] = useState(false)
  const [newCategory, setNewCategory] = useState('')



  useEffect(() => {
    const categoryProductsOptions = categories.flatMap(cat => cat.items).map((i) => ({ label: i.name, value: i }))
    const selectAll = { label: "Selecionar Todos" }
    categoryProductsOptions.unshift(selectAll)
    setOPDVProductOptions(categoryProductsOptions)
    fetchFMCategories()
  }, [categories])

  async function fetchFMCategories() {
    const { data: categories } = await GetCategoryProducts({
      include: "products",
    });
    const filteredCategories = categories.data.map((c) => ({ label: c.name, value: c.id }))
    setFeedmeCategories(filteredCategories)
  }

  function handleChange(event) {
    if (event.target.value.includes('Selecionar Todos')) {
      if (selectedProducts.length === OPDVproductOptions.length - 1) {
        setSelectedProducts([])
      } else {
        const all = OPDVproductOptions.map(c => c.label)
        const allminusone = all.shift()
        setSelectedProducts(all)
      }
    } else setSelectedProducts((prev) => {
      return event.target.value
    })
  };

  async function handleImportSelectedProducts() {
    try {
      const combinedItems = allOPDVCategories.flatMap(category => category.items);
      const rawProducts = combinedItems.filter((p) => {
        if (selectedProducts.includes(p.name) || selectedProducts.includes(p.name)) return true
      })
      const formattedProducts = rawProducts.map((p) => convertOPDVProduct(p, selectedFMCategory))
      Promise.all(formattedProducts.map(async (p) => {
        try {
          await PostProduct(p)
          toast.success("Produto importado com sucesso!")
        } catch (e) {
          if (e.message.includes("500")) toast.error("Produto já importado")
        }
      }))
      setSelectedProducts([])
      setSelectedFMCategory(null)
    } catch (e) {
      toast.error("Aconteceu um erro ao importar os produtos")
      console.log(e)
    }
    setConfirmModal(false)
  }

  async function handleCreateCategory() {
    const payload = { name: newCategory }
    try {
      await PostCategory({ payload })
      toast.success('Categoria criada')
    } catch (e) {
      console.log(e)
      toast.error('Erro ao criar categoria')
    }
    fetchFMCategories()
    setNewCategory('')
    setNewCategoryModal(false)
  }

  function handleChangeNewCategory(event) {
    setNewCategory(event.target.value)
  }

  return (
    <>
      <ImportsModal
        isOpen={confirmModal}
        setModal={setConfirmModal}
        okFunction={handleImportSelectedProducts}
        text={`Tem certeza que deseja importar ${selectedProducts} para a categoria ${feedmeCategories?.find(c => c.value === selectedFMCategory)?.label}?`}
      />
      <ComponentModal
        customStyles={{ background: "var(--color-primary-200)" }}
        onRequestClose={() => setNewCategoryModal(false)} isOpen={newCategoryModal}
      >
        <div>
          <Input
            customStyles={{ color: "white" }}
            onChange={handleChangeNewCategory}
            label="Nome da categoria"
            value={newCategory}
          />
          < Button
            onClick={() => setNewCategoryModal(false)}
            customStyles={{
              background: "var(--color-primary)",
              borderRadius: "1.6rem",
              marginRight: "1.6rem",
              height: "4rem",
            }}
          >
            CANCELAR
          </Button >

          <Button
            customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
            onClick={handleCreateCategory}
          >
            CONFIRMAR
          </Button>
        </div>
      </ComponentModal>

      <div
        style={{ marginTop: 10 }}>
        <span
          style={{ color: "var(--color-gold)", fontWeight: '300' }}
        >Produtos
        </span>

        <FormControl
          style={{ paddingTop: 10, width: '100%', marginTop: 10, backgroundColor: 'white', borderRadius: 5 }}
        >
          <Select
            multiple
            value={selectedProducts}
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
          >
            {OPDVproductOptions.map((prod) => {
              return (
                <MenuItem key={prod.value} value={prod.label} >
                  <Checkbox checked={selectedProducts.includes(prod.label)} />
                  <ListItemText primary={prod.label} />
                </MenuItem>)
            })}
          </Select>
        </FormControl>
      </div>

      <HiArrowNarrowDown
        size={20} style={{ marginTop: '2rem', marginLeft: '61rem' }} />
      <InputSelect
        customStyles={{ color: "var(--color-gold)" }}
        bgIcon="var(--color-primary)"
        options={feedmeCategories}
        name="categoryFM"
        label="Categoria FeedMe"
        onChange={(e) => setSelectedFMCategory(e.target.value)}
        value={selectedFMCategory}
      />

      {selectedProducts.length > 0 &&
        <>
          <Button
            onClick={() => setConfirmModal(true)}
            customStyles={{
              borderRadius: "1.6rem",
              height: "4rem",
              marginRight: "1.6rem",
            }}
          >
            IMPORTAR PRODUTOS
          </Button>
          <Button
            onClick={() => setNewCategoryModal(true)}
            customStyles={{
              borderRadius: "1.6rem",
              height: "4rem",
              marginRight: "1.6rem",
            }}
          >
            CRIAR NOVA CATEGORIA
          </Button>
        </>

      }
    </>
  );
};

export default ImportProducts;
