import React, { useEffect, useState } from 'react'
import { HiCheck, HiOutlinePlus, HiTrash, HiX } from 'react-icons/hi'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { Button, CheckBox, ComponentModal, ContainerComponent, Input, Switch } from '../../../components'
import { DeletePost, PatchPost, PostPost } from '../../../services/Posts'
import notImage from '../../../assets/images/notImage.png'
import { uploadImageFirebase } from '../../../utils'

import { Container } from './styles'

const PostsHandle = () => {
  const history = useHistory()

  const [imagePreview, setImagePreview] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [post, setPost] = useState({})

  function fetchData() {
    const postHistory = history?.location?.state?.post

    if (!postHistory) return

    setImagePreview(postHistory.image)
    setPost(postHistory)
    setIsEdit(true)
  }

  async function handleCreateOrUpdatePost() {
    const payload = {
      image: post.image_url || post.image,
      active: post.active || false,
      pinned: post.pinned || false,
      title: post.title,
      body: post.body
    }

    try {
      if (!isEdit) {
        await PostPost(payload)
        toast.success('Publicação criada com sucesso')
      }

      if (isEdit) {
        await PatchPost({ id: post.id, payload })
        toast.success('Publicação atualizada com sucesso')
      }

      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleDeletePost() {
    try {
      await DeletePost(post.id)

      toast.success('Publicação excluída com sucesso')
      history.goBack()
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  async function handleSelectImage(event) {
    if (!event.target.files) return

    const file = event.target.files[0]

    await uploadImageFirebase(setPost, post, file)
    const imagePreview = URL.createObjectURL(file)
    setImagePreview(imagePreview)
  }

  function handleChangeState(event) {
    const { value, name, type } = event.target

    if (type === 'checkbox') {
      setPost(prevState => ({ ...prevState, [name]: !post[name] }))
    } else {
      setPost(prevState => ({ ...prevState, [name]: value }))
    }
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      headerLeft={['Conteúdo do mural', isEdit ? 'Editar publicação' : 'Criar publicação']}
      title={isEdit ? 'Editar publicação' : 'Criar publicação'}
    >
      <ComponentModal onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <h3>{`Publicação ${isEdit ? 'editada' : 'criada'} com sucesso!`}</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <Container>
        <div className="inbox__change__image">
          <label htmlFor="select-image">
            <img src={imagePreview || notImage} alt="Imagem do milestone" />
          </label>

          <input accept=".jpeg, .jpg" onChange={handleSelectImage} id="select-image" type="file" />
        </div>

        <Input
          customStyles={{ color: 'var(--color-gold)' }}
          placeholder="Insira o título da publicação"
          onChange={handleChangeState}
          label="Título da publicação"
          value={post.title}
          name="title"
          type="text"
        />

        <div className="box__description">
          <span>Texto da publicação</span>
          <textarea value={post.body} label="Insira o texto da publicação" onChange={handleChangeState} name="body" />
        </div>

        <div className="box__options">
          <div>
            <CheckBox
              customStyles={{ marginRight: '1.6rem' }}
              onChange={handleChangeState}
              checked={!!post.pinned}
              name="pinned"
            >
              Fixar publicação no mural.
            </CheckBox>

            <Switch onClick={() => setPost(prevState => ({ ...prevState, active: !post.active }))} active={post.active}>
              {post.active ? 'Ativo' : 'Inativo'}
            </Switch>
          </div>
        </div>

        <div className="box__options">
          <Button
            customStyles={{
              background: 'var(--color-primary-500)',
              borderRadius: '1.6rem',
              margin: '0 0 0 1.6rem',
              height: '4rem'
            }}
            onClick={history.goBack}
            icon={HiX}
          >
            CANCELAR
          </Button>

          {isEdit && (
            <Button
              customStyles={{ borderRadius: '1.6rem', height: '4rem', margin: '0 0 0 1.6rem' }}
              onClick={handleDeletePost}
              icon={HiTrash}
            >
              EXCLUIR
            </Button>
          )}

          <Button
            customStyles={{ borderRadius: '1.6rem', height: '4rem', margin: '0 0 0 1.6rem' }}
            icon={isEdit ? HiCheck : HiOutlinePlus}
            onClick={handleCreateOrUpdatePost}
          >
            {isEdit ? 'Salvar' : 'CRIAR'}
          </Button>
        </div>
      </Container>
    </ContainerComponent>
  )
}

export { PostsHandle }
