import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { HiOutlineCheck, HiX } from 'react-icons/hi'
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { Button, CardBox, ComponentModal, ContainerComponent, Input } from '../../../components'
import { PostTable } from '../../../services/Tables'

import { Container } from './styles'

const schema = yup.object().shape({
  start_number: yup.number().required('Campo obrigatório.').typeError('Informe somente números.'),
  quantity: yup.number().required('Campo obrigatório.').typeError('Informe somente números.')
})

const CreateTable = () => {
  const { formState, handleSubmit, register } = useForm({ resolver: yupResolver(schema) })
  const { errors } = formState
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)

  async function handleCreateHall({ start_number, quantity }) {
    try {
      for (let index = 0; index < quantity; index++) {
        const table_number = String(start_number)
        const payload = { name: `Mesa ${table_number}`, number: '' + table_number, status: 'AVAILABLE' }
        await PostTable(payload)
        start_number++
      }

      toast.success('Mesas criadas com sucesso!')
      setOpenModal(true)
    } catch (error) {
      toast.error('Não foi possível criar novas mesas')
    }
  }

  return (
    <ContainerComponent title="Criar mesa" headerLeft={['Salão', 'Criar mesa']}>
      <ComponentModal isOpen={openModal}>
        Mesa criada com sucesso!
        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem' }}
          onClick={() => history.goBack()}
          icon={HiOutlineCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <CardBox>
        <Container onSubmit={handleSubmit(handleCreateHall)}>
          <Input
            customStyles={{ color: 'var(--color-gold)' }}
            label="Quantidade de mesas"
            register={register}
            errors={errors}
            name="quantity"
          />

          <Input
            customStyles={{ color: 'var(--color-gold)' }}
            label="Numeração a partir de"
            name="start_number"
            register={register}
            errors={errors}
          />

          <div className="box__options">
            <Button
              onClick={() => history.goBack()}
              customStyles={{
                background: 'var(--color-primary-500)',
                borderRadius: '1.6rem',
                marginRight: '1.6rem',
                height: '4rem'
              }}
              icon={HiX}
            >
              CANCELAR
            </Button>

            <Button customStyles={{ borderRadius: '1.6rem', height: '4rem' }} icon={HiOutlineCheck} type="submit">
              CRIAR MESA
            </Button>
          </div>
        </Container>
      </CardBox>
    </ContainerComponent>
  )
}

export { CreateTable }
