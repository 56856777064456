import * as yup from 'yup'
import { statesObject } from '../../../constants'

const cnpjRegex = /\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}/g
const cpfRegex = /\d{3}\.\d{3}\.\d{3}\-\d{2}/g

export const schemaRestaurantCreate = yup.object().shape({
  description: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),

  // INFO
  cnpj: yup.string().matches(cnpjRegex, 'Informe um CNPJ válido XX.XXX.XXX/XXXX-XX').required('Campo obrigatório'),
  full_description: yup.string().required('Campo obrigatório'),
  company_name: yup.string().required('Campo obrigatório'),
  instagram: yup.string().required('Campo obrigatório'),
  site: yup.string().url(),
  whatsapp: yup.string(),
  phone: yup.string(),

  // ADDRESS
  uf: yup.string().oneOf(Object.keys(statesObject), 'Informe uma UF válida').required('Campo obrigatório'),
  street_number: yup.number().required('Campo obrigatório').typeError('informe somente números'),

  neighborhood: yup.string().required('Campo obrigatório'),
  zip_code: yup.string().required('Campo obrigatório'),
  street: yup.string().required('Campo obrigatório'),
  city: yup.string().required('Campo obrigatório'),

  // RESPONSIBLE
  cpf: yup.string().matches(cpfRegex, 'Informe um CPF válido XXX.XXX.XXX-XX').required('Campo obrigatório'),
  email_admin: yup.string().email().required('Campo obrigatório'),
  name_responsible: yup.string().required('Campo obrigatório'),
  email: yup.string().email().required('Campo obrigatório'),
  phone_responsible: yup.string(),

  // FINANCIAL
  feedme_physical_tax: yup.number().required('Campo obrigatório').typeError('Informe somente números ex: 1.2'),
  feedme_digital_tax: yup.number().required('Campo obrigatório').typeError('Informe somente números ex: 1.2')
})
