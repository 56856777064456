import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .message__error {
    color: var(--color-orange);
    font-weight: 600;
    margin-top: 0.4rem;
    font-size: 1.4rem;
    display: block;
  }
`
