export default function formatterPhone (raw) {
  const numberCleaned = ('' + raw).replace(/\D/g, '')
  let match = []

  if (numberCleaned.length <= 10) {
    match = numberCleaned.match(/^(\d{1,2})?[- ]?(\d{1,4})?(\d{1,4})?(.*)?$/)
  } else {
    match = numberCleaned.match(/^(\d{1,2})?[- ]?(\d{1,5})?(\d{1,4})?(.*)?$/)
  }

  for (let i = 1; i <= 3; i++) {
    if (!match[i]) match[i] = ''
  }

  if (!match[1] || numberCleaned.length < 2) return raw
  if (match[1] && !match[2]) return `(${match[1]}) `
  if (match[2] && !match[3]) return `(${match[1]}) ${match[2]}${numberCleaned.length < 6 ? '' : '-'}`
  if (match[3]) return `(${match[1]}) ${match[2]}-${match[3]}`
}
