import React, { useState } from 'react'
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form'
import { HiChevronDown } from 'react-icons/hi'

import { Container } from './styles'

export interface ISuggestions {
  value: string;
  text: string;
}

interface IProps extends React.InputHTMLAttributes<any> {
  setState?: React.Dispatch<React.SetStateAction<any>>;
  customStyles?: React.CSSProperties;
  suggestions: ISuggestions[];
  labelColor?: string;
  label?: string;

  register?: UseFormRegister<FieldValues>;
  error?: FieldError;
}

const AutoComplete: React.FC<IProps> = ({ customStyles, suggestions, setState, register, error, label, ...rest }) => {
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [changed, setChanged] = useState(false)
  const [input, setInput] = useState()

  function onChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    const userInput = target.value

    const unLinked = suggestions.filter(
      suggestion => suggestion.text.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    )

    if (setState) setState(undefined)
    setFilteredSuggestions(unLinked)
    setActiveSuggestionIndex(0)
    setShowSuggestions(true)
    setInput(target.value)
    setChanged(true)
  }

  function onClick({ target }: any) {
    if (setState) setState(target.id)

    setActiveSuggestionIndex(0)
    setFilteredSuggestions([])
    setInput(target.innerText)
    setShowSuggestions(false)
  }

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className="box__suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className

          if (index === activeSuggestionIndex) className = 'suggestion-active'

          return (
            <li className={className} key={index} onClick={onClick} id={suggestion.value}>
              {suggestion.text}
            </li>
          )
        })}
      </ul>
    ) : (
      <div className="box__no__suggestions">Sem sugestões!</div>
    )
  }

  return (
    <Container customStyles={customStyles}>
      <span style={{ color: rest.labelColor || 'var(--color-pink)', fontSize: '1.6rem', fontWeight: 'bold' }}>
        {label}
      </span>

      <div className="box__input">
        {register && rest.name ? (
          <input
            value={changed ? input || rest.value : rest.defaultValue}
            {...register(rest.name)}
            onChange={onChange}
            {...rest}
          />
        ) : (
          <input onChange={onChange} value={changed ? input || rest.value : rest.defaultValue} {...rest} />
        )}

        <HiChevronDown color="var(--color-pink)" />

        {showSuggestions && input && <SuggestionsListComponent />}
      </div>

      <span>{!!error && error.message}</span>
    </Container>
  )
}

export { AutoComplete }
