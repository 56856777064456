export function calcVariation (past, current, fractionDigits = 0) {
    if ((past === 0 || past === null || past === undefined) && current) {
      return current
    }
    if ((current === 0 || current === null || current === undefined) && past) {
      return -past
    }
  
    if (!current && !past) {
      return 0
    }
  
    const n = (((current - past) / past)) * 100
    if (fractionDigits) {
      return Number(n.toFixed(fractionDigits))
    }
  
    return n
  }