import styled from 'styled-components'

export const Container = styled.div`
  .box__user {
    strong {
      color: var(--color-gold);
      font-weight: 400;
      font-size: 1.6rem;
    }

    div {
      display: flex;
      align-items: center;

      border-radius: 0.4rem;
      margin-top: 0.6rem;
      background: white;
      padding: 0.1rem;

      p,
      strong {
        color: black;
      }

      img {
        border-radius: 0.8rem;
        margin-right: 0.8rem;
        object-fit: cover;
        height: 3.2rem;
        width: 3.2rem;
      }

      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  .box__options {
    display: flex;
    justify-content: space-between;
    grid-column: 1/3;
  }
`
