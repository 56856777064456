export default function formatterCEP (raw) {
  const numberCleaned = ('' + raw).replace(/\D/g, '')
  const match = numberCleaned.match(/^(\d{1,5})?[- ]??[\s]?(\d{1,3})?(.*)?$/)

  for (let i = 1; i <= 2; i++) {
    if (!match[i]) match[i] = ''
  }

  if (!match[1] || numberCleaned.length < 5) return raw

  return `${match[1]}-${match[2]}`
}
