import React, { useEffect, useState } from "react";
import {
  HiArrowNarrowDown,
} from "react-icons/hi";
import data from "./mock";
import { toast } from "react-toastify";
import { PostCategory, GetCategoryProducts } from "../../../services/Category";
import { PostProduct, PatchProduct } from "../../../services/Product";
import {
  Button, CardBox,
  ContainerComponent,
  InputSelect,
} from "../../../components";
import { Container } from "./styles";
import ProductLink from "./Components/ProductLink";
import ImportProducts from "./Components/ImportProducts";
import { GetOpdvCatalog } from "../../../services/Stores";
import ImportsModal from "./Components/ImportsModal";
import { FormControl, Select, Checkbox, MenuItem, ListItemText } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core"

const Imports = () => {
  const [opdv, setOpdv] = useState(false)

  const [importByCategory, setImportByCategory] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [productSelection, setProductSelection] = useState(false)
  const [feedmeCategories, setFeedmeCategories] = useState(null)
  const [productLinking, setProductLinkin] = useState(false)
  const [allFMProducts, setAllFMProducts] = useState([])

  const [allOPDVCategories, setAllOPDVCategories] = useState(null)
  const [response, setResponse] = useState(null)

  const [modalStart, setModalStart] = useState(false)
  const [modalImportAll, setModalImportAll] = useState(false)
  const [modalImportCategories, setModalImportCategories] = useState(false)

  useEffect(() => {
    if (!allOPDVCategories && response && opdv) getAllOPDVCategoriesWithItems()
    fetchFMCategoriesAndProducts()
  }, [response])

  async function fetchFMCategoriesAndProducts() {
    const { data: categories } = await GetCategoryProducts({
      include: "products",
    });

    const allFMProducts = categories.data.flatMap(c => c.products)
    const filteredCategories = categories.data.map((c) => ({ label: c.name, value: c.id }))

    setFeedmeCategories(filteredCategories)
    setAllFMProducts(allFMProducts)
  }

  async function handleSetToOpdv() {
    setOpdv(true)
    setModalStart(false)
    try {
      // TO DO: SALVAR EM MEMO?
      const opdvCatalog = await GetOpdvCatalog('opdv')
      if (!opdvCatalog.data.data.status) setResponse(opdvCatalog.data.data)
      // setResponse(data)

    } catch (e) {
      console.log("ERRO>>>>", e.message)
      setOpdv(false)
      if (e.message.includes('429')) toast.error('Limite de chamadas alcançado. Tente novamente em alguns minutos')
      if (e.message.includes('400')) toast.error('Não há integração configurada para esta loja')
    }
  }

  async function handleSelectProducts() {
    //
    setProductSelection(true)
  }

  function goBack() {
    setImportByCategory(false)
    setProductSelection(false)
    setProductLinkin(false)
  }

  function isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  function convertOPDVCategory(category) {
    if (!isValidURL(category.image)) category.image = 'http://www.noimage.com'
    const { name, image } = category
    return {
      display: "ALL",
      name,
      available: true,
      image
    }
  }

  //TO DO: GARANTIR CODIGO UNICO PARA PRODUTO
  function generateRandomId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomId = '';

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }


  function convertOPDVProduct(product, category_id) {
    if (!isValidURL(product.image)) product.image = 'http://www.noimage.com'
    if (!product.description) product.description = '  '
    const { name, digitalName, description, image, sku, price, priceDelivery, id,
      category_name: integration_category_name,
      category_id: integration_category_id } = product

    return {
      available: true,
      name,
      external_code: generateRandomId(),
      description,
      price: price.toString().replace('.', ''),
      image_aspect_ratio: 1,
      thumbnail: image,
      image,
      category_id,
      images: [image],
      origin: "opdv",
      integration_code: id.toString(),
      integration_name: digitalName,
      integration_category_name,
      integration_category_id,
    }
  }

  let allCategories = []

  async function getAllOPDVCategoriesWithItems(categories) {
    if (!Array.isArray(categories)) categories = response
    let otherCategories = []
    if (!otherCategories.length) {
      categories.forEach(c => {
        if (!c.categories.length) {
          allCategories.push(c)
        } else {
          otherCategories.push(...c.categories)
        }
      })
    }
    if (otherCategories.length) {
      getAllOPDVCategoriesWithItems(otherCategories)
    } else {
      setAllOPDVCategories(allCategories)
    }
  }

  async function handleImportCompleteMenu(categories) {
    const otherCategories = []
    if (!Array.isArray(categories)) categories = allOPDVCategories
    try {
      categories.forEach(c => {
        if (!c.categories.length) {
          handleImportAllProducts(c, true)
        } else {
          otherCategories.push(...c.categories)
        }
      })
    } catch (e) {
      toast.error("Aconteceu um erro ao importar")
      console.log(e)
    }
    if (otherCategories.length) handleImportCompleteMenu(otherCategories)
    else {
      setModalImportAll(false)
      toast.success("Cardápio importado com sucesso!")
    }
  }

  async function handleImportAllProducts(category, menu = false) {
    try {
      const payload = convertOPDVCategory(category)
      const createCategory = await PostCategory({ payload })
      const { id } = createCategory.data.data
      const convertedProducts = category.items.map(p => convertOPDVProduct({ ...p, category_name: category.name, category_id: category.id }, id))
      try {
        Promise.all(convertedProducts.map(async (p) => await PostProduct(p)))
      } catch (e) {
        if (e.message.includes("500")) toast.error("Produto já importado")
      }
      if (!menu) toast.success("Categoria importada com sucesso!")
    } catch (e) {
      toast.error("Houve um ero ao importar a categoria.")
      console.log(e)
    }
  }

  async function handleImportSelectedCategories() {
    const categories = allOPDVCategories.filter((cat) => selectedCategories.includes(cat.name))
    categories.forEach(cat => handleImportAllProducts(cat))
    setModalImportCategories(false)
  }

  function handleChange(event) {
    const allOPDVCategoryOptions = allOPDVCategories?.map(c => ({ label: c.name, value: c }))
    if (event.target.value.includes('Selecionar Todos')) {
      if (selectedCategories.length === allOPDVCategoryOptions.length) {
        setSelectedCategories([])
      } else {
        const all = allOPDVCategoryOptions.map(c => c.label)
        setSelectedCategories(all)
      }
    } else setSelectedCategories((prev) => {
      return event.target.value
    })
  };

  return (
    <>
      <ImportsModal
        isOpen={modalStart}
        setModal={setModalStart}
        okFunction={handleSetToOpdv}
        text='Iniciar Importação?'
      />
      <ImportsModal
        isOpen={modalImportAll}
        setModal={setModalImportAll}
        okFunction={handleImportCompleteMenu}
        text='Tem certeza que deseja importar o cardápio completo?'
      />
      <ImportsModal
        isOpen={modalImportCategories}
        setModal={setModalImportCategories}
        okFunction={handleImportSelectedCategories}
        text={`Tem certeza que deseja importar ${selectedCategories.length === 1 ? `a categoria ${selectedCategories}` : `as categorias ${selectedCategories}`} ?`}
      />

      <ContainerComponent
        headerLeft={["Produtos", "Categorias", "Importar produtos"]}
        title="Importar Produtos"
      >
        <Container>
          <CardBox>
            {!opdv && <p
              style={{ fontSize: 25, marginTop: 5 }}
            >Selecione o tipo de importação:</p>}

            <header
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <div style={{ cursor: 'pointer', width: '10rem' }}
                onClick={opdv ? null : () => setModalStart(true)}
              >
                <img
                  style={{ width: '10rem', borderRadius: 15 }}
                  src={"https://cnd.opdv.com.br/wp-content/uploads/2023/02/OPDV-Logotipo-02-300x300.png"} alt="OPDV" />
              </div>
              {importByCategory && <Button
                onClick={goBack}
                customStyles={{
                  borderRadius: "1.6rem",
                  height: "4rem",
                  marginRight: "1.6rem",
                }}
              >
                VOLTAR
              </Button>}
            </header>

            {opdv &&
              <>
                {!importByCategory &&
                  <>
                    <Button
                      onClick={() => setModalImportAll(true)}
                      customStyles={{
                        borderRadius: "1.6rem",
                        height: "4rem",
                        marginRight: "1.6rem",
                      }}
                    >
                      IMPORTAR CARDÁPIO COMPLETO
                    </Button>
                    <Button
                      onClick={() => setImportByCategory(true)}
                      customStyles={{
                        borderRadius: "1.6rem",
                        height: "4rem",
                        marginRight: "1.6rem",
                      }}
                    >
                      IMPORTAR CATEGORIAS E PRODUTOS
                    </Button>
                  </>}

                {importByCategory &&
                  <div
                    style={{ marginTop: 10 }}
                  >
                    <span
                      style={{ color: "var(--color-gold)", fontWeight: '300' }}
                    >Categorias OPDV
                    </span>
                    <FormControl
                      style={{ paddingTop: 10, width: '100%', marginTop: 10, backgroundColor: 'white', borderRadius: 5 }}
                    >
                      <Select
                        multiple
                        value={selectedCategories}
                        onChange={handleChange}
                        renderValue={(selected) => selected.join(', ')}
                      >
                        {[{ label: "Selecionar Todos" }, ...allOPDVCategories?.map(c => ({ label: c.name, value: c }))].map((cat) => {
                          return (
                            <MenuItem key={cat.value} value={cat.label}>
                              <Checkbox checked={selectedCategories.includes(cat.label)} />
                              <ListItemText primary={cat.label} />
                            </MenuItem>)
                        })}
                      </Select>
                    </FormControl>

                    {selectedCategories.length > 0 && !productSelection && !productLinking &&
                      <>
                        <Button
                          onClick={() => setModalImportCategories(true)}
                          customStyles={{
                            borderRadius: "1.6rem",
                            height: "4rem",
                            marginRight: "1.6rem",
                          }}
                        >
                          IMPORTAR CATEGORIAS COMPLETAS
                        </Button>

                        <Button
                          onClick={handleSelectProducts}
                          customStyles={{
                            borderRadius: "1.6rem",
                            height: "4rem",
                            marginRight: "1.6rem",
                          }}
                        >
                          SELECIONAR PRODUTOS
                        </Button>
                        <Button
                          onClick={() => setProductLinkin(true)}
                          customStyles={{
                            borderRadius: "1.6rem",
                            height: "4rem",
                            marginRight: "1.6rem",
                          }}
                        >
                          VINCULAR PRODUTOS
                        </Button>
                      </>
                    }

                    {productSelection && feedmeCategories &&
                      <ImportProducts
                        categories={allOPDVCategories.filter((cat) => selectedCategories.includes(cat.name))}
                        allOPDVCategories={allOPDVCategories}
                        convertOPDVProduct={convertOPDVProduct}
                      />
                    }

                    {productLinking &&
                      <>
                        <ProductLink fmProducts={allFMProducts} categories={allOPDVCategories.filter((cat) => selectedCategories.includes(cat.name))} />
                      </>
                    }
                  </div>
                }
              </>
            }
          </CardBox>
        </Container>
      </ContainerComponent>
    </>
  );
};

export { Imports };
