import {
  HiQuestionMarkCircle,
  HiOutlineBookmark,
  HiCurrencyDollar,
  HiOutlineQrcode,
  HiShieldCheck,
  HiReceiptTax,
  HiChartPie,
  HiViewGrid,
  HiBell,
  HiOutlineUsers
} from 'react-icons/hi'

import { linkRoutes } from '../routes'

export const linksAdmin = [
  { title: 'Estatísticas', icon: <HiChartPie />, path: linkRoutes.statistics },
  // { title: 'Financeiro', icon: <HiCurrencyDollar />, path: linkRoutes.financial },
  { title: 'Restaurantes', icon: <HiOutlineBookmark />, path: linkRoutes.restaurants },
  { title: 'Cozinhas', icon: <HiViewGrid />, path: linkRoutes.cousines },
  { title: 'Usuários', icon: <HiOutlineUsers />, path: linkRoutes.customers },
  // { title: 'Promocodes', icon: <HiOutlineQrcode />, path: linkRoutes.promoCodes },
  // { title: 'Prg. Fidelidade', icon: <HiReceiptTax />, path: linkRoutes.loyaltyPrograms },
  { title: 'Notificações', icon: <HiBell />, path: linkRoutes.notifications },
  { title: 'Permissões', icon: <HiShieldCheck />, path: linkRoutes.admins },
  { title: 'Dúvidas', icon: <HiQuestionMarkCircle />, path: linkRoutes.fac }
]
