import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { HiCheck } from 'react-icons/hi'
import { toast } from 'react-toastify'

import { ContainerComponent, Input, CardBox, Switch, Button } from '../../../components'
import { GetOneCustomer, PatchCustomer } from '../../../services/Customers'
import { formatDate, formatterCPF, formatterPhone } from '../../../utils'
import notImage from '../../../assets/images/notImage.png'

import { Container } from './styles'

const CustomersDetails = () => {
  const history = useHistory()

  const [customer, setCustomer] = useState({ available: false })
  const [isAdmin, setIsAdmin] = useState(true)

  async function fetchData() {
    const customerHistory = history.location.state.customer
    if (customerHistory.isNotAdmin) setIsAdmin(false)

    try {
      const { data: response } = await GetOneCustomer({ id: customerHistory.id })
      setCustomer(response.data)
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar carregar as informações do usuário')
    }
  }

  async function handleUpdateCustomer() {
    const { feedhunter } = customer

    try {
      await PatchCustomer(customer.id, { feedhunter })
      toast.success('Usuário atualizado com sucesso!')
      history.goBack()
    } catch (error) {
      toast.error('Não foi possível atualizar os dados do usuário')
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setCustomer(prevState => ({ ...prevState, [name]: value }))
  }

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent headerLeft={['Usuários', 'Lista de usuários', 'Detalhes']} title="Detalhes">
      <Container>
        <CardBox
          label="Dados pessoais"
          customStyles={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: '1.6rem 3.2rem',
            marginTop: '6.4rem',
            padding: '3.2rem'
          }}
        >
          <div className="box__user">
            <strong>Usuário</strong>

            <div>
              <img alt={`foto do usuário ${customer?.fullname}`} src={customer?.profile_picture || notImage} />

              <p>
                {customer?.fullname || '---'} <strong>(@{customer?.username || '---'})</strong>
              </p>
            </div>
          </div>

          <Input
            customStyles={{ color: 'var(--color-gold)' }}
            label="Nome completo do usuário"
            value={customer?.fullname}
            disabled
          />

          <Input customStyles={{ color: 'var(--color-gold)' }} value={customer?.username} label="Username" disabled />
          <Input customStyles={{ color: 'var(--color-gold)' }} value={customer?.email} label="Email" disabled />

          <Input
            customStyles={{ color: 'var(--color-gold)' }}
            value={formatterPhone(customer?.phone)}
            label="Telefone"
            disabled
          />

          <Input
            customStyles={{ color: 'var(--color-gold)' }}
            value={formatterCPF(customer?.cpf)}
            label="CPF"
            disabled
          />

          <Input
            value={customer?.info?.gender ? (customer?.info.gender === 'F' ? 'Feminino' : 'Masculino') : '-'}
            customStyles={{ color: 'var(--color-gold)' }}
            onChange={handleChangeState}
            label="Sexo"
            disabled
          />

          <Input
            value={customer?.info?.birthdate ? formatDate(customer?.info?.birthdate) : '--/--/--'}
            customStyles={{ color: 'var(--color-gold)' }}
            onChange={handleChangeState}
            label="Aniversário"
            disabled
          />

          {isAdmin && (
            <div className="box__options">
              <Switch
                onClick={() => setCustomer(prevState => ({ ...prevState, feedhunter: !customer?.feedhunter }))}
                active={customer?.feedhunter}
              >
                {customer?.feedhunter ? 'Este usuário é um FeedHunter.' : 'Este usuário não é um FeedHunter.'}
              </Switch>

              <Button
                customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem', margin: 0 }}
                onClick={handleUpdateCustomer}
                icon={HiCheck}
              >
                ATUALIZAR
              </Button>
            </div>
          )}
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { CustomersDetails }
