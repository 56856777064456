import React, { useState } from 'react'
import { HiCheck, HiOutlineX } from 'react-icons/hi'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { Button, CardBox, ComponentModal, ContainerComponent, Input, InputCalendar } from '../../../components'
import { PostReservations } from '../../../services/Reservations'

import { Container } from './styles'

const ReserveCreate = () => {
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)
  const [reserve, setReserve] = useState({})

  async function handleCreateReserve() {
    const dateParsedToString = String(reserve.date)
    const dateSplit = dateParsedToString.split('00:00')
    const date = dateSplit.join(reserve.time)

    delete reserve.time

    try {
      await PostReservations({ ...reserve, date: new Date(date), phone: reserve.phone.replace(/\D/g, '') })
      toast.success('Reserva criada com sucesso!')
      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setReserve(prevState => ({ ...prevState, [name]: value }))
  }

  return (
    <>
      <ComponentModal onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <h3>Reserva criada com sucesso!</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <ContainerComponent title="Criar reserva" headerLeft={['Reservas', 'Criar reserva']}>
        <Container>
          <CardBox customStyles={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '3.2rem' }}>
            <Input
              customStyles={{ color: 'var(--color-gold)' }}
              placeholder="Insira o nome do usuário"
              onChange={handleChangeState}
              label="Nome do usuário"
              name="fullname"
              type="text"
            />

            <Input
              placeholder="Insira o telefone de contato do usuário"
              customStyles={{ color: 'var(--color-gold)' }}
              onChange={handleChangeState}
              label="Telefone para contato"
              name="phone"
              type="text"
            />

            <Input
              placeholder="Insira o número de pessoas"
              customStyles={{ color: 'var(--color-gold)' }}
              onChange={handleChangeState}
              label="Número de pessoas"
              name="n_persons"
              type="text"
            />

            <div className="flex">
              <InputCalendar
                customStyles={{ color: 'var(--color-gold)', marginRight: '1.6rem' }}
                onChange={handleChangeState}
                label="Data"
                name="date"
                type="text"
              />

              <Input
                customStyles={{ color: 'var(--color-gold)' }}
                onChange={handleChangeState}
                label="Horário"
                name="time"
                type="time"
              />
            </div>

            <div className="box__options">
              <Button
                onClick={history.goBack}
                icon={HiOutlineX}
                customStyles={{
                  background: 'var(--color-grey-400)',
                  borderRadius: '1.6rem',
                  marginRight: '1.6rem',
                  height: '4rem',
                  width: '14rem'
                }}
              >
                Cancelar
              </Button>

              <Button
                customStyles={{ borderRadius: '1.6rem', width: '14rem', height: '4rem' }}
                onClick={handleCreateReserve}
                icon={HiCheck}
              >
                Criar
              </Button>
            </div>
          </CardBox>
        </Container>
      </ContainerComponent>
    </>
  )
}

export { ReserveCreate }
