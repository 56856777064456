import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      light: "#D60747",
      main: "#D60747",
      dark: "#D60747",
      contrastText: "#D60747",
    },
  },
});
