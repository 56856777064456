import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 6.4rem;

  margin-bottom: 3.2rem;
  .card__container {
    h3 {
      margin: 0 0 0.4rem 0;
      font-size: 3.2rem;
      padding: 0;
    }
    h4,
    p {
      margin: 0;
    }
    .card__box {
      border: 0.1rem solid var(--color-primary-500);
      background: var(--color-primary-700);
      border-radius: 1.6rem;
      min-height: 1rem;
      padding: 1.6rem;
      position: relative;
      button {
        display: flex;
        justify-content: center;
        align-items: center;

        background: var(--color-gold);
        border-radius: 0.8rem;
        position: absolute;
        cursor: pointer;
        right: -1.5rem;
        border: none;
        height: 3rem;
        width: 3rem;
        top: 40%;
        svg {
          fill: white;
        }
        :first-child {
          left: -1.5rem;
        }
      }
    }
    .payment__status {
      display: flex;
      flex-direction: column;
      align-items: center;
      .payment__method {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        div {
          display: flex;
          align-items: center;
          span {
            margin-right: 1rem;
            display: block;
            height: 0.8rem;
            content: '';
            width: 3rem;
          }
          p {
            display: flex;
            align-items: center;
            font-weight: 400;
          }
        }
      }
    }
    .revenues__status {
      display: grid;
      grid-template-rows: 3rem 1fr;
      p {
        display: flex;
        align-items: center;
        margin: 0;
        ::before {
          background: var(--color-pink);
          margin-right: 1rem;
          display: block;
          height: 0.8rem;
          content: '';
          width: 3rem;
        }
      }
    }
    .revenues__status,
    .payment__status {
      height: 30rem;
    }
    .footer__statistics {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 8rem;
      .statistics {
        display: grid;
        grid-template-rows: 2rem 1fr;
        grid-gap: 1.6rem;
        :first-child {
          border-right: 0.1rem solid var(--color-primary-400);
          padding-right: 1.6rem;
          margin-right: 1.6rem;
        }
        h4 {
          color: var(--color-gold);
          text-align: center;
          font-size: 3.2rem;
        }
        header {
          display: flex;
          justify-content: space-between;
          div {
            display: flex;
            align-items: center;
            span {
              display: flex;
              justify-content: center;
              align-items: center;

              background: var(--color-green);
              border-radius: 0.4rem;
              margin-right: 0.8rem;
              height: 2.2rem;
              width: 2.2rem;
            }
            :first-child {
              span {
                background: var(--color-pink);
              }
            }
          }
        }
      }
    }
    :nth-child(1) {
      grid-column: 1/4;
    }
    :nth-child(2) {
      grid-column: 4/9;
    }
    :nth-child(3) {
      grid-column: 1/5;
    }
    :nth-child(4) {
      grid-column: 5/9;
    }
  }
`
