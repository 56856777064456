import React, { useEffect, useState } from 'react'
import { HiChevronLeft, HiChevronRight, HiCurrencyDollar, HiOutlineClipboardList, HiTrendingUp } from 'react-icons/hi'
import { ResponsiveContainer, AreaChart, PieChart, Tooltip, XAxis, YAxis, Cell, Area, Pie } from 'recharts'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, ContainerComponent, linkRoutes } from '../../components'
import { GetFinancial } from '../../services/Financial'
import { formatterPrice } from '../../utils'

import { Container } from './styles'

export const Financial = () => {
  const [statistics, setStatistics] = useState({})
  const [avgTicket, setAvgTicket] = useState([])
  const [incomes, setIncomes] = useState([])

  const COLORS = ['var(--color-pink)', 'var(--color-pink-600)', 'var(--color-gold)', 'white']

  async function fetchData() {
    try {
      const { data } = await GetFinancial()
      const { avg_ticket, incomes, methods, by_month } = data.data

      const statusPayment = methods.map(item => ({ name: item.label, value: item.percentage }))
      const statusByMonth = by_month.map(item => ({ name: item.label, valor: item.amount }))

      setStatistics({ statusByMonth, statusPayment, avg_ticket, incomes })
      setAvgTicket([avg_ticket[0], avg_ticket[1]])
      setIncomes([incomes[0], incomes[1]])
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function changeIncomes(option, type) {
    if (type === 'incomes') {
      if (option === 'previous') setIncomes([statistics.incomes[0], statistics.incomes[1]])
      if (option === 'next') setIncomes([statistics.incomes[1], statistics.incomes[2]])
    }

    if (type === 'avgTicket') {
      if (option === 'previous') setAvgTicket([statistics.avg_ticket[0], statistics.avg_ticket[1]])
      if (option === 'next') setAvgTicket([statistics.avg_ticket[1], statistics.avg_ticket[2]])
    }
  }

  const componentStatistic = (item, index) => (
    <div key={index} className="statistics">
      <header>
        <div>
          <span>
            <HiCurrencyDollar />
          </span>
          {item.label}
        </div>

        <div>
          <span>
            <HiTrendingUp />
          </span>
          {item.variation}%
        </div>
      </header>

      <h4>{formatterPrice(item.amount)}</h4>
    </div>
  )

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      title="Financeiro"
      headerRight={
        <Link to={linkRoutes.financialHistory}>
          <Button
            customStyles={{ borderRadius: '1.6rem', marginRight: '1.6rem', height: '4rem' }}
            icon={HiOutlineClipboardList}
          >
            Histórico
          </Button>
        </Link>
      }
    >
      <Container>
        <div className="card__container">
          <h3>Pagamentos</h3>

          <div className="card__box payment__status">
            {statistics.statusPayment && (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie data={statistics.statusPayment}>
                    {statistics.statusPayment.map((item, index) => (
                      <Cell key={index} fill={COLORS[index]} />
                    ))}
                  </Pie>

                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            )}

            <div className="payment__method">
              {statistics?.statusPayment?.map((item, index) => (
                <div key={index}>
                  <span style={{ background: COLORS[index] }} />
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="card__container">
          <h3>Faturamento acumulado</h3>

          <div className="card__box revenues__status">
            <p>Ano atual</p>

            {statistics.statusByMonth && (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={statistics.statusByMonth}>
                  <defs>
                    <linearGradient id="colorPink" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="var(--color-pink)" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="var(--color-pink)" stopOpacity={0} />
                    </linearGradient>
                  </defs>

                  <YAxis dataKey="valor" />
                  <XAxis dataKey="name" />

                  <Tooltip />

                  <Area
                    stroke="var(--color-pink)"
                    fill="url(#colorPink)"
                    fillOpacity={1}
                    dataKey="valor"
                    type="monotone"
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        <div className="card__container">
          <h3>Receita líquida</h3>

          <div className="card__box">
            <button onClick={() => changeIncomes('previous', 'incomes')}>
              <HiChevronLeft />
            </button>

            <button onClick={() => changeIncomes('next', 'incomes')}>
              <HiChevronRight />
            </button>

            <div className="footer__statistics">{incomes.map((item, index) => componentStatistic(item, index))}</div>
          </div>
        </div>

        <div className="card__container">
          <h3>Ticket médio por usuário</h3>

          <div className="card__box">
            <button onClick={() => changeIncomes('previous', 'avgTicket')}>
              <HiChevronLeft />
            </button>

            <button onClick={() => changeIncomes('next', 'avgTicket')}>
              <HiChevronRight />
            </button>

            <div className="footer__statistics">{avgTicket.map((item, index) => componentStatistic(item, index))}</div>
          </div>
        </div>
      </Container>
    </ContainerComponent>
  )
}
