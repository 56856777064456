import React, { useEffect, useState } from "react";
import {
  HiCheck,
  HiEye,
  HiEyeOff,
  HiOutlinePlus,
  HiPencil,
  HiPlus,
  HiTrash,
  HiDownload,
} from "react-icons/hi";
import { Link, useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toast } from "react-toastify";

import {
  GetCategoryProducts,
  DeletedCategory,
  PatchCategoriesReorder,
  PatchCategory,
} from "../../services/Category";
import {
  Button,
  CardBox,
  ComponentModal,
  ContainerComponent,
  linkRoutes,
} from "../../components";
import { ProductsDeleted, ProductsUpdateId } from "../../services/Product";
import { deleteFileFirebase, formatterPrice } from "../../utils";
import notImage from "../../assets/images/notImage.png";
import { GetCategories } from "../../services/Category";
import { Container } from "./styles";

const Products = () => {
  const history = useHistory();

  const handleCreateProduct = (category) =>
    history.push(linkRoutes.productsCreate, { category });
  const handleShowCategory = (category) =>
    history.push(linkRoutes.categoryUpdate, { category });
  const handleShowProduct = (product) =>
    history.push(linkRoutes.productsUpdate, { product });

  const [categoryProducts, setCategoryProducts] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [category, setCategory] = useState(null);
  const [reorder, setReorder] = useState(false);
  const [product, setProduct] = useState(null);

  async function fetchData() {
    try {
      const { data: response } = await GetCategoryProducts({
        include: "products",
      });
      const categoriesFormatted = response.data.map((item) => ({
        ...item,
        products: item.products.map((product) => ({
          ...product,
          price: formatterPrice(product.price),
        })),
        total_products: item.products.length,
      }));

      setCategoryProducts(categoriesFormatted);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleReorderCategories() {
    const payload = { ids: categoryProducts.map((item) => item.id) };

    try {
      await PatchCategoriesReorder({ payload });
      toast.success("Ordem das categorias atualizada com sucesso!");
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleChangeAvailableProduct(product) {
    const payload = { id: product.id, available: !product.available };

    try {
      await ProductsUpdateId({ id: product.id, payload });

      let newArrayCategoryProducts = categoryProducts;
      for (const category of newArrayCategoryProducts) {
        if (product.category_id === category.id) {
          for (const item of category.products) {
            if (item.id === product.id) item.available = !product.available;
          }
        }
      }

      setCategoryProducts([...newArrayCategoryProducts]);

      toast.success("Visibilidade do produto alterada com sucesso!");
    } catch (error) {
      toast.error("Error alterar visibilidade do produto");
    }
  }

  async function handleDeletedCategory() {
    try {
      await DeletedCategory({ category_id: category.id });
      console.log(category.image)
      if (category.image && category.image.includes("firebasestorage")) deleteFileFirebase(category.image);
      await fetchData();

      toast.success("Categoria excluída com sucesso");
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }

    handleCloseModal();
  }

  async function handleDeleteProduct() {
    try {
      await ProductsDeleted(product.id);
      fetchData();
      toast.success("Produto excluído com sucesso");
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }

    handleCloseModal();
  }

  async function handleChangeAvailableCategory(category) {
    const payload = { available: !category.available };

    try {
      await PatchCategory({ id: category?.id, payload });

      const newCategoryProducts = categoryProducts.map((item) => {
        if (item.id === category.id)
          return { ...item, available: !category.available };
        return item;
      });

      toast.success("Visibilidade da categoria alterada com sucesso!");
      setCategoryProducts(newCategoryProducts);
    } catch (error) {
      toast.error("Error alterar visibilidade da categoria");
    }
  }

  function handleCloseModal() {
    setOpenModal(false);
    setCategory(null);
    setProduct(null);
  }

  function handleOpenModalCategory(category) {
    setCategory(category);
    setOpenModal(true);
  }

  function handleOpenModalProduct(product) {
    setProduct(product);
    setOpenModal(true);
  }

  const ColumnCategory = (category) => (
    <div className="row__datatable">
      <img src={category.image || notImage} alt="" />
      <p>{category.name}</p>
    </div>
  );

  const ColumnQuantProduct = (category) => (
    <div className="row__datatable centralized">
      {category.total_products} Produtos
    </div>
  );

  const ColumnCategoryOptions = (category) => (
    <div className="row__datatable centralized">
      <button
        onClick={() => handleCreateProduct(category)}
        title="Criar novo produto"
      >
        <HiPlus />
      </button>

      <button onClick={() => handleShowCategory(category)} title="Editar">
        <HiPencil />
      </button>

      <button
        style={{ background: category.available ? "" : "var(--color-pink)" }}
        onClick={() => handleChangeAvailableCategory(category)}
        title={category.available ? "Visível" : "Oculto"}
      >
        {category.available ? <HiEye /> : <HiEyeOff />}
      </button>

      <button onClick={() => handleOpenModalCategory(category)} title="Excluir">
        <HiTrash />
      </button>
    </div>
  );

  const ColumnProductCode = (product) => (
    <div className="row__datatable centralized">#{product.external_code}</div>
  );
  const ColumnProductPrice = (product) => (
    <div className="row__datatable centralized">{product.price}</div>
  );

  const ColumnProduct = (category) => (
    <div className="row__datatable">
      <img src={category.thumbnail || notImage} alt="" />
      <p>{category.name}</p>
    </div>
  );

  const ColumnProductOptions = (product) => (
    <div className="row__datatable centralized">
      <button onClick={() => handleShowProduct(product)} title="Editar">
        <HiPencil />
      </button>

      <button
        style={{ background: product.available ? "" : "var(--color-pink)" }}
        onClick={() => handleChangeAvailableProduct(product)}
        title={product.available ? "Visível" : "Oculto"}
      >
        {product.available ? <HiEye /> : <HiEyeOff />}
      </button>

      <button onClick={() => handleOpenModalProduct(product)} title="Excluir">
        <HiTrash />
      </button>
    </div>
  );

  const rowExpansionCategory = (data) => (
    <DataTable value={data.products}>
      <Column header="Produto" body={ColumnProduct} />
      <Column header="Código" body={ColumnProductCode} />
      <Column header="Valor" body={ColumnProductPrice} />
      <Column header="Opções" body={ColumnProductOptions} />
    </DataTable>
  );

  useEffect(() => fetchData(), []);

  return (
    <>
      <ComponentModal onRequestClose={handleCloseModal} isOpen={openModal}>
        <p>
          Tem certeza que deseja excluir
          <strong>
            {!!category
              ? ` categoria ${category?.name}`
              : ` produto ${product?.name}`}
          </strong>
          ?
        </p>

        <div>
          <Button
            customStyles={{
              background: "var(--color-primary)",
              borderRadius: "1.6rem",
              marginRight: "1.6rem",
              height: "4rem",
            }}
            onClick={handleCloseModal}
          >
            CANCELAR
          </Button>

          <Button
            customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
            onClick={!!category ? handleDeletedCategory : handleDeleteProduct}
          >
            EXCLUIR
          </Button>
        </div>
      </ComponentModal>

      <ContainerComponent
        headerLeft={["Produtos", "Categorias"]}
        title="Categorias"
        headerRight={
          <div
          style={{ display: 'flex'}}
          >
            {reorder && (
              <Button
                customStyles={{
                  borderRadius: "1.6rem",
                  height: "4rem",
                  marginRight: "1.6rem",
                }}
                onClick={handleReorderCategories}
                icon={HiCheck}
              >
                SALVAR ORDEM
              </Button>
            )}

          
            <Link to={linkRoutes.imports}>
              <Button
                customStyles={{
                  borderRadius: "1.6rem",
                  height: "4rem",
                  marginRight: "1.6rem",
                }}
                icon={HiDownload}
              >
                IMPORTAR PRODUTOS
              </Button>
            </Link>

            <Link to={linkRoutes.productComplements}>
              <Button
                customStyles={{
                  borderRadius: "1.6rem",
                  height: "4rem",
                  marginRight: "1.6rem",
                }}
              >
                COMPLEMENTOS
              </Button>
            </Link>

            <Link to={linkRoutes.categoryCreate}>
              <Button
                customStyles={{
                  borderRadius: "1.6rem",
                  height: "4rem",
                  marginRight: "1.6rem",
                }}
                icon={HiOutlinePlus}
              >
                NOVA CATEGORIA
              </Button>
            </Link>

            <Link to={linkRoutes.productsCreate}>
              <Button
                customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
                icon={HiOutlinePlus}
              >
                NOVO PRODUTO
              </Button>
            </Link>
          </div>
        }
      >
        <Container>
          <CardBox>
            <DataTable
              onRowToggle={(event) => setExpandedRows(event.data)}
              rowExpansionTemplate={rowExpansionCategory}
              expandedRows={expandedRows}
              onRowReorder={(event) => {
                setCategoryProducts(event.value);
                setReorder(true);
              }}
              value={categoryProducts}
              dataKey="id"
            >
              <Column
                rowReorder
                style={{ width: "3.2em", paddingLeft: "1.6rem" }}
              />
              <Column expander style={{ width: "1.6em" }} />
              <Column header="Categoria" body={ColumnCategory} />
              <Column header="Produtos" body={ColumnQuantProduct} />
              <Column
                header="Ações"
                body={ColumnCategoryOptions}
                style={{ width: "30rem" }}
              />
            </DataTable>
          </CardBox>
        </Container>
      </ContainerComponent>
    </>
  );
};

export { Products };
