import styled from 'styled-components'

export const Container = styled.div`
  .filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.2rem;

    label {
      margin-right: 1.6rem;
    }
  }

  .row__datatable {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 0.8rem;
      fill: white;
    }
  }
`
