import React, { useState } from 'react'
import { HiCheck, HiChevronDown, HiTrash } from 'react-icons/hi'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import {
  ContainerComponent,
  ComponentModal,
  InputCalendar,
  InputSelect,
  Button,
  Switch,
  Input
} from '../../../components'

import { typesOrigins, typesUse } from '../../../utils/optionsSelect'
import { PostPromoCodeId } from '../../../services/PromoCodes'
import { formatterPrice } from '../../../utils/formatters'
import { validatePayload } from '../../../utils'

import { Container } from './styles'

const PromoCodesCreate = () => {
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)
  const [promoCode, setPromoCode] = useState({})

  async function handleCreatePromoCode() {
    const payload = {
      discount: promoCode?.discount?.replace(/\D/g, '').replace(/([0-9]{2}$)/g, '.$1'),
      max_discount: +promoCode?.max_discount?.replace(/\D/g, ''),
      n_available: +promoCode?.n_available,
      description: promoCode?.description,
      active: promoCode?.active || false,
      expires_at: promoCode?.expires_at,
      origin: promoCode?.origin,
      code: promoCode?.code,
      type: promoCode?.type
    }

    if (!validatePayload(payload)) return

    try {
      await PostPromoCodeId(payload)
      toast.success('Promocode criado com sucesso')
      setOpenModal(true)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setPromoCode(prevState => ({ ...prevState, [name]: value }))
  }

  return (
    <ContainerComponent title="Adicionar promocode" headerLeft={['Promocode', 'Adicionar promocode']}>
      <ComponentModal onRequestClose={() => setOpenModal(!openModal)} isOpen={openModal}>
        <h3>Promocode criada com sucesso!</h3>

        <Button
          customStyles={{ borderRadius: '1.6rem', height: '4rem', width: '20rem' }}
          onClick={() => history.goBack()}
          icon={HiCheck}
        >
          OK
        </Button>
      </ComponentModal>

      <Container>
        <Input
          customStyles={{ color: 'var(--color-gold)', gridColumn: '1 / 3' }}
          onChange={handleChangeState}
          name="description"
          label="Descrição"
          type="text"
        />

        <Input
          customStyles={{ color: 'var(--color-gold)' }}
          onChange={handleChangeState}
          label="Código"
          name="code"
          type="text"
        />

        <Input
          onChange={event =>
            setPromoCode(prevState => ({ ...prevState, max_discount: event.target.value.replace(/\D/g, '') }))
          }
          value={`${promoCode.max_discount || 0} % off`}
          customStyles={{ color: 'var(--color-gold)' }}
          name="max_discount"
          label="Desconto"
        />

        <Input
          value={formatterPrice(promoCode.discount)}
          customStyles={{ color: 'var(--color-gold)' }}
          onChange={handleChangeState}
          label="Valor Minimo"
          name="discount"
          type="text"
        />

        <InputSelect
          customStyles={{ color: 'var(--color-gold)' }}
          bgIcon="var(--color-primary)"
          onChange={handleChangeState}
          options={typesOrigins}
          icon={HiChevronDown}
          label="Origem"
          name="origin"
        />

        <Input
          onChange={event =>
            setPromoCode(prevState => ({ ...prevState, n_available: event.target.value.replace(/\D/g, '') }))
          }
          value={`${promoCode.n_available || 0} usos`}
          customStyles={{ color: 'var(--color-gold)' }}
          label="Disponibilidade"
        />

        <InputSelect
          customStyles={{ color: 'var(--color-gold)' }}
          bgIcon="var(--color-primary)"
          onChange={handleChangeState}
          icon={HiChevronDown}
          label="Tipo de uso"
          options={typesUse}
          name="type"
        />

        <InputCalendar
          customStyles={{ color: 'var(--color-gold)' }}
          onChange={handleChangeState}
          label="Expira em"
          name="expires_at"
        />

        <div className="box__options">
          <Switch
            onClick={() => setPromoCode(prevState => ({ ...prevState, active: !promoCode.active }))}
            active={promoCode.active}
          >
            {promoCode.active ? 'Ativo' : 'Desativado'}
          </Switch>

          <div>
            <Button
              onClick={history.goBack}
              icon={HiTrash}
              customStyles={{
                background: 'var(--color-grey-400)',
                borderRadius: '1.6rem',
                marginRight: '1.6rem',
                width: '14rem',
                height: '4rem'
              }}
            >
              Cancelar
            </Button>

            <Button
              customStyles={{ borderRadius: '1.6rem', width: '14rem', height: '4rem' }}
              onClick={handleCreatePromoCode}
              icon={HiCheck}
            >
              Adicionar
            </Button>
          </div>
        </div>
      </Container>
    </ContainerComponent>
  )
}

export { PromoCodesCreate }
