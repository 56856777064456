import styled from 'styled-components'

export const Container = styled.label`
  display: flex;
  flex-direction: column;

  color: var(--color-pink);
  width: 100%;

  ${({ customStyles }) => customStyles}

  span {
    margin-bottom: 0.8rem;
    font-weight: 400;
    font-size: 1.6rem;
  }

  .MuiFormControl-root {
    display: flex;
    flex-direction: row;

    height: 3.2rem;
    width: 100%;

    .MuiOutlinedInput-root {
      border-radius: 0.4rem 0 0 0.4rem;
      background: white;
      width: 100%;

      svg {
        display: none;
      }
    }

    svg {
      background-color: ${({ bgIcon }) => bgIcon || 'var(--color-pink)'};
      border-radius: 0 0.4rem 0.4rem 0;
      padding: 0.4rem;
      width: 2rem;
      height: auto;
      color: white;
    }
  }
`
