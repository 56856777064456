import styled from 'styled-components'

export const Container = styled.div`
  border: 0.1rem solid var(--color-primary-500);
  background: var(--color-primary-600);
  border-radius: 1.6rem;
  padding: 3.2rem;

  header {
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    grid-gap: 1.6rem;

    margin: 3.2rem 0;
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      background: var(--color-primary-800);
      border-radius: 1.6rem;
      list-style: none;
      height: 25rem;
      header {
        display: flex;
        align-items: center;

        background: var(--color-primary-700);
        border-radius: 1.6rem 1.6rem 0 0;
        height: 5rem;
        width: 100%;
      }
      img {
        background: var(--color-primary-700);
        border-radius: 1.6rem;
        margin-top: 1.6rem;
        height: 10rem;
        width: 10rem;
      }
      footer {
        display: flex;
        flex-direction: column;

        border-radius: 0 0 1.6rem 1.6rem;
        text-align: center;
        padding: 1.6rem 0;
        font-size: 1.6rem;
        width: 100%;
        h2 {
          color: var(--color-gold);
          font-size: 1.6rem;
          margin: 0;
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;
  }
`
