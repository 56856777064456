import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  LineChart,
  CartesianGrid,
  Line,
} from "recharts";
import { HiCurrencyDollar, HiTrendingDown, HiTrendingUp } from "react-icons/hi";

import { GetStatisticsGeneral } from "../../../services/Statistics";
import { formatDate, formatterPrice } from "../../../utils";
import { ContainerComponent } from "../../../components";

import { Container } from "./styles";

const StatisticsAdmin = () => {
  const [statistics, setStatistics] = useState(null);

  async function fetchData() {
    try {
      const { data: response } = await GetStatisticsGeneral();

      const orders = response.data.orders.map((item) => ({
        ...item,
        date: formatDate(item.day),
      }));

      const platforms = [];
      for (const item of response.data.platforms) {
        const { platform, total, label } = item;

        const itemExist = platforms.find(
          (itemFind) => itemFind.label === label
        );

        if (itemExist) {
          platforms.forEach((element) => {
            if (element.label === item.label)
              Object.assign(element, { [platform]: total });
          });
        }

        if (!itemExist) platforms.push({ label, [platform]: total });
      }

      const monthOrder = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"];
      platforms.sort((a, b) => monthOrder.indexOf(a.label) - monthOrder.indexOf(b.label));



      const grossVariationUp = response.data.financial?.gross?.variation >= 0;
      const grossLiquidUp = response.data.financial?.liquid?.variation >= 0;
      const totalUsers = response.data.totalUsers;

      setStatistics({
        ...response.data,
        grossVariationUp,
        grossLiquidUp,
        platforms,
        orders,
        totalUsers,
      });
    } catch (error) {
      console.log(error?.response?.data);
    }
  }

  useEffect(() => fetchData(), []);

  return (
    <ContainerComponent title="Estatísticas">
      <Container>
        <div className="box__container">

          <div
            className={
              statistics?.grossVariationUp
                ? "card__box amount__up"
                : "card__box amount__down"
            }
          >
            <label>Faturamento</label>
            <header>
              <span>
                <HiCurrencyDollar />
                Bruto
              </span>
              <span>
                {statistics?.grossVariationUp ? (
                  <HiTrendingUp />
                ) : (
                  <HiTrendingDown />
                )}
                {statistics?.financial?.gross?.variation}%
              </span>
            </header>
            <main>
              <h3>
                {formatterPrice(statistics?.financial?.gross?.amount || 0)}
              </h3>
              <p>Valor total sem descontos</p>
            </main>
          </div>

          <div
            className={
              statistics?.grossLiquidUp
                ? "card__box amount__up"
                : "card__box amount__down"
            }
          >
            <header>
              <span>
                <HiCurrencyDollar />
                Líquido
              </span>
              <span>
                {statistics?.grossLiquidUp ? (
                  <HiTrendingUp />
                ) : (
                  <HiTrendingDown />
                )}
                {statistics?.financial?.liquid?.variation}%
              </span>
            </header>
            <main>
              <h3>
                {formatterPrice(statistics?.financial?.liquid?.amount || 0)}
              </h3>
              <p>Valor com descontos</p>
            </main>
          </div>

          <div className="card__box amount__up">
            <label>Total de usuários</label>
            <main>
              <h3 className="total__users">{statistics?.totalUsers}</h3>
              <p>Contas criadas</p>
            </main>
          </div>
        </div>

        <div className="card__box cards__statistics__right">
          <label>Pedidos nos últimos 15 dias</label>
          {statistics?.orders && (
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={statistics.orders}>
                <defs>
                  <linearGradient id="colorPink" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="var(--color-pink)"
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor="var(--color-pink)"
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>

                <YAxis dataKey="total" />
                <XAxis dataKey="date" />

                <Tooltip />

                <Area
                  stroke="var(--color-pink)"
                  fill="url(#colorPink)"
                  fillOpacity={1}
                  dataKey="total"
                  type="monotone"
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </div>

        <div className="box__container">
          <div className="card__box cards__statistics__full">
            <label>Plataformas utilizadas</label>

            <div className="platforms__labels">
              <p>IOS</p>
              <p>Android</p>
            </div>

            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={statistics?.platforms}>
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip />

                <CartesianGrid stroke="none" />
                <Line
                  type="monotone"
                  dataKey="android"
                  stroke="var(--color-gold)"
                />
                <Line
                  type="monotone"
                  dataKey="ios"
                  stroke="var(--color-pink)"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Container>
    </ContainerComponent>
  );
};

export { StatisticsAdmin };
