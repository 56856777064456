import styled from 'styled-components'

export const Container = styled.div`
  .filter {
    display: grid;
    grid-template-columns: repeat(2, 1fr) 16rem;
    grid-gap: 1.6rem;

    margin-bottom: 3.2rem;
  }

  .row__datatable {
    display: flex;
    align-items: center;

    text-overflow: ellipsis;
    padding-left: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    height: 6.2rem;

    strong {
      margin-left: 0.8rem;
    }

    img {
      border-radius: 0.8rem;
      margin-right: 0.8rem;
      height: 4.9rem;
      width: 4.9rem;
    }

    button {
      background: var(--color-green);
      border-radius: 0.4rem;
      cursor: pointer;
      margin: 0.8rem;
      height: 3.2rem;
      width: 3.2rem;
      border: none;
      color: white;

      :last-child {
        background: var(--color-pink);
      }
    }
  }

  .centralized {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
  }
`
