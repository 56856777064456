import {
  HiOutlineDocumentText,
  HiOutlineBadgeCheck,
  HiInformationCircle,
  HiCurrencyDollar,
  HiOutlineQrcode,
  HiClipboardList,
  HiReceiptTax,
  HiCalendar,
  HiChartPie,
  HiHashtag,
  HiHome,
  HiCog,
  HiTag,
  HiOutlineCog
} from 'react-icons/hi'

import { linkRoutes } from '../routes'

export const linksMerchant = [
  { title: 'Estatísticas', icon: <HiChartPie />, path: linkRoutes.statistics },
  // { title: 'Financeiro', icon: <HiCurrencyDollar />, path: linkRoutes.financial },
  // { title: 'Pedidos', icon: <HiClipboardList />, path: linkRoutes.orders },
  { title: 'Comandas', icon: <HiClipboardList />, path: linkRoutes.commands },
  { title: 'Salão', icon: <HiHome />, path: linkRoutes.hall },
  { title: 'Reservas', icon: <HiCalendar />, path: linkRoutes.reserves },
  { title: 'Produtos', icon: <HiTag />, path: linkRoutes.products },
  // { title: 'Promocodes', icon: <HiOutlineQrcode />, path: linkRoutes.promoCodes },
  // { title: 'Prg. Fidelidade', icon: <HiReceiptTax />, path: linkRoutes.loyaltyPrograms },
  { title: 'Configurações', icon: <HiCog />, path: linkRoutes.settings },
  { title: 'Conteúdos do Mural', icon: <HiOutlineDocumentText />, path: linkRoutes.post },
  { title: 'Destaques', icon: <HiHashtag />, path: linkRoutes.feedHunters },
  { title: 'Avaliações', icon: <HiOutlineBadgeCheck />, path: linkRoutes.reviews },
  { title: 'Política de uso', icon: <HiInformationCircle />, path: linkRoutes.usagePolicies },
  { title: 'Integrações', icon: <HiOutlineCog />, path: linkRoutes.integrations },
]
