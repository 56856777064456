import React, { useEffect, useState } from 'react'
import { AiOutlineArrowDown, AiOutlineCheck } from 'react-icons/ai'
import { PanelMenu } from 'primereact/panelmenu'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { toast } from 'react-toastify'

import { ContainerComponent, ComponentModal, CardBox, InputCalendar, InputSelect, Button } from '../../components'
import { formatDate, formatterPrice } from '../../utils'
import { GetOrdersSearch } from '../../services/Order'
import { commandsStatus } from './optionsCommands'

import { CardOrder, Container } from './styles'

const Commands = () => {
  const [modal, setModal] = useState(false)
  const [orders, setOrders] = useState([])
  const [params, setParams] = useState({})
  const [order, setOrder] = useState({})

  async function fetchData() {
    try {
      const { data: response } = await GetOrdersSearch(params)

      const ordersFormatted = response.data.map(item => ({
        ...item,
        payment_status: item?.payment_status === 'PAID' ? 'PAGO' : 'NÃO PAGO',
        status: item?.status === 'OPENED' ? 'ABERTO' : 'FECHADO',
        total_paid: formatterPrice(item?.total_paid),
        created_at: formatDate(item?.created_at)
      }))

      setOrders(ordersFormatted)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleShowOrder(event) {
    setOrder(event?.data)
    setModal(true)
  }

  function handleChangeState(event) {
    const { value, name } = event.target
    setParams(prevState => ({ ...prevState, [name]: value }))
  }

  const ColumnPaymentStatus = rowData => <div className="row__datatable centralized">{rowData?.payment_status}</div>
  const ColumnTickets = rowData => <div className="row__datatable centralized">{rowData?.tickets?.length}</div>
  const ColumnTotalPaid = rowData => <div className="row__datatable centralized">{rowData?.total_paid}</div>
  const ColumnDiscount = rowData => <div className="row__datatable centralized">{rowData?.discount} %</div>
  const ColumnTable = rowData => <div className="row__datatable centralized">{rowData?.table.number}</div>
  const ColumnDate = rowData => <div className="row__datatable centralized">{rowData?.created_at}</div>
  const ColumnStatus = rowData => <div className="row__datatable centralized">{rowData?.status}</div>

  useEffect(() => fetchData(), [])

  return (
    <>
      <ComponentModal customStyles={{ background: '#414a55' }} onRequestClose={() => setModal(false)} isOpen={modal}>
        <CardOrder>
          <header>
            <h3>
              Comanda da mesa: <strong>{order?.table?.number}</strong>
            </h3>
          </header>

          <main>
            {order?.tickets?.map(ticket => (
              <div key={ticket.id} className="container__customer">
                <div className="card__customer">
                  <img src={ticket?.customer?.profile_picture} alt="" />

                  <div className="card__customer__infos">
                    <div>
                      <h4>{ticket?.customer?.fullname}</h4>
                      <h5>@{ticket?.customer?.username}</h5>
                    </div>

                    <p className="customer__customer">{formatterPrice(ticket?.subtotal)}</p>
                  </div>
                </div>

                <div className="customer__tickets">
                  <PanelMenu
                    model={[
                      {
                        label: 'Items pedidos',
                        items: ticket?.items.map(item => ({
                          label: `${item?.name} - ${formatterPrice(item?.product_price)}`
                        }))
                      }
                    ]}
                    style={{ width: '22rem' }}
                  />
                </div>
              </div>
            ))}
          </main>

          <footer>
            <p>
              Total pago: <strong>{formatterPrice(order?.total_paid)}</strong>
            </p>
          </footer>
        </CardOrder>
      </ComponentModal>

      <ContainerComponent title="Comandas">
        <Container>
          <div className="filter">
            <InputCalendar onChange={handleChangeState} label="Data de inicio" name="start_at" />
            <InputCalendar onChange={handleChangeState} label="Data final" name="end_at" />

            <InputSelect
              onChange={handleChangeState}
              icon={AiOutlineArrowDown}
              options={commandsStatus}
              label="Status"
              name="status"
            />

            <Button iconRight={AiOutlineCheck} onClick={fetchData}>
              Aplicar
            </Button>
          </div>

          <CardBox>
            <DataTable onRowClick={handleShowOrder} value={orders} autoLayout>
              <Column body={ColumnTable} header="Mesa" field="table.number" sortable />
              <Column body={ColumnTickets} header="Usuários" sortable/>
              <Column body={ColumnStatus} header="Status" field="status" sortable />
              <Column body={ColumnPaymentStatus} header="Status do pagamento" field="payment_status" sortable />
              <Column body={ColumnDiscount} header="Desconto" field="discount" sortable />
              <Column body={ColumnTotalPaid} header="Total pago" field="total_paid" sortable />
              <Column body={ColumnDate} header="Data" field="created_at" sortable />
            </DataTable>
          </CardBox>
        </Container>
      </ContainerComponent>
    </>
  )
}

export { Commands }
