import React from 'react'
import { HiCheck } from 'react-icons/hi'

import { Container } from './style'

const CheckBox = ({ id, name, checked, children, onChange, className, customStyles, ...props }) => {
  return (
    <Container onChange={onChange} checked={checked} className={className} customStyles={customStyles}>
      <div className="box">
        <input onChange={onChange} type="checkbox" name={name} {...props} />

        {checked && (
          <span className="check">
            <HiCheck />
          </span>
        )}
      </div>

      <label>{children}</label>
    </Container>
  )
}

export { CheckBox }
