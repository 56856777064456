export const gender = [
  { name: 'feedhunter', value: undefined, label: 'Todos' },
  { name: 'gender', value: 'M', label: 'Masculino' },
  { name: 'gender', value: 'F', label: 'Feminino' },
  { name: 'gender', value: 'O', label: 'Outro' }
]

export const feedhunter = [
  { name: 'feedhunter', value: undefined, label: 'Todos' },
  { name: 'feedhunter', value: true, label: 'Sim' },
  { name: 'feedhunter', value: false, label: 'Não' }
]
