import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 40rem;
  grid-gap: 6.4rem;

  margin-top: 6.4rem;

  .platforms__labels {
    display: flex;
    p {
      display: flex;
      align-items: center;

      margin: 0 3.2rem 0 0;
      font-weight: 400;
      ::before {
        background: var(--color-pink);
        margin-right: 1rem;
        display: block;
        height: 0.8rem;
        content: "";
        width: 3rem;
      }
      :nth-child(2n) {
        ::before {
          background: var(--color-gold);
        }
      }
    }
  }

  .box__header {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.6rem;

    margin-top: 3.2rem;
    position: relative;

    label {
      position: absolute;
      font-weight: 400;
      font-size: 2.2rem;
      top: -6.4rem;
      left: 0;
    }

    .cards {
      display: flex;
      flex-direction: column;
      align-items: center;

      header {
        display: flex;
        align-items: center;

        width: 100%;

        svg {
          background: var(--color-pink);
          border-radius: 0.4rem;
          margin-right: 0.8rem;
          padding: 0.4rem;
          height: 1.6rem;
          width: 1.6rem;
        }
      }

      h2 {
        color: var(--color-gold);
        font-size: 3.2rem;
        margin: 1.6rem 0;
      }
    }
  }
`;
