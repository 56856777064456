export const ageOptions = [
  { value: 18, text: '18' },
  { value: 19, text: '19' },
  { value: 20, text: '20' },
  { value: 21, text: '21' },
  { value: 22, text: '22' },
  { value: 23, text: '23' },
  { value: 24, text: '24' },
  { value: 25, text: '25' },
  { value: 26, text: '26' },
  { value: 27, text: '27' },
  { value: 28, text: '28' },
  { value: 29, text: '29' },
  { value: 30, text: '30' },
  { value: 31, text: '31' },
  { value: 32, text: '32' },
  { value: 33, text: '33' },
  { value: 34, text: '34' },
  { value: 35, text: '35' },
  { value: 36, text: '36' },
  { value: 37, text: '37' },
  { value: 38, text: '38' },
  { value: 39, text: '39' },
  { value: 40, text: '40' },
  { value: 41, text: '41' },
  { value: 42, text: '42' },
  { value: 43, text: '43' },
  { value: 44, text: '44' },
  { value: 45, text: '45' },
  { value: 46, text: '46' },
  { value: 47, text: '47' },
  { value: 48, text: '48' },
  { value: 49, text: '49' },
  { value: 50, text: '50' }
]

export const gendersOptions = [
  { name: 'genders', value: ['M', 'F'], label: 'Todos' },
  { name: 'genders', value: ['M'], label: 'Masculino' },
  { name: 'genders', value: ['F'], label: 'Feminino' }
]
