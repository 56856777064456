import React from 'react'
import { motion } from 'framer-motion'

import { Container } from './styles'

const Switch = ({ children, active, ...props }) => {
  return (
    <Container active={active} {...props}>
      <div>
        <motion.div
          className="switch__span"
          layout
          transition={{
            stiffness: 700,
            type: 'spring',
            damping: 30
          }}
        />
      </div>

      <p>{children}</p>
    </Container>
  )
}

export default Switch
