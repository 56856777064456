import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-gap: 1.6rem;

  border: 0.1rem solid var(--color-primary-500);
  background: var(--color-primary-600);
  border-radius: 1.6rem;
  padding: 3.2rem;

  .inbox__change__image {
    img {
      background: var(--color-primary-900);
      border-radius: 1.6rem;
      cursor: pointer;
      height: 100%;
      width: 100%;
    }

    input {
      display: none;
    }
  }

  .box__description {
    display: flex;
    flex-direction: column;

    span {
      color: var(--color-gold);
      margin-bottom: 0.8rem;
      font-weight: 400;
    }

    textarea {
      border-radius: 0.4rem;
      padding: 0.8rem;
      height: 100%;
    }
  }

  .box__options {
    display: flex;
    justify-content: flex-end;
  }

  .box__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1.6rem;
  }

  .filepond--item {
    height: 25.6rem;
    width: 25.6rem;
  }
`

export const ModalContainer = styled.div`
  .box__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .change__icons {
    text-align: left;
    color: white;

    h2,
    h3 {
      border-bottom: 0.1rem solid var(--color-grey);
      padding-bottom: 1.6rem;
      margin: 0 0 1.6rem 0;
      width: 100%;
    }

    h4 {
      color: var(--color-gold);
      font-size: 1.6rem;
      width: 100%;
      margin: 0;
    }

    .box__icons {
      display: flex;
      justify-content: space-between;

      background: var(--color-grey-500);
      border-radius: 1.6rem;
      margin: 1.6rem 0;
      padding: 1.6rem;
      width: 100%;

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;

        max-width: 10rem;
        margin: 0 1.6rem;
        width: 100%;
      }
    }
  }
`
