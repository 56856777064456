import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { HiPlus } from 'react-icons/hi'
import { toast } from 'react-toastify'

import { Button, CardBox, ContainerComponent, linkRoutes } from '../../components'
import { GetAllCousines } from '../../services/Cousines'
import { formatDate } from '../../utils'

import { Container } from './styles'

const Cousines = () => {
  const history = useHistory()

  const [cousines, setCousines] = useState([])

  async function fetchData() {
    try {
      const { data: response } = await GetAllCousines()

      const cousinesFormatted = response.data.map(item => ({
        ...item,
        style: item.available ? 'span-active' : 'span-inactive',
        status: item.available ? 'Ativo' : 'Inativo',
        created_at: formatDate(item.created_at)
      }))

      setCousines(cousinesFormatted)
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  function handleShowCousine(event) {
    const cousine = event.data
    history.push(linkRoutes.cousinesDetails, { cousine })
  }

  const ColumnStores = value => <div className="row__datatable centralized">{value.n_stores}</div>
  const ColumnDate = value => <div className="row__datatable centralized">{value.created_at}</div>

  const ColumnStatus = value => (
    <div className="row__datatable centralized">
      <span className={value.style}>{value.status}</span>
    </div>
  )

  const ColumnName = value => (
    <div className="row__datatable">
      <strong>{value.name}</strong>
    </div>
  )

  useEffect(() => fetchData(), [])

  return (
    <ContainerComponent
      title="Cozinhas"
      headerRight={
        <Link to={linkRoutes.cousinesNew}>
          <Button icon={HiPlus} customStyles={{ borderRadius: '1.6rem', height: '4rem' }}>
            NOVA COZINHA
          </Button>
        </Link>
      }
    >
      <Container>
        <CardBox>
          <DataTable onRowClick={handleShowCousine} value={cousines} dataKey="id">
            <Column header="Cozinha" body={ColumnName} field="name" sortable />
            <Column header="Quantidade de restaurantes" body={ColumnStores} field="n_stores" sortable />
            <Column header="Status" body={ColumnStatus} field="available" sortable />
            <Column header="Data de criação" body={ColumnDate} field="created_at" sortable />
          </DataTable>
        </CardBox>
      </Container>
    </ContainerComponent>
  )
}

export { Cousines }
