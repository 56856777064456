import React, { useEffect, useState } from "react";
import { HiChevronDown, HiX } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ContainerComponent,
  ComponentModal,
  AutoComplete,
  InputSelect,
  Button,
  Switch,
} from "../../../../components";
import {
  CreateComplementsGroup,
  GetProductName,
  PatchComplementGroup,
} from "../../../../services/Product";
import { optionsComplementsValues } from "./optionsComplements";
import { Input } from "../../../../components";

import { BoxOptions, BoxWrapperSelectedProducts, Container } from "./styles";

const ComplementsGroupHandle = () => {
  const history = useHistory();

  const [group, setGroup] = useState({});
  const [groupId, setGroupId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState(null);

  async function fetchData() {
    const complementHistory = history?.location?.state?.complement;

    if (!!complementHistory) {
      const { name, min_items, max_items, allow_repeat, id } =
        complementHistory;
      setGroupId(id);
      setGroup({ name, min_items, max_items, allow_repeat });
      setMin(min_items);
      setMax(max_items);
      const historyProducts = complementHistory["products"].map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      });
      setSelectedProducts(historyProducts);
      setIsEdit(true);
    }
    try {
      const { data } = await GetProductName();

      const newComplements = data.data.map(({ id, name }) => ({
        text: name,
        value: id,
      }));
      setProducts(newComplements);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  async function handleCreateOrUpdateGroup() {
    const products = selectedProducts.map((item) => item.value);
    Object.assign(group, { products });
    setGroup((prevState) => ({ ...prevState, min_items: min, max_items: max }));

    try {
      if (isEdit) await PatchComplementGroup({ id: groupId, payload: group });
      if (!isEdit) await CreateComplementsGroup(group);
      toast.success(
        `Grupo de complementos ${isEdit ? "atualizado" : "criado"} com sucesso`
      );
      setOpenModal(true);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  }

  function handleSelectProduct(product_id) {
    if (!product_id) return;

    const product = products.find((item) => item.value === product_id);

    if (selectedProducts.includes(product)) {
      toast.error("Produto já selecionado");
      return;
    }

    setSelectedProducts([...selectedProducts, product]);
  }

  function handleDeleteSelectedProducts(product) {
    const array = [...selectedProducts];

    const arrayFiltered = array.filter((item) => item !== product);
    setSelectedProducts(arrayFiltered);
  }

  function handleChangeState(event) {
    const { value, name } = event.target;
    setGroup((prevState) => ({ ...prevState, [name]: value }));
  }

  useEffect(() => fetchData(), []);

  return (
    <>
      <ComponentModal
        onRequestClose={() => setOpenModal(false)}
        isOpen={openModal}
      >
        <h3>{`Grupo de complementos ${
          isEdit ? "editado" : "criado"
        } com sucesso!`}</h3>

        <Button onClick={history.goBack}>OK</Button>
      </ComponentModal>

      <ContainerComponent
        headerLeft={[
          "Complementos",
          isEdit
            ? "Editar grupo de complementos"
            : "Criar grupo de complementos",
        ]}
        title={isEdit ? "Editar grupo de complementos" : "Criar grupo de complementos"}
      >
        <Container>
          <Input
            customStyles={{ color: "var(--color-gold)" }}
            placeholder="Insira o nome do grupo"
            onChange={handleChangeState}
            label="Nome do grupo"
            value={group?.name}
            name="name"
          />

          <InputSelect
            customStyles={{ color: "var(--color-gold)" }}
            options={optionsComplementsValues}
            bgIcon="var(--color-primary)"
            onChange={(event) => setMin(event.target.value)}
            value={min}
            icon={HiChevronDown}
            name="min_items"
            label="Minimo"
          />

          <InputSelect
            customStyles={{ color: "var(--color-gold)" }}
            options={optionsComplementsValues}
            bgIcon="var(--color-primary)"
            onChange={handleChangeState}
            value={max}
            icon={HiChevronDown}
            name="max_items"
            label="Máximo"
          />

          <BoxWrapperSelectedProducts>
            <AutoComplete
              label="Selecione o complemento"
              labelColor="var(--color-gold)"
              setState={handleSelectProduct}
              placeholder="Complementos"
              suggestions={products}
            />

            <div className="content__selected__products">
              {selectedProducts &&
                selectedProducts?.map((item) => (
                  <div className="content__product" key={item.value}>
                    <div className="item">
                      <span>{item?.text}</span>

                      <HiX
                        className="close"
                        onClick={() => handleDeleteSelectedProducts(item)}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </BoxWrapperSelectedProducts>

          <BoxOptions>
            <Switch
              onClick={() =>
                setGroup({ ...group, allow_repeat: !group?.allow_repeat })
              }
              active={group?.allow_repeat}
            >
              {group?.allow_repeat ? "Repetir a opção" : "Não repetir a opção"}
            </Switch>

            <div>
              <Button
                onClick={history.goBack}
                customStyles={{
                  background: "var(--color-grey-400)",
                  borderRadius: "1.6rem",
                  marginRight: "1.6rem",
                  height: "4rem",
                }}
              >
                Cancelar
              </Button>

              <Button
                customStyles={{ borderRadius: "1.6rem", height: "4rem" }}
                onClick={handleCreateOrUpdateGroup}
              >
                {isEdit ? 'Salvar' : 'Adicionar'}
              </Button>
            </div>
          </BoxOptions>
        </Container>
      </ContainerComponent>
    </>
  );
};

export { ComplementsGroupHandle };
