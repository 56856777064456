import styled from 'styled-components'

export const Container = styled.label`
  display: flex;
  align-items: center;

  cursor: pointer;
  flex-shrink: 0;

  ${({ customStyles }) => customStyles}

  .box {
    background-color: ${({ checked }) => (!checked ? 'white' : 'var(--color-pink)')};
    border-radius: 0.2rem;
    position: relative;
    line-height: 1;
    flex-shrink: 0;
    height: 1.6rem;
    width: 1.6rem;
    input {
      visibility: hidden;
      position: absolute;
    }
    .check {
      font-size: 2.6rem;
      color: white;
      svg {
        position: absolute;
        left: -0.2rem;
        height: 2rem;
        top: -0.2rem;
        width: 2rem;
      }
    }
  }
  label {
    margin-left: 0.8rem;
    font-weight: 400;
    font-size: 1.2rem;
  }
`
