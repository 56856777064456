import styled from 'styled-components'

export const Container = styled.div`
  .box__category {
    display: grid;
    grid-template-columns: 25.6rem 1fr;
    grid-gap: 3.2rem;

    margin: 1.6rem;
  }

  .row__datatable {
    display: flex;
    align-items: center;

    padding-left: 1.6rem;
    height: 4.9rem;

    img {
      background: var(--color-primary-900);
      border-radius: 1rem;
      margin-right: 1rem;
      object-fit: cover;
      height: 4rem;
      width: 4rem;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--color-primary-700);
      border-radius: 0.8rem;
      margin-left: 1.6rem;
      cursor: pointer;
      border: none;
      height: 3rem;
      width: 3rem;

      :first-child {
        margin: 0;
      }

      :hover {
        background: var(--color-pink);
      }

      svg {
        color: white;
      }
    }
  }

  .centralized {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box__options {
    display: flex;
    justify-content: flex-end;
  }
`
