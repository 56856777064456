import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-rows: 16rem auto;
  grid-gap: 3.2rem;

  margin-top: 12.8rem;

  .box__header {
    position: relative;

    label {
      position: absolute;
      font-weight: 400;
      font-size: 2.2rem;
      top: -6.4rem;
      left: 0;
    }

    .box__header__cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1.6rem;

      .cards {
        display: flex;
        flex-direction: column;
        align-items: center;

        header {
          display: flex;
          align-items: center;

          width: 100%;

          svg {
            background: var(--color-pink);
            border-radius: 0.4rem;
            margin-right: 0.8rem;
            padding: 0.4rem;
            height: 1.6rem;
            width: 1.6rem;
          }
        }

        h2 {
          color: var(--color-gold);
          font-size: 3.2rem;
          margin: 1.6rem 0;
        }
      }
    }
  }

  .cards__restaurants {
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;

      padding: 0.8rem;

      .restaurant__position {
        display: flex;
        justify-content: center;
        align-items: center;

        background: var(--color-pink);
        border-radius: 0.4rem;
        font-size: 1rem;
        padding: 0.2rem;
        height: 1.6rem;
        width: 1.6rem;
      }

      .restaurant__image {
        display: flex;
        align-items: center;

        width: 100%;

        img {
          border-radius: 0.8rem;
          margin: 0 1.6rem;
          height: 4.2rem;
          width: 4.2rem;
        }
      }

      p {
        max-width: 10rem;
        text-align: end;
        width: 100%;
      }

      :nth-child(even) {
        background: var(--color-primary-800);
      }

      :nth-child(-n + 3) {
        .restaurant__position {
          background: var(--color-gold);
        }
      }
    }
  }
`
