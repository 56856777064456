import Client from '../client'

const route = '/api/products/'

const GetListProducts = ({ page, page_size, search, category_id }) =>
  Client.apiYounner.get(route + 'search', { params: { category_id, search, page_size, page } })

const GetSimpleListProducts = store_id => Client.apiYounner.get(route + 'simplelist', { params: { store_id } })
const PatchProductReorder = ({ id, payload }) => Client.apiYounner.patch(route + 'reorder', payload)
const ProductsUpdateId = ({ id, payload }) => Client.apiYounner.patch(route + `id/${id}`, payload)
const postSortProduct = payload => Client.apiYounner.post(route + 'sort', { products: payload })
const PatchProduct = ({ id, payload }) => Client.apiYounner.patch(route + `id/${id}`, payload)
const ProductsDeleted = id => Client.apiYounner.delete(route + `id/${id}`)
const DeleteProduct = id => Client.apiYounner.delete(route + `id/${id}`)
const PostProduct = payload => Client.apiYounner.post(route, payload)
const GetProductName = () => Client.apiYounner.get(route + 'names')
const GetProduct = id => Client.apiYounner.get(route + `id/${id}`)
const GetCategoryProducts = ({ include }) => Client.apiYounner.get(`/api/categories`, { params: { include } })
const CreateComplementsGroup = payload => Client.apiYounner.post(`/api/complement-groups`, payload)
const PatchComplementGroup = ({id, payload}) => Client.apiYounner.patch(`/api/complement-groups/id/${id}`, payload)
const DeleteProdFromComplementGroup = ({group_id, prod_id}) => Client.apiYounner.delete(`api/complement-groups/id/${group_id}/products/${prod_id}`)
const getCategoryId = () => Client.apiYounner.get(`/api/categories?include=products`)
const GetComplementGroup = () => Client.apiYounner.get(`/api/complement-groups`)
const DeleteComplementsGroup = complementGroup_id =>
  Client.apiYounner.delete(`/api/complement-groups/id/${complementGroup_id}`)

export {
  DeleteProdFromComplementGroup,
  DeleteComplementsGroup,
  CreateComplementsGroup,
  GetSimpleListProducts,
  GetCategoryProducts,
  PatchProductReorder,
  PatchComplementGroup,
  GetComplementGroup,
  ProductsUpdateId,
  postSortProduct,
  ProductsDeleted,
  GetListProducts,
  GetProductName,
  DeleteProduct,
  getCategoryId,
  PatchProduct,
  PostProduct,
  GetProduct
}
